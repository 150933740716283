/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import get from 'lodash/fp/get.js';
import reduce from 'lodash/fp/reduce.js';
import isNull from 'lodash/fp/isNull.js';
import isUndefined from 'lodash/fp/isUndefined.js';
import { pipe, keys } from 'ramda';
import { initializeModelFormFields } from '#actions/models/modelForm.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { selectModelFormInputs, selectModelHasOutputData, selectModelStatus } from '#selectors/entities/models.js';
import getComponent from './withFormInitialization.jsx';

const reduceFormInputs = (field, obj) => reduce((acc, curr) => {
  const prop = get(`${curr}.${field}`, obj);
  return isNull(prop) || isUndefined(prop) ? acc : { ...acc, [curr]: prop };
}, {}, keys(obj));

const getContainer = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { modelType } = ownProps;
    const { selectAutofilledValues, selectFormInitialized } = modelTypesById[modelType];
    return ({
      modelFormInputs: selectModelFormInputs(state, ownProps),
      autofilledValues: selectAutofilledValues(state, ownProps),
      initialized: selectFormInitialized(state),
      modelHasOutputData: selectModelHasOutputData(state, ownProps),
      modelStatus: selectModelStatus(state, ownProps),
      ...ownProps,
    });
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    initialize: (formInputs, autofilledValues) => {
      const values = {
        ...autofilledValues,
        ...reduceFormInputs('value', formInputs),
      };
      const visibleFields = reduceFormInputs('visible', formInputs);
      const autofilled = reduceFormInputs('autofilled', formInputs);
      dispatch(initializeModelFormFields(
        ownProps.form,
        values,
        visibleFields,
        autofilled,
      ));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(C);
};

export default pipe(
  getComponent,
  getContainer
);
