/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { combineReducers } from 'redux';
import filters from './filters/index.js';
import sorted from './sorted/index.js';

export default combineReducers({
  filters,
  sorted,
});
