/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectStructuralResponseReturnPeriodsByEdpKey,
  selectStructuralResponseDataForDirectionWithLocationLabelsByLocation,
} from '#selectors/entities/models.js';
import StructuralResponseTable from './StructuralResponseTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId, edpKey, direction } = ownProps;

  const responseDataByLocation = (
    selectStructuralResponseDataForDirectionWithLocationLabelsByLocation(
      state,
      { modelId, edpKey, direction }
    )
  );

  const returnPeriods = selectStructuralResponseReturnPeriodsByEdpKey(
    state,
    { modelId, edpKey, direction }
  );

  return {
    returnPeriods,
    responseDataByLocation,
  };
};

export default connect(mapStateToProps)(StructuralResponseTable);
