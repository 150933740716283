/**
 * Owner: Haselton Baker Risk Group, LLC
  * Date: 11/28/22
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import TermsOfServiceForm from './TermsOfServiceForm/index.js';

type Props = {|
  markdownContent: string,
  initializeTerms: Function,
|};

const TermsOfService = (props: Props): Element<typeof Modal> => {
  const { markdownContent, initializeTerms } = props;
  useEffect(initializeTerms, []);
  return (
    <Modal
      className="terms-of-service-modal"
      isOpen
      backdrop
      centered
      fullscreen="lg"
      size="lg"
      scrollable
    >
      <ModalHeader>Terms and Conditions</ModalHeader>
      <ModalBody>
        <ReactMarkdown>
          {markdownContent}
        </ReactMarkdown>
        <TermsOfServiceForm />
      </ModalBody>
      <ModalFooter>
        &nbsp;
      </ModalFooter>
    </Modal>
  );
};
export default TermsOfService;
