import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import Row
  from '#components/pages/Components/support/tables/ConsequencePropertyRowTable/Row/Row.jsx';
import {
  selectSomeConsequenceHasVisibleInstancesOfFieldSchema as formModeSelector,
} from '#selectors/ui/form/component.js';

import {
  someConsequenceHasVisibleInstancesOfFieldSchemaUuid as entityModeSelector,
} from '#selectors/entities/components.js';

const mapStateToProps = (state, ownProps) => {
  const { fieldSchema, entityMode, component } = ownProps;
  const visible = entityMode
    ? entityModeSelector(state, { fieldSchema, [UUID]: component[UUID] })
    : formModeSelector(state, { fieldSchema });
  return {
    visible,
  };
};

export default connect(mapStateToProps)(Row);
