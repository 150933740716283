/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow

import type { Element } from 'react';

import React from 'react';
import {
  Col, Form, FormGroup, Label,
} from 'reactstrap';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { MODEL_TYPE_1_ID } from '#src/js/constants/models/modelTypes/index.js';
import StatePicker from './StatePicker/index.jsx';
import ProjectSelector from './ProjectSelector/index.js';
import CountrySelectMenu from './CountrySelectMenu/index.jsx';

const buildStateLabel = (country) => {
  if (country === 'US') {
    return 'State';
  }
  if (country === 'CA') {
    return 'Province / Territory';
  }
  return 'State / Province / Region';
};

const buildZipLabel = (country) => {
  if (country === 'US') {
    return 'Zip Code';
  }
  if (country === 'CA') {
    return 'Postal Code';
  }
  return 'Zip Code';
};

type Props = {|
  form: string,
  heading: string,
  onNext: Function,
  modelType: number,
  country: string,
|};

const ModelAndSiteInfoSection = ({
  form, heading, onNext, modelType, country,
}: Props): Element<'div'> => {
  const onlyUS = modelType === MODEL_TYPE_1_ID;
  const stateLabel = buildStateLabel(country);
  const zipLabel = buildZipLabel(country);

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        <FormGroup tag="fieldset">
          <legend>General Information</legend>
          <ProjectSelector form={form} />
          <FormGroup row>
            <Col lg="9">
              <Label htmlFor="modelName">
                Model
              </Label>
              <Field
                form={form}
                id="modelName"
                name="modelName"
                type="text"
                component={RenderInput}
              />
              <FieldFeedback name="modelName" form={form} />
            </Col>
          </FormGroup>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>
            Site Address
            {' '}
            <Tip tipId="siteAddress" />
          </legend>
          <FormGroup row>
            <Col lg="4">
              <Label htmlFor="country">
                Country
              </Label>
              <CountrySelectMenu form={form} onlyUS={onlyUS} />
              <FieldFeedback name="country" form={form} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg="4">
              <Label htmlFor="street">
                Street
              </Label>
              <Field
                form={form}
                id="street"
                name="street"
                type="text"
                component={RenderInput}
              />
              <FieldFeedback name="street" form={form} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg="4">
              <Label htmlFor="city">
                City
              </Label>
              <Field
                form={form}
                id="city"
                name="city"
                type="text"
                component={RenderInput}
              />
              <FieldFeedback name="city" form={form} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg="4">
              <Label htmlFor="state">
                {stateLabel}
              </Label>
              <StatePicker country={country} form={form} />
              <FieldFeedback name="state" form={form} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg="4">
              <Label htmlFor="zip">
                {zipLabel}
              </Label>
              <Field
                form={form}
                id="zip"
                name="zip"
                type="text"
                component={RenderInput}
              />
              <FieldFeedback name="zip" form={form} />
            </Col>
          </FormGroup>
        </FormGroup>
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default ModelAndSiteInfoSection;
