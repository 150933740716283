// @flow
import type { Element } from 'react';

import React, { useState, Fragment } from 'react';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import type { Component } from '#types/Component/index.js';

import ComponentSummaryTop
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryTop/index.jsx';
import ComponentSummaryBottom
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/index.jsx';
import { COMPONENTS, GENERAL, MODELS } from '#constants/pathStrings.js';

type Props = {|
  component: Component,
|};

const makeHandleClick = (url) => () => {
  window.open(
    url,
    '_blank',
    'width=900,height=900'
  );
  return false;
};

const ComponentSummary = ({
  component,
}: Props): Element<typeof Fragment> | null => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const toggle = () => setDetailsOpen(!detailsOpen);
  if (!component) {
    return null;
  }

  const handleClickDetails = makeHandleClick(`/${MODELS}/${COMPONENTS}/${component[UUID]}/${GENERAL}`);

  return (
    <>
      <ComponentSummaryTop
        component={component}
      />
      <Row>
        <Col>
          <Button id="toggleComponentDetails" color="link" onClick={toggle} className="pl-0 pt-0">
            {detailsOpen ? 'Hide details' : 'More details'}
          </Button>
        </Col>
        <Col>
          {detailsOpen && (
            <Button
              color="link"
              className="float-right"
              onClick={handleClickDetails}
            >
              View Full Details
            </Button>
          )}
        </Col>
      </Row>
      <Collapse isOpen={detailsOpen}>
        <ComponentSummaryBottom component={component} />
      </Collapse>
    </>
  );
};

export default ComponentSummary;
