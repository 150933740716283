/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Container, Nav, NavItem } from 'reactstrap';
import { footer as footerClass, navItem } from './index.module.scss';

const Footer = (): Element<'footer'> => (
  <footer className={footerClass}>
    <Container>
      <Nav className="float-right">
        <NavItem className={navItem}>
          &copy;
          {(new Date()).getFullYear()}
          {' '}
          <a href="https://www.hbrisk.com" target="_blank" rel="noopener noreferrer">Haselton Baker Risk Group</a>
        </NavItem>
        <NavItem className={navItem}>|</NavItem>
        <NavItem className={navItem}><a href="https://www.hbrisk.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></NavItem>
      </Nav>
    </Container>
  </footer>
);

export default Footer;
