/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';

type Props = {|
  handleToggle: Function,
  autofilled: boolean,
  fieldName: string,
|};

const populationToggleIdFromFieldName = (name) => `${name}PopulationToggle`;

const FieldPopulationToggle = (props: Props): Element<typeof Button> => {
  const {
    handleToggle,
    autofilled,
    fieldName,
  } = props;

  return autofilled
    ? (<Button id={populationToggleIdFromFieldName(fieldName)} color="link" onClick={handleToggle(false)}>Edit</Button>)
    : (<Button id={populationToggleIdFromFieldName(fieldName)} color="link" onClick={handleToggle(true)}>Set to default</Button>);
};

export default FieldPopulationToggle;
