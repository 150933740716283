/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { disabled } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.module.scss';

type CellType = (data: CellData) => Element<'span'>;

const accessor = (data: Object) => {
  const typeId = get('type', data);
  const hasRecoveryTime = get('hasRecoveryTime', data);
  return `${modelTypesById[typeId].shortName}${hasRecoveryTime ? ' + RT' : ''}`;
};

const Cell: CellType = (data) => {
  const typeId = get('original.type', data);
  const archived = get('original.archived', data);
  const hasRecoveryTime = get('original.hasRecoveryTime', data);
  const modelLevelName = `${modelTypesById[typeId].name}${hasRecoveryTime ? ' with Recovery Time' : ''}`;
  const modelLevelShortName = get('value', data);
  return (
    <span
      title={modelLevelName}
      className={archived ? disabled : ''}
    >
      {modelLevelShortName}
    </span>
  );
};

const column: ColumnType = {
  id: 'modelLevel',
  accessor,
  Header: 'Level',
  width: 80,
  Cell,
  additionalClasses: ['text-wrap'],
};

export default column;
