/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  INITIALIZE_MODEL_PAGE,
  RESET_MODEL_PAGE,
} from '#constants/actionTypes.js';

export const resetModelPage = () => ({
  type: RESET_MODEL_PAGE,
});

export const initializeModelPage = (payload, modelType) => ({
  meta: { modelType },
  type: INITIALIZE_MODEL_PAGE,
  payload,
});
