/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { formValueSelector, reduxForm, SubmissionError } from 'redux-form';
import { acceptTerms } from '#actions/app/terms.js';
import { selectAuthUserId } from '#selectors/entities/users.js';
import { selectCurrentTermsId } from '#selectors/entities/terms.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import TermsOfServiceForm from './TermsOfServiceForm.jsx';

const form = 'termsOfService';

const makeOnSubmit = (userId, termsId) => (values, dispatch) => (
  dispatch(acceptTerms(userId, termsId))
    .catch(() => (
      Promise.reject(new SubmissionError({ _error: PROBLEM_WITH_REQUEST }))
    ))
);

const mapStateToProps = (state) => ({
  areTermsChecked: formValueSelector(form)(state, 'areTermsChecked'),
  userId: selectAuthUserId(state),
  termsId: selectCurrentTermsId(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userId, termsId, ...restStateProps } = stateProps;
  return {
    ...ownProps,
    ...restStateProps,
    onSubmit: makeOnSubmit(userId, termsId),
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(reduxForm({
  form,
})(TermsOfServiceForm));
