/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import * as React from 'react';
import { Alert } from 'reactstrap';
import { isError, message, statusReached } from '@hbrisk/sp3-risk-model-support/models/runStatusHelpers.js';
import { PDF_GEN_SUCCESS, SPEX_SERVICE_SUCCESS } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import { UNPROCESSED_MODEL } from '#constants/models/warningMessages.js';
import RunProgressCard from './RunProgressCard/index.js';
import DownloadsProgressCard from './DownloadsProgressCard/index.js';

type Props = {|
  children: React.Node,
  desiredStatus: string,
  isProcessedModel: boolean,
  currentStatus: string,
  modelId: string,
  sectionGroup: string,
  showAlertForUnprocessedModels: boolean,
  uploadsInProgress: boolean,
|};

type State = {
  showContent: boolean,
}

const mapDesiredStatusesToCards = {
  [SPEX_SERVICE_SUCCESS]: RunProgressCard,
  [PDF_GEN_SUCCESS]: DownloadsProgressCard,
};

type GetComponent = (React.ComponentType<Props>) => React.ComponentType<Props>

const getComponent: GetComponent = (C) => class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showContent: false,
    };
  }

  componentDidMount() {
    const {
      currentStatus,
      desiredStatus,
      isProcessedModel,
      showAlertForUnprocessedModels,
    } = this.props;
    if (statusReached(currentStatus, desiredStatus)) {
      this.setShowContent(true);
    }
    if (!isProcessedModel && !showAlertForUnprocessedModels) {
      this.setShowContent(true);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { currentStatus, desiredStatus } = this.props;
    if (prevProps.currentStatus !== currentStatus) {
      if (!statusReached(currentStatus, desiredStatus)) {
        this.setShowContent(false);
      }
    }
  }

  onProgressCompletion = () => {
    const { currentStatus, desiredStatus } = this.props;
    if (statusReached(currentStatus, desiredStatus)) {
      this.setShowContent(true);
    }
  };

  setShowContent = (value: boolean) => {
    this.setState({ showContent: value });
  };

  render() {
    const {
      modelId,
      desiredStatus,
      isProcessedModel,
      currentStatus,
      showAlertForUnprocessedModels,
      sectionGroup,
    } = this.props;
    const { showContent } = this.state;

    const Card = mapDesiredStatusesToCards[desiredStatus];

    if (!isProcessedModel) {
      if (showAlertForUnprocessedModels) {
        return <Alert color="info">{UNPROCESSED_MODEL}</Alert>;
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      return showContent ? <C {...this.props} /> : null;
    }

    if (isError(currentStatus) && !statusReached(currentStatus, desiredStatus)) {
      const alert = <Alert fade={false} color="danger">{message(currentStatus)}</Alert>;
      /* eslint-disable react/jsx-props-no-spreading */
      return sectionGroup === 'outputs'
        ? (
          alert
        ) : (
          <>
            {alert}
            <C {...this.props} />
          </>
        );
      /* eslint-enable react/jsx-props-no-spreading */
    }
    /* eslint-disable react/jsx-props-no-spreading */
    return showContent
      ? <C {...this.props} />
      : <Card done={this.onProgressCompletion} modelId={modelId} />;
    /* eslint-enable react/jsx-props-no-spreading */
  }
};

export default getComponent;
