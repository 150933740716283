import keyBy from 'lodash/fp/keyBy.js';

const globalUnderscoreErrorOrDotRegex = /\.|_error/g;
const arrayIndexWithBracketsRegex = /\[\d*\]/;

const sectionNameFromUnqualifiedFieldName = (unqualifiedFieldName, fieldsByName) => {
  const simpleNamesInUnqualifiedFieldName = unqualifiedFieldName
    .replace(globalUnderscoreErrorOrDotRegex, '')
    .split(arrayIndexWithBracketsRegex);

  return simpleNamesInUnqualifiedFieldName.reduce((acc, simpleName, i, arr) => {
    const fieldDefinition = acc[simpleName];
    return i === arr.length - 1
      ? fieldDefinition.section
      : keyBy('name', fieldDefinition.itemSchema);
  }, fieldsByName);
};

export default sectionNameFromUnqualifiedFieldName;
