const bySlice = (byField) => Object.keys(byField).reduce((acc, name) => ({
  autofilled: {
    ...acc.autofilled || {},
    [name]: byField[name].autofilled,
  },
  values: {
    ...acc.values || {},
    [name]: byField[name].value,
  },
  visibleFields: {
    ...acc.visibleFields || {},
    [name]: byField[name].visible,
  },
}), {});

export default bySlice;
