/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { Amplify } from 'aws-amplify';
import React from 'react';
// eslint-disable-next-line import/extensions
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';
import '#src/scss/app.scss';
import store from '#store/index.js';
import App from '#components/App.jsx';
import config, {
  initializeConfigs,
  AWS_REGION,
  COGNITO_USER_POOL_ID,
  COGNITO_WEB_CLIENT_ID,
  ENVIRONMENT,
  ROLLBAR_ACCESS_TOKEN,
  ROLLBAR_ENABLED,
} from '#support/app/config.js';
import rollbarInit from '#support/app/rollbarInit.js';

initializeConfigs().then(() => {
  rollbarInit(config(ROLLBAR_ENABLED), config(ENVIRONMENT), config(ROLLBAR_ACCESS_TOKEN));

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: config(COGNITO_WEB_CLIENT_ID),
        userPoolId: config(COGNITO_USER_POOL_ID),
        loginWith: {
          username: 'true',
          password: 'true',
        },
        region: config(AWS_REGION),
      },
    },
  });

  const render = (Component) => {
    const container = document.getElementById('root');
    if (container !== null) {
      const root = createRoot(container);
      root.render(<Component store={store} />);
    }
  };

  render(App);

  // If hot module replacement is enabled in development
  if (module.hot) {
    // When an update to the app is available
    module.hot.accept('#components/App', () => {
      // Render the updated app
      render(App);
    });
  }
});
