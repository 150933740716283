/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

const calculateUserRequestPostBody = (values) => {
  const {
    isAdmin, firstName, lastName, email, orgId,
  } = values;

  const permissions = isAdmin !== undefined ? { isAdmin } : {};

  return {
    active: true,
    firstName,
    lastName,
    email,
    orgId,
    permissions,
  };
};

export default calculateUserRequestPostBody;
