import { connect } from 'react-redux';
import FormGroup from '#components/pages/Components/support/form/FormGroup/FormGroup.jsx';
import { selectAtLeastOneFieldInArrayIsVisible } from '#selectors/ui/form/component.js';

const mapStateToProps = (state, ownProps) => {
  const { fields } = ownProps;
  return {
    visible: selectAtLeastOneFieldInArrayIsVisible(state, { fields }),
  };
};
export default connect(mapStateToProps)(FormGroup);
