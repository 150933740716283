/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SIGN_IN } from '#constants/pathStrings.js';

const Page3 = (): Element<typeof Row> => (
  <Row className="reset-password-form reset-password-form-success-page">
    <Col lg="8">
      <p>
        <strong>Success!</strong>
        {' '}
        Your password has been updated. You can now use your new password
        to
        <Link to={`${SIGN_IN}`}>sign in</Link>
        {' '}
        to your account.
      </p>
    </Col>
  </Row>
);

export default Page3;
