/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { combineReducers } from 'redux';
import type1ModelPageReducer from './type1ModelPage.js';
import type2ModelPageReducer from './type2ModelPage.js';

export default combineReducers({
  type1: type1ModelPageReducer,
  type2: type2ModelPageReducer,
});
