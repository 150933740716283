/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

export const DAMAGE_CORRELATION_MODEL_FEMA_P58 = 'fema';

export const DAMAGE_CORRELATION_MODEL_ATC_138_ID = 'atc138';

export const damageCorrelationModels = [
  { id: DAMAGE_CORRELATION_MODEL_FEMA_P58, name: 'Original FEMA P-58', value: DAMAGE_CORRELATION_MODEL_FEMA_P58 },
  { id: DAMAGE_CORRELATION_MODEL_ATC_138_ID, name: 'ATC-138 Updated', value: DAMAGE_CORRELATION_MODEL_ATC_138_ID },
];
