// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { ADD, EDIT, COMPONENT_UUID } from '#constants/pathStrings.js';
import AddComponentView from './AddView/index.jsx';
import MainView from './MainView/index.js';
import EditComponentItem from './EditView/index.js';

type Props = {|
  form: string,
  heading: string,
  modelType: number,
  onBack: Function,
  onNext: Function,
|}

const BuildingComponentsTableSection = ({
  form,
  heading,
  modelType,
  onBack,
  onNext,
}: Props): Element<typeof Routes> => (
  <Routes>
    <Route
      index
      element={
        (
          <MainView
            form={form}
            heading={heading}
            modelType={modelType}
            onBack={onBack}
            onNext={onNext}
          />
        )
      }
    />
    <Route path={`${EDIT}/:${COMPONENT_UUID}`} element={<EditComponentItem form={form} />} />
    <Route path={`${ADD}`} element={<AddComponentView form={form} />} />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);

export default BuildingComponentsTableSection;
