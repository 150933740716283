/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import filter from 'lodash/fp/filter.js';
import {
  selectMeanLossPercentBreakdownByReturnPeriod,
  selectMeanLossPercentBreakdownColumnsOrderedArray,
} from '#selectors/entities/models.js';
import RepairCostByIntensityPlot from './RepairCostByIntensityPlot.jsx';

const mapStateToProps = (state, ownProps) => {
  const data = selectMeanLossPercentBreakdownByReturnPeriod(state, ownProps);
  const columns = filter((x) => x !== 'total', selectMeanLossPercentBreakdownColumnsOrderedArray(state, ownProps));
  return ({
    data,
    columns,
  });
};

export default connect(mapStateToProps)(RepairCostByIntensityPlot);
