/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import isWoodLightFrame from '#support/models/buildingType/isWoodLightFrame.js';
import isSteelLightFrame from '#support/models/buildingType/isSteelLightFrame.js';
import isRWFD from '#support/models/buildingType/isRWFD.js';

const isLightFrameOrRWFD = (buildingType) => (
  isWoodLightFrame(buildingType)
  || isSteelLightFrame(buildingType)
  || isRWFD(buildingType)
);

export default isLightFrameOrRWFD;
