import intersection from 'lodash/fp/intersection.js';
import { componentQuestionsByName, rootComponentQuestionNames } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';
import { countryIsJapan } from '#support/models/localeHelpers/index.js';
import getImmediateDescendants from '#support/models/componentQuestions/getImmediateDescendants.js';
import getParentName from '#support/models/componentQuestions/getParentName.js';

const questionShouldBeVisible = (name, ancestorVisibility, state) => {
  const question = componentQuestionsByName[name];
  const { values } = state;
  const {
    buildingTypeDir1,
    buildingTypeDir2,
    country,
  } = values;

  if (countryIsJapan(country)) {
    return false;
  }

  const buildingTypeIds = [buildingTypeDir1, buildingTypeDir2];
  if (question.isRoot) {
    return (
      intersection([null, undefined, ''], buildingTypeIds).length > 0
      || intersection(question.buildingTypeIds, buildingTypeIds).length > 0
      || question.buildingTypeIds.includes('all')
    );
  }

  const parentName = getParentName(name);
  const parentValue = values[parentName];
  if (!ancestorVisibility[parentName] || [null, '', undefined].includes(parentValue)) {
    return false;
  }
  const parent = componentQuestionsByName[parentName];
  const selectedAnswerToParent = parent.answers.find((a) => a.value === parseInt(parentValue, 10));

  if (!selectedAnswerToParent || !Array.isArray(selectedAnswerToParent.nextQuestions)) {
    return false;
  }

  return selectedAnswerToParent.nextQuestions.includes(name);
};

const getAllQuestionVisibilityRecursively = (questions, ancestorVisibility, state) => {
  if (questions.length < 1) {
    return ancestorVisibility;
  }

  const nextGenerationQuestions = questions.reduce((acc, name) => [
    ...acc,
    ...getImmediateDescendants(name),
  ], []);

  const currentGenerationVisibility = questions.reduce((acc, question) => {
    const questionVisibility = questionShouldBeVisible(question, ancestorVisibility, state);
    return ({
      ...acc,
      [question]: questionVisibility,
    });
  }, {});

  return getAllQuestionVisibilityRecursively(
    nextGenerationQuestions,
    {
      ...ancestorVisibility,
      ...currentGenerationVisibility,
    },
    state,
  );
};

const makeComponentQuestionShouldBeVisible = () => {
  let cache = {
    state: null,
    visibility: {},
  };
  return (name) => (state) => {
    if (state !== cache.state) {
      cache = {
        state,
        visibility: {},
      };
    }

    if (cache.visibility[name] === undefined) {
      cache = {
        ...cache,
        visibility: getAllQuestionVisibilityRecursively(
          rootComponentQuestionNames,
          {},
          state
        ),
      };
    }
    return cache.visibility[name];
  };
};

export const componentQuestionVisibilityReducer = makeComponentQuestionShouldBeVisible();
