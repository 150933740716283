/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { MODELS_TABLE_CLEAR_FILTERS, MODELS_TABLE_SET_FILTERS, MODELS_TABLE_SORT_CHANGE } from '#constants/actionTypes.js';

export const sortChange = (sorted) => ({
  type: MODELS_TABLE_SORT_CHANGE,
  payload: sorted,
});

export const setFilters = (filters) => ({
  type: MODELS_TABLE_SET_FILTERS,
  payload: filters,
});

export const clearFilters = () => ({
  type: MODELS_TABLE_CLEAR_FILTERS,
});
