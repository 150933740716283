/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import React from 'react';
import numeral from 'numeral';
import {
  DiscreteColorLegend,
  RadialChart,
} from 'react-vis';
import { colorLegend } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import { componentBreakdownById } from '#constants/models/componentBreakDownProperties.js';

type RepairCostAnnual = {
  total: number,
  structuralComponents: number,
  plumbingAndHvac: number,
  exteriorCladding: number,
  interiorFinishes: number,
  collapse: number,
  otherNonStructuralComponents: number,
  residualDrift: number,
  partitionWalls: number,
};

type Props = {|
  loss: RepairCostAnnual,
  fieldsSortOrder: string[],
|};

const RepairCostAt475ReturnPeriodPlot = (props: Props): Element<'div'> => {
  const { loss, fieldsSortOrder } = props;

  const width = 350;
  const height = 350;

  const pieSlice = (field) => {
    const angle = Math.round((loss[field] / loss.total) * 100);
    const label = angle > 5
      ? numeral(loss[field]).format('0.0%')
      : '';
    const color = get(`${field}.color`, componentBreakdownById);
    const res = {
      angle,
      label,
      color,
    };
    return res;
  };

  const legend = (field) => {
    const { description, color } = componentBreakdownById[field];
    const absolute = loss[field];
    const title = `${description} (${numeral(absolute).format('0.0%')})`;
    return { title, color, strokeWidth: 12 };
  };

  const fieldsOrderedByPercentAnnualLoss = fieldsSortOrder
    .filter((x) => x !== 'total');

  return (
    <div
      id="repairCostAt475ReturnPeriodPlot"
      style={{ position: 'relative', width: width + 50, height: height + 50 }}
    >
      <div>
        Expected Loss Ratio for 10% in 50 years is:
        {' '}
        {numeral(loss.total).format('0.0%')}
        .
      </div>
      <div className="pt-2">
        <RadialChart
          width={width}
          height={height}
          showLabels
          labelsRadiusMultiplier={0.7}
          labelsStyle={{ fontSize: 12 }}
          colorType="literal"
          data={fieldsOrderedByPercentAnnualLoss.map(pieSlice)}
        >
          <DiscreteColorLegend
            className={colorLegend}
            color="grey"
            width={225}
            items={fieldsOrderedByPercentAnnualLoss.map(legend)}
            style={{
              position: 'absolute',
              top: '15px',
              left: `${width + 25}px`,
            }}
          />
        </RadialChart>
      </div>
    </div>
  );
};

export default RepairCostAt475ReturnPeriodPlot;
