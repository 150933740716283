import makeFormMiddleware from '#middlewares/support/makeFormMiddlware.js';
import { modelTypes } from '#constants/models/modelTypes/index.js';
import { putModel } from '#actions/models/model.js';
import calculateModelPutPayload from '#support/models/calculateModelPutPayload.js';
import { currentModelId } from '#selectors/entities/models.js';
import geocodeOnAddressChange from '#middlewares/modelForm/geocodeOnAddressChange.js';
import validateOnFetchResponse from '#middlewares/modelForm/validateOnFetchResponse.js';

const modelFormMiddlewares = modelTypes.map((modelType) => makeFormMiddleware({
  form: modelType.modelFormName,
  fields: modelType.fields,
  selectCurrentUuid: currentModelId,
  saveActionCreator: putModel,
  calculatePayload: calculateModelPutPayload,
  extensions: [
    validateOnFetchResponse,
    geocodeOnAddressChange,
  ],
}));

export default modelFormMiddlewares;
