import { connect } from 'react-redux';
import pipe from 'lodash/fp/pipe.js';
import Component from '#components/pages/Components/Component/Component.jsx';
import withComponentPolling from '#components/support/polling/withComponentPolling/index.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';

const mapStateToProps = (state, ownProps) => ({
  componentUuid: ownProps.routeParams[COMPONENT_UUID],
});

export default pipe(
  withComponentPolling,
  connect(mapStateToProps),
  withRouteParams,
)(Component);
