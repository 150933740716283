/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectModeShapeForDirGroupByFloorAndMode,
  selectModeShapeFloors,
  selectModeShapeModes,
} from '#selectors/entities/models.js';
import ModeShapeTable from './ModeShapeTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const modeShapeDataGroupedByFloorAndMode = selectModeShapeForDirGroupByFloorAndMode(
    state,
    ownProps
  );
  const floors = selectModeShapeFloors(state, ownProps);
  const modes = selectModeShapeModes(state, ownProps);
  return {
    modeShapeDataGroupedByFloorAndMode,
    floors,
    modes,
  };
};

export default connect(mapStateToProps)(ModeShapeTable);
