/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import trim from 'lodash/fp/trim.js';
import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import { Field } from 'redux-form';
import {
  emptyOrMinLength8,
  hasLowerCaseLetters,
  hasSpecialCharacters,
  required,
} from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {|
  form: string,
|};

const NewPasswordField = ({ form }: Props): Element<typeof Fragment> => (
  <>
    <Label htmlFor="newPassword">
      New Password
    </Label>
    {' '}
    <Tip tipId="newPassword" />
    <Field
      id="newPassword"
      name="newPassword"
      type="password"
      component={RenderInput}
      validate={[required, hasLowerCaseLetters, hasSpecialCharacters, emptyOrMinLength8]}
      normalize={trim}
    />
    <FieldFeedback name="newPassword" form={form} />
  </>
);

export default NewPasswordField;
