/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { pipe } from 'ramda';
import isEmpty from 'lodash/fp/isEmpty.js';
import { connect } from 'react-redux';
import {
  selectCurrentRun,
  modelById,
  selectModelStatus,
  selectHasUploadsInProgress,
} from '#selectors/entities/models.js';
import getComponent from './withSectionLevelStatusIndication.jsx';

const getContainer = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const model = modelById(state, ownProps);
    const run = selectCurrentRun(state, ownProps);
    const currentStatus = selectModelStatus(state, ownProps);
    const uploadsInProgress = selectHasUploadsInProgress(state, ownProps);

    return ({
      showAlertForUnprocessedModels: ownProps.sectionGroup === 'outputs',
      currentStatus,
      isProcessedModel: (!isEmpty(model) && !isEmpty(run)) || false,
      uploadsInProgress,
      ...ownProps,
    });
  };
  return connect(mapStateToProps)(C);
};

export default pipe(getComponent, getContainer);
