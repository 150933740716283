/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { selectAuthUserHasType2ModelPermissions, selectAuthUserIsAdmin } from '#selectors/entities/users.js';
import Header from './Header.jsx';

const mapStateToProps = (state) => ({
  isAdmin: selectAuthUserIsAdmin(state),
  hasType2ModelPermissions: selectAuthUserHasType2ModelPermissions(state),
});

export default connect(mapStateToProps)(Header);
