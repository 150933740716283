/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import AdditionalNotesSection from '#components/pages/Models/Model/sections/shared/AdditionalNotesSection/index.jsx';
import AnalysisOptionsSection from '#components/pages/Models/Model/sections/shared/AnalysisOptionsSection/index.js';
import BuildingComponentsTableSection from '#components/pages/Models/Model/sections/type2/BuildingComponentsTableSection/index.jsx';
import BuildingDesignInfoSection from '#components/pages/Models/Model/sections/shared/BuildingDesignInfoSection/index.js';
import BuildingLayoutSection from '#components/pages/Models/Model/sections/shared/BuildingLayoutSection/index.js';
import BuildingModifiersChecklistSection from '#components/pages/Models/Model/sections/shared/BuildingModifiersChecklistSection/index.js';
import BuildingStabilitySection from '#components/pages/Models/Model/sections/shared/BuildingStabilitySection/index.js';
import ComponentChecklistSection from '#components/pages/Models/Model/sections/shared/ComponentChecklistSection/index.jsx';
import ComponentModifiersSection from '#components/pages/Models/Model/sections/type1/ComponentModifiersSection/index.js';
import DownloadsSection from '#components/pages/Models/Model/sections/shared/DownloadsSection/index.jsx';
import GeneralInfoSection from '#components/pages/Models/Model/sections/shared/ModelAndSiteInfoSection/index.js';
import GroundMotionSection from '#components/pages/Models/Model/sections/type2/GroundMotionSection/index.js';
import PrimaryBuildingInfoFormSection from '#components/pages/Models/Model/sections/shared/PrimaryBuildingInfoSection/index.js';
import RepairCostExposureSection from '#components/pages/Models/Model/sections/shared/RepairCostExposureSection/index.js';
import RepairCostBreakdownSection from '#components/pages/Models/Model/sections/shared/RepairCostBreakdownSection/index.js';
import RepairCostByIntensitySection from '#components/pages/Models/Model/sections/shared/RepairCostByIntensitySection/index.jsx';
import RepairTimeOptionsSection from '#components/pages/Models/Model/sections/shared/RepairTimeOptionsSection/index.js';
import RepairTimeSection from '#components/pages/Models/Model/sections/shared/RepairTimeSection/index.js';
import SiteCoordinatesFormSection from '#components/pages/Models/Model/sections/shared/SiteCoordinatesSection/index.jsx';
import StructuralPropertiesFormSection from '#components/pages/Models/Model/sections/shared/StructuralPropertiesSection/index.js';
import StructuralResponsesFormSection from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/index.jsx';
import {
  ADDITIONAL_NOTES_SECTION,
  ANALYSIS_OPTIONS_SECTION,
  REPAIR_COST_BREAKDOWN_SECTION,
  COMPONENT_GROUP_MODIFIERS_SECTION,
  BUILDING_COMPONENTS_TABLE_SECTION,
  BUILDING_DESIGN_SECTION,
  BUILDING_LAYOUT_SECTION,
  BUILDING_MODIFIERS_CHECKLIST_SECTION,
  BUILDING_STABILITY_SECTION,
  COMPONENT_CHECKLIST_SECTION,
  DOWNLOADS_SECTION,
  GROUND_MOTION_SECTION,
  MODEL_AND_SITE_INFO_SECTION,
  PRIMARY_BUILDING_INFO_SECTION,
  REPAIR_COST_BY_INTENSITY_SECTION,
  REPAIR_COST_EXPOSURE,
  REPAIR_TIME_OPTIONS_SECTION,
  REPAIR_TIME_SECTION,
  SITE_COORDINATES_SECTION,
  STRUCTURAL_PROPERTIES_SECTION,
  STRUCTURAL_RESPONSES_SECTION,
} from './sectionIds.js';

const sectionCmponentsById = {
  [ADDITIONAL_NOTES_SECTION]: AdditionalNotesSection,
  [REPAIR_COST_BREAKDOWN_SECTION]: RepairCostBreakdownSection,
  [ANALYSIS_OPTIONS_SECTION]: AnalysisOptionsSection,
  [COMPONENT_GROUP_MODIFIERS_SECTION]: ComponentModifiersSection,
  [BUILDING_COMPONENTS_TABLE_SECTION]: BuildingComponentsTableSection,
  [BUILDING_DESIGN_SECTION]: BuildingDesignInfoSection,
  [BUILDING_LAYOUT_SECTION]: BuildingLayoutSection,
  [BUILDING_MODIFIERS_CHECKLIST_SECTION]: BuildingModifiersChecklistSection,
  [BUILDING_STABILITY_SECTION]: BuildingStabilitySection,
  [COMPONENT_CHECKLIST_SECTION]: ComponentChecklistSection,
  [DOWNLOADS_SECTION]: DownloadsSection,
  [GROUND_MOTION_SECTION]: GroundMotionSection,
  [MODEL_AND_SITE_INFO_SECTION]: GeneralInfoSection,
  [PRIMARY_BUILDING_INFO_SECTION]: PrimaryBuildingInfoFormSection,
  [REPAIR_COST_BY_INTENSITY_SECTION]: RepairCostByIntensitySection,
  [REPAIR_COST_EXPOSURE]: RepairCostExposureSection,
  [REPAIR_TIME_OPTIONS_SECTION]: RepairTimeOptionsSection,
  [REPAIR_TIME_SECTION]: RepairTimeSection,
  [SITE_COORDINATES_SECTION]: SiteCoordinatesFormSection,
  [STRUCTURAL_PROPERTIES_SECTION]: StructuralPropertiesFormSection,
  [STRUCTURAL_RESPONSES_SECTION]: StructuralResponsesFormSection,
};

export default sectionCmponentsById;
