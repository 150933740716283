/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Col, Form, FormGroup, FormText, Row, Table,
} from 'reactstrap';
import { formatWithCommas, normalizeWithCommas } from '@hbrisk/sp3-risk-model-support/utility/form/withCommas.js';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import SiteClassSelectMenu from '#components/pages/Models/Model/sections/support/forms/SiteClassSelectMenu/index.jsx';
import { BUILDING_LAYOUT_SECTION } from '#constants/models/modelPage/sectionIds.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import FloorPlanFieldSet from './FloorPlanFieldset/index.js';
import TdmfBraceConfigurationSelectMenu from './TdmfBraceConfigurationSelectMenu/index.jsx';
import YlbfBraceConfigurationSelectMenu from './YlbfBraceConfigurationSelectMenu/index.jsx';
import YieldLinksPerBraceSelectMenu from './YieldLinksPerBraceSelectMenu/index.jsx';
import BraceExposureSelectMenu from './BraceExposureSelectMenu/index.jsx';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
  showRepairCostsScaleWithBuildingValue: boolean,
  showFirstStoryFullWallRatioDir1: boolean,
  showFirstStoryFullWallRatioDir2: boolean,
  showFirstStoryInfilledWallRatioDir1: boolean,
  showFirstStoryInfilledWallRatioDir2: boolean,
  showUpperStoryFullWallRatioDir1: boolean,
  showUpperStoryFullWallRatioDir2: boolean,
  showUpperStoryInfilledWallRatioDir1: boolean,
  showUpperStoryInfilledWallRatioDir2: boolean,
  showUpperStoryHeight: boolean,
  showYlbfBayLengthDir1: boolean,
  showYlbfBayLengthDir2: boolean,
  showYlbfBraceConfigDir1: boolean,
  showYlbfBraceConfigDir2: boolean,
  showYlbfBraceExposureDir1: boolean,
  showYlbfBraceExposureDir2: boolean,
  showYlbfNumFusesPerBraceDir1: boolean,
  showYlbfNumFusesPerBraceDir2: boolean,
  showTdmfDamperConfigDir1: boolean,
  showTdmfBayLengthDir1: boolean,
  showTdmfDamperExposureDir1: boolean,
  showTdmfDamperConfigDir2: boolean,
  showTdmfBayLengthDir2: boolean,
  showTdmfDamperExposureDir2: boolean,
  isTwoStories: boolean,
|};

const BuildingLayoutSection = (props: Props): Element<'div'> => {
  const {
    heading,
    form,
    onBack,
    onNext,
    showRepairCostsScaleWithBuildingValue,
    showFirstStoryFullWallRatioDir1,
    showFirstStoryFullWallRatioDir2,
    showFirstStoryInfilledWallRatioDir1,
    showFirstStoryInfilledWallRatioDir2,
    showUpperStoryFullWallRatioDir1,
    showUpperStoryFullWallRatioDir2,
    showUpperStoryInfilledWallRatioDir1,
    showUpperStoryInfilledWallRatioDir2,
    showUpperStoryHeight,
    showYlbfBayLengthDir1,
    showYlbfBayLengthDir2,
    showYlbfBraceConfigDir1,
    showYlbfBraceConfigDir2,
    showYlbfBraceExposureDir1,
    showYlbfBraceExposureDir2,
    showYlbfNumFusesPerBraceDir1,
    showYlbfNumFusesPerBraceDir2,
    showTdmfDamperConfigDir1,
    showTdmfBayLengthDir1,
    showTdmfDamperExposureDir1,
    showTdmfDamperConfigDir2,
    showTdmfBayLengthDir2,
    showTdmfDamperExposureDir2,
    isTwoStories,
  } = props;

  const { isFieldInSection } = modelTypesByForm[form];
  const showSoilInfo = isFieldInSection('siteClass', BUILDING_LAYOUT_SECTION);
  const showYlbf = [
    showYlbfBayLengthDir1,
    showYlbfBayLengthDir2,
    showYlbfBraceConfigDir1,
    showYlbfBraceConfigDir2,
    showYlbfBraceExposureDir1,
    showYlbfBraceExposureDir2,
    showYlbfNumFusesPerBraceDir1,
    showYlbfNumFusesPerBraceDir2,
  ].some((x) => x);

  const showTdmf = [
    showTdmfDamperConfigDir1,
    showTdmfBayLengthDir1,
    showTdmfDamperExposureDir1,
    showTdmfDamperConfigDir2,
    showTdmfBayLengthDir2,
    showTdmfDamperExposureDir2,
  ].some((x) => x);

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        <FormGroup tag="fieldset">
          <legend>Building Replacement Value</legend>
          <GridField
            name="costPerSqFt"
            form={form}
            options={{
              columnSize: 3,
              labelText: 'Total Cost per Square Foot',
              ComponentType: 'text',
              includeToolTip: true,
              format: formatWithCommas,
              normalize: normalizeWithCommas,
            }}
          />
          {showRepairCostsScaleWithBuildingValue ? (
            <GridField
              name="repairCostsScaleWithBuildingValue"
              form={form}
              options={{
                columnSize: 6,
                labelText: 'Scale component repair costs with building value',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          ) : (
            <FormText color="muted">
              *Component repair costs scale with building value
            </FormText>
          )}
        </FormGroup>
        <FloorPlanFieldSet form={form} />
        <FormGroup tag="fieldset">
          <legend>Story Heights</legend>
          <GridField
            name="firstStoryHeight"
            form={form}
            options={{
              columnSize: 3,
              labelText: 'First Story',
              ComponentType: 'text',
              inputGroup: {
                addonType: 'append',
                addonText: 'ft.',
              },
            }}
          />
          { showUpperStoryHeight && (
            <GridField
              name="upperStoryHeight"
              form={form}
              options={{
                columnSize: 3,
                labelText: isTwoStories ? 'Second Story' : 'Upper Stories',
                ComponentType: 'text',
                inputGroup: {
                  addonType: 'append',
                  addonText: 'ft.',
                },
              }}
            />
          )}
        </FormGroup>
        {
          (showFirstStoryFullWallRatioDir1 || showFirstStoryFullWallRatioDir2)
          && (
          <FormGroup tag="fieldset">
            <legend>Wood Light Frame Properties</legend>
            <Row>
              <Col lg="6">
                <strong>Full Height Exterior Wall (Fraction)</strong>
                {' '}
                <Tip tipId="fullHeightExteriorWallFraction" />
              </Col>
            </Row>
            <Table
              responsive
              className="structural-properties"
            >
              <thead>
                <tr>
                  <th />
                  <th>Direction 1</th>
                  <th>Direction 2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-nowrap">First Story</td>
                  <td>
                    {
                      showFirstStoryFullWallRatioDir1
                      && (
                      <GridField
                        name="firstStoryFullWallRatioDir1"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                        }}
                      />
                      )
                    }
                  </td>
                  <td>
                    {
                      showFirstStoryFullWallRatioDir2
                      && (
                      <GridField
                        name="firstStoryFullWallRatioDir2"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                        }}
                      />
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap">Upper Stories</td>
                  <td>
                    {
                      showUpperStoryFullWallRatioDir1
                      && (
                      <GridField
                        name="upperStoryFullWallRatioDir1"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                        }}
                      />
                      )
                    }
                  </td>
                  <td>
                    {
                      showUpperStoryFullWallRatioDir2
                      && (
                      <GridField
                        name="upperStoryFullWallRatioDir2"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                        }}
                      />
                      )
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </FormGroup>
          )
        }
        {
          (showFirstStoryInfilledWallRatioDir1 || showFirstStoryInfilledWallRatioDir2)
          && (
            <FormGroup tag="fieldset">
              <legend>Masonry Infill Properties</legend>
              <Row>
                <Col lg="6">
                  <strong>Infilled Exterior Wall (Fraction)</strong>
                  {' '}
                  <Tip tipId="infilledExteriorWallFraction" />
                </Col>
              </Row>
              <Table
                responsive
              >
                <thead>
                  <tr>
                    <th />
                    <th>Direction 1</th>
                    <th>Direction 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap">First Story</td>
                    <td>
                      {
                        showFirstStoryInfilledWallRatioDir1
                        && (
                          <GridField
                            name="firstStoryInfilledWallRatioDir1"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showFirstStoryInfilledWallRatioDir2
                        && (
                          <GridField
                            name="firstStoryInfilledWallRatioDir2"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">Upper Stories</td>
                    <td>
                      {
                        showUpperStoryInfilledWallRatioDir1
                        && (
                          <GridField
                            name="upperStoryInfilledWallRatioDir1"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showUpperStoryInfilledWallRatioDir2
                        && (
                          <GridField
                            name="upperStoryInfilledWallRatioDir2"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </FormGroup>
          )
        }
        {
          showTdmf && (
            <FormGroup tag="fieldset">
              <legend>Taylor Damped Moment Frame Properties</legend>
              <Table
                responsive
              >
                <thead>
                  <tr>
                    <th />
                    <th>Direction 1</th>
                    <th>Direction 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap">
                      Damper Brace Configuration
                      {' '}
                      <Tip tipId="tdmfBraceConfig" />
                    </td>
                    <td>
                      {
                        showTdmfDamperConfigDir1
                        && (
                          <GridField
                            name="tdmfDamperConfigDir1"
                            form={form}
                            options={{
                              ComponentType: TdmfBraceConfigurationSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showTdmfDamperConfigDir2
                        && (
                          <GridField
                            name="tdmfDamperConfigDir2"
                            form={form}
                            options={{
                              ComponentType: TdmfBraceConfigurationSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">
                      Damper Bay Length
                      {' '}
                      <Tip tipId="tdmfBayLength" />
                    </td>
                    <td>
                      {
                        showTdmfBayLengthDir1
                        && (
                          <GridField
                            name="tdmfBayLengthDir1"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                              inputGroup: {
                                addonType: 'append',
                                addonText: 'ft',
                              },
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showTdmfBayLengthDir2
                        && (
                          <GridField
                            name="tdmfBayLengthDir2"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                              inputGroup: {
                                addonType: 'append',
                                addonText: 'ft',
                              },
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">
                      Damper Exposure
                      {' '}
                      <Tip tipId="tdmfDamperExposure" />
                    </td>
                    <td>
                      {
                        showTdmfDamperExposureDir1
                        && (
                          <GridField
                            name="tdmfDamperExposureDir1"
                            form={form}
                            options={{
                              ComponentType: BraceExposureSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showTdmfDamperExposureDir2
                        && (
                          <GridField
                            name="tdmfDamperExposureDir2"
                            form={form}
                            options={{
                              ComponentType: BraceExposureSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </FormGroup>
          )
        }
        {
          showYlbf && (
            <FormGroup tag="fieldset">
              <legend>Simpson Strong-Tie Yield-Link Braced Frame Properties</legend>
              <Table
                responsive
              >
                <thead>
                  <tr>
                    <th />
                    <th>Direction 1</th>
                    <th>Direction 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap">
                      Brace Configuration
                      {' '}
                      <Tip tipId="ylbfBraceConfig" />
                    </td>
                    <td>
                      {
                        showYlbfBraceConfigDir1
                        && (
                          <GridField
                            name="ylbfBraceConfigDir1"
                            form={form}
                            options={{
                              ComponentType: YlbfBraceConfigurationSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showYlbfBraceConfigDir2
                        && (
                          <GridField
                            name="ylbfBraceConfigDir2"
                            form={form}
                            options={{
                              ComponentType: YlbfBraceConfigurationSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">
                      Yield-Links per Brace
                      {' '}
                      <Tip tipId="ylbfNumFusesPerBrace" />
                    </td>
                    <td>
                      {
                        showYlbfNumFusesPerBraceDir1
                        && (
                          <GridField
                            name="ylbfNumFusesPerBraceDir1"
                            form={form}
                            options={{
                              ComponentType: YieldLinksPerBraceSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showYlbfNumFusesPerBraceDir2
                        && (
                          <GridField
                            name="ylbfNumFusesPerBraceDir2"
                            form={form}
                            options={{
                              ComponentType: YieldLinksPerBraceSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">
                      Bay Length
                      {' '}
                      <Tip tipId="ylbfBayLength" />
                    </td>
                    <td>
                      {
                        showYlbfBayLengthDir1
                        && (
                          <GridField
                            name="ylbfBayLengthDir1"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                              inputGroup: {
                                addonType: 'append',
                                addonText: 'ft',
                              },
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showYlbfBayLengthDir2
                        && (
                          <GridField
                            name="ylbfBayLengthDir2"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                              inputGroup: {
                                addonType: 'append',
                                addonText: 'ft',
                              },
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap">
                      Brace Exposure
                      {' '}
                      <Tip tipId="ylbfBraceExposure" />
                    </td>
                    <td>
                      {
                        showYlbfBraceExposureDir1
                        && (
                          <GridField
                            name="ylbfBraceExposureDir1"
                            form={form}
                            options={{
                              ComponentType: BraceExposureSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showYlbfBraceExposureDir2
                        && (
                          <GridField
                            name="ylbfBraceExposureDir2"
                            form={form}
                            options={{
                              ComponentType: BraceExposureSelectMenu,
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </FormGroup>
          )
        }
        { showSoilInfo
          && (
          <FormGroup tag="fieldset">
            <legend>Soil Information</legend>
            <GridField
              name="siteClass"
              form={form}
              options={{
                columnSize: 4,
                labelText: 'Site Class',
                ComponentType: SiteClassSelectMenu,
                includeToolTip: true,
              }}
            />
          </FormGroup>
          )}
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default BuildingLayoutSection;
