/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';

type Props = {|
  className: string,
  offText: string,
  on: boolean,
  onClick: Function,
  onText: string,
|};

const FieldVisibilityToggle = ({
  className, on, onText, offText, onClick,
}: Props): Element<typeof Button> => {
  const text = on ? onText : offText;
  return (
    <Button
      className={className}
      color="link"
      onClick={onClick}
      type="button"
    >
      {text}
    </Button>
  );
};

export default FieldVisibilityToggle;
