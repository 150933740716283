/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import moment from 'moment';
import classNames from 'classnames';
import React from 'react';
import ReactTable from 'react-table';
import LinkCell from './LinkCell/index.jsx';
import {
  orgsTable,
  orgsTableTd,
  orgsTableTh,
  td,
  th,
} from './ViewOrganizationsTable.module.scss';

const dateCell = (data: Object) => {
  const updatedAt = get('row.updatedAt', data);
  return moment(updatedAt).format('M/D/YYYY');
};

const columnsWithDefaults = (withoutDefaults: any) => withoutDefaults.map((column) => (
  {
    ...{
      className: classNames(td, orgsTableTd),
      headerClassName: classNames('font-weight-bold', th, orgsTableTh),
    },
    ...column,
  }
));

const myColumns = columnsWithDefaults([
  {
    id: 'name',
    accessor: 'name',
    Header: 'Organization',
    maxWidth: 425,
    Cell: LinkCell,
  },
  {
    id: 'status',
    accessor: 'active',
    Header: 'Status',
    maxWidth: 100,
    className: classNames(td, orgsTableTd),
    Cell: (data) => {
      const { original: { active } } = data;
      return (
        <span>
          { active ? 'Active' : 'Inactive' }
        </span>
      );
    },
  },
  {
    id: 'lastUpdated',
    accessor: 'lastUpdated',
    Header: 'Last Updated',
    maxWidth: 150,
    className: classNames(td, orgsTableTd, 'text-right'),
    Cell: dateCell,
  },
  {
    id: 'modelTypes',
    accessor: 'permissions.modelTypes',
    Header: 'Model Types',
    maxWidth: 150,
    Cell: (data) => {
      const { original: { permissions: { modelTypes } } } = data;
      return (
        <span>
          {modelTypes.join(', ')}
        </span>
      );
    },
  },
]);

type Organization = {
  uuid: string,
  status: boolean,
  name: string,
  lastUpdated: string,
  modelTypes: string,
};

type Props = {|
  data: Array<Organization>,
|};

const ViewOrganizationTable = (props: Props): Element<typeof ReactTable> => {
  const { data } = props;

  return (
    <ReactTable
      className={classNames(['orgs-table', orgsTable, '-striped', 'rounded'])}
      columns={myColumns}
      data={data}
      defaultPageSize={100000}
      showPagination={false}
      minRows={0}
      resizable={false}
      defaultSorted={[{ id: 'name' }]}
    />
  );
};
export default ViewOrganizationTable;
