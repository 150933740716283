/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import countries from './countries.js';

const getOptions = (onlyUS) => (
  countries
    .filter((country) => (
      onlyUS
        ? country.abbreviation === 'US'
        : true
    ))
    .map((country) => ({
      id: country.abbreviation,
      name: country.name,
      value: country.abbreviation,
    }))
);

type Props = {|
  form: string,
  onlyUS: boolean,
|};

const CountrySelectMenu = (
  { form, onlyUS }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={getOptions(onlyUS)}
    id="country"
    name="country"
    inputClassName={classNames('custom-select', 'country-select')}
    includeEmptyOption={false}
  />
);

export default CountrySelectMenu;
