/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import unset from 'lodash/fp/unset.js';
import {
  CREATE_MODEL_REQUEST,
  CREATE_RUN_REQUEST,
  FETCH_MODEL_REQUEST,
  UPDATE_MODEL_REQUEST,
  FETCH_MODELS_REQUEST,
  FETCH_MODELS_RESPONSE,
} from '#constants/actionTypes.js';

const initialState = {
  validFetchRequests: {},
  createRequests: {},
};

const allReducer = (state = initialState, action = {}) => {
  const { meta: actionMeta, type } = action;
  switch (type) {
    case FETCH_MODELS_REQUEST: {
      const { requestId } = actionMeta;
      return {
        ...state,
        validFetchRequests: {
          [requestId]: true,
        },
      };
    }
    case FETCH_MODELS_RESPONSE: {
      const { requestId } = actionMeta;
      const { validFetchRequests } = state;
      return {
        ...state,
        validFetchRequests: unset(requestId, validFetchRequests),
      };
    }
    case FETCH_MODEL_REQUEST:
    case CREATE_MODEL_REQUEST:
    case UPDATE_MODEL_REQUEST:
    case CREATE_RUN_REQUEST: {
      return {
        ...state,
        validFetchRequests: {},
      };
    }
    default: {
      return state;
    }
  }
};

export default allReducer;
