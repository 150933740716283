/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import keyBy from 'lodash/fp/keyBy.js';

const methods = [
  { id: 'sp3', name: 'SP3 Default' },
  { id: 'upload', name: 'User Defined Upload' },
];

export const methodById = keyBy('id', methods);

export default methods;
