/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import throttle from 'lodash/fp/throttle.js';
import { curry } from 'ramda';
import { connect } from 'react-redux';
import { change as reduxFormChange, getFormValues } from 'redux-form';
import format from '#support/models/formatCoordinateValue.js';
import SiteMap from './SiteMap.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const formValues = getFormValues(form)(state);
  const latString = get('lat', formValues);
  const lngString = get('lng', formValues);
  return ({
    markerPosition: {
      lat: latString ? parseFloat(latString) : 0,
      lng: lngString ? parseFloat(lngString) : 0,
    },
  });
};

const handleMarkerDragEnd = (dispatch, change, form, event) => {
  const { lat, lng } = event.latLng;
  dispatch(change(form, 'lat', format(lat())));
  dispatch(change(form, 'lng', format(lng())));
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { form } = ownProps;
  return ({
    handleMarkerDragEnd: curry(handleMarkerDragEnd)(dispatch, reduxFormChange, form),
    handleMarkerDrag: throttle(250, curry(handleMarkerDragEnd)(dispatch, reduxFormChange, form)),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteMap);
