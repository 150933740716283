/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Button, Card, CardBody, Col, Form, FormGroup, Label, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import EdpSelectMenu from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/support/EdpSelectMenu/index.jsx';

type Props = {|
  canAdd: boolean,
  showError: boolean,
  handleSubmit: Function,
|};

const render = (props: Props): Element<'div'> => {
  const { handleSubmit, canAdd, showError } = props;
  return (
    <div>
      <Form noValidate>
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h2>Add Response</h2>
                </Col>
                <Col>
                  <span className="float-right">
                    <Button id="topCloseButton" tag={Link} color="link" to="..">Close</Button>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg="5">
                  <FormGroup>
                    <Label htmlFor="edpKey">Engineering Demand Parameter</Label>
                    <Field
                      name="edpKey"
                      component={EdpSelectMenu}
                    />
                  </FormGroup>
                </Col>
              </Row>
              { showError && (
                <Row>
                  <Col>
                    <span className="float-right text-muted"> A response with this EDP is already in your model</span>
                  </Col>
                </Row>
              )}
              <Row className="pt-3">
                <Col>
                  <Button id="bottomCloseButton" tag={Link} to="..">Back to Structural Responses</Button>
                </Col>
                <Col>
                  <Button
                    id="addResponseButton"
                    className="float-right"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!canAdd}
                  >
                    Add Response To Model
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Form>
    </div>
  );
};

export default render;
