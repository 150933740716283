/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import { ATC_138_ID, REDI_ID } from '@hbrisk/sp3-risk-model-support/models/repairTimeMethods.js';
import IsLFOrRWFD from '#support/models/buildingType/isLightFrameOrRWFD/index.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';
import { REDUX_FORM_CHANGE } from '#constants/actionTypes.js';
import {
  countryIsJapan as isJapan,
  countryIsUS as isUS,
  countryIsUSorJapan as isUSorJapan,
} from '#support/models/localeHelpers/index.js';
import {
  COLLAPSE_METHOD_FEMA,
  COLLAPSE_METHOD_MCE,
  COLLAPSE_METHOD_MEDIAN,
} from '#constants/models/collapseDefinitionMethods.js';
import { COMPONENT_SELECTION_METHOD_AUTOMATIC } from '#constants/models/componentSelectionMethods.js';
import isJapanBuildingType from '#support/models/buildingType/isJapanBuildingType.js';
import isWLF from '#support/models/buildingType/isWoodLightFrame.js';
import isRWFD from '#support/models/buildingType/isRWFD.js';
import isInf from '#support/models/buildingType/isInfilled.js';
import groundMotionIsUploadMethod from '#support/models/groundMotion/groundMotionIsUploadMethod.js';
import isWLFOrSLF from '#support/models/buildingType/isWoodOrSteelLightFrame/index.js';
import isSimpsonYLBF from '#support/models/buildingType/isSimpsonYLBF.js';
import isTDMF from '#support/models/buildingType/isTDMF.js';
import hasConcreteShearWalls from '#support/models/buildingType/hasConcreteShearWalls.js';
import hasMasonryShearWalls from '#support/models/buildingType/hasMasonryShearWalls.js';

export * from './support/utility/index.js';
export { structuralResponsesValueReducer } from './support/structuralResponses/index.js';
export { componentQuestionVisibilityReducer } from './support/componentQuestions/index.js';

const femaTypeIncludedIn = (femaTypes) => (v) => {
  const femaType = get(`${v}.femaType`, buildingTypesById);
  return femaTypes.includes(femaType);
};

export const
  atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn = (
    femaTypes
  ) => (prev) => {
    const { buildingTypeDir1, buildingTypeDir2 } = prev.values;

    if (!buildingTypeDir1 || !buildingTypeDir2) {
      return true;
    }

    const buildingTypes = [buildingTypeDir1, buildingTypeDir2];
    return buildingTypes.some(femaTypeIncludedIn(femaTypes));
  };

export const atLeastOneBuildingTypeIsEmptyOrWoodOrSteelLightFrame = (
  prev
) => {
  const { buildingTypeDir1, buildingTypeDir2 } = prev.values;
  const buildingTypes = [buildingTypeDir1, buildingTypeDir2];
  return (
    (!buildingTypeDir1 || !buildingTypeDir2)
    || buildingTypes.some(isWLFOrSLF)
  );
};

export const atLeastOneBuildingTypeIsWoodLightFrame = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  if (buildingTypeDir1 === undefined && buildingTypeDir2 === undefined) {
    return undefined;
  }
  return [buildingTypeDir1, buildingTypeDir2].some(isWLF);
};

export const atLeastOneBuildingTypeIsRWFD = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  if (buildingTypeDir1 === undefined && buildingTypeDir2 === undefined) {
    return undefined;
  }
  return [buildingTypeDir1, buildingTypeDir2].some(isRWFD);
};

export const atLeastOneBuildingTypeIsLightFrameOrRWFD = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  if (buildingTypeDir1 === undefined && buildingTypeDir2 === undefined) {
    return undefined;
  }
  return [buildingTypeDir1, buildingTypeDir2].some(IsLFOrRWFD);
};

export const everyBuildingTypeIsLightFrameOrRWFD = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  if (buildingTypeDir1 === undefined || buildingTypeDir2 === undefined) {
    return undefined;
  }
  return [buildingTypeDir1, buildingTypeDir2].every(IsLFOrRWFD);
};

export const everyBuildingTypeIsWoodLightFrame = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  if (buildingTypeDir1 === undefined || buildingTypeDir2 === undefined) {
    return undefined;
  }
  return [buildingTypeDir1, buildingTypeDir2].every(isWLF);
};

export const everyBuildingTypeIsWoodOrSteelLightFrame = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  if (buildingTypeDir1 === undefined || buildingTypeDir2 === undefined) {
    return undefined;
  }
  return [buildingTypeDir1, buildingTypeDir2].every(isWLFOrSLF);
};

export const modeShapeMethodIsUpload = (prev) => {
  const { modeShapeMethod } = prev.values;
  if (modeShapeMethod === undefined) {
    return undefined;
  }
  return modeShapeMethod === 'upload';
};

export const isBRB = (name) => (prev) => {
  const { [name]: value } = prev.values;
  if (value === undefined) {
    return undefined;
  }
  return value === 'BRB';
};

export const isEmptyOrRWFD = (name) => (prev) => {
  const { [name]: value } = prev.values;
  return !value || isRWFD(value);
};

export const isInfilled = (name) => (prev) => {
  const { [name]: value } = prev.values;
  if (value === undefined) {
    return undefined;
  }
  return isInf(value);
};

export const isLightFrameOrRWFD = (name) => (prev) => {
  const { [name]: value } = prev.values;
  if (value === undefined) {
    return undefined;
  }
  return IsLFOrRWFD(value);
};

export const isWoodLightFrame = (name) => (prev) => {
  const { [name]: value } = prev.values;
  if (value === undefined) {
    return undefined;
  }
  return isWLF(value);
};

export const isWoodOrSteelLightFrame = (name) => (prev) => {
  const { [name]: value } = prev.values;
  if (value === undefined) {
    return undefined;
  }
  return isWLFOrSLF(value);
};

export const atLeastOneBuildingTypeIsEmptyOrConcreteShearWall = (
  prev
) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;

  if (!buildingTypeDir1 || !buildingTypeDir2) {
    return true;
  }

  return [buildingTypeDir1, buildingTypeDir2].some(hasConcreteShearWalls);
};

export const atLeastOneBuildingTypeIsEmptyOrMasonryShearWall = (
  prev
) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;

  if (!buildingTypeDir1 || !buildingTypeDir2) {
    return true;
  }

  return [buildingTypeDir1, buildingTypeDir2].some(hasMasonryShearWalls);
};

export const isSimpsonYieldLinkBraceFrame = (name) => (prev) => {
  const { [name]: value } = prev.values;
  return isSimpsonYLBF(value);
};

export const isTaylorDampedMomentFrame = (name) => (prev) => {
  const { [name]: value } = prev.values;
  return isTDMF(value);
};

export const clearOnCountryChange = (name) => (prev, action) => {
  const {
    values: {
      [name]: prevValue,
    },
  } = prev;
  if (action) {
    const { meta, type } = action;
    if (type === REDUX_FORM_CHANGE && meta && meta.field === 'country') {
      return null;
    }
  }
  return prevValue;
};

export const clearBuildingTypeIfInconsistentWithCountry = (name) => (prev) => {
  const {
    values: {
      [name]: prevValue,
      country,
    },
  } = prev;

  if (isJapan(country)) {
    return isJapanBuildingType(prevValue)
      ? prevValue
      : null;
  }
  if (isJapanBuildingType(prevValue)) {
    return null;
  }
  return prevValue;
};

export const clearServiceLocationsIfRepairTimeMethodIsREDI = (prev) => {
  const { componentPopulation, repairTimeMethod } = prev.values;
  if (componentPopulation && repairTimeMethod === REDI_ID) {
    return componentPopulation.map((populationItem) => ({
      ...populationItem,
      performanceGroups: populationItem.performanceGroups.map((group) => ({
        ...group,
        serviceLocations: null,
      })),
    }));
  }
  return componentPopulation;
};

export const collapseDefinitionMethodIsEmptyOrFEMA = (prev) => {
  const { collapseDefinitionMethod } = prev.values;
  return (
    !collapseDefinitionMethod || collapseDefinitionMethod === COLLAPSE_METHOD_FEMA
  );
};

export const collapseDefinitionMethodIsMCE = (prev) => {
  const { collapseDefinitionMethod } = prev.values;
  if (collapseDefinitionMethod === undefined) {
    return undefined;
  }
  return collapseDefinitionMethod === COLLAPSE_METHOD_MCE;
};

export const collapseDefinitionMethodIsMedian = (prev) => {
  const { collapseDefinitionMethod } = prev.values;
  if (collapseDefinitionMethod === undefined) {
    return undefined;
  }
  return collapseDefinitionMethod === COLLAPSE_METHOD_MEDIAN;
};

export const componentPopulationMethodIsAutomatic = (prev) => {
  const { componentPopulationMethod } = prev.values;
  if (componentPopulationMethod === undefined) {
    return undefined;
  }
  return componentPopulationMethod === COMPONENT_SELECTION_METHOD_AUTOMATIC;
};

export const countryIsJapan = (prev) => {
  const { country } = prev.values;
  return country === undefined
    ? undefined
    : isJapan(country);
};

export const countryIsUS = (prev) => {
  const { country } = prev.values;
  return country === undefined
    ? undefined
    : isUS(country);
};

export const countryIsUSOrJapan = (prev) => {
  const { country } = prev.values;
  return country === undefined
    ? undefined
    : isUSorJapan(country);
};

export const useSmartEquipmentComponentsIsYes = (prev) => {
  const { useSmartEquipmentComponents } = prev.values;
  return useSmartEquipmentComponents === undefined
    ? undefined
    : useSmartEquipmentComponents === 'yes';
};

export const equipmentPrequalifiedIsYes = (prev) => {
  const { equipmentPrequalified } = prev.values;
  return equipmentPrequalified === undefined
    ? undefined
    : equipmentPrequalified === 'yes';
};

export const prequalifiedDesignCodeYearIsOverride = (prev) => {
  const { prequalifiedDesignCodeYear } = prev.values;
  return prequalifiedDesignCodeYear === undefined
    ? undefined
    : prequalifiedDesignCodeYear === 'override';
};

export const includeDesignIntensitiesIsTrue = (prev) => {
  const { includeDesignIntensities } = prev.values;
  return includeDesignIntensities === undefined
    ? undefined
    : includeDesignIntensities === true;
};

export const includeRepairTimeIsTrue = (state) => {
  const { includeRepairTime } = state.values;
  return includeRepairTime === undefined
    ? undefined
    : !!includeRepairTime;
};

export const includeRetrofitIsTrue = (state) => {
  const { includeRetrofit } = state.values;
  return includeRetrofit === undefined
    ? undefined
    : !!includeRetrofit;
};

export const includeSurgeDemandIsYes = (prev) => {
  const { includeSurgeDemand } = prev.values;
  return includeSurgeDemand === undefined
    ? undefined
    : includeSurgeDemand === 'yes';
};

export const isGreaterThanOrEqualToOne = (name) => (prev) => {
  const { [name]: value } = prev.values;
  return value === undefined
    ? undefined
    : parseInt(value, 10) >= 1;
};

export const needElevatorForFunctionIsYes = (prev) => {
  const { needElevatorForFunction } = prev.values;
  return needElevatorForFunction === undefined
    ? undefined
    : needElevatorForFunction === 'yes';
};

export const repairTimeMethodIsATC138 = (state) => {
  const { repairTimeMethod } = state.values;
  return repairTimeMethod === undefined
    ? undefined
    : repairTimeMethod === ATC_138_ID;
};

export const medianIfCountryIsJapan = (name) => (prev) => {
  const {
    values: {
      [name]: prevValue,
      country,
    },
  } = prev;
  if (isJapan(country)) {
    return COLLAPSE_METHOD_MEDIAN;
  }
  return prevValue;
};

export const componentPopulationMethodIsCustom = (prev) => {
  const {
    componentPopulationMethod,
  } = prev.values;

  return componentPopulationMethod === undefined
    ? undefined
    : componentPopulationMethod === 'custom';
};

export const groundMotionMethodSupportsUpload = (prev) => {
  const { groundMotionMethod } = prev.values;
  if (groundMotionMethod === undefined) {
    return undefined;
  }
  return groundMotionIsUploadMethod(groundMotionMethod);
};

export const numberOfStoriesIsOne = (prev) => {
  const { numberOfStories } = prev.values;

  return numberOfStories === undefined
    ? undefined
    : numberOfStories === '1';
};

export const numberOfStoriesIsOneOR2 = (prev) => {
  const { numberOfStories } = prev.values;
  return numberOfStories === undefined
    ? undefined
    : ['1', '2'].includes(numberOfStories);
};

export const designCodeYearVisibilityReducer = (prev) => {
  const { country } = prev.values;
  const { designCodeYear: prevVisibility } = prev.visibleFields;
  if (country) {
    if (isJapan(country)) {
      return false;
    }
    if (country && !isUS(country)) {
      return true;
    }
  }
  return prevVisibility;
};

export const yearOfConstructionVisibilityReducer = (prev) => {
  const { country } = prev.values;
  const { yearOfConstruction: prevVisibility } = prev.visibleFields;
  if (country) {
    if (isJapan(country)) {
      return true;
    }
    if (country && !isUS(country)) {
      return false;
    }
  }
  return prevVisibility;
};

export const nonStructuralRetrofitCodeYearVisibilityReducer = (prev) => {
  const { includeRetrofit } = prev.values;
  const { nonStructuralRetrofitCodeYear: prevVisibility } = prev.visibleFields;

  if (!includeRetrofit) {
    return false;
  }

  return prevVisibility === undefined
    ? false
    : prevVisibility;
};

export const nonStructuralRetrofitYearVisibilityReducer = (
  prev,
  action,
) => {
  const { includeRetrofit } = prev.values;
  const {
    nonStructuralRetrofitYear: prevVisibility,
  } = prev.visibleFields;

  if (action && action.meta) {
    const { meta: { field }, type, payload } = action;
    if (
      type === REDUX_FORM_CHANGE
      && field === 'includeRetrofit'
      && payload === true
    ) {
      return true;
    }
  }

  if (!includeRetrofit) {
    return false;
  }

  return prevVisibility === undefined
    ? true
    : prevVisibility;
};

export const structuralRetrofitCodeYearVisibilityReducer = (prev) => {
  const { includeRetrofit } = prev.values;
  const { structuralRetrofitCodeYear: prevVisibility } = prev.visibleFields;

  if (!includeRetrofit) {
    return false;
  }

  return prevVisibility === undefined
    ? false
    : prevVisibility;
};

export const structuralRetrofitYearVisibilityReducer = (prev, action) => {
  const { includeRetrofit } = prev.values;
  const {
    structuralRetrofitYear: prevVisibility,
  } = prev.visibleFields;

  if (action && action.meta) {
    const { meta: { field }, type, payload } = action;
    if (
      type === REDUX_FORM_CHANGE
      && field === 'includeRetrofit'
      && payload === true
    ) {
      return true;
    }
  }

  if (!includeRetrofit) {
    return false;
  }

  return (prevVisibility === undefined)
    ? true
    : prevVisibility;
};
