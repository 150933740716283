/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 5.30/18
 * Copyright All Rights Reserved
 */

const loadState = (storage = localStorage) => {
  /* Wrap the main functionality in  try-catch because some modern browsers (e.g., Safari)
  * throw when an attempt to access local storage is made while the browser is in private mode
  */
  try {
    /*
     * Read state from local storage
     */
    const serializedState = storage.getItem('state');
    /*
    * If no state is stored in locatStorage we return undefined, so that the reducers will
    * initialize with their default state values.
     */
    if (serializedState === null) {
      return undefined;
    }
    /*
     * Otherwise, deserialize and then return persisted state data
     */
    return JSON.parse(serializedState);
  } catch (err) {
    // TODO: add logging
    return undefined;
  }
};

const saveState = (state, storage = localStorage) => {
  /* Wrap the main functionality in try-catch because some modern browsers (e.g., Safari)
   * throw when an attempt to access local storage is made while the browser is in private mode
   */
  try {
    /*
    * Serialize state and save it to local storage
     */
    const serializedState = JSON.stringify(state);
    storage.setItem('state', serializedState);
  } catch (err) {
  // TODO: add logging
    // eslint-disable-next-line no-console
    console.log('Error saving state to local storage: ', err);
  }
};

export { loadState, saveState };
