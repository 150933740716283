// @flow
import type { Element, Node } from 'react';

import React from 'react';
import RequireAuthorization from '#components/support/routing/RequireAuthorization/index.jsx';
import { MODELS } from '#constants/pathStrings.js';

type Props = {|
  children: Node,
  hasType2ModelPermissions: boolean,
  isAuthenticated: boolean,
  redirectTo?: string,
|};

const RequireType2ModelPermissions = ({
  children,
  hasType2ModelPermissions,
  isAuthenticated,
  redirectTo = `/${MODELS}`,
}: Props): Element<typeof RequireAuthorization> => (
  <RequireAuthorization
    isAuthorized={hasType2ModelPermissions && isAuthenticated}
    redirectTo={redirectTo}
  >
    {children}
  </RequireAuthorization>
);

export default RequireType2ModelPermissions;
