/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { autofillModelFormField, hideModelFormField, showModelFormField } from '#actions/models/modelForm.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { currentModelId } from '#selectors/entities/models.js';
import FieldVisibilityToggle from './FieldVisibilityToggle.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form, onFields, offFields } = ownProps;
  const { isAutofillable, makePickedAutofilledValuesSelector } = modelTypesByForm[form];
  const selectPickedAutofilledValues = makePickedAutofilledValuesSelector();
  const modelId = currentModelId(state);
  const autofilledFields = [...onFields, ...offFields].filter(isAutofillable);
  const autofilledValues = selectPickedAutofilledValues(
    state,
    { modelId, names: autofilledFields }
  );
  return ({
    autofilledValues,
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    afterToggleOff,
    afterToggleOn,
    offFields,
    on,
    onFields,
    form,
  } = ownProps;
  return ({
    makeOnClick: (autofilledValues) => () => {
      if (on) {
        onFields.forEach((key) => {
          dispatch(hideModelFormField(form, key));
        });
        offFields.forEach((key) => {
          dispatch(showModelFormField(form, key));
          if (Object.keys(autofilledValues).includes(key)) {
            dispatch(autofillModelFormField(form, key, autofilledValues[key]));
          }
        });
        if (afterToggleOff) {
          afterToggleOff();
        }
      } else {
        offFields.forEach((key) => {
          dispatch(hideModelFormField(form, key));
        });
        onFields.forEach((key) => {
          dispatch(showModelFormField(form, key));
          if (Object.keys(autofilledValues).includes(key)) {
            dispatch(autofillModelFormField(form, key, autofilledValues[key]));
          }
        });
        if (afterToggleOn) {
          afterToggleOn();
        }
      }
    },
  });
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { autofilledValues } = stateProps;
  return {
    onClick: dispatchProps.makeOnClick(autofilledValues),
    ...ownProps,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FieldVisibilityToggle);
