// @flow
import type { Element } from 'react';
import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import {
  Sidebar,
} from '#src/js/components/support/layout/SidebarLayout/index.js';

import CreateComponentButton from '#components/pages/Components/Dashboard/Sidebar/CreateComponentButton/index.js';
import ComponentSelector
  from '#components/pages/Components/Dashboard/Sidebar/ComponentSelector/index.js';
import { treeViewInSidebar } from '#components/pages/Components/support/ComponentSelector/index.module.scss';

type Props = {
  top?: string,
};

const ComponentsDashboardSidebar = ({ top = '116px' }: Props): Element<typeof Sidebar> => {
  const navigate = useNavigate();
  const setSelected = (componentUuid) => {
    navigate(`../${componentUuid}`);
  };

  return (
    <Sidebar md="4" top={top}>
      <Row>
        <Col md="12">
          <CreateComponentButton
            color="link"
            className={classNames('create-component-button', 'float-right')}
          >
            Create Component +
          </CreateComponentButton>
        </Col>
      </Row>
      <ComponentSelector
        name="componentsComponentSelector"
        onSelect={setSelected}
        collapsable={false}
        showIncludeArchivedToggle
        additionalTreeViewClasses={treeViewInSidebar}
      />
    </Sidebar>
  );
};

export default ComponentsDashboardSidebar;
