import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import fields from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import { updateComponent } from '#actions/components/components.js';
import { selectCurrentComponentUuid } from '#selectors/entities/components.js';
import makeFormMiddleware from '#middlewares/support/makeFormMiddlware.js';
import calculateComponentPutPayload from '#support/components/calculateComponentPutPayload.js';

const componentSaveMiddleware = makeFormMiddleware({
  form: COMPONENT_FORM,
  fields,
  saveActionCreator: updateComponent,
  selectCurrentUuid: selectCurrentComponentUuid,
  calculatePayload: calculateComponentPutPayload,
});

export default componentSaveMiddleware;
