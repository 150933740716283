import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import pipe from 'lodash/fp/pipe.js';
import { underscoreErrorRegex } from '@hbrisk/sp3-risk-model-support/regularExpressions.js';
import getFieldsFlattenedWithErrors from '#support/utility/form/validation/getFieldsFlattenedWithErrors/index.js';

const makeCalculateErrorCountBySection = (
  sectionNameFromUnqualifiedFieldName
) => (
  formErrors,
  touched,
  fieldsByName,
  invisibleFields
) => pipe(
  (errors) => getFieldsFlattenedWithErrors(errors),
  (errors) => Object.keys(errors).reduce((acc, errorPath) => {
    const shouldIgnoreField = (
      (
        invisibleFields.includes(errorPath)
        || get(errorPath, touched) === undefined
      )
      && !underscoreErrorRegex.test(errorPath)
    );

    if (shouldIgnoreField) {
      return acc;
    }

    const section = sectionNameFromUnqualifiedFieldName(errorPath, fieldsByName);

    return {
      ...acc,
      [section]: getOr(0, section, acc) + errors[errorPath],
    };
  }, {}),
)(formErrors);

export default makeCalculateErrorCountBySection;
