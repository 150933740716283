/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType, Element } from 'react';

import React from 'react';
import { Field } from 'redux-form';
import mapItemsToOptions from '#components/support/forms/SelectMenus/mapItemsToOptions/index.jsx';

type Props = {|
  className?: string | null,
  component: string | ComponentType<any>,
  disabled?: boolean,
  id: string,
  items: { id: string | number, name: string | number, value: string | number }[],
  name: string,
  includeEmptyOption?: boolean,
  inputClassName?: string | null,
  validate?: Array<Function>
|}

const BasicReduxFormSelectMenu = ({
  className = null,
  component,
  disabled = false,
  id,
  items,
  name,
  includeEmptyOption = true,
  inputClassName = null,
  validate = [],
}: Props): Element<typeof Field> => (
  <Field
    id={id}
    className={className}
    component={component}
    disabled={disabled}
    name={name}
    type="select"
    inputClassName={inputClassName}
    validate={validate}
  >
    {
      includeEmptyOption && <option />
    }
    {mapItemsToOptions(items)}
  </Field>
);

export default BasicReduxFormSelectMenu;
