/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { FETCH_TERMS_REQUEST, FETCH_TERMS_RESPONSE } from '#constants/actionTypes.js';

const defaultState = {
  fetching: false,
  current: {},
};

const termsReducer = (state = defaultState, action = {}) => {
  const { error, type, payload } = action;
  switch (type) {
    case FETCH_TERMS_REQUEST:
      return {
        fetching: true,
        current: {},
      };
    case FETCH_TERMS_RESPONSE:
      return error
        ? state
        : {
          fetching: false,
          current: payload,
        };
    default:
      return state;
  }
};

export default termsReducer;
