// @flow

import type { Element } from 'react';

import React from 'react';
import type { Fields } from 'redux-form/lib/FieldArrayProps.types';
import consequenceNameFromIndexes
  from '#support/components/consequenceNameFromIndexes.js';
import type { Component } from '#types/Component/index.js';

const getHeadings = (damageStates) => damageStates.reduce((acc, damageState, i) => {
  const { consequences } = damageState;
  const hasSiblings = consequences.length > 1;
  const hs = consequences.map((consequence, j) => consequenceNameFromIndexes(i, j, hasSiblings));
  return [
    ...acc,
    ...hs,
  ];
}, []);

type EntityModeTableHeadProps = {|
  entityMode: true,
  component: Component,
|};

type FormModeTableHeadProps = {|
  entityMode: false,
  fields: Fields,
|};

type TableHeadProps = EntityModeTableHeadProps | FormModeTableHeadProps;

/* eslint-disable react/destructuring-assignment */

// In this component we purposefully avoid destructuring props so that flow can properly
// handle TableProps as union type
const TableHead = (props: TableHeadProps): Element<'thead'> => {
  const damgeStates = props.entityMode
    ? props.component.formInputs.values.damageStates
    : props.fields.getAll();

  const headings = getHeadings(damgeStates);

  return (
    <thead>
      <tr>
        <th />
        {
          headings.map((heading) => (<th key={heading}>{heading}</th>))
        }
      </tr>
    </thead>
  );
};

export default TableHead;
