import keyBy from 'lodash/fp/keyBy.js';
import get from 'lodash/fp/get.js';
import set from 'lodash/fp/set.js';
import union from 'lodash/union.js';
import { REDUX_FORM_CHANGE } from '#constants/actionTypes.js';
import structuralResponsesDefaultValueArray
  from '#constants/models/form/model/support/structuralResponsesDefaultValueArray.js';
import requiredEdpsForBuildingTypes from '#support/models/buildingType/requiredEdpsForBuildingTypes/index.js';

const defaultStructuralResponseValuesByEdpKey = keyBy('edpKey', structuralResponsesDefaultValueArray);

const adjustEDPsOnBuildingTypeChange = (prev, action) => {
  const type = get('type', action);
  const field = get('meta.field', action);

  const isBuildingTypeChange = (
    type === REDUX_FORM_CHANGE
    && ['buildingTypeDir1', 'buildingTypeDir2'].includes(field)
  );

  const { buildingTypeDir1, buildingTypeDir2, structuralResponses } = prev.values;
  const requiredEdpKeys = requiredEdpsForBuildingTypes(buildingTypeDir1, buildingTypeDir2);
  const retainedEdpKeys = Array.isArray(structuralResponses)
    ? (
      structuralResponses
        .filter((response) => !isBuildingTypeChange || response.method !== 'sp3')
        .map((response) => response.edpKey)
    )
    : [];
  const wantedEdpKeys = union(retainedEdpKeys, requiredEdpKeys);
  const nextStructuralResponses = wantedEdpKeys.reduce((acc, edpKey) => {
    const existing = Array.isArray(structuralResponses)
      ? structuralResponses.find((response) => response.edpKey === edpKey)
      : null;
    return existing
      ? [...acc, existing]
      : [...acc, defaultStructuralResponseValuesByEdpKey[edpKey]];
  }, []);
  return nextStructuralResponses;
};

const clearUploadFieldOnMethodChange = (prev, action) => {
  if (action) {
    const { meta, type } = action;
    if (type === REDUX_FORM_CHANGE) {
      const { field } = meta;
      const pattern = /^structuralResponses\[(\d+)].method$/;
      const match = field.match(pattern);
      if (match) {
        const { structuralResponses } = prev.values;
        const index = match[1];
        return set(`[${index}].upload`, null, structuralResponses);
      }
    }
  }
  return undefined;
};

export const structuralResponsesValueReducer = (prev, action) => {
  const { structuralResponses: prevStructuralResponses } = prev.values;
  const reducers = [adjustEDPsOnBuildingTypeChange, clearUploadFieldOnMethodChange];
  const name = 'structuralResponses';
  let nextStructuralResponses = reducers.reduce((acc, reducer) => {
    const nextState = acc === undefined
      ? prev
      : {
        ...prev,
        values: {
          ...prev.values || {},
          [name]: acc,
        },
      };
    const result = reducer(nextState, action);
    return result === undefined
      ? acc
      : result;
  }, undefined);

  nextStructuralResponses = nextStructuralResponses || prevStructuralResponses;

  return nextStructuralResponses;
};
