/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { SET_CURRENT_MODEL } from '#constants/actionTypes.js';

const currentReducer = (state, action) => {
  const { payload, type } = action;
  const { current } = state;
  switch (type) {
    case SET_CURRENT_MODEL:
      return payload;
    default:
      return current;
  }
};

export default currentReducer;
