/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import BuildingComponentSection from './BuildingComponentsSection.jsx';

const mapStateToProps = (state, ownProps) => ({
  hasWoodOrSteelLightFrame: modelTypesByForm[ownProps.form].selectFormFieldVisibility(state)('interiorWallsCostMultiplier'),
  hasOtherBuildingType: modelTypesByForm[ownProps.form].selectFormFieldVisibility(state)('structuralCostMultiplier'),
  hasComponentModifiers: modelTypesByForm[ownProps.form].selectFormFieldVisibility(state)('ceilingsCapacityMultiplier'),
});

export default connect(mapStateToProps)(BuildingComponentSection);
