/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// eslint-disable-next-line import/extensions
import { signOut } from 'aws-amplify/auth';
import { SIGN_OUT_USER } from '#constants/actionTypes.js';

const signOutMiddleware = () => (next) => (action) => {
  const { type } = action;
  if (type === SIGN_OUT_USER) {
    signOut();
    localStorage.clear();
  }
  return next(action);
};

export default signOutMiddleware;
