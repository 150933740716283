export default [
  {
    lng: -125.1545699,
    lat: 49.25363276,
  },
  {
    lng: -95.61155914,
    lat: 49.53104359,
  },
  {
    lng: -95.04704301,
    lat: 49.71598415,
  },
  {
    lng: -94.52956989,
    lat: 49.11492734,
  },
  {
    lng: -88.55510753,
    lat: 48.56010568,
  },
  {
    lng: -83.56854839,
    lat: 46.52575958,
  },
  {
    lng: -82.39247312,
    lat: 45.64729194,
  },
  {
    lng: -81.78091398,
    lat: 44.49141347,
  },
  {
    lng: -81.875,
    lat: 43.33553501,
  },
  {
    lng: -82.62768817,
    lat: 42.22589168,
  },
  {
    lng: -80.27553763,
    lat: 42.82694848,
  },
  {
    lng: -79.61693548,
    lat: 43.10435931,
  },
  {
    lng: -79.28763441,
    lat: 43.98282695,
  },
  {
    lng: -76.98252688,
    lat: 44.07529723,
  },
  {
    lng: -75.57123656,
    lat: 45.23117569,
  },
  {
    lng: -71.90188172,
    lat: 45.36988111,
  },
  {
    lng: -69.59677419,
    lat: 47.77410832,
  },
  {
    lng: -68.18548387,
    lat: 47.68163804,
  },
  {
    lng: -67.4327957,
    lat: 47.17305152,
  },
  {
    lng: -67.05645161,
    lat: 45.97093791,
  },
  {
    lng: -66.30376344,
    lat: 44.81505945,
  },
  {
    lng: -69.97311828,
    lat: 43.24306473,
  },
  {
    lng: -69.6438172,
    lat: 42.22589168,
  },
  {
    lng: -69.78494624,
    lat: 40.79260238,
  },
  {
    lng: -73.59543011,
    lat: 40.05284016,
  },
  {
    lng: -75.33602151,
    lat: 37.09379128,
  },
  {
    lng: -74.95967742,
    lat: 35.29062087,
  },
  {
    lng: -78.86424731,
    lat: 33.16380449,
  },
  {
    lng: -80.74596774,
    lat: 31.40686922,
  },
  {
    lng: -80.9811828,
    lat: 30.667107,
  },
  {
    lng: -79.75806452,
    lat: 27.29194188,
  },
  {
    lng: -79.71102151,
    lat: 25.95112285,
  },
  {
    lng: -80.08736559,
    lat: 24.79524439,
  },
  {
    lng: -81.02822581,
    lat: 24.332893,
  },
  {
    lng: -82.2983871,
    lat: 24.10171731,
  },
  {
    lng: -83.00403226,
    lat: 24.56406869,
  },
  {
    lng: -81.63978495,
    lat: 25.21136063,
  },
  {
    lng: -83.28629032,
    lat: 27.43064729,
  },
  {
    lng: -83.09811828,
    lat: 28.91017173,
  },
  {
    lng: -83.99193548,
    lat: 29.60369881,
  },
  {
    lng: -85.26209677,
    lat: 29.18758256,
  },
  {
    lng: -86.20295699,
    lat: 29.92734478,
  },
  {
    lng: -88.41397849,
    lat: 29.8348745,
  },
  {
    lng: -89.07258065,
    lat: 28.6327609,
  },
  {
    lng: -93.54166667,
    lat: 29.37252312,
  },
  {
    lng: -96.64650538,
    lat: 27.84676354,
  },
  {
    lng: -96.92876344,
    lat: 26.55217966,
  },
  {
    lng: -96.88172043,
    lat: 25.62747688,
  },
  {
    lng: -97.39919355,
    lat: 25.35006605,
  },
  {
    lng: -99.375,
    lat: 26.18229855,
  },
  {
    lng: -101.2567204,
    lat: 29.14134742,
  },
  {
    lng: -102.4327957,
    lat: 29.37252312,
  },
  {
    lng: -102.9502688,
    lat: 28.72523118,
  },
  {
    lng: -103.844086,
    lat: 28.72523118,
  },
  {
    lng: -105.1142473,
    lat: 29.97357992,
  },
  {
    lng: -106.6666667,
    lat: 31.2681638,
  },
  {
    lng: -111.4650538,
    lat: 31.08322325,
  },
  {
    lng: -114.9462366,
    lat: 32.23910172,
  },
  {
    lng: -117.3924731,
    lat: 32.23910172,
  },
  {
    lng: -119.3212366,
    lat: 32.74768824,
  },
  {
    lng: -121.0147849,
    lat: 33.90356671,
  },
  {
    lng: -123.0376344,
    lat: 37.18626156,
  },
  {
    lng: -124.7782258,
    lat: 40.42272127,
  },
  {
    lng: -124.7311828,
    lat: 44.58388375,
  },
  {
    lng: -125.3897849,
    lat: 48.51387054,
  },
  {
    lng: -154.4623656,
    lat: 19.33949802,
  },
  {
    lng: -156.061828,
    lat: 18.41479524,
  },
  {
    lng: -157.0967742,
    lat: 20.0330251,
  },
  {
    lng: -160.3427419,
    lat: 21.42007926,
  },
  {
    lng: -168.2459677,
    lat: 24.88771466,
  },
  {
    lng: -174.3145161,
    lat: 25.71994716,
  },
  {
    lng: -174.2674731,
    lat: 26.50594452,
  },
  {
    lng: -167.6344086,
    lat: 25.30383091,
  },
  {
    lng: -161.4247312,
    lat: 23.08454425,
  },
  {
    lng: -158.3669355,
    lat: 22.20607662,
  },
  {
    lng: -154.5564516,
    lat: 19.94055482,
  },
  {
    lng: -125.5,
    lat: 49.23513871,
  },
  {
    lng: -133.7163978,
    lat: 54.47820343,
  },
  {
    lng: -140.0201613,
    lat: 59.10171731,
  },
  {
    lng: -145.1478495,
    lat: 59.51783355,
  },
  {
    lng: -147.2647849,
    lat: 58.87054161,
  },
  {
    lng: -155.4502688,
    lat: 55.35667107,
  },
  {
    lng: -161.6129032,
    lat: 53.73844122,
  },
  {
    lng: -169.1397849,
    lat: 52.12021136,
  },
  {
    lng: -179.1599462,
    lat: 50.77939234,
  },
  {
    lng: -183.3938172,
    lat: 51.334214,
  },
  {
    lng: -188.1451613,
    lat: 52.62879789,
  },
  {
    lng: -187.6747312,
    lat: 53.50726552,
  },
  {
    lng: -185.6048387,
    lat: 53.18361955,
  },
  {
    lng: -182.0295699,
    lat: 52.7675033,
  },
  {
    lng: -178.125,
    lat: 52.53632761,
  },
  {
    lng: -172.6680108,
    lat: 53.04491413,
  },
  {
    lng: -168.8575269,
    lat: 53.96961691,
  },
  {
    lng: -164.2002688,
    lat: 55.49537649,
  },
  {
    lng: -158.5551075,
    lat: 57.85336856,
  },
  {
    lng: -167.7755376,
    lat: 59.65653897,
  },
  {
    lng: -167.7284946,
    lat: 60.58124174,
  },
  {
    lng: -166.4583333,
    lat: 61.04359313,
  },
  {
    lng: -166.6465054,
    lat: 61.9682959,
  },
  {
    lng: -164.811828,
    lat: 63.49405548,
  },
  {
    lng: -161.8481183,
    lat: 63.95640687,
  },
  {
    lng: -163.3534946,
    lat: 64.2338177,
  },
  {
    lng: -166.4112903,
    lat: 64.09511229,
  },
  {
    lng: -167.4932796,
    lat: 64.64993395,
  },
  {
    lng: -168.2930108,
    lat: 63.12417437,
  },
  {
    lng: -169.469086,
    lat: 62.52311757,
  },
  {
    lng: -171.9153226,
    lat: 60.30383091,
  },
  {
    lng: -169.9395161,
    lat: 57.39101717,
  },
  {
    lng: -169.0927419,
    lat: 56.55878468,
  },
  {
    lng: -169.9865591,
    lat: 56.14266843,
  },
  {
    lng: -170.7392473,
    lat: 57.2985469,
  },
  {
    lng: -170.5510753,
    lat: 57.71466314,
  },
  {
    lng: -172.1975806,
    lat: 59.84147952,
  },
  {
    lng: -173.655914,
    lat: 60.58124174,
  },
  {
    lng: -173.1384409,
    lat: 61.13606341,
  },
  {
    lng: -172.1505376,
    lat: 60.71994716,
  },
  {
    lng: -170.0336022,
    lat: 62.70805812,
  },
  {
    lng: -172.3387097,
    lat: 63.21664465,
  },
  {
    lng: -171.7741935,
    lat: 64.28005284,
  },
  {
    lng: -168.5752688,
    lat: 63.77146631,
  },
  {
    lng: -168.1048387,
    lat: 64.55746367,
  },
  {
    lng: -169.327957,
    lat: 65.75957728,
  },
  {
    lng: -168.5282258,
    lat: 66.17569353,
  },
  {
    lng: -164.2473118,
    lat: 66.96169089,
  },
  {
    lng: -167.3521505,
    lat: 68.21003963,
  },
  {
    lng: -166.7876344,
    lat: 69.22721268,
  },
  {
    lng: -163.3064516,
    lat: 70.29062087,
  },
  {
    lng: -159.4489247,
    lat: 71.35402906,
  },
  {
    lng: -154.5564516,
    lat: 71.67767503,
  },
  {
    lng: -140.8198925,
    lat: 70.33685601,
  },
  {
    lng: -140.4905914,
    lat: 60.85865258,
  },
  {
    lng: -138.702957,
    lat: 60.67371202,
  },
  {
    lng: -137.1975806,
    lat: 59.51783355,
  },
  {
    lng: -136.1155914,
    lat: 60.39630119,
  },
  {
    lng: -133.9045699,
    lat: 59.332893,
  },
  {
    lng: -129.5766129,
    lat: 56.4663144,
  },
  {
    lng: -129.6236559,
    lat: 54.52443857,
  },
  {
    lng: -131.2231183,
    lat: 53.87714663,
  },
  {
    lng: -125.1545699,
    lat: 49.25363276,
  },
];
