import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import getComponent from '#components/support/polling/support/withPollingOnMount.jsx';
import { startPollingModel, stopPollingModel } from '#actions/models/model.js';

const getContainer = (C) => {
  const mapDispatchToProps = (dispatch, ownProps) => {
    const { modelId } = ownProps;
    return ({
      poll: () => {
        if (modelId) {
          dispatch(startPollingModel(modelId));
          return () => {
            dispatch(stopPollingModel(modelId));
          };
        }
        return () => {};
      },
    });
  };

  return connect(undefined, mapDispatchToProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
