/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import keys from 'lodash/fp/keys.js';
import classNames from 'classnames';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import { materialTypes, buildingTypesByMaterial } from '#constants/buildingTypes/index.js';
import BuildingTypeOptionGroup from './BuildingTypeOptionGroup/index.jsx';

type Props = {|
  form: string,
  id: string,
  isJapan: boolean,
|};

const BuildingTypeSelectMenu = (props: Props): Element<typeof Field> => {
  const { form, id, isJapan } = props;

  const options = keys(materialTypes).map((materialType) => (
    <BuildingTypeOptionGroup
      key={materialType}
      buildingTypes={buildingTypesByMaterial[materialType]}
      materialDescription={materialTypes[materialType]}
      isJapan={isJapan}
    />
  ));
  return (
    <Field
      form={form}
      id={id}
      name={id}
      type="select"
      component={RenderInput}
      inputClassName={classNames('custom-select', 'building-type-select')}
    >
      <option />
      {options}
    </Field>
  );
};

export default BuildingTypeSelectMenu;
