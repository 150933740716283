/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import RepairCostByIntensityPlot from './RepairCostByIntensityPlot/index.js';
import RepairCostByIntensityTable from './RepairCostByIntensityTable/index.js';

type Props = {|
  heading: string,
  modelId: string,
  onBack: Function,
  onNext: Function,
|};

const RepairCostByIntensitySection = ({
  heading, modelId, onBack, onNext,
}: Props): Element<'div'> => (
  <div>
    <h2>
      {heading}
      {' '}
      <Tip tipId="repairCostByIntensity" />
    </h2>
    <div className="pt-2 pb-2">
      <h5>Loss metrics across all intensity levels analyzed</h5>
      <RepairCostByIntensityPlot modelId={modelId} />
    </div>
    <div className="pt-2 pb-2">
      <h5>Expected loss normalized by building cost</h5>
      <RepairCostByIntensityTable modelId={modelId} />
    </div>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </div>
);

export default RepairCostByIntensitySection;
