/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { signInUser, fetchUser } from '#actions/users/users.js';
import withLocation from '#components/support/routing/withLocation/index.jsx';
import { SIGN_IN_FORM } from '#constants/users/form/signIn/name.js';
import { selectAuthUserTokenIsSet, selectAuthUserOrgIsSet } from '#selectors/entities/users.js';
import SignInForm from './SignInForm.jsx';

const initialValues = {
  email: '',
  password: '',
};

const onSubmit = (values, dispatch) => (
  dispatch(signInUser(values.email, values.password, dispatch))
    .then((response) => {
      // if the user was authenticated, fetch the user's data
      if (response.payload.authUserId) {
        dispatch(fetchUser(response.payload.authUserId));
      }
    })
    .catch((response) => Promise.reject(new SubmissionError({ _error: response.payload.message })))
);

const mapStateToProps = (state) => (
  {
    tokenIsSet: selectAuthUserTokenIsSet(state),
    orgIsSet: selectAuthUserOrgIsSet(state),
  }
);

export default pipe(
  reduxForm({
    initialValues,
    form: SIGN_IN_FORM,
    onSubmit,
  }),
  connect(mapStateToProps),
  withLocation,
)(SignInForm);
