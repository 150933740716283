/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import keyBy from 'lodash/fp/keyBy.js';

export const COMPONENT_SELECTION_METHOD_AUTOMATIC = 'automatic';
export const COMPONENT_SELECTION_METHOD_CUSTOM = 'custom';

const methods = [
  { id: COMPONENT_SELECTION_METHOD_AUTOMATIC, name: 'SP3 Automated' },
  { id: COMPONENT_SELECTION_METHOD_CUSTOM, name: 'User Defined' },
];

export const methodById = keyBy('id', methods);

export default methods;
