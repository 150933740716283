/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 12/14/2016
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import Admin from '#components/pages/Admin/index.js';
import Components from '#components/pages/Components/index.js';
import Models from '#components/pages/Models/index.js';
import ResetPassword from '#components/pages/ResetPassword/index.js';
import SignIn from '#components/pages/SignIn/index.js';
import Maintenance from '#components/pages/Maintenanace/index.js';
import RequireMaintenanceOff from '#components/support/routing/RequireMaintenanceOff/index.js';
import RequireAdminPermissions from '#components/support/routing/RequireAdminPermissions/index.js';
import RequireAuthentication from '#components/support/routing/RequireAuthentication/index.js';
import RequireType2ModelPermissions from '#components/support/routing/RequireType2ModelPermissions/index.js';
import {
  ADMIN,
  COMPONENTS,
  MAINTENANCE,
  MODELS,
  RESET_PASSWORD,
  SIGN_IN,
} from '#constants/pathStrings.js';

type Store = {
  dispatch: Function,
  getState: Function,
  replaceReducer: Function,
  subscribe: Function,
};

type Props = {|
  store: Store,
|};

/**
 * Defines the app's root component and router. Also makes Redux store
 * available to all sub-components
 */

const App = ({ store }: Props): Element<typeof Provider> => (
  <Provider store={store}>
    <Router>
      <Routes>
        <Route
          index
          element={(
            <RequireMaintenanceOff>
              <SignIn />
            </RequireMaintenanceOff>
          )}
        />
        <Route
          path={`/${MAINTENANCE}`}
          element={<Maintenance />}
        />
        <Route
          path={`/${SIGN_IN}`}
          element={(
            <RequireMaintenanceOff>
              <SignIn />
            </RequireMaintenanceOff>
          )}
        />
        <Route
          path={`/${RESET_PASSWORD}`}
          element={(
            <RequireMaintenanceOff>
              <ResetPassword />
            </RequireMaintenanceOff>
          )}
        />
        <Route
          path={`/${ADMIN}/*`}
          element={(
            <RequireAdminPermissions>
              <Admin />
            </RequireAdminPermissions>
          )}
        />
        <Route
          path={`/${COMPONENTS}/*`}
          element={(
            <RequireType2ModelPermissions>
              <Components />
            </RequireType2ModelPermissions>
          )}
        />
        <Route
          path={`/${MODELS}/*`}
          element={(
            <RequireAuthentication>
              <Models />
            </RequireAuthentication>
          )}
        />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Router>
  </Provider>
);

export default App;
