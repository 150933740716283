import kebabCase from 'lodash/fp/kebabCase.js';
import * as componentSectionNames from '@hbrisk/sp3-risk-model-support/components/sections/names.js';

const pathFromSection = (section) => {
  const { id, path } = section;
  if (path) {
    return path;
  }
  return id ? `${kebabCase(id)}` : undefined;
};

const urlFromSection = (section) => {
  const { id, url } = section;
  if (url) {
    return url;
  }
  return id ? `${kebabCase(id)}` : undefined;
};

const baseSections = [
  {
    id: componentSectionNames.GENERAL,
    heading: 'General',
  },
  {
    id: componentSectionNames.FRAGILITY_INFO,
    heading: 'Fragility Info',
  },
  {
    id: componentSectionNames.COMPONENT_GROUPING,
    heading: 'Component Grouping',
  },
  {
    id: componentSectionNames.CAPACITY_CALCULATION,
    heading: 'Capacity Calculation',
  },
  {
    id: componentSectionNames.RECOVERY_TIME,
    heading: 'Recovery Time',
  },
  {
    id: componentSectionNames.SYSTEM_SPECIFIC_INFO,
    heading: 'System Specific Info',
  },
  {
    id: componentSectionNames.DAMAGE_STATES,
    heading: 'Damage States',
  },
  {
    id: componentSectionNames.CONSEQUENCES,
    heading: 'Consequences',
  },
  {
    id: componentSectionNames.CONSEQUENCES_REPAIR_COST,
    heading: 'Consequences - Repair Cost',
    path: 'consequences/repair-cost',
    url: 'consequences/repair-cost',
  },
  {
    id: componentSectionNames.CONSEQUENCES_REPAIR_TIME,
    heading: 'Consequences - Repair Time',
    path: 'consequences/repair-time',
    url: 'consequences/repair-time',
  },
  {
    id: componentSectionNames.CONSEQUENCES_LIFE_SAFETY,
    heading: 'Consequences - Life Safety',
    path: 'consequences/life-safety',
    url: 'consequences/life-safety',
  },
  {
    id: componentSectionNames.CONSEQUENCES_RECOVERY_TIME,
    heading: 'Consequences - Recovery Time',
    path: 'consequences/recovery-time',
    url: 'consequences/recovery-time',
  },
  {
    id: componentSectionNames.CONSEQUENCES_OTHER,
    heading: 'Consequences - Other',
    path: 'consequences/other',
    url: 'consequences/other',
  },
  {
    id: componentSectionNames.MODEL_USAGE,
    heading: 'Model Usage',
  },
];

const sections = baseSections.map((section) => ({
  ...section,
  path: pathFromSection(section),
  url: urlFromSection(section),
}));

export default sections;
