import { merge } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
  FETCH_ACTION,
  FETCH_COMPONENT_CATEGORIES_REQUEST,
  FETCH_COMPONENT_CATEGORIES_RESPONSE,
  FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_REQUEST,
  FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_RESPONSE,
} from '#constants/actionTypes.js';
import { selectComponentCategoriesLastUpdated } from '#selectors/entities/componentCategories.js';

const componentCategoriesSchema = new schema.Entity('componentCategories', undefined, {
  idAttribute: 'uuid',
});

const fetchComponentCategoriesLastUpdated = ({
  [FETCH_ACTION]: {
    types: [
      FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_REQUEST,
      FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_RESPONSE,
    ],
    endpoint: '/componentCategory/lastUpdated',
    options: {
      method: 'GET',
    },
  },
});

const fetchComponentCategoriesData = {
  [FETCH_ACTION]: {
    types: [FETCH_COMPONENT_CATEGORIES_REQUEST, FETCH_COMPONENT_CATEGORIES_RESPONSE],
    endpoint: '/componentCategory',
    options: {
      method: 'GET',
    },
    dataTransform: (json) => {
      const defaultEntities = { componentCategories: {} };
      const { result, entities } = normalize(json, [componentCategoriesSchema]);
      return {
        result,
        entities: merge(defaultEntities, entities),
      };
    },
  },
};

export const fetchComponentCategories = async (dispatch, getState) => {
  const state = getState();
  const previousLastUpdated = selectComponentCategoriesLastUpdated(state);

  const { payload } = await dispatch(fetchComponentCategoriesLastUpdated);
  const { lastUpdated } = payload;
  if (!previousLastUpdated || new Date(lastUpdated) > new Date(previousLastUpdated)) {
    dispatch(fetchComponentCategoriesData);
  }
};
