/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { spinner } from './index.module.scss';

type Props = {
  children?: Node,
  className?: string | null,
  color?: string,
  disabled?: boolean,
  onClick?: Function,
  submitting?: boolean,
  submittingText?: string,
  type?: string,
  tag?: string,
}

const SubmitButton = ({
  children = 'Submit',
  className = null,
  color = 'primary',
  disabled = false,
  onClick = null,
  submitting = false,
  submittingText = 'Submitting...',
  tag = 'button',
  type = 'submit',
}: Props): Element<typeof Button> => (
  submitting
    ? (
      <Button className={className} color={color} tag={tag} type={type} disabled>
        <Spinner size="sm" className={spinner} />
        {' '}
        {submittingText}
      </Button>
    )
    : (
      <Button
        disabled={disabled}
        className={className}
        color={color}
        onClick={onClick}
        tag={tag}
        type={type}
      >
        {children}
      </Button>
    )
);

export default SubmitButton;
