/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import makeComponentFormValidator from '@hbrisk/sp3-risk-model-support/components/form/validation/formValidator/index.js';
import { selectVisibleFields, selectAutofilled } from '#selectors/ui/form/component.js';

const withFormValidation = (C) => {
  const mapStateToProps = (state) => ({
    validate: makeComponentFormValidator(
      selectAutofilled(state),
      selectVisibleFields(state),
      state
    ),
  });
  return connect(mapStateToProps)(C);
};

export default withFormValidation;
