/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import getDesignCodeYearDescFromDesignCodeYear from '#support/models/getDesignCodeYearDescFromDesignCodeYear.js';
import SelectMenu from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const years = [
  2022, 2016, 2010, 2005, 2002, 1997,
  1994, 1991, 1988, 1985,
  1982, 1979, 1976, 1973,
  1967, 1964, 1961, 1958,
  1955, 1952, 1949, 1946,
  1943, 1940, 1937, 1935,
  1927,
];

const getDesc = (year: number): string => {
  if (year === 2022) {
    return 'ASCE 2022/ASCE 2016 (non-struct/struct)';
  }
  return getDesignCodeYearDescFromDesignCodeYear(year);
};

const items = years.map((year) => ({
  id: year, name: getDesc(year), value: year,
}));

const CodeYearSelectMenu = (props: Props): Element<typeof Field> => {
  const { form, name } = props;
  return (
    <SelectMenu
      form={form}
      id={name}
      name={name}
      type="select"
      items={items}
      inputClassName={classNames('custom-select', 'code-year-select')}
    />
  );
};

export default CodeYearSelectMenu;
