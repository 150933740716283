import { connect } from 'react-redux';

import { selectAuthUserTokenIsSet, selectAuthUserHasType2ModelPermissions } from '#selectors/entities/users.js';
import RequireType2ModelPermissions from './RequireType2ModelPermissions.jsx';

const mapStateToProps = (state) => ({
  hasType2ModelPermissions: selectAuthUserHasType2ModelPermissions(state),
  isAuthenticated: selectAuthUserTokenIsSet(state),
});

export default connect(mapStateToProps)(RequireType2ModelPermissions);
