import { connect } from 'react-redux';
import AddDamageStateButton
  from '#components/pages/Components/Component/sections/DamageStates/renderDamageSatesTable/AddDamageStateButton/AddDamageStateButton.jsx';
import { selectConsequenceMaxExceeded, selectDamageStateMaxExceeded } from '#selectors/ui/form/component.js';

const mapStateToProps = (state) => ({
  disabled: selectDamageStateMaxExceeded(state),
  includeConsequence: !selectConsequenceMaxExceeded(state),
});

export default connect(mapStateToProps)(AddDamageStateButton);
