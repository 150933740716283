/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import { connect } from 'react-redux';
import { copyModel, toggleModelArchive } from '#actions/models/model.js';
import { selectAuthUserId, selectAuthUserOrgId } from '#selectors/entities/users.js';
import { selectModelIsCopying, selectModelIsTogglingArchive } from '#selectors/entities/models.js';
import Action from './ActionCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  userId: selectAuthUserId(state),
  orgId: selectAuthUserOrgId(state),
  isCopying: selectModelIsCopying(state, { modelId: ownProps.uuid }),
  isTogglingArchive: selectModelIsTogglingArchive(state, { modelId: ownProps.uuid }),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const uuid = get('uuid', ownProps);
  const archived = get('archived', ownProps);
  return {
    makeHandleCopy: (userId, orgId) => () => {
      dispatch(copyModel(userId, orgId, uuid));
    },
    handleArchiveToggle: () => {
      dispatch(toggleModelArchive(uuid, !archived));
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userId, orgId } = stateProps;
  const { makeHandleCopy, handleArchiveToggle } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    handleCopy: makeHandleCopy(userId, orgId),
    handleArchiveToggle,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Action);
