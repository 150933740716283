/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import debounce from 'lodash/fp/debounce.js';
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { MODELS_TABLE_FORM } from '#constants/models/form/modelsTable/name.js';
import { selectModelsTableFilters } from '#selectors/ui/modelsTable.js';
import { clearFilters, setFilters } from '#actions/models/modelsTable.js';
import ModelsFilterForm from './ModelsFilterForm.jsx';

const form = MODELS_TABLE_FORM;

const onSubmit = () => {};

const onChange = debounce(1000, (values, dispatch) => {
  dispatch(setFilters(values));
});

const mapStateToProps = (state) => ({
  initialValues: selectModelsTableFilters(state),
  form,
});

const mapDispatchToProps = (dispatch) => ({
  handleClearFilters: () => dispatch(clearFilters()),
});

export default pipe(
  reduxForm({
    form,
    onSubmit,
    onChange,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ModelsFilterForm);
