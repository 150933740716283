/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import RequireAuthorization from '#components/support/routing/RequireAuthorization/index.jsx';
import { MODELS } from '#constants/pathStrings.js';

type Props = {|
  children: Node,
  isAdmin: boolean,
  isAuthenticated: boolean,
  redirectTo?: string,
|};

const RequireAdminPermissions = ({
  children,
  isAdmin,
  isAuthenticated,
  redirectTo = `/${MODELS}`,
}: Props): Element<typeof RequireAuthorization> => (
  <RequireAuthorization
    isAuthorized={isAdmin && isAuthenticated}
    redirectTo={redirectTo}
  >
    {children}
  </RequireAuthorization>
);

export default RequireAdminPermissions;
