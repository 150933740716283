/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const items = [
  { id: 'private', name: 'Private Loans', value: 'private' },
  { id: 'sba', name: 'SBA Backed Loans', value: 'sba' },
  { id: 'insurance', name: 'Insurance', value: 'insurance' },
];

type Props = {|
  form: string,
  name: string,
|};

const FundingSourceSelectMenu = ({ form, name }: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'funding-source-select')}
  />
);

export default FundingSourceSelectMenu;
