/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';

type Props = {|
  onClick: Function,
|};

const BackButton = ({ onClick }: Props): Element<typeof Button> => (
  <Button id="back" className="float-left" onClick={onClick}>
    Previous
  </Button>
);

export default BackButton;
