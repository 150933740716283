/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { GENERAL } from '@hbrisk/sp3-risk-model-support/components/sections/names.js';
import { createComponent, copyComponent } from '#actions/components/components.js';
import withNavigate from '#components/support/routing/withNavigate/index.jsx';
import withLocation from '#components/support/routing/withLocation/index.jsx';
import {
  selectAuthUserId,
  selectAuthUserOrgId,
} from '#selectors/entities/users.js';
import CreateComponentButton from './CreateComponentButton.jsx';

const mapStateToProps = (state) => ({
  userId: selectAuthUserId(state),
  orgId: selectAuthUserOrgId(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { navigate, sourceComponentUuid } = ownProps;
  const makeHandleClick = (userId, orgId) => async () => {
    const { payload: { uuid } } = sourceComponentUuid
      ? await dispatch(copyComponent(userId, orgId, sourceComponentUuid))
      : await dispatch(createComponent(userId, orgId));
    const pathPrefix = sourceComponentUuid ? '../..' : '..';
    navigate(`${pathPrefix}/${uuid}/${GENERAL}`);
  };

  return {
    makeHandleClick,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    userId,
    orgId,
  } = stateProps;
  const { makeHandleClick } = dispatchProps;
  return ({
    ...ownProps,
    handleClick: makeHandleClick(
      userId,
      orgId
    ),
  });
};

const CreateComponentButtonContainer = pipe(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withNavigate,
  withLocation
)(CreateComponentButton);

export default CreateComponentButtonContainer;
