import { merge } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
  _FORM_ATTRIBUTES,
  NAME,
  CREATED_BY_ID,
  ORG_ID,
  SOURCE_COMPONENT_UUID, UUID, ARCHIVED,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import {
  CREATE_COMPONENT_REQUEST,
  CREATE_COMPONENT_RESPONSE,
  FETCH_ACTION,
  FETCH_COMPONENT_REQUEST,
  FETCH_COMPONENT_RESPONSE,
  FETCH_COMPONENT_HAS_UPDATE_REQUEST,
  FETCH_COMPONENT_HAS_UPDATE_RESPONSE,
  FETCH_COMPONENTS_LAST_UPDATED_REQUEST,
  FETCH_COMPONENTS_LAST_UPDATED_RESPONSE,
  FETCH_COMPONENTS_BY_RANGE_REQUEST,
  FETCH_COMPONENTS_BY_RANGE_RESPONSE,
  SET_CURRENT_COMPONENT,
  UNSET_CURRENT_COMPONENT,
  UPDATE_COMPONENT_REQUEST,
  UPDATE_COMPONENT_RESPONSE,
  TOGGLE_COMPONENT_ARCHIVE_START,
  TOGGLE_COMPONENT_ARCHIVE_END,
} from '#constants/actionTypes.js';
import {
  selectComponentLastUpdated,
  selectComponentMaxUpdatedAtByUuid,
  selectComponentModelPopulationItemsCountByUuid,
  selectComponentRunPopulationItemsCountByUuid,
} from '#selectors/entities/components.js';

export const COMPONENT_UUID = 'componentUuid';

const componentSchema = new schema.Entity('components', undefined, {
  idAttribute: 'uuid',
});

const fetchComponentLastUpdated = (orgId, updatedAfter) => {
  const updatedAfterParam = updatedAfter ? `?updatedAfter=${updatedAfter}` : '';
  return ({
    [FETCH_ACTION]: {
      types: [FETCH_COMPONENTS_LAST_UPDATED_REQUEST, FETCH_COMPONENTS_LAST_UPDATED_RESPONSE],
      endpoint: `/org/${orgId}/component/lastUpdated${updatedAfterParam}`,
      options: {
        method: 'GET',
      },
    },
  });
};

const fetchComponentsByRange = (orgId, offset, limit, updatedAfter) => {
  const updatedAfterParam = updatedAfter ? `&updatedAfter=${updatedAfter}` : '';
  return ({
    [FETCH_ACTION]: {
      types: [FETCH_COMPONENTS_BY_RANGE_REQUEST, FETCH_COMPONENTS_BY_RANGE_RESPONSE],
      endpoint: `/org/${orgId}/component?offset=${offset}&limit=${limit}${updatedAfterParam}`,
      options: {
        method: 'GET',
      },
      meta: {
        offset,
        limit,
        validationTime: updatedAfter,
      },
      dataTransform: (json) => {
        const defaultEntities = { components: {} };
        const { result, entities } = normalize(json, [componentSchema]);
        return {
          result,
          entities: merge(defaultEntities, entities),
        };
      },
    },
  });
};

// Note: this will not fetch an updated collection of components if a component
// is deleted form the database
export const fetchComponents = (orgId) => async (dispatch, getState) => {
  const BIN_SIZE = 300;
  const state = getState();
  const previousLastUpdated = selectComponentLastUpdated(state);
  const { payload } = await dispatch(fetchComponentLastUpdated(orgId, previousLastUpdated));
  const { lastUpdated, updatedCount } = payload;
  if (!previousLastUpdated || new Date(lastUpdated) > new Date(previousLastUpdated)) {
    const numBins = Math.ceil(updatedCount / BIN_SIZE);
    for (let i = 0; i < numBins; i += 1) {
      dispatch(fetchComponentsByRange(orgId, i * BIN_SIZE, BIN_SIZE, previousLastUpdated));
    }
  }
};

export const fetchComponentHasUpdate = (componentUuid, maxUpdatedAt, modelItems, runItems) => ({
  [FETCH_ACTION]: {
    types: [FETCH_COMPONENT_HAS_UPDATE_REQUEST, FETCH_COMPONENT_HAS_UPDATE_RESPONSE],
    endpoint: `/component/${componentUuid}/hasUpdate?maxUpdatedAt=${maxUpdatedAt}&modelItems=${modelItems}&runItems=${runItems}`,
    options: {
      method: 'GET',
    },
  },
});

export const fetchComponent = (componentUuid) => ({
  [FETCH_ACTION]: {
    types: [FETCH_COMPONENT_REQUEST, FETCH_COMPONENT_RESPONSE],
    endpoint: `/component/${componentUuid}`,
    options: {
      method: 'GET',
    },
  },
});

export const fetchComponentIfUpdated = (componentUuid) => async (dispatch, getState) => {
  const state = getState();
  const previousMaxUpdatedAt = selectComponentMaxUpdatedAtByUuid(state, { uuid: componentUuid });
  const modelItems = selectComponentModelPopulationItemsCountByUuid(state, { uuid: componentUuid });
  const runItems = selectComponentRunPopulationItemsCountByUuid(state, { uuid: componentUuid });

  if (!previousMaxUpdatedAt) {
    return dispatch(fetchComponent(componentUuid));
  }
  const {
    payload: { hasUpdate },
  } = await dispatch(fetchComponentHasUpdate(
    componentUuid,
    previousMaxUpdatedAt,
    modelItems,
    runItems
  ));

  if (hasUpdate) {
    return dispatch(fetchComponent(componentUuid));
  }
  return undefined;
};

export const createComponent = (createdById, orgId) => ({
  [FETCH_ACTION]: {
    types: [CREATE_COMPONENT_REQUEST, CREATE_COMPONENT_RESPONSE],
    endpoint: '/component',
    options: {
      method: 'POST',
      body: {
        [CREATED_BY_ID]: createdById,
        [ORG_ID]: orgId,
        [_FORM_ATTRIBUTES]: {
          [NAME]: 'New Component',
        },
      },
    },
  },
});

export const updateComponent = ({ uuid, payload }) => ({
  [FETCH_ACTION]: {
    types: [UPDATE_COMPONENT_REQUEST, UPDATE_COMPONENT_RESPONSE],
    endpoint: `/component/${uuid}`,
    options: {
      method: 'PUT',
      body: payload,
    },
    meta: {
      [COMPONENT_UUID]: uuid,
    },
  },
});

export const setCurrentComponent = (componentUuid) => ({
  type: SET_CURRENT_COMPONENT,
  payload: componentUuid,
});

export const unsetCurrentComponent = () => ({
  type: UNSET_CURRENT_COMPONENT,
  payload: null,
});

export const copyComponent = (createdById, orgId, sourceComponentUuid) => ({
  [FETCH_ACTION]: {
    types: [CREATE_COMPONENT_REQUEST, CREATE_COMPONENT_RESPONSE],
    endpoint: '/component',
    options: {
      method: 'POST',
      body: {
        [CREATED_BY_ID]: createdById,
        [ORG_ID]: orgId,
        [SOURCE_COMPONENT_UUID]: sourceComponentUuid,
      },
    },
  },
});

const toggleComponentArchiveStart = (uuid) => ({
  type: TOGGLE_COMPONENT_ARCHIVE_START,
  meta: { componentUuid: uuid },
});

const toggleComponentArchiveEnd = (uuid) => ({
  type: TOGGLE_COMPONENT_ARCHIVE_END,
  meta: { componentUuid: uuid },
});

export const toggleComponentArchived = (uuid, archived) => async (dispatch) => {
  dispatch(toggleComponentArchiveStart(uuid));
  await dispatch(
    updateComponent({ [UUID]: uuid, payload: { [ARCHIVED]: archived } })
  );
  dispatch(toggleComponentArchiveEnd(uuid));
};
