// @flow
import type { Element } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  SidebarLayout, MainContentArea,
} from '#src/js/components/support/layout/SidebarLayout/index.js';
import Sidebar from '#components/pages/Components/Component/Sidebar/index.jsx';
import SubNav from '#components/pages/Components/Component/SubNav/index.js';
import Page from '#components/support/layout/Page/index.js';
import Header from '#components/support/layout/Header/index.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';
import Router from '#components/pages/Components/Component/support/Router/index.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

type Props = {
  className?: string,
  includeHeader?: boolean,
};

const Component = ({ includeHeader = true, className = 'component-page' }: Props): Element<typeof Page> => {
  const { [COMPONENT_UUID]: componentUuid } = useParams();
  return (
    <Page className={className}>
      {
        includeHeader && <Header />
      }
      <MainPageContainer withRoomForHeader={includeHeader !== false}>
        <SubNav componentUuid={componentUuid} top={includeHeader === false ? '0' : '61px'} />
        <SidebarLayout>
          <Sidebar md="2" top={includeHeader === false ? '55px' : '111px'} componentUuid={componentUuid} />
          <MainContentArea md="10" top={includeHeader === false ? '55px' : '40px'}>
            <Router componentUuid={componentUuid} />
          </MainContentArea>
        </SidebarLayout>
      </MainPageContainer>
    </Page>
  );
};

export default Component;
