/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectRunHasExposureBreakdown } from '#selectors/entities/models.js';
import RepairCostExposureSection from './RepairCostExposureSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const showExposure = selectRunHasExposureBreakdown(state, ownProps);
  return {
    showExposure,
  };
};

export default connect(mapStateToProps)(RepairCostExposureSection);
