/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import map from 'lodash/fp/map.js';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
  returnPeriods: Array<number>,
|};

const ReturnPeriodSelectMenu = ({
  form, name, returnPeriods,
}: Props): Element<typeof SelectMenu> => {
  const items = map((returnPeriod) => ({
    id: returnPeriod,
    name: returnPeriod,
    value: returnPeriod,
  }), returnPeriods);
  return (
    <SelectMenu
      form={form}
      items={items}
      id={name}
      name={name}
      key={name}
    />
  );
};

export default ReturnPeriodSelectMenu;
