/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';

import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import CollapseDefinitionMethodSelectMenu from './CollapseDefinitionMethodSelectMenu.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { selectIsCountryJapan } = modelTypesByForm[form];
  return ({
    isJapan: selectIsCountryJapan(state),
  });
};

export default connect(mapStateToProps)(CollapseDefinitionMethodSelectMenu);
