// extracted by mini-css-extract-plugin
var _1 = "eSFo1SltWAdTHkJ96mYE";
var _2 = "orZcHNLROSXWgU_qNAKH";
var _3 = "iZse4CiIwONmR0brNaqd";
var _4 = "NEDGGFD2ZSRBNRlau12U";
var _5 = "iqzgeEE1UJ_e7X33JAq8";
var _6 = "WSu6NbBDnT3bX6enkaQN";
var _7 = "#067370";
var _8 = "uc7ADauDjNRYzlVWD9fR";
var _9 = "zL0BICEjRLR4HVA6xEJO";
var _a = "lOW3Cq0IlwarICdTDlT2";
export { _1 as "disabled", _2 as "fixedHeader", _3 as "highlight", _4 as "modelsTable", _5 as "modelsTableTd", _6 as "modelsTableTh", _7 as "primary", _8 as "td", _9 as "th", _a as "wrap" }
