/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import numeral from 'numeral';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { displayDaysFormat } from '#support/utility/mathFunctions.js';
import IntensityName from '#components/support/forms/IntensityName/index.js';
import { repairTimeTable } from './RepairTimeTable.module.scss';

type RepairTimeTable = {
  returnPeriod: number,
  pga: number,
  reoccupancy: number,
  functionalRecovery: number,
  fullRecovery: number,
};

const tableRow = (props: RepairTimeTable) => {
  const {
    returnPeriod,
    pga,
    reoccupancy,
    functionalRecovery,
    fullRecovery,
  } = props;

  return (
    <tr key={returnPeriod} className="text-center">
      <td className="text-nowrap"><IntensityName returnPeriod={returnPeriod} /></td>
      <td className="text-nowrap">{returnPeriod}</td>
      <td>{numeral(pga).format('0,0.00')}</td>
      <td>{displayDaysFormat(reoccupancy)}</td>
      <td>{displayDaysFormat(functionalRecovery)}</td>
      <td>{displayDaysFormat(fullRecovery)}</td>
    </tr>
  );
};

const renderRows = (data: RepairTimeTable[]) => data.map((d) => tableRow(d));

type Props = {|
  repairTimeData: RepairTimeTable[]
|};

const RepairCostByIntensityTable = (props: Props): Element<typeof Table> => {
  const { repairTimeData } = props;
  return (
    <Table striped bordered className={repairTimeTable}>
      <thead>
        <tr>
          <th>
            Intensity
            {' '}
            <Tip tipId="intensity" />
          </th>
          <th>
            Return Period
            {' '}
            <Tip tipId="returnPeriods" />
          </th>
          <th>
            PGA
            {' '}
            <Tip tipId="pga" />
          </th>
          <th>
            Re-Occupancy
            {' '}
            <Tip tipId="reoccupancy" />
          </th>
          <th>
            Functional Recovery
            {' '}
            <Tip tipId="functionalRecovery" />
          </th>
          <th>
            Full Recovery
            {' '}
            <Tip tipId="fullRecovery" />
          </th>
        </tr>
      </thead>
      <tbody>
        { renderRows(repairTimeData) }
      </tbody>
    </Table>
  );
};

export default RepairCostByIntensityTable;
