/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { emptyOr10to10M, required } from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import createValidatorForFieldArrayWithLocationSpecifier from
  '#support/models/form/modelForm/validators/support/createValidatorForFieldArrayWithLocationSpecifier.js';

const itemFieldValidations = [
  {
    name: 'locationSpecifier',
    validation: [required],
  },
  {
    name: 'ftDir1',
    validation: [required, emptyOr10to10M],
  },
  {
    name: 'ftDir2',
    validation: [required, emptyOr10to10M],
  },
];

export const validateFloorPlanByStoryFieldArray = createValidatorForFieldArrayWithLocationSpecifier(
  {
    itemFieldValidations,
    requireAtLeastOneItem: true,
    allowLocationOverlap: false,
    requireLocationExhaustiveness: true,
  }
);
