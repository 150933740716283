// @flow
import type { Element, ComponentType } from 'react';

import classNames from 'classnames';
import React from 'react';
import { icon as iconClass } from './index.module.scss';

type Props = {
  className?: string | null,
  icon: ComponentType<any>,
}
const SnippetItemIcon = ({ className = null, icon }: Props): Element<any> => (
  React.createElement(icon, { className: classNames(iconClass, className) })
);

export default SnippetItemIcon;
