/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { combineReducers } from 'redux';
import app from './app/index.js';
import componentSelector from '#reducers/ui/componentSelector/index.js';
import modelsTable from './modelsTable/index.js';
import modelPage from './modelPage/index.js';

export const COMPONENT_SELECTOR = 'componentSelector';

export default combineReducers({
  app,
  [COMPONENT_SELECTOR]: componentSelector,
  modelsTable,
  modelPage,
});
