/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

const buildingDesignRiskCategories = [
  { riskCategoryDesc: 'I/II', riskCategory: 2 },
  { riskCategoryDesc: 'III', riskCategory: 3 },
  { riskCategoryDesc: 'IV', riskCategory: 4 },
];

export default buildingDesignRiskCategories;
