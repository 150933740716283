/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import numeral from 'numeral';

type Props = {|
original: {
    quantity: number,
    errorCount: number
  }
|}
const QuantityCell = (props: Props): Element<'span'> => {
  const { original: { quantity, errorCount } } = props;
  return errorCount > 0
    ? <span>--</span>
    : <span className="quantity">{numeral(quantity).format('0,0.00')}</span>;
};

export default QuantityCell;
