/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesByForm, MODEL_TYPE_2_ID } from '#src/js/constants/models/modelTypes/index.js';
import {
  COLLAPSE_METHOD_FEMA,
  COLLAPSE_METHOD_MCE,
  COLLAPSE_METHOD_MEDIAN,
} from '#constants/models/collapseDefinitionMethods.js';
import { selectRunOutputProbCollapseMce, selectRunOutputCollapseCapacityMedian } from '#selectors/entities/models.js';
import BuildingStabilityView from './BuildingStabilityView.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId, form, modelType } = ownProps;
  const {
    selectFormFieldVisibility,
    selectFormValues,
    selectIsCountryJapan,
  } = modelTypesByForm[form];

  const collapseDefinitionMethod = selectFormValues(state, 'collapseDefinitionMethod');
  const showMessage = !selectFormValues(state, 'includeCollapse');
  const isJapan = selectIsCountryJapan(state);
  const showFema154Score = selectFormFieldVisibility(state)('fema154Score');
  const showProbCollapseMce = selectFormFieldVisibility(state)('probCollapseMce');
  const showCollapseMedian = selectFormFieldVisibility(state)('collapseCapacityMedian');
  const showCollapseBeta = selectFormFieldVisibility(state)('collapseCapacityBeta');

  const probCollapseMce = selectRunOutputProbCollapseMce(state, { modelId });
  const collapseCapacityMedian = selectRunOutputCollapseCapacityMedian(state, { modelId });

  const showProbCollapseMceRO = !isJapan && modelType === MODEL_TYPE_2_ID
    && [COLLAPSE_METHOD_FEMA, COLLAPSE_METHOD_MEDIAN].includes(collapseDefinitionMethod);

  const showCollapseMedianRO = !isJapan && modelType === MODEL_TYPE_2_ID
    && [COLLAPSE_METHOD_FEMA, COLLAPSE_METHOD_MCE].includes(collapseDefinitionMethod);

  return {
    showFema154Score,
    showProbCollapseMce,
    showProbCollapseMceRO,
    showCollapseMedian,
    showCollapseMedianRO,
    probCollapseMce,
    collapseCapacityMedian,
    showCollapseBeta,
    showMessage,
  };
};
export default connect(mapStateToProps)(BuildingStabilityView);
