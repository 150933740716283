// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form,
  FormGroup,
  Table,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { TypeOfResistanceSelectMenuDir1, TypeOfResistanceSelectMenuDir2 } from './TypeOfResistanceSelectMenu/index.jsx';
import PeriodRow from './PeriodRow/index.js';
import ModeShapeMethodSelectMenu from './ModeShapeMethodSelectMenu/index.jsx';
import ModeShapeUploader from './ModeShapeUploader/index.js';
import ModeShapeTable from './ModeShapeTable/index.js';
import ModeShapePlot from './ModeShapePlot/index.js';

type Props = {|
  form: string,
  heading: string,
  modelId: string,
  onBack: Function,
  onNext: Function,
  showAllowComponentsToAffectStructuralProperties: boolean,
  showBrbToDiaphragmRatioDir1: boolean,
  showBrbToDiaphragmRatioDir2: boolean,
  showLinesOfResistanceDir1: boolean,
  showLinesOfResistanceDir2: boolean,
  showTypeOfResistanceDir1: boolean,
  showTypeOfResistanceDir2: boolean,
  showYieldStoryDrift: boolean,
  showDesignPeriod1Dir1: boolean,
  showDesignPeriod1Dir2: boolean,
  showModeShapes: boolean,
  showModeShapeUploader: boolean,
  showModeShapeDetails: boolean,
|};

const StructuralPropertiesSection = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    modelId,
    onBack,
    onNext,
    showAllowComponentsToAffectStructuralProperties,
    showBrbToDiaphragmRatioDir1,
    showBrbToDiaphragmRatioDir2,
    showLinesOfResistanceDir1,
    showLinesOfResistanceDir2,
    showTypeOfResistanceDir1,
    showTypeOfResistanceDir2,
    showYieldStoryDrift,
    showDesignPeriod1Dir1,
    showDesignPeriod1Dir2,
    showModeShapes,
    showModeShapeUploader,
    showModeShapeDetails,
  } = props;

  const modelTypeHasBrbToDiaphragmRatio = modelTypesByForm[form]
    .fieldsByName.brbToDiaphragmRatioDir1 !== undefined;

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        {
          showAllowComponentsToAffectStructuralProperties
          && (
          <FormGroup tag="fieldset">
            <legend>Wood Light Frame Options</legend>
            <GridField
              name="allowComponentsToAffectStructuralProperties"
              form={form}
              options={{
                columnSize: 10,
                labelText: 'Allow Components to Affect Structural Properties?',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          </FormGroup>
          )
        }
        <FormGroup tag="fieldset">
          <legend>Structural Properties</legend>
          <Table
            responsive
            className="structural-properties"
          >
            <thead>
              <tr>
                <th />
                <th>Direction 1</th>
                <th>Direction 2</th>
              </tr>
            </thead>
            <tbody>
              { (showDesignPeriod1Dir1 || showDesignPeriod1Dir2)
                && (
                <tr>
                  <td className="text-nowrap">
                    Design Model Period (T
                    <sub>{1}</sub>
                    )
                    {' '}
                    <Tip tipId="designPeriod1" />
                  </td>
                  <td>
                    { showDesignPeriod1Dir1
                      && (
                      <GridField
                        name="designPeriod1Dir1"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                          inputGroup: {
                            addonType: 'append',
                            addonText: 'sec',
                          },
                        }}
                      />
                      )}
                  </td>
                  <td>
                    { showDesignPeriod1Dir2
                      && (
                      <GridField
                        name="designPeriod1Dir2"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                          inputGroup: {
                            addonType: 'append',
                            addonText: 'sec',
                          },
                        }}
                      />
                      )}
                  </td>
                </tr>
                )}
              <tr>
                <td>
                  Base Shear Strength
                  {' '}
                  <Tip tipId="baseShearStrength" />
                </td>
                <td>
                  <GridField
                    name="baseShearStrengthDir1"
                    form={form}
                    options={{
                      ComponentType: 'text',
                      includeFormatting: false,
                      inputGroup: {
                        addonType: 'append',
                        addonText: 'g',
                      },
                    }}
                  />
                </td>
                <td>
                  <GridField
                    name="baseShearStrengthDir2"
                    form={form}
                    options={{
                      ComponentType: 'text',
                      includeFormatting: false,
                      inputGroup: {
                        addonType: 'append',
                        addonText: 'g',
                      },
                    }}
                  />
                </td>
              </tr>
              {
                showYieldStoryDrift && (
                  <tr>
                    <td>
                      Yield Story Drift
                      {' '}
                      <Tip tipId="yieldStoryDrift" />
                    </td>
                    <td>
                      <GridField
                        name="yieldStoryDriftDir1"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                          inputGroup: {
                            addonType: 'append',
                            addonText: '%',
                          },
                        }}
                      />
                    </td>
                    <td>
                      <GridField
                        name="yieldStoryDriftDir2"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                          inputGroup: {
                            addonType: 'append',
                            addonText: '%',
                          },
                        }}
                      />
                    </td>
                  </tr>
                )
              }
              <PeriodRow form={form} period={1} />
              <PeriodRow form={form} period={2} />
              <PeriodRow form={form} period={3} />
            </tbody>
          </Table>
        </FormGroup>
        {
          (showLinesOfResistanceDir1 || showLinesOfResistanceDir2) && (
            <FormGroup tag="fieldset">
              <legend>Rigid-Wall Flexible Diaphragm Properties</legend>
              <Table
                responsive
                className="structural-properties"
              >
                <thead>
                  <tr>
                    <th />
                    <th>Direction 1</th>
                    <th>Direction 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap">
                      Interior Lines of LFRS
                      {' '}
                      <Tip tipId="linesOfResistance" />
                    </td>
                    <td>
                      {
                        showLinesOfResistanceDir1 && (
                          <GridField
                            name="linesOfResistanceDir1"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                    <td>
                      {
                        showLinesOfResistanceDir2 && (
                          <GridField
                            name="linesOfResistanceDir2"
                            form={form}
                            options={{
                              ComponentType: 'text',
                              includeFormatting: false,
                            }}
                          />
                        )
                      }
                    </td>
                  </tr>
                  {
                    (showTypeOfResistanceDir1 || showTypeOfResistanceDir2) && (
                      <tr>
                        <td className="text-nowrap">
                          Type of Interior LFRS
                          {' '}
                          <Tip tipId="typeOfResistance" />
                        </td>
                        <td>
                          {
                            showTypeOfResistanceDir1
                              && (
                              <GridField
                                name="typeOfResistanceDir1"
                                form={form}
                                options={{
                                  ComponentType: TypeOfResistanceSelectMenuDir1,
                                  includeFormatting: false,
                                }}
                              />
                              )
                          }
                        </td>
                        <td>
                          {
                            showTypeOfResistanceDir2
                              && (
                              <GridField
                                name="typeOfResistanceDir2"
                                form={form}
                                options={{
                                  ComponentType: TypeOfResistanceSelectMenuDir2,
                                  includeFormatting: false,
                                }}
                              />
                              )
                          }
                        </td>
                      </tr>
                    )
                  }
                  {
                    (showBrbToDiaphragmRatioDir1 || showBrbToDiaphragmRatioDir2)
                    && modelTypeHasBrbToDiaphragmRatio
                    && (
                      <tr>
                        <td>
                          BRB/Diaph. Strength Ratio
                          {' '}
                          <Tip tipId="brbToDiaphragmRatio" />
                        </td>
                        <td>
                          {
                            showBrbToDiaphragmRatioDir1 && (
                              <GridField
                                name="brbToDiaphragmRatioDir1"
                                form={form}
                                options={{
                                  ComponentType: 'text',
                                  includeFormatting: false,
                                }}
                              />
                            )
                          }
                        </td>
                        <td>
                          {
                            showBrbToDiaphragmRatioDir2 && (
                              <GridField
                                name="brbToDiaphragmRatioDir2"
                                form={form}
                                options={{
                                  ComponentType: 'text',
                                  includeFormatting: false,
                                }}
                              />
                            )
                          }
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </FormGroup>
          )
        }
        { showModeShapes && (
          <FormGroup tag="fieldset">
            <legend>
              Mode Shapes
              {' '}
              <Tip tipId="modeShapes" />
            </legend>
            <GridField
              name="modeShapeMethod"
              form={form}
              options={{
                columnSize: 5,
                labelText: 'Source',
                ComponentType: ModeShapeMethodSelectMenu,
              }}
            />
            { showModeShapeUploader && (
              <ModeShapeUploader
                form={form}
                name="modeShapeUpload"
                modelId={modelId}
                accept=".csv"
                maxBytes={3e6}
              />
            )}
            { showModeShapeDetails && (
              <div>
                <div className="pt-2 pb-2">
                  <h5>Direction 1</h5>
                  <ModeShapePlot modelId={modelId} direction={1} key="model-shape-plot-dir-1" />
                  <ModeShapeTable modelId={modelId} direction={1} key="model-shape-table-dir-1" />
                </div>
                <div className="pt-2 pb-2">
                  <h5>Direction 2</h5>
                  <ModeShapePlot modelId={modelId} direction={2} key="model-shape-plot-dir-2" />
                  <ModeShapeTable modelId={modelId} direction={2} key="model-shape-table-dir-2" />
                </div>
              </div>
            )}
          </FormGroup>
        )}

        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default StructuralPropertiesSection;
