import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { SidebarBadge } from '#components/support/layout/SidebarLayout/index.js';
import { selectErrorCountBySection, selectWarningCountBySection } from '#selectors/ui/componentDetailView.js';
import { selectFormInitialized } from '#selectors/ui/form/component.js';

const mapStateToProps = (state, ownProps) => {
  const { section, level, componentUuid } = ownProps;
  const countBySection = level === 'warning'
    ? selectWarningCountBySection(state, { [UUID]: componentUuid })
    : selectErrorCountBySection(state);
  const count = countBySection[section];
  const initialized = selectFormInitialized(state);
  const visible = count > 0 && initialized;
  return {
    visible,
    count,
  };
};

export default connect(mapStateToProps)(SidebarBadge);
