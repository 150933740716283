/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectAbsoluteAnnualLoss, selectPercentAnnualLoss } from '#selectors/entities/models.js';
import RepairCostAnnualPlot from './RepairCostAnnualPlot.jsx';

const mapStateToProps = (state, ownProps) => {
  const absoluteAnnualLoss = selectAbsoluteAnnualLoss(state, ownProps);
  const percentAnnualLoss = selectPercentAnnualLoss(state, ownProps);

  return ({
    absoluteAnnualLoss,
    percentAnnualLoss,
  });
};

export default connect(mapStateToProps)(RepairCostAnnualPlot);
