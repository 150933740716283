/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import BasicReduxFormSelectMenu from '#components/support/forms/SelectMenus/BasicReduxFormSelectMenu/index.jsx';

const items = [
  { id: 'all', name: 'All', value: 'all' },
  { id: 'std', name: 'Standard', value: 'std' },
  { id: 'std-rt', name: 'Standard + RT', value: 'std-rt' },
  { id: 'adv', name: 'Advanced', value: 'adv' },
  { id: 'adv-rt', name: 'Advanced + RT', value: 'adv-rt' },
];

type Props = {|
  name: string,
|};

const ModelLevelSelectMenu = ({ name }: Props): Element<typeof BasicReduxFormSelectMenu> => (
  <BasicReduxFormSelectMenu
    id={name}
    name={name}
    component={RenderInput}
    items={items}
    inputClassName={classNames('custom-select', 'model-level-select')}
    includeEmptyOption={false}
  />
);

export default ModelLevelSelectMenu;
