import values from 'lodash/fp/values.js';
import { createSelector } from 'reselect';
import { BY_UUID, LAST_UPDATED } from '#reducers/entities/componentCategories/index.js';

export const selectComponentCategoriesByUuid = (
  state
) => state.entities.componentCategories[BY_UUID];

export const selectComponentCategoriesLastUpdated = (
  state
) => state.entities.componentCategories[LAST_UPDATED];

export const selectComponentCategories = createSelector(
  selectComponentCategoriesByUuid,
  (categoriesByUuid) => values(categoriesByUuid),
);
