/**
 * Owner: Haselton Baker Risk Group, LLC
 * Product: SP3 - SP3-Risk-Model-U
 * Date: 2/4/22
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  FormGroup,
} from 'reactstrap';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import YesNoSelectMenu from '#components/pages/Models/Model/sections/support/forms/YesNoSelectMenu/index.jsx';
import ComponentQuestion from './ComponentQuestion/index.js';

type ComponentQuestionsProps = {|
  getSubGroups: Function,
  getQuestions: Function,
  showPercentGlazing: boolean,
  showPercentOfPartitionsAreFireBrake: boolean,
  showMasonryShearWallsLoadBearing: boolean,
  showConcreteShearWallsLoadBearing: boolean,
  form: string,
|};

const ComponentQuestions = (props: ComponentQuestionsProps): Element<'div'> => {
  const {
    form,
    getSubGroups,
    getQuestions,
    showPercentGlazing,
    showPercentOfPartitionsAreFireBrake,
    showMasonryShearWallsLoadBearing,
    showConcreteShearWallsLoadBearing,
  } = props;

  const percentGlazingField = showPercentGlazing && (
    <GridField
      name="percentGlazing"
      form={form}
      options={{
        columnSize: 4,
        labelText: 'Percent of Building Surface Glazed',
        ComponentType: 'text',
        includeToolTip: true,
        inputGroup: {
          addonType: 'append',
          addonText: '%',
        },
      }}
    />
  );

  const percentOfPartitionsAreFireBrakeField = showPercentOfPartitionsAreFireBrake && (
    <GridField
      name="percentOfPartitionsAreFireBrake"
      form={form}
      options={{
        columnSize: 4,
        labelText: 'What percentage of partitions are fire breaks?',
        ComponentType: 'text',
        includeToolTip: true,
        inputGroup: {
          addonType: 'append',
          addonText: '%',
        },
      }}
    />
  );

  const sheerWallsLoadBearingField = showConcreteShearWallsLoadBearing && (
    <GridField
      name="concreteShearWallsLoadBearing"
      form={form}
      options={{
        columnSize: 4,
        labelText: 'Are the building\'s shear walls load bearing?',
        ComponentType: YesNoSelectMenu,
        includeToolTip: true,
      }}
    />
  );

  const masonryShearWallsLoadBearingField = showMasonryShearWallsLoadBearing && (
    <GridField
      name="masonryShearWallsLoadBearing"
      form={form}
      options={{
        columnSize: 4,
        labelText: 'Are the building\'s masonry walls load bearing?',
        ComponentType: YesNoSelectMenu,
        includeToolTip: true,
      }}
    />
  );

  const renderComponentQuestionGroup = (componentGroup) => {
    const subGroups = getSubGroups(componentGroup);
    const componentQuestionsBySubGroup = subGroups.map((subGroup) => {
      const questionsForSubGroup = getQuestions(componentGroup, subGroup);
      return questionsForSubGroup.length ? (
        <FormGroup tag="fieldset" key={`${componentGroup}-${subGroup}`}>
          <legend>
            {componentGroup === 'structural' ? `Structural - ${subGroup}` : subGroup}
          </legend>
          {componentGroup === 'structural'
            && subGroup === 'Masonry'
            && masonryShearWallsLoadBearingField}
          {componentGroup === 'structural'
            && subGroup === 'Concrete'
            && sheerWallsLoadBearingField}
          {componentGroup === 'nonStructural'
            && subGroup === 'Exterior'
            && percentGlazingField}
          {componentGroup === 'nonStructural'
            && subGroup === 'Interior Finishes'
            && percentOfPartitionsAreFireBrakeField}
          {questionsForSubGroup.map((question) => (
            <ComponentQuestion key={`${componentGroup}-${question.name}`} form={form} name={question.name} />
          ))}
        </FormGroup>
      ) : null;
    });
    return componentQuestionsBySubGroup;
  };

  return (
    <div>
      {renderComponentQuestionGroup('structural')}
      {renderComponentQuestionGroup('nonStructural')}
    </div>
  );
};

export default ComponentQuestions;
