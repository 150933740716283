/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import RepairTimeOptionsSection from './RepairTimeOptionsSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { selectFormFieldVisibility, selectFormValues } = modelTypesByForm[form];
  return {
    showDefaultATC138Fields: selectFormFieldVisibility(state)('cashOnHand'),
    showIsDenseUrbanArea: selectFormFieldVisibility(state)('isDenseUrbanArea'),
    showMaxWalkableStory: selectFormFieldVisibility(state)('maxWalkableStory'),
    showEquipmentPrequalified: selectFormFieldVisibility(state)('equipmentPrequalified'),
    showPrequalifiedDesignCodeYear: selectFormFieldVisibility(state)('prequalifiedDesignCodeYear'),
    showPrequalifiedDetails: selectFormFieldVisibility(state)('prequalifiedAccGround'),
    showIncludeImpedanceLongLeadTimeWarning: selectFormValues(state, 'includeImpedanceLongLeadTime') === false,
  };
};

export default connect(mapStateToProps)(RepairTimeOptionsSection);
