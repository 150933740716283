/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';

const getMinDesignCodeYearForBuildingType = (buildingType) => {
  const minDesignCodeYear = get(`${buildingType}.minDesignCodeYear`, buildingTypesById);
  return minDesignCodeYear;
};

export default getMinDesignCodeYearForBuildingType;
