const siteClasses = [
  { siteClassId: 'A', value: '2000', range: `${String.fromCharCode(8805)} 1500` },
  { siteClassId: 'B', value: '1150', range: '945 - 1500' },
  { siteClassId: 'B/C', value: '760', range: '660 - 945' },
  { siteClassId: 'C', value: '537', range: '460 - 660' },
  { siteClassId: 'C/D', value: '360', range: '315 - 460' },
  { siteClassId: 'D', value: '259', range: '225 - 315' },
  { siteClassId: 'D/E', value: '180', range: `${String.fromCharCode(60)} 225` },
];

export default siteClasses;
