/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';
import classNames from 'classnames';
import React from 'react';
import { edps } from '@hbrisk/sp3-risk-model-support/edps/edps.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import BasicReduxFormSelectMenu from '#components/support/forms/SelectMenus/BasicReduxFormSelectMenu/index.jsx';
import SelectMenu from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const items = edps.map(({ key, name }) => ({
  id: key,
  name,
  value: key,
}));

const EdpSelectMenu = (): Element<typeof SelectMenu> => (
  <BasicReduxFormSelectMenu
    items={items}
    id="edpKey"
    name="edpKey"
    inputClassName={classNames('custom-select', 'edp-select')}
    component={RenderInput}
  />
);

export default EdpSelectMenu;
