/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import BuildingModifiersChecklistSection from './BuildingModifiersChecklistSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { selectFormFieldVisibility } = modelTypesByForm[ownProps.form];
  const getVisibilityObjectFromField = (name) => ({
    [name]: selectFormFieldVisibility(state)(name),
  });

  const fields = [
    'buildingModsColumnPierSpandrelRatio',
    'buildingModsColumnRatioLessThanTypical',
    'buildingModsConstructionQuality',
    'buildingModsCrossGrainOopConn',
    'buildingModsDiaphramOpen',
    'buildingModsFlatPlateAsBeam',
    'buildingModsFullStoryGrade',
    'buildingModsFullStoryGradeWLF',
    'buildingModsInteriorWallDensity',
    'buildingModsKBracing',
    'buildingModsMaterialDeterioration',
    'buildingModsNonParallel',
    'buildingModsNonStructuralConstructionQuality',
    'buildingModsOopOffset',
    'buildingModsOtherPlanIrregularity',
    'buildingModsOtherVerticalIrregularity',
    'buildingModsRedundancy',
    'buildingModsReentrant',
    'buildingModsSetbackInboard',
    'buildingModsSetbackInPlaneLfrs',
    'buildingModsSetbackOutboard',
    'buildingModsSplitLevel',
    'planTorsion',
    'verticalIrregularity',
  ];

  const fieldsVisibility = fields.reduce((acc, curr) => ({
    ...acc,
    ...getVisibilityObjectFromField(curr),
  }), {});

  return {
    fieldsVisibility,
  };
};

export default connect(mapStateToProps)(BuildingModifiersChecklistSection);
