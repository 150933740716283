/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { createSelector } from 'reselect';

const selectApp = (state) => state.ui.app;

const selectVersion = createSelector(
  selectApp,
  (app) => app.version,
);

const selectMaintenance = createSelector(
  selectApp,
  (app) => app.maintenance,
);

export const selectIsNewAppVersion = createSelector(
  selectVersion,
  (version) => version.isNewVersion,
);

export const selectIsMaintenanceMode = createSelector(
  selectMaintenance,
  (maintenance) => maintenance.on && !maintenance.override,
);
