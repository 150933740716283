/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { selectComponentByUuid, selectComponents } from '#selectors/entities/components.js';
import {
  selectFilters,
  selectExpandedNodes,
  selectTreeData,
} from '#selectors/ui/componentSelector.js';
import { selectComponentCategories } from '#selectors/entities/componentCategories.js';
import {
  expandAllNodes,
  setExpandedNodes,
  setFilters,
  setTreeData,
} from '#actions/components/componentSelector.js';
import ComponentSelector from '#components/pages/Components/support/ComponentSelector/ComponentSelector.jsx';

const mapStateToProps = (state, ownProps) => {
  const { name, selectedUuid } = ownProps;

  const component = selectComponentByUuid(state, { uuid: selectedUuid });
  const components = selectComponents(state);
  const categories = selectComponentCategories(state);
  return ({
    selected: component,
    categories,
    components,
    filters: selectFilters(name)(state),
    expandedNodes: selectExpandedNodes(name)(state),
    treeData: selectTreeData(name)(state),
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { name } = ownProps;
  return ({
    setFilters: (filters) => dispatch(setFilters(name, filters)),
    setExpandedNodes: (expanded) => dispatch(setExpandedNodes(name, expanded)),
    expandAllNodes: () => dispatch(expandAllNodes(name)),
    makeSetTreeData: (
      components,
      categories
    ) => () => dispatch(setTreeData(name, { components, categories })),
  });
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { components, categories } = stateProps;
  const { makeSetTreeData, ...restDispatchProps } = dispatchProps;
  return ({
    ...ownProps,
    ...stateProps,
    ...restDispatchProps,
    setTreeData: makeSetTreeData(
      components,
      categories,
    ),
  });
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ComponentSelector);
