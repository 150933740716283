const getFieldPermissionOverrides = (
  fields,
  permissions,
) => fields.reduce((acc, field) => {
  const { permission } = field;
  if (!permission) {
    return acc;
  }
  return {
    ...acc,
    [field.name]: !!permissions[permission],
  };
}, {});

export default getFieldPermissionOverrides;
