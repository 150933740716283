/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  MODELS_TABLE_CLEAR_FILTERS,
  MODELS_TABLE_SET_FILTERS,
  SIGN_OUT_USER,
} from '#constants/actionTypes.js';

const initialState = {
  modelName: '',
  projectName: '',
  createdStart: '',
  createdEnd: '',
  updatedStart: '',
  updatedEnd: '',
  modelLevel: 'all',
  author: '',
  includeArchived: false,
};

const modelsFilterReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case MODELS_TABLE_SET_FILTERS: {
      return {
        ...initialState,
        ...payload,
      };
    }
    case MODELS_TABLE_CLEAR_FILTERS:
    case SIGN_OUT_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default modelsFilterReducer;
