import { connect } from 'react-redux';
import { selectUploadedGroundMotionReturnPeriods } from '#selectors/entities/models.js';
import ReturnPeriodSelectMenu from './ReturnPeriodSelectMenu.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId } = ownProps;
  const returnPeriods = selectUploadedGroundMotionReturnPeriods(
    state,
    { modelId }
  );

  return ({
    returnPeriods,
  });
};

export default connect(mapStateToProps)(ReturnPeriodSelectMenu);
