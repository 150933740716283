/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import * as React from 'react';
import { SPEX_SERVICE_SUCCESS } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import { statusReached } from '@hbrisk/sp3-risk-model-support/models/runStatusHelpers.js';

type Props = {|
  initialize: Function,
  initialized: boolean,
  modelFormInputs: Object,
  autofilledValues: Object,
  modelStatus: string,
  modelHasOutputData: boolean,
|};

type GetComponent = React.ComponentType<any> => React.ComponentType<any>;

const getComponent: GetComponent = (C) => class extends React.Component<Props> {
  componentDidMount() {
    const {
      initialize,
      initialized,
      modelFormInputs,
      autofilledValues,
    } = this.props;

    if (!initialized && modelFormInputs) {
      initialize(modelFormInputs, autofilledValues);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      initialize,
      initialized,
      modelFormInputs,
      autofilledValues,
      modelHasOutputData,
      modelStatus,
    } = this.props;

    if (!initialized && modelFormInputs) {
      initialize(modelFormInputs, autofilledValues);
    }

    const {
      modelStatus: prevModelStatus,
      modelHasOutputData: prevModelHasOutputData,
    } = prevProps;

    const runJustCompleted = (
      !statusReached(prevModelStatus, SPEX_SERVICE_SUCCESS)
      && statusReached(modelStatus, SPEX_SERVICE_SUCCESS)
    );

    if (runJustCompleted || modelHasOutputData !== prevModelHasOutputData) {
      initialize(modelFormInputs, autofilledValues);
    }
  }

  render() {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (<C {...this.props} />);
  }
};

export default getComponent;
