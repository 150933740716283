/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { modelTypesById } from '#constants/models/modelTypes/index.js';

const calculateInitialSectionGroupIds = (nonDefaults, modelType) => {
  const { allSectionGroups } = modelTypesById[modelType];
  const defaultActiveSectionGroupIds = allSectionGroups.reduce(
    (acc, item) => (item.defaultVisible ? { ...acc, [item.id]: true } : acc),
    {}
  );
  return {
    ...defaultActiveSectionGroupIds,
    ...nonDefaults,
  };
};

const calculateInitialSectionIds = (nonDefaults, modelType) => {
  const { allSections } = modelTypesById[modelType];
  const defaultActiveSectionIds = allSections.reduce((
    acc,
    section
  ) => (
    section.defaultVisible
      ? { ...acc, [section.id]: true }
      : acc
  ), {});

  return {
    ...defaultActiveSectionIds,
    ...nonDefaults,
  };
};

const calculateModelPageInitializationPayload = (
  { nonDefaultSectionGroupIds, nonDefaultSectionIds },
  modelType
) => ({
  sectionGroupIds: calculateInitialSectionGroupIds(nonDefaultSectionGroupIds, modelType),
  sectionIds: calculateInitialSectionIds(nonDefaultSectionIds, modelType),
});

export default calculateModelPageInitializationPayload;
