/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { ActionRowButton } from '#components/support/layout/ActionRow/index.jsx';

type Props = {|
  children: Node,
  className: string,
  handleClick: Function,
|};

const CreateModelButton = ({
  className,
  children,
  handleClick,
}: Props): Element<typeof ActionRowButton> => (
  <ActionRowButton
    className={classNames('create-model-button', className)}
    onClick={handleClick}
  >
    {children}
  </ActionRowButton>
);

export default CreateModelButton;
