import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import keyBy from 'lodash/fp/keyBy.js';
import keys from 'lodash/fp/keys.js';
import pipe from 'lodash/fp/pipe.js';
import filter from 'lodash/fp/filter.js';
import orderBy from 'lodash/fp/orderBy.js';
import groupBy from 'lodash/fp/groupBy.js';
import set from 'lodash/fp/set.js';
import uniq from 'lodash/fp/uniq.js';
import isArray from 'lodash/fp/isArray.js';
import {
  ARCHIVED,
  CATEGORY_UUID,
  COMPONENT_ID,
  NAME,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import {
  CATEGORY_ID,
  UUID,
  NAME as CATEGORY_NAME,
} from '@hbrisk/sp3-risk-model-support/componentCategories/attributes/names.js';
import {
  COMPONENT_SELECTOR_SET_FILTERS,
  COMPONENT_SELECTOR_SET_EXPANDED,
  COMPONENT_SELECTOR_SET_TREE_DATA,
  COMPONENT_SELECTOR_EXPAND_ALL,
  SIGN_OUT_USER,
} from '#constants/actionTypes.js';

export const FILTERS = 'filters';
export const EXPANDED_NODES = 'expandedNodes';
export const TREE_DATA = 'treeData';

const getCategoryTree = (categoriesByUuid, uuid, acc) => {
  if (!Object.keys(categoriesByUuid).length) { return acc; }
  const category = categoriesByUuid[uuid];
  if (category.parentUuid) {
    return getCategoryTree(categoriesByUuid, category.parentUuid, [...acc, category]);
  }
  return [...acc, category];
};

const buildTree = (categoryNameByCategoryId, initialTree) => {
  const recurse = (tree) => {
    if (isArray(tree)) {
      return tree.map((component) => ({
        [COMPONENT_ID]: component[COMPONENT_ID],
        [UUID]: component[UUID],
        [NAME]: component[NAME],
        [ARCHIVED]: component[ARCHIVED],
      }));
    }
    return keys(tree).map((category) => {
      const label = categoryNameByCategoryId(category);
      return {
        category,
        label,
        children: recurse(tree[category]),
      };
    });
  };
  return recurse(initialTree);
};

const calculateTreeData = (
  components,
  categories,
) => (filters = {}) => {
  const categoryUuidToCategoryParentTree = (
    uuid
  ) => getCategoryTree(keyBy(UUID, categories), uuid, []);
  const categoryIdToCategoryName = (categoryId) => get(`${categoryId}.${CATEGORY_NAME}`, keyBy(CATEGORY_ID, categories));
  const { searchText, includeArchived } = filters;
  const filteredComponents = pipe(
    filter((component) => {
      const {
        [COMPONENT_ID]: componentId,
        [CATEGORY_UUID]: categoryUuid,
        [NAME]: name,
        [ARCHIVED]: archived,
      } = component;
      const tree = categoryUuidToCategoryParentTree(categoryUuid);

      // filter by search text
      if (searchText) {
        const categoryNames = tree.map((category) => category[CATEGORY_NAME]);

        if (
          !searchText
            .toLowerCase()
            .split(' ')
            .every((searchTextWord) => (
              componentId.toLowerCase().indexOf(searchTextWord) !== -1
              || (name || '').toLowerCase().indexOf(searchTextWord) !== -1
              || categoryNames.some((cat) => (cat.toLowerCase().indexOf(searchTextWord) !== -1))
            ))
        ) {
          return false;
        }
      }
      // filter by includeArchive toggle
      if (!includeArchived && archived) {
        return false;
      }

      return true;
    }),
    orderBy([COMPONENT_ID], ['asc'])
  )(components);

  const grouped = groupBy(CATEGORY_UUID, filteredComponents);
  const expandableNodes = [];
  let tree = {};
  keys(grouped).forEach((categoryUuid) => {
    const categoryIds = (
      categoryUuidToCategoryParentTree(categoryUuid)
        .map((category) => category[CATEGORY_ID])
    );
    tree = set(categoryIds.reverse().join('.'), grouped[categoryUuid], tree);
    expandableNodes.push(...categoryIds);
  });

  return {
    tree: buildTree(categoryIdToCategoryName, tree),
    expandableNodes: uniq(expandableNodes),
  };
};

const initialState = {};
const initialNamespaceState = {
  [FILTERS]: {},
  [EXPANDED_NODES]: [],
  [TREE_DATA]: {},
};

const componentSelectorReducer = (state = initialState, action = {}) => {
  const { type, meta, payload } = action;
  const componentSelectorName = get('componentSelectorName', meta);
  if (!componentSelectorName) {
    return state;
  }
  const namespaceState = getOr(initialNamespaceState, componentSelectorName, state);
  const {
    [FILTERS]: filters,
    [TREE_DATA]: treeData,
  } = namespaceState;
  switch (type) {
    case COMPONENT_SELECTOR_SET_FILTERS:
      return {
        ...state,
        [componentSelectorName]: {
          ...namespaceState,
          [FILTERS]: {
            ...filters,
            ...payload,
          },
        },
      };
    case COMPONENT_SELECTOR_SET_EXPANDED:
      return {
        ...state,
        [componentSelectorName]: {
          ...namespaceState,
          [EXPANDED_NODES]: payload,
        },
      };
    case COMPONENT_SELECTOR_EXPAND_ALL:
      return {
        ...state,
        [componentSelectorName]: {
          ...namespaceState,
          [EXPANDED_NODES]: treeData.expandableNodes || [],
        },
      };
    case COMPONENT_SELECTOR_SET_TREE_DATA: {
      const { components, categories } = payload;
      return {
        ...state,
        [componentSelectorName]: {
          ...namespaceState,
          [TREE_DATA]: calculateTreeData(
            components,
            categories
          )(filters),
        },
      };
    }
    case SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default componentSelectorReducer;
