// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import BaseTip from '#components/support/tooltips/Tip/BaseTip/index.jsx';
import tipsById from '#components/support/tooltips/Tip/tips/index.jsx';

type Props = {|
  className?: string | null,
  targetId?: string | null,
  tipId: string,
|};

const Tip = ({
  className = null,
  targetId = null,
  tipId,
}: Props): Element<typeof BaseTip> | null => {
  const tip = tipsById[tipId];
  if (!tip) {
    return null;
  }
  const { header, body } = tipsById[tipId];
  return (
    <BaseTip
      className={classNames('tip', className)}
      targetId={targetId}
      tipId={tipId}
      body={body}
      header={header}
    />
  );
};

export default Tip;
