/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { EDIT } from '#constants/pathStrings.js';

type Props = {|
  original: {
    componentUuid: string,
    componentId: string,
    errorCount: number,
  }
|};

const ComponentIdCell = (props: Props): Element<typeof Link> => {
  const { original: { componentUuid, componentId, errorCount } } = props;
  return (
    <Link to={`${EDIT}/${componentUuid}`}>
      <div className="text-nowrap">
        <span className="pr-2">{componentId}</span>
        { errorCount > 0 && (
          <Badge color="danger">{errorCount}</Badge>
        )}
      </div>
    </Link>
  );
};

export default ComponentIdCell;
