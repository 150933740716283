/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 5/30/19
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';

import { createSelector } from 'reselect';

export const usersById = (state) => state.entities.users.byId;

export const selectAuthUserId = (state) => state.entities.users.authenticated.uuid;

export const selectAuthUserToken = (state) => state.entities.users.authenticated.token;

const selectUserId = (_, props) => props.userId;

export const selectAuthUser = createSelector(
  selectAuthUserId,
  usersById,
  (id, byId) => byId[id],
);

export const selectAuthUserOrgId = createSelector(
  selectAuthUser,
  (user) => get('org.uuid', user),
);

export const selectAuthUserOrgIsSet = createSelector(
  selectAuthUserOrgId,
  (orgId) => !!orgId
);

export const selectAuthUserHasPerRealizationAccess = createSelector(
  selectAuthUser,
  (user) => get('permissions.perRealization', user) === true,
);

export const selectAuthUserPermissions = createSelector(
  selectAuthUser,
  (user) => getOr({}, 'permissions', user),
);

export const selectAuthUserHasRecoveryTimeAccess = createSelector(
  selectAuthUser,
  (user) => get('permissions.recoveryTime', user) === true,
);

export const selectAuthUserModelTypes = createSelector(
  selectAuthUser,
  (user) => get('permissions.modelTypes', user),
);

export const selectAuthUserIsAdmin = createSelector(
  selectAuthUser,
  (user) => get('permissions.isAdmin', user) === true,
);

export const selectAuthUserTokenIsSet = createSelector(
  selectAuthUserToken,
  (value) => !!value
);

export const selectAuthUserNeedsTermsOfService = createSelector(
  selectAuthUser,
  (user) => !getOr(true, 'acceptedTerms', user),
);

export const selectAuthUserHasType2ModelPermissions = createSelector(
  selectAuthUser,
  (user) => getOr([], 'permissions.modelTypes', user).includes(2),
);

export const selectUsers = createSelector(
  usersById,
  (byId) => Object.values(byId),
);

export const selectUserById = createSelector(
  usersById,
  selectUserId,
  (byId, id) => byId[id]
);
