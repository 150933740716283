/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { pipe } from 'ramda';
import { connect } from 'react-redux';
import { selectModelHasFormInputs } from '#selectors/entities/models.js';
import getComponent from './withModelLoader.jsx';

const getContainer = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const showContent = selectModelHasFormInputs(state, ownProps);
    return {
      showContent,
      ...ownProps,
    };
  };
  return connect(mapStateToProps)(C);
};

export default pipe(getComponent, getContainer);
