import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { CAPACITY_UNIT } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import DamageStateRowTable
  from '#components/pages/Components/support/tables/DamageStateRowTable/DamageStateRowTable.jsx';

const mapStateToProps = (state) => ({
  [CAPACITY_UNIT]: formValueSelector(COMPONENT_FORM)(state, CAPACITY_UNIT),
});

export default connect(mapStateToProps)(DamageStateRowTable);
