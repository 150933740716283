/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React, { useState } from 'react';
import isInteger from 'lodash/fp/isInteger.js';
import orderBy from 'lodash/fp/orderBy.js';
import range from 'lodash/fp/range.js';

import numeral from 'numeral';
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  LineMarkSeries,
  DiscreteColorLegend,
  Hint,
} from 'react-vis';
import { colorLegend, hint } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import { COLORS } from '#constants/plotColors.js';
import CustomAxisLabel, { defaultLeftOffset } from '#components/pages/Models/Model/sections/support/plots/CustomAxisLabel.jsx';
import IntensityName from '#components/support/forms/IntensityName/index.js';
import isFloor from '#support/models/isFloor.js';
import getLocationLabel from '#support/models/locationHelpers/getLocationLabel.js';
import { edpsByKey } from '#constants/edps/index.js';

import type { EdpKey } from '#constants/edps/index.js';

type Response = {
  location: number,
  returnPeriod: number,
  median: number,
};

type Props = {|
edpKey: EdpKey,
  responseDataByReturnPeriod: {
    [returnPeriod: number]: Array<Response>,
  },
  returnPeriods: Array<number>,
  maxMedian: number,
  maxLocation: number,
|};

const Line = (points, color, returnPeriod, locationType, setTip) => (
  <LineMarkSeries
    key={returnPeriod}
    color={color}
    data={points}
    onValueMouseOver={(value) => setTip(value)}
    onValueMouseOut={() => setTip(false)}
    markStyle={{ opacity: 0.8 }}
    size={isFloor(locationType) ? 3 : 2}
  />
);

const mapData = (rp) => rp.map((item) => ({
  x: item.median,
  y: item.location,
}));

const formatCoordinates = (locationType, data) => {
  if (isFloor(locationType)) {
    return data;
  }

  const res = data && data.reduce((acc, curr) => [
    ...acc,
    {
      location: curr.location + 1,
      returnPeriod: curr.returnPeriod,
      median: curr.median,
    },
    curr,
  ], []);

  return orderBy('location', 'asc', res);
};

const StructuralResponsePlot = (props: Props): Element<'div'> => {
  const {
    edpKey,
    returnPeriods,
    responseDataByReturnPeriod,
    maxMedian,
    maxLocation,
  } = props;

  const [tipId, setTip] = useState(false);

  const {
    locationType,
    isPercent,
    edpUnit,
    name,
  } = edpsByKey[edpKey];

  const width = 600;
  const height = 400;

  const unit = isPercent ? '%' : edpUnit;
  const xAxisTitle = `${name} [${unit}]`;

  const xAxisFormat = (x) => {
    const res = isPercent ? '0.00%' : '0.00';
    return numeral(x).format(res);
  };

  const yAxisFormat = (loc) => {
    const location = getLocationLabel(
      loc,
      'floor', // always label it as Floors
      isFloor(locationType) ? maxLocation : maxLocation + 1
    );
    return isInteger(location)
      ? numeral(location).format('0')
      : location;
  };

  const yTickValues = range(1, isFloor(locationType) ? maxLocation + 1 : maxLocation + 2);

  const legendItems = returnPeriods.map((returnPeriod, index) => ({
    title: (
      <span>
        <IntensityName includeUploads returnPeriod={returnPeriod} />
        {' '}
        (
        {returnPeriod}
        {' '}
        yrs)
      </span>
    ),
    color: COLORS[index % 10],
    strokeWidth: 12,
  }));

  const leftMargin = 70;

  return (
    <div
      className="structural-response-plot"
      style={{ position: 'relative', width: width + 50, height: height + 50 }}
    >
      <XYPlot
        width={width}
        height={height}
        xDomain={[0, maxMedian]}
        onMouseLeave={() => setTip(false)}
        margin={{ left: leftMargin }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickFormat={xAxisFormat} />
        <YAxis
          tickValues={yTickValues}
          tickFormat={yAxisFormat}
        />

        { returnPeriods.map((returnPeriod, index) => {
          const data = formatCoordinates(locationType, responseDataByReturnPeriod[returnPeriod]);
          return Line(
            mapData(data),
            COLORS[index % 10],
            returnPeriod,
            locationType,
            setTip
          );
        })}

        {tipId ? (
          <Hint value={tipId} className={hint}>
            <div>
              <div>
                Floor:
                {yAxisFormat(tipId.y)}
              </div>
              <div>
                Median:
                {xAxisFormat(tipId.x)}
              </div>
            </div>
          </Hint>
        ) : null}

        <DiscreteColorLegend
          className={colorLegend}
          color="grey"
          width={200}
          items={legendItems}
          style={{
            position: 'absolute',
            top: '15px',
            left: `${width}px`,
          }}
        />
      </XYPlot>
      <CustomAxisLabel
        title="Floors"
        innerHeight={height}
        innerWidth={width}
        leftOffset={leftMargin + defaultLeftOffset + -15}
      />
      <CustomAxisLabel
        title={xAxisTitle}
        xAxis
        innerHeight={height}
        innerWidth={width}
      />
    </div>
  );
};

export default StructuralResponsePlot;
