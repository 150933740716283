/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { pipe } from 'ramda';
import { connect } from 'react-redux';
import pick from 'lodash/fp/pick.js';
import { selectCurrentRun } from '#selectors/entities/models.js';
import getComponent from './withProgressCardFunctionality.jsx';

const getContainer = ({
  calculatePercentComplete,
  calculateProgressText,
  desiredStatus,
}) => (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { status, elapsed } = pick(['status', 'elapsed'], selectCurrentRun(state, ownProps));
    return ({
      ...ownProps,
      calculatePercentComplete,
      calculateProgressText,
      desiredStatus,
      elapsed,
      status,
    });
  };
  return connect(mapStateToProps)(C);
};

export default (options) => pipe(
  getComponent,
  getContainer(options),
);
