// @flow

import type { Element } from 'react';
import React from 'react';
import { Col, Row } from 'reactstrap';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import BackButton from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import ModelUsageTable from '#components/pages/Components/Component/sections/ModelUsage/ModelUsageTable/index.js';

const ModelUsage = ({
  onBack,
  componentUuid,
}: SectionComponentProps): Element<'div'> => (
  <div>
    <Row>
      <Col className="mt-2">
        <ModelUsageTable componentUuid={componentUuid} />
      </Col>
    </Row>
    <Row className="pt-3">
      <Col>
        <BackButton onClick={onBack} />
      </Col>
    </Row>
  </div>
);

export default ModelUsage;
