// @flow
import type { Element } from 'react';

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page from '#components/support/layout/Page/index.js';
import Dashboard from '#components/pages/Models/Dashboard/index.jsx';
import Model from '#components/pages/Models/Model/index.js';
import ModelTypes from '#components/pages/Models/ModelTypes/index.js';
import ComponentsWindow from '#src/js/components/pages/Models/Components/index.js';

import {
  COMPONENTS, MODEL_ID, MODEL_TYPES,
} from '#constants/pathStrings.js';
import RequireType2ModelPermissions from '#components/support/routing/RequireType2ModelPermissions/index.js';

const Models = (): Element<typeof Page> => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path={`${MODEL_TYPES}`} element={<ModelTypes />} />
    <Route path={`:${MODEL_ID}/*`} element={<Model />} />
    <Route
      path={`${COMPONENTS}/*`}
      element={
        (
          <RequireType2ModelPermissions>
            <ComponentsWindow />
          </RequireType2ModelPermissions>
        )
       }
    />
    <Route path="*" element={<Dashboard />} />
  </Routes>
);

export default Models;
