// @flow
import resolveFormInputs from '@hbrisk/sp3-risk-model-support/utility/form/resolveFormInputs/index.js';
import { modelTypesById } from '#constants/models/modelTypes/index.js';
import getFieldPermissionOverrides from '#src/js/support/utility/form/getFieldPermissionOverrides/index.js';
import byField from '#support/utility/form/formInputsTransforms/byField/index.js';

type FormInputMap = {
  [field: string]: {
    value: string | null,
    visible: boolean,
    autofilled: false,
  },
}

type CalculateNewModelPostPayload = (
  type: number,
  createdById: string,
  orgId: string,
  permissions: {},
) => ({
  formInputs: FormInputMap,
  type: number,
  createdById: string,
  orgId: string,
});

/**
 * This function calculates the payload for requesting a new model on the server, based on the
 * model's type and the user's permissions
 */
const calculateNewModelPostPayload: CalculateNewModelPostPayload = (
  type,
  createdById,
  orgId,
  permissions,
) => {
  const { fields } = modelTypesById[type];

  const overrides = getFieldPermissionOverrides(
    fields,
    permissions,
  );

  const formInputsBySlice = resolveFormInputs(
    fields,
    { visibleFields: overrides }
  );

  return {
    formInputs: byField(formInputsBySlice),
    type,
    createdById,
    orgId,
  };
};

export default calculateNewModelPostPayload;
