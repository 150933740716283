import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import { setCurrentComponent, unsetCurrentComponent } from '#actions/components/components.js';
import ComponentPageRouter
  from '#components/pages/Components/Component/support/Router/Router.jsx';
import {
  selectComponentNameByUuid,
  selectComponentIdByUuid,
  selectComponentIsReadOnlyByUuid,
  selectComponentArchivedByUuid,
} from '#selectors/entities/components.js';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const name = selectComponentNameByUuid(state, { uuid: componentUuid });
  const componentId = selectComponentIdByUuid(state, { uuid: componentUuid });
  const readOnly = selectComponentIsReadOnlyByUuid(state, { uuid: componentUuid });
  const archived = selectComponentArchivedByUuid(state, { uuid: componentUuid });

  return {
    componentId,
    name,
    readOnly,
    archived,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { componentUuid } = ownProps;
  return ({
    handleMount: () => {
      dispatch(setCurrentComponent(componentUuid));
      return () => {
        dispatch(unsetCurrentComponent());
        dispatch(destroy(COMPONENT_FORM));
      };
    },
  });
};
export default connect(mapStateToProps, mapDispatchToProps)(ComponentPageRouter);
