/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import { reduxForm, SubmissionError } from 'redux-form';
import { postUser } from '#actions/users/users.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import { USER_EMAIL_ALREADY_EXISTS } from '#constants/users/errorMessages.js';
import calculateUserRequestPostBody from '#support/users/calculateUserRequestPostBody.js';
import { CREATE_USER } from '#constants/users/form/createUser/name.js';
import CreateUserForm from './CreateUserForm.jsx';

const onSubmit = (values, dispatch) => (
  dispatch(postUser(calculateUserRequestPostBody(values)))
    .catch((action) => {
      const serverMessage = get('payload.message', action);
      const message = [USER_EMAIL_ALREADY_EXISTS].includes(serverMessage)
        ? serverMessage
        : PROBLEM_WITH_REQUEST;
      return Promise.reject(new SubmissionError({ _error: message }));
    })
);

export default reduxForm({
  onSubmit,
  form: CREATE_USER,
})(CreateUserForm);
