import { connect } from 'react-redux';
import EntityModeAddon from '#components/pages/Components/support/tables/support/EnityModeAddon/EntityModeAddon.jsx';

const getAffixText = (affix, entity) => (
  typeof affix === 'function'
    ? affix(entity)
    : affix
);

const mapStateToProps = (state, ownProps) => {
  const { field: { prefix, suffix }, addonType, component } = ownProps;
  const affix = addonType === 'prepend' ? prefix : suffix;
  return {
    text: component ? getAffixText(affix, component) : null,
  };
};

export default connect(mapStateToProps)(EntityModeAddon);
