// extracted by mini-css-extract-plugin
var _1 = "hRYIr_ngBy3PYL706w_6";
var _2 = "zEEpnhJDtp7RLm9gjYFM";
var _3 = "XqZO2oGf82gcgsV86Iew";
var _4 = "Htkqa8neVKlVnBZ9Mih4";
var _5 = "#067370";
var _6 = "cZe2JVw0HUeqRr9K_k2B";
var _7 = "FkV8NNu_8lDyABFWV1ve";
var _8 = "nfC_qFwH7i_z9rE5VNpH";
export { _1 as "highlight", _2 as "modelComponentsTable", _3 as "modelComponentsTableTd", _4 as "modelComponentsTableTh", _5 as "primary", _6 as "td", _7 as "th", _8 as "wrap" }
