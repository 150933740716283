import { touch } from 'redux-form';
import {
  COMPONENT_FORM,
} from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import fields from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import {
  INITIALIZE_FORM,
} from '#constants/actionTypes.js';
import calculateFieldArrayFieldNames from '#support/utility/form/calculateFieldArrayFieldNames/index.js';

export const initializeComponentDetailForm = (inputs = {}) => ({
  type: INITIALIZE_FORM,
  meta: {
    form: COMPONENT_FORM,
  },
  payload: {
    ...(inputs.values ? { values: inputs.values } : {}),
    ...(inputs.visibleFields ? { visibleFields: inputs.visibleFields } : {}),
  },
});

export const touchAll = (values) => {
  const toTouch = fields.reduce(
    (acc, field) => {
      const { itemSchema, name, type } = field;
      return type === 'FieldArray'
        ? [...acc, ...calculateFieldArrayFieldNames(name, itemSchema, values)]
        : [...acc, name];
    },
    []
  );
  return touch(COMPONENT_FORM, ...toTouch);
};
