/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import {
  setSubmitFailed,
  submit,
  getFormValues,
  updateSyncErrors,
} from 'redux-form';
import makeFormValidator
  from '@hbrisk/sp3-risk-model-support/utility/form/validation/makeFormValidator/index.js';
import { touchAll } from '#actions/support/touchAll.js';

const withCustomHandleSubmit = (config = {}) => (C) => {
  const mapStateToProps = (state, ownProps) => {
    const fields = ownProps.fields || config.fields;
    const form = ownProps.form || config.form;
    const selectAutofilled = ownProps.selectAutofilled || config.selectAutofilled;
    const selectVisibleFields = ownProps.selectVisibleFields || config.selectVisibleFields;
    const autofilled = typeof selectAutofilled === 'function'
      ? selectAutofilled(state)
      : {};

    const visibleFields = typeof selectVisibleFields === 'function'
      ? selectVisibleFields(state)
      : {};
    return {
      validate: (values) => makeFormValidator(fields)(autofilled, visibleFields, state)(values),
      values: getFormValues(form)(state),
      fields,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    const fields = ownProps.fields || config.fields;
    const form = ownProps.form || config.form;
    return ({
      makeHandleSubmit: (validate, values) => (e) => {
        e.preventDefault();
        const syncErrors = validate(values);
        dispatch(updateSyncErrors(form, syncErrors));
        const syncErrorsExist = Object.keys(syncErrors).length > 0;
        if (syncErrorsExist) {
          dispatch(touchAll(fields, form, values));
          dispatch(setSubmitFailed(form));
        } else {
          dispatch(submit(form));
        }
      },
    });
  };

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { validate, values } = stateProps;
    const { makeHandleSubmit } = dispatchProps;
    return ({
      ...ownProps,
      handleSubmit: makeHandleSubmit(validate, values),
    });
  };
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default withCustomHandleSubmit;
