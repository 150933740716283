/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import isEqual from 'lodash/fp/isEqual.js';
import { FETCH_MODEL_RESPONSE, FETCH_MODELS_RESPONSE } from '#constants/actionTypes.js';

const byIdReducer = (state, action) => {
  const {
    meta: actionMeta,
    payload,
    type,
    error,
  } = action;
  const { byId, current, meta } = state;
  switch (type) {
    case FETCH_MODEL_RESPONSE: {
      if (error) return byId;
      const { requestId } = actionMeta;
      const { uuid } = payload;
      const modelMeta = meta.byId[uuid] || {};
      const modelValidFetchRequests = modelMeta.validFetchRequests || {};
      const isValid = modelValidFetchRequests[requestId];
      return (!isValid || isEqual(byId[uuid], payload))
        ? byId
        : { ...byId, [uuid]: payload };
    }
    case FETCH_MODELS_RESPONSE: {
      if (error) return byId;
      const { requestId } = actionMeta;
      const { validFetchRequests } = meta.all;
      const isValid = validFetchRequests[requestId];
      const nextById = Object.values(payload.entities.models).reduce((acc, model) => ({
        ...acc,
        [model.uuid]: (
          model.uuid === current
            ? byId[model.uuid]
            : model
        ),
      }), {});

      return (!isValid || isEqual(byId, nextById))
        ? byId
        : nextById;
    }
    default: {
      return byId;
    }
  }
};

export default byIdReducer;
