/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { merge } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_RESPONSE,
  FETCH_ACTION,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_RESPONSE,
  UPDATE_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_RESPONSE,
  UPDATE_ORGANIZATION_RESPONSE,
} from '#constants/actionTypes.js';

export const fetchOrganization = (orgId) => ({
  [FETCH_ACTION]: {
    types: [FETCH_ORGANIZATION_REQUEST, FETCH_ORGANIZATION_RESPONSE],
    endpoint: `/org/${orgId}`,
    options: {
      method: 'GET',
    },
  },
});

const organization = new schema.Entity('organizations', undefined, {
  idAttribute: 'uuid',
});

export const fetchOrgs = ({
  [FETCH_ACTION]: {
    types: [FETCH_ORGANIZATIONS_REQUEST, FETCH_ORGANIZATIONS_RESPONSE],
    endpoint: '/org',
    options: {
      method: 'GET',
    },
    dataTransform: (json) => {
      const defaultEntities = { organizations: {} };
      const { result, entities } = normalize(json, [organization]);
      return {
        result,
        entities: merge(defaultEntities, entities),
      };
    },
  },
});

export const postOrganization = (body) => ({
  [FETCH_ACTION]: {
    types: [CREATE_ORGANIZATION_REQUEST, CREATE_ORGANIZATION_RESPONSE],
    endpoint: '/org',
    options: {
      method: 'POST',
      body,
    },
  },
});

export const putOrganization = (orgId, body) => ({
  [FETCH_ACTION]: {
    types: [UPDATE_ORGANIZATION_REQUEST, UPDATE_ORGANIZATION_RESPONSE],
    endpoint: `/org/${orgId}`,
    options: {
      method: 'PUT',
      body,
    },
  },
});
