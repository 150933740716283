import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import getComponent from '#components/support/polling/support/withPollingOnMount.jsx';
import { fetchComponentIfUpdated } from '#actions/components/components.js';

const getContainer = (C) => {
  const mapDispatchToProps = (dispatch, ownProps) => {
    const { componentUuid } = ownProps;
    return ({
      poll: () => {
        const POLLING_INTERVAL = 5000;
        dispatch(fetchComponentIfUpdated(componentUuid));
        const pollingId = setInterval(() => {
          dispatch(fetchComponentIfUpdated(componentUuid));
        }, POLLING_INTERVAL);

        return () => {
          clearInterval(pollingId);
        };
      },
    });
  };
  return connect(undefined, mapDispatchToProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
