import { connect } from 'react-redux';
import { SidebarBadge } from '#components/support/layout/SidebarLayout/index.js';
import { modelTypesById } from '#constants/models/modelTypes/index.js';

const mapStateToProps = (state, ownProps) => {
  const { section, level, modelType } = ownProps;

  const {
    selectErrorCountBySection,
    selectFormInitialized,
    selectWarningCountBySection,
  } = modelTypesById[modelType];

  const countBySection = level === 'warning'
    ? selectWarningCountBySection(state)
    : selectErrorCountBySection(state);

  const count = countBySection[section];

  const initialized = selectFormInitialized(state);

  const visible = count > 0 && initialized;
  return {
    visible,
    count,
  };
};

export default connect(mapStateToProps)(SidebarBadge);
