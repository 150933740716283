/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import capitalize from 'lodash/fp/capitalize.js';

const authorAbbr = (firstName, lastName) => `${firstName} ${capitalize(lastName.substring(0, 1))}.`;

export default authorAbbr;
