/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { Fragment, useState } from 'react';
import {
  Button,
} from 'reactstrap';
import numeral from 'numeral';
import classNames from 'classnames';
import {
  scaleFactorGeneral,
  scaleFactorTotalCollapsed,
  scaleFactorTotalExpanded,
} from './index.module.scss';

type Props = {|
  locationItem: string,
  isDirectional: boolean,
  costModifier: number,
  capacityModifier: number,
  timeModifier: number,
  regionCostMultiplier: number,
  dateCostMultiplier: number,
  occupancyCostMultiplier: number,
  buildingValueModifier: number,
|};

const CalculatedCapacities = (props: Props): Element<'tr'> | Element<typeof Fragment> => {
  const [showDetail, setShowDetail] = useState(false);
  const {
    locationItem,
    isDirectional,
    costModifier,
    capacityModifier,
    timeModifier,
    regionCostMultiplier,
    dateCostMultiplier,
    occupancyCostMultiplier,
    buildingValueModifier,
  } = props;

  const toggleButton = (
    <Button
      className="pt-0"
      id={`${locationItem}.factorsBtn`}
      color="link"
      size="sm"
      onClick={() => {
        setShowDetail(!showDetail);
      }}
    >
      { showDetail ? 'Collapse' : 'Expand' }
    </Button>
  );

  // TODO: add component group modifier when we recreate the groups

  const totalCostScaleFactor = (
    parseFloat(costModifier)
      * parseFloat(regionCostMultiplier)
      * parseFloat(dateCostMultiplier)
      * parseFloat(occupancyCostMultiplier)
      * parseFloat(buildingValueModifier)
  );
  const totalCapacityScaleFactor = parseFloat(capacityModifier);
  const totalTimeScaleFactor = parseFloat(timeModifier);

  return showDetail
    ? (
      <>
        <tr className={classNames(scaleFactorGeneral, 'scale-factor-expanded')}>
          <td>
            {toggleButton}
          </td>
          <td className="text-right" colSpan={isDirectional ? 2 : 1}>
            Region Cost Multiplier:
          </td>
          <td>{numeral(regionCostMultiplier).format('0.00')}</td>
          <td>--</td>
          <td>--</td>
        </tr>
        <tr className={scaleFactorGeneral}>
          <td />
          <td className="text-right" colSpan={isDirectional ? 2 : 1}>
            Date Cost Multiplier:
          </td>
          <td>{numeral(dateCostMultiplier).format('0.00')}</td>
          <td>--</td>
          <td>--</td>
        </tr>
        <tr className={scaleFactorGeneral}>
          <td />
          <td className="text-right" colSpan={isDirectional ? 2 : 1}>
            Occupancy Cost Multiplier:
          </td>
          <td>{numeral(occupancyCostMultiplier).format('0.00')}</td>
          <td>--</td>
          <td>--</td>
        </tr>
        <tr className={scaleFactorGeneral}>
          <td />
          <td className="text-right" colSpan={isDirectional ? 2 : 1}>
            Building Value Modifier:
          </td>
          <td>{numeral(buildingValueModifier).format('0.00')}</td>
          <td>--</td>
          <td>--</td>
        </tr>
        <tr className={scaleFactorTotalExpanded}>
          <td />
          <td className="text-right" colSpan={isDirectional ? 2 : 1}>
            Total Scale Factor:
          </td>
          <td>{numeral(totalCostScaleFactor).format('0.00')}</td>
          <td>{numeral(totalCapacityScaleFactor).format('0.00')}</td>
          <td>{numeral(totalTimeScaleFactor).format('0.00')}</td>
        </tr>
      </>
    )
    : (
      <tr className={classNames(scaleFactorTotalCollapsed, 'scale-factor-collapsed')}>
        <td>
          {toggleButton}
        </td>
        <td className="text-right" colSpan={isDirectional ? 2 : 1}>
          Total Scale Factor:
        </td>
        <td>{numeral(totalCostScaleFactor).format('0.00')}</td>
        <td>{numeral(totalCapacityScaleFactor).format('0.00')}</td>
        <td>{numeral(totalTimeScaleFactor).format('0.00')}</td>
      </tr>
    );
};

export default CalculatedCapacities;
