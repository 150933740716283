import head from 'lodash/fp/head.js';
import get from 'lodash/fp/get.js';

const makeNoDuplicateValuesAtFieldAcrossItemsValidator = (
  arrayPattern,
  indexCapturePattern,
  message
) => (value, values, state, path) => {
  const arrayPath = head(path.match(arrayPattern) || []);
  const array = get(arrayPath, values);
  const indexMatch = path.match(indexCapturePattern) || [];
  const index = indexMatch.length > 0
    ? parseInt(indexMatch[1], 10)
    : null;
  const duplicate = array.some(
    (item, i) => item.name === value && i !== index
  );
  return duplicate ? message : undefined;
};

export default makeNoDuplicateValuesAtFieldAcrossItemsValidator;
