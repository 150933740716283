/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import toLower from 'lodash/fp/toLower.js';
import trim from 'lodash/fp/trim.js';

const trimAndToLower = pipe(trim, toLower);

export default trimAndToLower;
