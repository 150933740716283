// @flow
import type { Element } from 'react';
import React from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import {
  MainContentArea,
} from '#src/js/components/support/layout/SidebarLayout/index.js';
import ComponentSummary from '#components/pages/Components/Dashboard/Main/ComponentSummary/index.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

type Props = {
  top?: string,
};
const ComponentsDashboardMainContentArea = ({ top = '40px' }: Props): Element<typeof MainContentArea> => (
  <MainContentArea md="8" top={top}>
    <Routes>
      <Route path={`:${COMPONENT_UUID}`} element={<ComponentSummary showCopyButton showArchiveToggle />} />
      <Route path="/*" element={<ComponentSummary showCopyButton showArchiveToggle />} />
    </Routes>
  </MainContentArea>
);

export default ComponentsDashboardMainContentArea;
