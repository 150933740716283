/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { ActionRowButton } from '#components/support/layout/ActionRow/index.jsx';

type Props = {|
  children: Node,
  className: string,
  disabled: boolean,
  handleClick: Function,
|};

const CreateComponentButton = ({
  className,
  children,
  disabled,
  handleClick,
}: Props): Element<typeof ActionRowButton> => (
  <ActionRowButton
    className={className}
    onClick={handleClick}
    disabled={disabled}
  >
    {children}
  </ActionRowButton>
);

export default CreateComponentButton;
