/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import findIndex from 'lodash/fp/findIndex.js';
import React from 'react';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import NextButton from '../NextButton.jsx';

type Props = {|
  group: string,
  modelType: number,
  onClick: Function,
|};

const nextSectionGroupHeading = (groupId, modelType) => {
  const allGroups = modelTypesById[modelType].allSectionGroups;
  const index = findIndex(({ id }) => id === groupId, allGroups);
  return allGroups[index + 1].heading;
};

const ContinueToNextSectionGroupButton = ({
  group,
  modelType,
  onClick,
}: Props): Element<typeof NextButton> => {
  const nextGroupHeading = nextSectionGroupHeading(group, modelType);
  return (
    <NextButton onClick={onClick}>
      {`Continue to ${nextGroupHeading}`}
    </NextButton>
  );
};

export default ContinueToNextSectionGroupButton;
