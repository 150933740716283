/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import get from 'lodash/fp/get.js';
import numeral from 'numeral';
import { modeShape } from './ModeShapeTable.module.scss';

type ModeShape = {
  floor: number,
  mode: number,
  value: number,
};

type Props = {|
modeShapeDataGroupedByFloorAndMode: {
    [string]: ModeShape[],
  },
  modes: number[],
  floors: number[],
|};

const ModeShapeTable = (props: Props): Element<typeof Table> => {
  const {
    modeShapeDataGroupedByFloorAndMode,
    modes,
    floors,
  } = props;

  const getFloorModeValue = (floor, mode) => get(`[${floor}|${mode}]`, modeShapeDataGroupedByFloorAndMode);

  return (
    <Table striped bordered className={modeShape} responsive>
      <thead>
        <tr>
          <th>Floor</th>
          {modes.map((mode) => (
            <th key={mode}>
              Mode
              {' '}
              {mode}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        { floors.map((floor) => (
          <tr key={floor}>
            <td>{floor}</td>
            { modes.map((mode) => (
              <td key={`${floor}-${mode}`}>
                {numeral(getFloorModeValue(floor, mode)).format('0.00')}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ModeShapeTable;
