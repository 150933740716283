import { connect } from 'react-redux';
import get from 'lodash/fp/get.js';
import SubNav from '#components/pages/Models/Model/SubNav/SubNav.jsx';
import {
  modelById,
  selectModelType,
} from '#selectors/entities/models.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { selectAuthUserIsAdmin } from '#selectors/entities/users.js';

const mapStateToProps = (state, ownProps) => {
  const { modelId } = ownProps;
  const modelTypeId = selectModelType(state, { modelId });
  const modelType = modelTypesById[modelTypeId];
  const modelTypeName = get('name', modelType);
  const model = modelById(state, { modelId });
  const modelName = get('modelName', model);
  return ({
    modelId,
    modelType: modelTypeId,
    modelTypeName,
    modelName,
    includeDebug: selectAuthUserIsAdmin(state),
  });
};

export default connect(mapStateToProps)(SubNav);
