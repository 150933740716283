/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';

import { selectAuthUserTokenIsSet, selectAuthUserIsAdmin } from '#selectors/entities/users.js';
import RequireAdminPermissions from './RequireAdminPermissions.jsx';

const mapStateToProps = (state) => ({
  isAdmin: selectAuthUserIsAdmin(state),
  isAuthenticated: selectAuthUserTokenIsSet(state),
});

export default connect(mapStateToProps)(RequireAdminPermissions);
