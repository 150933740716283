/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldArray } from 'redux-form';
import ResponseDetail from './ResponseDetail/index.js';

type RenderStructuralResponseProps = {|
  modelId: string,
  responseIndex: number,
  fields: {
    get: Function,
    getAll: Function,
    map: Function,
    push: Function,
  },
  form: string,
|}

const renderStructuralResponses = ({
  responseIndex, fields, form, modelId,
}: RenderStructuralResponseProps): any => {
  const edpResponses = fields.map((field, index) => {
    const { edpKey, method, upload } = fields.get(index);
    return responseIndex === index
      ? (
        <ResponseDetail
          modelId={modelId}
          form={form}
          field={field}
          responseIndex={responseIndex}
          edpKey={edpKey}
          method={method}
          responseId={upload}
          key="edp-structural-response"
        />
      ) : null;
  });
  return (
    <Row>
      <Col>
        {edpResponses}
      </Col>
    </Row>
  );
};

type Props = {|
  responseIndex: number,
  modelId: string,
  form: string,
|};

const Response = (props: Props): Element<typeof FieldArray> => {
  const {
    responseIndex,
    modelId,
    form,
  } = props;

  return (
    <FieldArray
      form={form}
      responseIndex={responseIndex}
      modelId={modelId}
      name="structuralResponses"
      component={renderStructuralResponses}
    />
  );
};

export default Response;
