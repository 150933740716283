// @flow
import type { Element } from 'react';
import classNames from 'classnames';
import React from 'react';
import { Badge } from 'reactstrap';
import { badge } from './index.module.scss';

type Props = {|
  className?: string | null,
  count?: number,
  level?: string,
  visible?: boolean,
|};

const SidebarBadge = ({
  className = null,
  count = 0,
  level = 'error',
  visible = true,
}: Props): Element<typeof Badge> | null => {
  const color = level === 'warning'
    ? 'warning'
    : 'danger';

  return visible
    ? <Badge className={classNames(badge, className)} color={color}>{count}</Badge>
    : null;
};

export default SidebarBadge;
