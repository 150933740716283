/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import withCustomHandleSubmit from '#components/support/forms/withCustomHandleSubmit/index.js';

const withModelFormCustomHandleSubmit = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { modelType } = ownProps;
    const {
      fields,
      modelFormName: form,
      selectAutofilledFormFields: selectAutofilled,
      selectFieldsVisibility: selectVisibleFields,
    } = modelTypesById[modelType];
    return {
      form,
      fields,
      selectAutofilled,
      selectVisibleFields,
    };
  };
  return connect(mapStateToProps)(withCustomHandleSubmit()(C));
};

export default withModelFormCustomHandleSubmit;
