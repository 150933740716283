// @flow

import get from 'lodash/fp/get.js';
import type { Element } from 'react';
import React from 'react';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';

const warningsColumn: ColumnType = {
  accessor: 'warning',
  id: 'warnings',
  Header: 'Warnings',
  additionalClasses: ['text-wrap'],
  Cell: (data: CellData): Element<'div'> | null => {
    const warning = get('original.warning', data);
    return warning
      ? <div className="text-warning">{warning}</div>
      : null;
  },
};

export default warningsColumn;
