// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { readOnlyView } from './index.module.scss';

type Props = {
  children: Node,
  id: string,
};

const ReadOnlyView = ({ id, children }: Props): Element<'div'> => (
  <div id={id} className={classNames('read-only-field', readOnlyView)}>
    {children}
  </div>
);

export default ReadOnlyView;
