/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {|
  regionCostMultiplier: number,
  dateCostMultiplier: number,
  occupancyCostMultiplier: number,
  buildingValueModifier: number,
|};

const RepairCostExposureScaleFactorsApplied = (props: Props): Element<'div'> => {
  const {
    regionCostMultiplier,
    dateCostMultiplier,
    occupancyCostMultiplier,
    buildingValueModifier,
  } = props;
  return (
    <div id="repairCostExposureScaleFactorsApplied">
      <FormGroup tag="fieldset">
        <legend>Scale Factors Applied</legend>
        <Row>
          <Col lg="4">
            Region Cost Multiplier
            {' '}
            <Tip tipId="regionCostMultiplier" />
            {' :'}
          </Col>
          <Col lg="8">{regionCostMultiplier}</Col>
        </Row>
        <Row>
          <Col lg="4">
            Date Cost Multiplier
            {' '}
            <Tip tipId="dateCostMultiplier" />
            {' :'}
          </Col>
          <Col lg="8">{dateCostMultiplier}</Col>
        </Row>
        <Row>
          <Col lg="4">
            Occupancy Cost Multiplier
            {' '}
            <Tip tipId="occupancyCostMultiplier" />
            {' :'}
          </Col>
          <Col lg="8">{occupancyCostMultiplier}</Col>
        </Row>
        <Row>
          <Col lg="4">
            Building Value Modifier
            {' '}
            <Tip tipId="buildingValueModifier" />
            {' :'}
          </Col>
          <Col lg="8">{buildingValueModifier}</Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default RepairCostExposureScaleFactorsApplied;
