// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  GROUP,
  MAJOR,
  MINOR,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { fieldsByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import {
  Field, Col, FormGroup, Fieldset,
} from '#components/pages/Components/support/form/index.js';

import BackButton from '../support/buttons/BackButton.jsx';
import NextButton from '../support/buttons/NextButton.jsx';

const ComponentGrouping = ({
  onBack, onNext, readOnly, form,
}: SectionComponentProps): Element<'div'> => (
  <Form noValidate>
    <Fieldset>
      <legend>Component Grouping</legend>
      <FormGroup fields={[GROUP]} row>
        <Col lg="6">
          <Field field={fieldsByName[GROUP]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup row form={form} fields={[MAJOR]}>
        <Col lg="6">
          <Field field={fieldsByName[MAJOR]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup row form={form} fields={[MINOR]}>
        <Col lg="6">
          <Field field={fieldsByName[MINOR]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default ComponentGrouping;
