import pipe from 'lodash/fp/pipe.js';
import Components from '#components/pages/Components/Components.jsx';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import withLocation from '#components/support/routing/withLocation/index.jsx';
import withComponentsPolling from '#components/support/polling/withComponentsPolling/index.js';
import withComponentCategoriesPolling from '#components/support/polling/withComponentCategoriesPolling/index.js';
import withModelsPolling from '#components/support/polling/withModelsPolling/index.js';
import withAppMaintenanceModePolling from '#components/support/polling/withAppMaintenanceModePolling/index.js';
import withAppVersionPolling from '#components/support/polling/withAppVersionPolling/index.js';
import withAuthUserPolling from '#components/support/polling/withAuthUserPolling/index.js';

export default pipe(
  withAppMaintenanceModePolling,
  withAppVersionPolling,
  withAuthUserPolling,
  withComponentCategoriesPolling,
  withComponentsPolling,
  withModelsPolling,
  withRouteParams,
  withLocation,
)(Components);
