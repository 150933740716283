/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Contributor: Michael Shaffer <michael@hbrisk.com>
 * Date: 5/30/18
 * Copyright All Rights Reserved
 */

/*
 * Use this module to set the runtime configs
 */
import get from 'lodash/fp/get.js';
import toString from 'lodash/fp/toString.js';

export const APP_VERSION = 'appVersion';
export const API_BASE_URL = 'apiBaseUrl';
export const AWS_REGION = 'region';
export const COGNITO_USER_POOL_ID = 'cognitoUserPoolId';
export const COGNITO_WEB_CLIENT_ID = 'cognitoWebClientId';
export const GOOGLE_MAPS_API_KEY = 'googleMapsApiKey';

export const ENVIRONMENT = 'environment';
export const ROLLBAR_ACCESS_TOKEN = 'rollbarAccessToken';
export const ROLLBAR_ENABLED = 'rollbarEnabled';

/**
 * The default set of configuration values that the application should revert to when no other
 * values can be found. This gets overwritten by the values for the specific context.
 */

const appConfigs = {
  [API_BASE_URL]: DEFAULT_API_BASE_URL, /* global DEFAULT_API_BASE_URL */
  [APP_VERSION]: DEFAULT_APP_VERSION, /* global DEFAULT_APP_VERSION */
  [AWS_REGION]: DEFAULT_AWS_REGION, /* global DEFAULT_AWS_REGION */
  [COGNITO_USER_POOL_ID]: DEFAULT_COGNITO_USER_POOL_ID, /* global DEFAULT_COGNITO_USER_POOL_ID */
  [COGNITO_WEB_CLIENT_ID]: DEFAULT_COGNITO_WEB_CLIENT_ID, /* global DEFAULT_COGNITO_WEB_CLIENT_ID */
  [GOOGLE_MAPS_API_KEY]: 'AIzaSyAH1ncbQd7B4hKgUgvR_f5plHYkQJJHQ_Y',
  [ROLLBAR_ACCESS_TOKEN]: '9e3589e10b7d4ffdbc06c276190399b4',
  [ROLLBAR_ENABLED]: false,
  [ENVIRONMENT]: toString(get('location.hostname', window)),
};

/**
 * Merge a provided set of configs with the list of default configs so that every environment only
 * has to specify the configs that it actually needs to be different from the defaults.
 *
 * @param configs
 * @returns {*}
 */
function mergeDefaults(configs) {
  return Object.assign(appConfigs, configs);
}

/**
 * Returns a promise that resolves the app's configs based on the current host name
 *
 * @returns {Promise}
 */
const requestConfigs = () => new Promise((resolve) => {
  switch (window.location.hostname) {
    case 'risk-model.hbrisk.com':
      resolve(mergeDefaults({
        [API_BASE_URL]: 'https://1oo4xslpxe.execute-api.us-west-2.amazonaws.com/Prod',
        [AWS_REGION]: 'us-west-2',
        [COGNITO_USER_POOL_ID]: 'us-west-2_dkC6RZUBd',
        [COGNITO_WEB_CLIENT_ID]: '1fo9rlhmasd63uoq2l8p1i3ch8',
        [ENVIRONMENT]: 'production',
        [ROLLBAR_ENABLED]: true,
      }));
      break;
    case 'staging.risk-model.hbrisk.com':
      resolve(mergeDefaults({
        [API_BASE_URL]: 'https://23ms753dvh.execute-api.us-west-2.amazonaws.com/Prod',
        [AWS_REGION]: 'us-west-2',
        [COGNITO_USER_POOL_ID]: 'us-west-2_Jd3hu0DuZ',
        [COGNITO_WEB_CLIENT_ID]: '4epqe360sda2no4dg3r82esa4j',
        [ENVIRONMENT]: 'staging',
      }));
      break;
    case 'dev.risk-model.hbrisk.com':
      resolve(mergeDefaults({
        [API_BASE_URL]: 'https://3w3jp34dr3.execute-api.us-west-2.amazonaws.com/Prod',
        [AWS_REGION]: 'us-west-2',
        [COGNITO_USER_POOL_ID]: 'us-west-2_1td9Xiru7',
        [COGNITO_WEB_CLIENT_ID]: '10m99l9is9o36oj5hr8jt1pp29',
        [ENVIRONMENT]: 'development',
      }));
      break;
    default:
      resolve(appConfigs);
      break;
  }
});

export const initializeConfigs = () => requestConfigs().then((configs) => {
  Object.keys(configs).forEach((prop) => {
    appConfigs[prop] = configs[prop];
  });
  return appConfigs;
});

/**
 * Gets the config values and then attempts to find the given name attribute returning undefined if
 * not found.
 *
 * @param name
 * @returns {*}
 */
const config = (name) => appConfigs[name];

export default config;
