/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  FETCH_ACTION,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_RESPONSE,
} from '#constants/actionTypes.js';

export const uploadToUrl = (file, endpoint) => ({
  [FETCH_ACTION]: {
    types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_RESPONSE],
    withAuth: false,
    options: {
      body: file,
      headers: { 'Content-Type': 'text/csv' },
      method: 'PUT',
    },
    endpoint,
  },
});
