/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import { COMPONENTS, MODELS } from '#constants/pathStrings.js';

type CellType = (data: CellData) => Element<typeof Button>;

const makeHandleClick = (url) => () => {
  window.open(
    url,
    '_blank',
    'width=1400,height=900'
  );
  return false;
};

const Cell: CellType = (data) => {
  const uuid = get('original.uuid', data);
  const modelName = get('original.modelName', data) || 'Unnamed Model';
  const archived = get('original.archived', data);
  const modelUuid = get('original.uuid', data);
  const handleClick = makeHandleClick(`/${COMPONENTS}/${MODELS}/${uuid}/`);
  return (
    <Button
      id={`modelButton-${modelUuid}`}
      to={`${uuid}`}
      disabled={archived}
      color="link"
      // tag={Link}
      onClick={handleClick}
      title={archived ? 'Model is archived. Restore model to edit' : modelName}
      className="p-0 text-left"
    >
      {modelName}
    </Button>
  );
};

const column: ColumnType = {
  id: 'modelName',
  accessor: 'modelName',
  Header: 'Model',
  Cell,
  additionalClasses: ['text-wrap'],
};

export default column;
