/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import BuildingDesignInfoSection from './BuildingDesignInfoSection.jsx';

const mapStateToProps = (state, ownProps) => ({
  showRetrofitFields: modelTypesByForm[ownProps.form].selectFormFieldVisibility(state)('structuralRetrofitLevel'),
  showRiskCategoryFields: modelTypesByForm[ownProps.form].selectFormFieldVisibility(state)('riskCategory'),
  showDriftLimit: modelTypesByForm[ownProps.form].selectFormFieldVisibility(state)('driftLimitDir1'),
});

export default connect(mapStateToProps)(BuildingDesignInfoSection);
