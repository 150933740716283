/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { arrayRemove } from 'redux-form';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import Action from './ActionCell.jsx';

const mapStateToProps = (state, ownProps) => {
  const { makeFieldIsAutofilledSelector } = modelTypesByForm[ownProps.form];
  const selectFieldIsAutofilled = makeFieldIsAutofilledSelector();

  return {
    autofilled: selectFieldIsAutofilled(state, { name: 'componentPopulation' }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { index, form } = ownProps;
  return {
    handleDelete: () => {
      dispatch(arrayRemove(form, 'componentPopulation', index));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Action);
