import { connect } from 'react-redux';
import AddConsequenceButton
  from '#components/pages/Components/Component/sections/Consequences/Main/renderMainConsequencesTable/AddConsequenceButton/AddConsequenceButton.jsx';
import { selectConsequenceMaxExceeded } from '#selectors/ui/form/component.js';

const mapStateToProps = (state) => ({
  disabled: selectConsequenceMaxExceeded(state),
});

export default connect(mapStateToProps)(AddConsequenceButton);
