import { connect } from 'react-redux';
import SaveStatusItem from '#components/pages/Components/Component/SubNav/Snippet/SaveStatusItem/SaveStatusItem.jsx';
import { selectCurrentComponentUpdating } from '#selectors/entities/components.js';
import { IN_PROGRESS, ERROR } from '#reducers/entities/components/index.js';

const mapStateToProps = (state) => {
  const updating = selectCurrentComponentUpdating(state);
  return {
    inProgress: updating[IN_PROGRESS],
    error: updating[ERROR],
  };
};

export default connect(mapStateToProps)(SaveStatusItem);
