/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import CodeYearSelectMenu from './CodeYearSelectMenu/index.jsx';

type Props = {|
  yearOfConstructionName: string,
  designCodeYearName: string,
  useYearOfConstruction: boolean,
  showDesignCodeYearToggle: boolean,
  showYearOfConstructionToggle: boolean,
  showInternationalVersion: boolean,
  form: string,
  handleToggle: Function,
|};

const YearOfConstructionOrCodeYear = (props: Props): Element<typeof Fragment> => {
  const {
    yearOfConstructionName,
    designCodeYearName,
    form,
    useYearOfConstruction,
    handleToggle,
    showDesignCodeYearToggle,
    showYearOfConstructionToggle,
    showInternationalVersion,
  } = props;

  const toggle = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleToggle(useYearOfConstruction);
  };

  return useYearOfConstruction ? (
    <>
      <GridField
        key={yearOfConstructionName}
        name={yearOfConstructionName}
        form={form}
        options={{
          labelText: 'Year of Construction',
          ComponentType: 'text',
          includeToolTip: true,
          includeFormatting: false,
        }}
      />
      {showDesignCodeYearToggle && (
        <Button id={`${yearOfConstructionName}Button`} color="link" onClick={toggle}>Use design code?</Button>
      )}
    </>
  ) : (
    <>
      <GridField
        key={designCodeYearName}
        name={designCodeYearName}
        form={form}
        options={{
          labelText: showInternationalVersion ? 'Equivalent Design Code' : 'Design Code',
          ComponentType: CodeYearSelectMenu,
          includeToolTip: true,
          toolTipId: showInternationalVersion ? 'designCodeYearInt' : designCodeYearName,
          includeFormatting: false,
        }}
      />
      {showYearOfConstructionToggle && (
        <Button id={`${designCodeYearName}Button`} color="link" onClick={toggle}>Use year of construction?</Button>
      )}
    </>
  );
};

export default YearOfConstructionOrCodeYear;
