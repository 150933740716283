import { connect } from 'react-redux';
import { COMPONENT_ID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import SubNav from '#components/pages/Components/Component/SubNav/SubNav.jsx';
import { selectComponentByUuid } from '#selectors/entities/components.js';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const component = selectComponentByUuid(state, { uuid: componentUuid });
  const componentId = component ? component[COMPONENT_ID] : null;
  return {
    componentId,
  };
};
export default connect(mapStateToProps)(SubNav);
