/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';

type SeismicityLevelLiProps = {|
  label: string,
  ss: number,
  s1: number,
|};

const SeismicityLevelLi = (props: SeismicityLevelLiProps): Element<'li'> => {
  const { label, ss, s1 } = props;
  return (
    <li key={label}>
      {label}
      : S
      <sub>s</sub>
      {' '}
      =
      {' '}
      {ss}
      g,
      S
      <sub>1</sub>
      {' '}
      =
      {' '}
      {s1}
      g
    </li>
  );
};

export default SeismicityLevelLi;
