/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import { damageCorrelationModels } from '#constants/models/damageCorrelationModels.js';

type Props = {|
  form: string,
  name: string,
|};

const DamageCorrelationModelSelectMenu = (
  { form, name }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={damageCorrelationModels}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'damage-correlation-model-select')}
    includeEmptyOption={false}
  />
);

export default DamageCorrelationModelSelectMenu;
