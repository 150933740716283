// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col } from 'reactstrap';
import type { columnProps } from '#components/support/layout/SidebarLayout/types/index.js';
import { mainContentArea } from './index.module.scss';

// type Props = {
//   children?: Node,
//   className?: string,
//   xs?: columnProps,
//   sm?: columnProps,
//   md?: columnProps,
//   lg?: columnProps,
//   xl?: columnProps,
//   widths?: Array<any>,
//   top?: string,
// };

type Props = {
  children?: Node,
  className?: string | null,
  xs?: columnProps | null,
  sm?: columnProps | null,
  md?: columnProps | null,
  lg?: columnProps | null,
  xl?: columnProps | null,
  top?: string,
}

const MainContentArea = ({
  children = null,
  className = null,
  xs = null,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  top = '85px',
}: Props): Element<typeof Col> => (
  <Col
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    className={classNames(mainContentArea, className)}
    role="main"
    style={{ top }}
  >
    {children}
  </Col>
);

export default MainContentArea;
