/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import {
  selectHasModeShapeData,
  selectIsModeShapeMatch,
} from '#selectors/entities/models.js';
import StructuralPropertiesSection from './StructuralPropertiesSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId, form } = ownProps;
  const {
    selectFormFieldVisibility,
    selectFormValues,
  } = modelTypesByForm[form];

  const {
    modeShapeMethod: method,
    modeShapeUpload: upload,
  } = selectFormValues(state, 'modeShapeMethod', 'modeShapeUpload');

  const isModeShapeMatch = selectIsModeShapeMatch(state, { modelId, method, upload });
  const hasModeShapeData = selectHasModeShapeData(state, { modelId });

  return ({
    showAllowComponentsToAffectStructuralProperties: selectFormFieldVisibility(state)('allowComponentsToAffectStructuralProperties'),
    showBrbToDiaphragmRatioDir1: selectFormFieldVisibility(state)('brbToDiaphragmRatioDir1'),
    showBrbToDiaphragmRatioDir2: selectFormFieldVisibility(state)('brbToDiaphragmRatioDir2'),
    showLinesOfResistanceDir1: selectFormFieldVisibility(state)('linesOfResistanceDir1'),
    showLinesOfResistanceDir2: selectFormFieldVisibility(state)('linesOfResistanceDir2'),
    showTypeOfResistanceDir1: selectFormFieldVisibility(state)('typeOfResistanceDir1'),
    showTypeOfResistanceDir2: selectFormFieldVisibility(state)('typeOfResistanceDir2'),
    showYieldStoryDrift: selectFormFieldVisibility(state)('yieldStoryDriftDir1'),
    showDesignPeriod1Dir1: selectFormFieldVisibility(state)('designPeriod1Dir1'),
    showDesignPeriod1Dir2: selectFormFieldVisibility(state)('designPeriod1Dir2'),
    showModeShapes: selectFormFieldVisibility(state)('modeShapeMethod'),
    showModeShapeUploader: selectFormFieldVisibility(state)('modeShapeUpload'),
    showModeShapeDetails: isModeShapeMatch && hasModeShapeData,
  });
};

export default connect(mapStateToProps)(StructuralPropertiesSection);
