/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectRunExposureBreakdown, selectRunExposureBreakdownColumnsOrdered } from '#selectors/entities/models.js';
import RepairCostAt475ReturnPeriodPlot from './RepairCostExposurePlot.jsx';

const mapStateToProps = (state, ownProps) => {
  const data = selectRunExposureBreakdown(state, ownProps);
  const fieldsSortOrder = selectRunExposureBreakdownColumnsOrdered(state, ownProps);
  return {
    data,
    fieldsSortOrder,
  };
};

export default connect(mapStateToProps)(RepairCostAt475ReturnPeriodPlot);
