/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { ATC_138_ID } from '@hbrisk/sp3-risk-model-support/models/repairTimeMethods.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { selectRunRepairTimeMethod } from '#selectors/entities/models.js';
import RepairTime from './RepairTime.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { selectFormValues } = modelTypesByForm[form];

  const formRepairTimeMethod = selectFormValues(state, 'repairTimeMethod');
  const runRepairTimeMethod = selectRunRepairTimeMethod(state, ownProps);

  return ({
    IsATC138: formRepairTimeMethod === ATC_138_ID,
    showContent: formRepairTimeMethod === runRepairTimeMethod,
  });
};

export default connect(mapStateToProps)(RepairTime);
