/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import React from 'react';
import type { Element } from 'react';
import type { Fields } from 'redux-form/lib/FieldArrayProps.types';

import AddResponseForm from './AddResponseForm/index.js';

type Props = {|
  fields: Fields,
|};

const render = ({
  fields,
}: Props): Element<typeof AddResponseForm> => (
  <AddResponseForm
    fields={fields}
  />
);

export default render;
