import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import getComponent from '#components/support/polling/support/withPollingOnMount.jsx';
import { fetchUsers } from '#actions/users/users.js';

const getContainer = (C) => {
  const mapDispatchToProps = (dispatch) => ({
    poll: () => {
      const POLLING_INTERVAL = 5000;
      dispatch(fetchUsers);
      const pollingId = setInterval(() => {
        dispatch(fetchUsers);
      }, POLLING_INTERVAL);

      return () => {
        clearInterval(pollingId);
      };
    },
  });

  return connect(undefined, mapDispatchToProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
