/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { selectIsMaintenanceMode, selectIsNewAppVersion } from '#selectors/ui/app.js';
import { selectAuthUserNeedsTermsOfService } from '#selectors/entities/users.js';

import Page from './Page.jsx';

const mapStateToProps = (state) => ({
  isAppMaintenanceMode: selectIsMaintenanceMode(state),
  hasNewAppVersion: selectIsNewAppVersion(state),
  userNeedsTermsOfService: selectAuthUserNeedsTermsOfService(state),
});

export default pipe(
  connect(mapStateToProps),
)(Page);
