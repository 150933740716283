/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { FormGroup } from 'reactstrap';
import { componentQuestionsByName } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import getImmediateDescendants from '#support/models/componentQuestions/getImmediateDescendants.js';
import ComponentQuestionSelectMenu from './ComponentQuestionSelectMenu/index.jsx';

type Props = {|
  form: string,
  name: string,
  visibleFields: [string],
|};

const getRenderFields = (form, visibleFields) => {
  const renderFields = (name: string): Element<'div'> | null => {
    const {
      answers,
      text,
      isRoot,
    } = componentQuestionsByName[name];
    const immediateDescendants = getImmediateDescendants(name);
    return visibleFields.includes(name) ? (
      <FormGroup key={name} className={isRoot ? '' : 'pl-5'}>
        <GridField
          form={form}
          name={name}
          options={{
            columnSize: 4,
            labelText: text,
            ComponentType: ComponentQuestionSelectMenu,
            componentProps: {
              name,
              answers,
            },
            includeToolTip: true,
          }}
        />
        { immediateDescendants.map((n) => renderFields(n))}
      </FormGroup>
    ) : null;
  };
  return renderFields;
};

const ComponentQuestion = ({
  form,
  name,
  visibleFields,
}: Props): Function => getRenderFields(form, visibleFields)(name);

export default ComponentQuestion;
