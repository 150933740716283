/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {|
  original: {
    uuid: string,
    name: string,
  },
|};

const LinkCell = (props: Props): Element<typeof Link> => {
  const { original: { uuid, name } } = props;
  return (
    <Link id={uuid} to={uuid}>
      {name}
    </Link>
  );
};

export default LinkCell;
