/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import difference from 'lodash/fp/difference.js';
import getOr from 'lodash/fp/getOr.js';
import { required } from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import {
  currentModelId,
  selectStructuralResponseLocations,
  selectStructuralResponseErrors,
  selectStructuralResponseReturnPeriodsById,
  selectStructuralResponseIsStory,
  selectUploadedGroundMotionReturnPeriods,
} from '#selectors/entities/models.js';
import isUploadMethod from '#support/models/structuralResponses/structuralResponseIsUploadMethod.js';
import validateItemFields from '#support/utility/form/validation/validateItemFields.js';
import isGroundMotionUpload from '#support/models/groundMotion/groundMotionIsUploadMethod.js';
import requiredEdpsForBuildingTypes from '#support/models/buildingType/requiredEdpsForBuildingTypes/index.js';

/* Created individual field validators */

const canBeSp3 = (value, values, array, index) => {
  const { buildingTypeDir1, buildingTypeDir2 } = values;
  if (buildingTypeDir1 && buildingTypeDir2 && value === 'sp3') {
    const { edpKey } = array[index];
    const requiredEdps = requiredEdpsForBuildingTypes(buildingTypeDir1, buildingTypeDir2);
    if (!requiredEdps.includes(edpKey)) {
      return 'Method is not supported for this model\'s structural systems';
    }
  }
  return undefined;
};

const noIncludeDesignIntensitiesWithUSGS = (value, values) => {
  const { includeDesignIntensities, groundMotionMethod } = values;
  const condition = (
    isUploadMethod(value)
    && !isGroundMotionUpload(groundMotionMethod)
    && includeDesignIntensities
  );

  return condition
    ? 'Can\'t upload Structural Responses when "Ground Motion Source" option is USGS and "Include Design Intensities" option is true'
    : undefined;
};

const requiredUpload = (value, values, array, index) => {
  const { method } = array[index];
  if (isUploadMethod(method) && !value) {
    return 'Upload file required';
  }
  return undefined;
};

const noBackendUploadErrors = (value, values, array, index, state) => {
  const modelId = currentModelId(state);
  const backendErrors = selectStructuralResponseErrors(state, { modelId, responseId: value });
  if (!Array.isArray(backendErrors)) {
    return undefined;
  }
  return backendErrors.length > 0 ? backendErrors : undefined;
};

const getPeriodsFromField = (values) => {
  const periodsAsString = getOr(null, 'returnPeriods', values);
  return periodsAsString === null
    ? periodsAsString
    : periodsAsString.split(',').map((string) => parseInt(string, 10));
};

const isNonEmptyArray = (v) => Array.isArray(v) && v.length > 0;

const getExpectedReturnPeriods = (modelId, values, state) => {
  const periodsFromField = getPeriodsFromField(values);
  const periodsFromGroundMotionUpload = selectUploadedGroundMotionReturnPeriods(
    state,
    { modelId }
  );
  if (isNonEmptyArray(periodsFromGroundMotionUpload)) {
    return periodsFromGroundMotionUpload;
  } if (isNonEmptyArray(periodsFromField)) {
    return periodsFromField;
  }
  return null;
};

const hasExpectedReturnPeriods = (value, values, array, index, state) => {
  const modelId = currentModelId(state);
  const uploadStructuralResponseReturnPeriods = selectStructuralResponseReturnPeriodsById(
    state,
    { modelId, responseId: value }
  );
  const expectedReturnPeriods = getExpectedReturnPeriods(modelId, values, state);

  if ([uploadStructuralResponseReturnPeriods, expectedReturnPeriods].includes(null)) {
    return undefined;
  }

  const missing = difference(expectedReturnPeriods, uploadStructuralResponseReturnPeriods);
  const unexpected = difference(uploadStructuralResponseReturnPeriods, expectedReturnPeriods);

  const missingErrors = missing.length > 0
    ? [`Missing return periods in uploaded file: ${missing.join(', ')}`]
    : [];

  const unexpectedErrors = unexpected.length > 0
    ? [`Unexpected return periods in uploaded file: ${unexpected.join(', ')}`]
    : [];

  const errors = [
    ...missingErrors,
    ...unexpectedErrors,
  ];
  return errors.length > 0 ? errors : undefined;
};

const hasExpectedLocations = (value, values, array, index, state) => {
  const modelId = currentModelId(state);
  const isStory = selectStructuralResponseIsStory(state, { modelId, responseId: value });
  const uploadLocations = selectStructuralResponseLocations(
    state,
    { modelId, responseId: value }
  );
  const numberOfStories = parseInt(getOr(null, 'numberOfStories', values), 10);

  if ([isStory, uploadLocations].includes(null) || Number.isNaN(numberOfStories)) {
    return undefined;
  }

  const locationDescription = isStory ? 'stories' : 'floors';

  const expectedNumberOfLocations = isStory ? numberOfStories : numberOfStories + 1;
  const expectedLocations = [...Array(expectedNumberOfLocations).keys()].map((v) => v + 1);

  const missing = difference(expectedLocations, uploadLocations);
  const unexpected = difference(uploadLocations, expectedLocations);

  const missingErrors = missing.length > 0
    ? [`Missing ${locationDescription} in uploaded file: ${missing.join(', ')}`]
    : [];

  const unexpectedErrors = unexpected.length > 0
    ? [`Unexpected ${locationDescription} in uploaded file: ${unexpected.join(', ')}`]
    : [];

  const errors = [
    ...missingErrors,
    ...unexpectedErrors,
  ];
  return errors.length > 0 ? errors : undefined;
};

/* Assign individual field validators to item fields */
const structuralResponseFieldValidations = [
  {
    name: 'method',
    validation: [required, canBeSp3, noIncludeDesignIntensitiesWithUSGS],
  },
  {
    name: 'upload',
    validation: [
      requiredUpload, noBackendUploadErrors, hasExpectedReturnPeriods, hasExpectedLocations,
    ],
  },
];

/* Validate the items in the field array that represent uploads */
export const validateStructuralResponsesFieldArray = (structuralResponses, values, state) => {
  const structuralResponseErrors = [];
  if (structuralResponses) {
    for (let i = 0; i < structuralResponses.length; i += 1) {
      const structuralResponse = structuralResponses[i];
      const itemErrors = validateItemFields(
        structuralResponseFieldValidations,
        structuralResponse,
        values,
        structuralResponses,
        i,
        state
      );
      if (Object.keys(itemErrors).length > 0) {
        structuralResponseErrors[i] = itemErrors;
      }
    }
  }
  return structuralResponseErrors.length > 0 ? structuralResponseErrors : undefined;
};
