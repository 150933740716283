// @flow
import type { Element } from 'react';
import get from 'lodash/fp/get.js';
import React from 'react';
import type { Component } from '#types/Component/index.js';
import type { Field } from '#components/pages/Components/support/tables/support/types/field';
import EntityModeAddon from '#components/pages/Components/support/tables/support/EnityModeAddon/index.js';
import {
  getSelectedOptionText,
} from '#components/pages/Components/support/form/Field/BaseField/support/options.jsx';

type Props = {
  component: Component,
  item: string,
  field: Field,
  excludePrefix?: boolean,
  excludeSuffix?: boolean,
}
const EntityModeTableCellContent = ({
  item, component, field, excludeSuffix = true, excludePrefix = true,
}: Props): Element<'span'> => {
  const { format, options, type } = field;
  const { formInputs: { values, visibleFields } } = component;
  const visible = get(`${item}.${field.name}`, visibleFields);
  const value = get(`${item}.${field.name}`, values);
  if (!visible) {
    return (
      <span>---</span>
    );
  }

  const prefixElement = excludePrefix === false && field.prefix
    ? (
      <EntityModeAddon field={field} addonType="prepend" component={component} />
    ) : null;

  const suffixElement = excludeSuffix === false && field.suffix
    ? (
      <EntityModeAddon field={field} addonType="append" component={component} />
    ) : null;

  const displayValue = type === 'select' && options
    ? getSelectedOptionText(options, value, component.formInputs)
    : value;
  const formattedDisplayValue = format === 'function' ? format(displayValue) : displayValue;
  return (
    <span>
      {prefixElement}
      {formattedDisplayValue}
      {suffixElement}
    </span>
  );
};

export default EntityModeTableCellContent;
