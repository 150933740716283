// @flow
import type { Element } from 'react';

import React from 'react';

type Props = {|
  innerHeight: number,
  innerWidth: number,
  title: string,
  xAxis?: boolean,
  leftOffset?: number,
|};

export const defaultLeftOffset = -40;

const CustomAxisLabel = ({
  innerHeight,
  title,
  innerWidth,
  xAxis = false,
  leftOffset = defaultLeftOffset,
}: Props): Element<'div'> => {
  const xStyle = {
    top: `${innerHeight}px`,
    left: `${(innerWidth / 2) - (title.length * 3)}px`,
  };

  const yStyle = {
    top: `${(innerHeight / 2) - (title.length * 2.5)}px`,
    left: leftOffset ? `${(title.length * -2.5) + leftOffset}px` : null,
    transform: 'rotate(-90deg)',
  };

  const style = {
    position: 'absolute',
    ...(xAxis ? xStyle : yStyle),
  };

  return (
    <div style={style}>
      {title}
    </div>
  );
};

export default CustomAxisLabel;
