/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import moment from 'moment';
import React from 'react';
import ReactTable from 'react-table';
import { useLocation } from 'react-router-dom';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import ActionCell from './ActionCell/index.js';
import ComponentIdCell from './ComponentIdCell/index.jsx';
import LocationCell from './LocationCell/index.jsx';
import QuantityCell from './QuantityCell/index.jsx';
import {
  modelComponentsTable,
  modelComponentsTableTd,
  modelComponentsTableTh,
  highlight,
  td,
  th,
  wrap,
} from './ModelComponentsTable.module.scss';

const columnsWithDefaults = (withoutDefaults: any) => withoutDefaults.map((column) => (
  {
    ...{
      className: classNames(td, modelComponentsTableTd),
      headerClassName: classNames('font-weight-bold', th, modelComponentsTableTh),
    },
    ...column,
  }
));

const myColumns = (form: string) => columnsWithDefaults([
  {
    id: 'componentId',
    accessor: 'componentId',
    Header: 'ID',
    className: classNames(td, modelComponentsTableTd, wrap),
    width: 125,
    Cell: ComponentIdCell,
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Component Name',
    width: 395,
    className: classNames(td, modelComponentsTableTd, wrap),
  },
  {
    id: 'group',
    accessor: 'group',
    Header: 'Type',
    width: 105,
    className: classNames(td, modelComponentsTableTd, wrap),
  },
  {
    id: 'locations',
    accessor: 'locations',
    Header: () => (
      <span>
        Locations
        {' '}
        <Tip tipId="rollupLocations" />
      </span>
    ),
    width: 125,
    className: classNames(td, modelComponentsTableTd, wrap),
    Cell: LocationCell,
  },
  {
    id: 'quantity',
    accessor: 'quantity',
    Header: () => (
      <span>
        Qty
        {' '}
        <Tip tipId="rollupTotalQuantity" />
      </span>
    ),
    width: 75,
    className: classNames(td, modelComponentsTableTd, wrap),
    Cell: QuantityCell,
  },
  {
    Header: '',
    id: 'action',
    sortable: false,
    width: 30,
    className: 'p-0',
    Cell: (data: Object) => {
      const index = get('original.index', data);
      return (<ActionCell index={index} form={form} />);
    },
  },
]);

type PopulationItem = {
  index: number,
  componentId: string,
  name: string,
  group: string,
  locations: string,
};

type Props = {|
data: Array<PopulationItem>,
  error: string,
  form: string,
|}

const ModelComponentsTable = (props: Props): Element<typeof ReactTable> | Element<'div'> => {
  const {
    error, data, form,
  } = props;

  const location = useLocation();
  const recentlyAdded = getOr({}, 'state.recentlyAdded', location);

  if (error) {
    return (<div className="text-danger">{error}</div>);
  }

  return (
    <ReactTable
      className={classNames(['model-components-table', modelComponentsTable, '-striped', 'rounded'])}
      columns={myColumns(form)}
      data={data}
      defaultPageSize={100000}
      showPagination={false}
      minRows={5}
      resizable={false}
      getTrProps={(state, rowInfo, column, instance) => {
        const refreshTimeOut = 5000;
        const componentUuid = get('original.componentUuid', rowInfo);
        const isNewlyAdded = (
          recentlyAdded.componentUuid
            && componentUuid === recentlyAdded.componentUuid
            && moment().diff(moment(recentlyAdded.dateTime)) <= refreshTimeOut
        );
        const withRecentlyAdded = isNewlyAdded ? [highlight] : [];

        // refresh the table after 5 seconds if it's newly added
        if (isNewlyAdded) {
          setTimeout(() => {
            instance.forceUpdate();
          }, refreshTimeOut);
        }

        return {
          className: classNames(withRecentlyAdded),
          id: `rowForComponent-${componentUuid}`,
        };
      }}
    />
  );
};
export default ModelComponentsTable;
