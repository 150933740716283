/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { disabled } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.module.scss';

type CellType = (data: CellData) => Element<'span'>;

const Cell: CellType = (data) => {
  const sel = get('row.sel', data);
  const archived = get('original.archived', data);
  const multiplied = 100 * sel;
  const res = sel ? `${multiplied.toFixed(0)}%` : '--';
  return (
    <span className={archived ? disabled : ''}>
      {res}
    </span>
  );
};

const Header = () => (
  <span>
    SEL
    {' '}
    <Tip tipId="meanLossHeader" />
  </span>
);

const column: ColumnType = {
  id: 'sel',
  accessor: 'currentRun.sel',
  Header,
  width: 60,
  Cell,
  additionalClasses: ['text-right'],
};

export default column;
