import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import EditComponentItem
  from '#components/pages/Models/Model/sections/type2/BuildingComponentsTableSection/EditView/EditComponentItem.jsx';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const mapStateToProps = (state, ownProps) => {
  const { routeParams } = ownProps;
  const { [COMPONENT_UUID]: componentUuid } = routeParams;
  const component = selectComponentByUuid(state, { [UUID]: componentUuid });
  return {
    currentComponent: component,
  };
};

export default pipe(
  connect(mapStateToProps),
  withRouteParams,
)(EditComponentItem);
