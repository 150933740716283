/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import omit from 'lodash/fp/omit.js';
import pipe from 'lodash/fp/pipe.js';
import { arrayUnshift } from 'redux-form';
import {
  COMPONENT_ID,
  DEFAULT_EDP,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { selectComponentsByUuid } from '#selectors/entities/components.js';
import withNavigate from '#components/support/routing/withNavigate/index.jsx';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import getLocationDefaults from '#components/pages/Models/Model/sections/type2/BuildingComponentsTableSection/support/getLocationDefaults/index.js';
import render from './render.jsx';

const mapStateToProps = (_, ownProps) => {
  const { form } = ownProps;
  const { makeFieldIsAutofilledSelector } = modelTypesByForm[form];
  const selectFieldIsAutofilled = makeFieldIsAutofilledSelector();
  return (state) => {
    const autofilled = selectFieldIsAutofilled(state, { name: 'componentPopulation' });
    const componentsById = selectComponentsByUuid(state);
    return ({
      componentsById,
      autofilled,
    });
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { form, navigate } = ownProps;

  return {
    makeHandleAdd: (componentId, componentUuid, edp) => () => {
      const componentPopulationItem = {
        componentUuid,
        performanceGroups: [{
          name: `${componentId} #1`,
          locations: [getLocationDefaults()],
          edp,
          serviceLocations: null,
        }],
      };

      const recentlyAdded = {
        componentUuid,
        dateTime: Date.now(),
      };

      dispatch(arrayUnshift(form, 'componentPopulation', componentPopulationItem));
      navigate('..', { state: { recentlyAdded } });
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { componentsById } = stateProps;
  const { makeHandleAdd } = dispatchProps;
  return ({
    ...ownProps,
    ...omit('componentsById', stateProps),
    handleAdd: (componentUuid) => {
      if (!componentUuid) {
        return null;
      }
      const component = componentsById[componentUuid];
      const edp = component[DEFAULT_EDP];
      const componentId = component[COMPONENT_ID];
      return makeHandleAdd(componentId, componentUuid, edp);
    },
  });
};
export default pipe(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withNavigate
)(render);
