// /**
//  * Owner: Haselton Baker Risk Group, LLC
// //  * Date: 2022-02-04
//  * Copyright All Rights Reserved
//  */
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import ComponentQuestion from './ComponentQuestion.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { selectVisibleFields } = modelTypesByForm[form];
  const visibleFields = selectVisibleFields(state);
  return {
    visibleFields,
  };
};

export default connect(mapStateToProps)(ComponentQuestion);
