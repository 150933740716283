// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form,
  FormGroup,
  Table,
  UncontrolledAlert as Alert,
} from 'reactstrap';
import camelCase from 'lodash/fp/camelCase.js';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import ComponentCostSelectMenu from './ComponentCostSelectMenu/index.jsx';

type ComponentRowProps = {|
  componentGroup: string,
  componentGroupDescription?: string | null,
  form: string,
|};

const ComponentMultiplierRow = ({
  componentGroup, componentGroupDescription = null, form,
}: ComponentRowProps) => (
  <tr>
    <td>{componentGroupDescription || componentGroup}</td>
    <td>
      <GridField
        name={`${camelCase(componentGroup)}CostMultiplier`}
        form={form}
        options={{
          ComponentType: ComponentCostSelectMenu,
          includeFormatting: false,
        }}
      />
    </td>
    <td>
      <GridField
        name={`${camelCase(componentGroup)}CapacityMultiplier`}
        form={form}
        options={{
          ComponentType: 'text',
          includeFormatting: false,
        }}
      />
    </td>
  </tr>
);

type ComponentMultipliersProps = {|
  form: string,
  hasWoodOrSteelLightFrame: boolean,
  hasOtherBuildingType: boolean,
|};

const ComponentMultipliers = ({
  form, hasWoodOrSteelLightFrame, hasOtherBuildingType,
}: ComponentMultipliersProps) => (
  <div>
    <FormGroup tag="fieldset">
      <legend>Component Group Modifiers</legend>
      <Table
        responsive
        className="component-info"
      >
        <thead>
          <tr>
            <th>Component Group</th>
            <th>
              Cost
              {' '}
              <Tip tipId="componentCostMultiplier" />
            </th>
            <th>
              Capacity
              {' '}
              <Tip tipId="componentCapacityMultiplier" />
            </th>
          </tr>
        </thead>
        <tbody>
          { hasWoodOrSteelLightFrame && (
          <>
            <ComponentMultiplierRow
              componentGroup="Interior Walls"
              componentGroupDescription="Interior Walls (Wood Light Frame)"
              form={form}
            />
            <ComponentMultiplierRow
              componentGroup="Exterior Walls"
              componentGroupDescription="Exterior Walls (Wood Light Frame)"
              form={form}
            />
          </>
          )}
          { hasOtherBuildingType && (
          <>
            <ComponentMultiplierRow componentGroup="Structural" form={form} />
            <ComponentMultiplierRow componentGroup="Partition Walls" form={form} />
            <ComponentMultiplierRow componentGroup="Exterior Finishes" form={form} />
          </>
          )}
          <ComponentMultiplierRow componentGroup="Ceilings" form={form} />
          <ComponentMultiplierRow componentGroup="Lighting" form={form} />
          <ComponentMultiplierRow componentGroup="HVAC" form={form} />
          <ComponentMultiplierRow componentGroup="Piping" form={form} />
          <ComponentMultiplierRow componentGroup="Elevators" form={form} />
        </tbody>
      </Table>
    </FormGroup>
  </div>
);

type BuildingComponentFormProps = {|
  error: string,
  form: string,
  handleSubmit: Function,
  hasWoodOrSteelLightFrame: boolean,
  hasOtherBuildingType: boolean,
  hasComponentModifiers: boolean,
  heading: string,
  onBack: Function,
  onNext: Function,
  submitting: boolean,
|};

const BuildingComponentsSection = (props: BuildingComponentFormProps): Element<'div'> => {
  const {
    error,
    form,
    handleSubmit,
    hasWoodOrSteelLightFrame,
    hasOtherBuildingType,
    hasComponentModifiers,
    heading,
    onBack,
    onNext,
    submitting,
  } = props;

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate onSubmit={handleSubmit}>
        {hasComponentModifiers && (
          <ComponentMultipliers
            hasWoodOrSteelLightFrame={hasWoodOrSteelLightFrame}
            hasOtherBuildingType={hasOtherBuildingType}
            form={form}
          />
        )}
        { error && !submitting ? <Alert color="danger">{error}</Alert> : ''}
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default BuildingComponentsSection;
