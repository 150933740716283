import get from 'lodash/fp/get.js';
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { IS_CUSTOM, UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import ComponentSummary
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummary.jsx';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const mapStateToProps = (state, ownProps) => {
  const { routeParams } = ownProps;
  const componentUuid = get(COMPONENT_UUID, routeParams);
  const component = selectComponentByUuid(
    state,
    { [UUID]: componentUuid, options: { includeFormInputs: true } }
  );
  return {
    component,
    componentUuid,
    showArchiveToggle: component && component[IS_CUSTOM],
  };
};

export default pipe(
  connect(mapStateToProps),
  withRouteParams,
)(ComponentSummary);
