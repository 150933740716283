import resolveFormInputs from '@hbrisk/sp3-risk-model-support/utility/form/resolveFormInputs/index.js';
import getFieldPermissionOverrides from '#src/js/support/utility/form/getFieldPermissionOverrides/index.js';
import bySlice from '#support/utility/form/formInputsTransforms/bySlice/index.js';
import { modelTypesById } from '#constants/models/modelTypes/index.js';
import byField from '#support/utility/form/formInputsTransforms/byField/index.js';

const calculateCopyModelPostPayload = (
  baseModel,
  createdById,
  orgId,
  permissions,
) => {
  const {
    type,
    formInputs,
    uploadedStructuralResponses,
    uploadedGroundMotionId,
    uploadedModeShapeId,
  } = baseModel;
  const { modelName: originalModelName } = formInputs;

  let newModelFormInputs = {
    ...formInputs,
    modelName: {
      ...originalModelName,
      value: `${originalModelName.value} Copy`,
    },
  };
  const { fields } = modelTypesById[type];
  newModelFormInputs = bySlice(newModelFormInputs);

  const overrides = getFieldPermissionOverrides(
    fields,
    permissions,
  );

  newModelFormInputs = resolveFormInputs(
    fields,
    {
      ...newModelFormInputs,
      visibleFields: {
        ...newModelFormInputs.visibleFields,
        ...overrides,
      },
    }
  );

  newModelFormInputs = byField(newModelFormInputs);

  return {
    createdById,
    orgId,
    type,
    formInputs: newModelFormInputs,
    uploadedStructuralResponses,
    uploadedGroundMotionId,
    uploadedModeShapeId,
  };
};

export default calculateCopyModelPostPayload;
