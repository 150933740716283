/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import BuildingLayoutSection from './BuildingLayoutSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { selectFormFieldVisibility, selectFormValues } = modelTypesByForm[ownProps.form];
  return {
    showRepairCostsScaleWithBuildingValue: selectFormFieldVisibility(state)('repairCostsScaleWithBuildingValue'),
    showFirstStoryFullWallRatioDir1: selectFormFieldVisibility(state)('firstStoryFullWallRatioDir1'),
    showFirstStoryFullWallRatioDir2: selectFormFieldVisibility(state)('firstStoryFullWallRatioDir2'),
    showFirstStoryInfilledWallRatioDir1: selectFormFieldVisibility(state)('firstStoryInfilledWallRatioDir1'),
    showFirstStoryInfilledWallRatioDir2: selectFormFieldVisibility(state)('firstStoryInfilledWallRatioDir2'),
    showUpperStoryFullWallRatioDir1: selectFormFieldVisibility(state)('upperStoryFullWallRatioDir1'),
    showUpperStoryFullWallRatioDir2: selectFormFieldVisibility(state)('upperStoryFullWallRatioDir2'),
    showUpperStoryInfilledWallRatioDir1: selectFormFieldVisibility(state)('upperStoryInfilledWallRatioDir1'),
    showUpperStoryInfilledWallRatioDir2: selectFormFieldVisibility(state)('upperStoryInfilledWallRatioDir2'),
    showUpperStoryHeight: selectFormFieldVisibility(state)('upperStoryHeight'),
    showYlbfBayLengthDir1: selectFormFieldVisibility(state)('ylbfBayLengthDir1'),
    showYlbfBayLengthDir2: selectFormFieldVisibility(state)('ylbfBayLengthDir2'),
    showYlbfBraceConfigDir1: selectFormFieldVisibility(state)('ylbfBraceConfigDir1'),
    showYlbfBraceConfigDir2: selectFormFieldVisibility(state)('ylbfBraceConfigDir2'),
    showYlbfBraceExposureDir1: selectFormFieldVisibility(state)('ylbfBraceExposureDir1'),
    showYlbfBraceExposureDir2: selectFormFieldVisibility(state)('ylbfBraceExposureDir2'),
    showYlbfNumFusesPerBraceDir1: selectFormFieldVisibility(state)('ylbfNumFusesPerBraceDir1'),
    showYlbfNumFusesPerBraceDir2: selectFormFieldVisibility(state)('ylbfNumFusesPerBraceDir2'),
    showTdmfDamperConfigDir1: selectFormFieldVisibility(state)('tdmfDamperConfigDir1'),
    showTdmfBayLengthDir1: selectFormFieldVisibility(state)('tdmfBayLengthDir1'),
    showTdmfDamperExposureDir1: selectFormFieldVisibility(state)('tdmfDamperExposureDir1'),
    showTdmfDamperConfigDir2: selectFormFieldVisibility(state)('tdmfDamperConfigDir2'),
    showTdmfBayLengthDir2: selectFormFieldVisibility(state)('tdmfBayLengthDir2'),
    showTdmfDamperExposureDir2: selectFormFieldVisibility(state)('tdmfDamperExposureDir2'),
    isTwoStories: selectFormValues(state, 'numberOfStories') === '2',
    defineFloorPlanByStory: !selectFormFieldVisibility(state)('buildingSquareFootage'),
  };
};

export default connect(mapStateToProps)(BuildingLayoutSection);
