// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { text } from './index.module.scss';

type Props = {
  children: Node,
  className?: string | null,
};

const SnippetItemText = ({ children, className = null }: Props): Element<'span'> => (
  <span
    className={classNames(className, text, 'snippet-item-text')}
  >
    {children}
  </span>
);

export default SnippetItemText;
