/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { EdpList } from '#constants/edps/index.js';

import {
  buildingTypes as _buildingTypes,
  buildingTypesById as _buildingTypesById,
  buildingTypesByMaterial as _buildingTypesByMaterial,
  materialTypes as _materialTypes,
} from '#constants/buildingTypes/buildingTypes.js';

type MaterialType = 'wood' | 'steel' | 'reinforcedConcrete' | 'reinforcedMasonry';
type MaterialTypes = { [MaterialType]: string };

export type CodeYear =
  2022 | 2016 | 2010 | 2005 | 2002 | 1997 | 1994
  | 1991 | 1988 | 1985 | 1982 | 1979 | 1976
  | 1973 | 1967 | 1964 | 1961 | 1958 | 1955
  | 1952 | 1949 | 1946 | 1943 | 1940 | 1937
  | 1935 | 1927 | 1800;

export type FemaType =
  'C1' |
  'C2' |
  'C3' |
  'PC1' |
  'PC2' |
  'RM1' |
  'RM2' |
  'S1' |
  'S2' |
  'S3' |
  'S4' |
  'S5' |
  'W1' |
  'W2';

export type BuildingType = {
  materialType: MaterialType,
  buildingTypeId: string,
  buildingType: string,
  femaType: FemaType,
  minDesignCodeYear: CodeYear,
  calibratedStories: number,
  maxStories: number,
  isPodium: boolean,
  isInfilled: boolean,
  isWoodLightFrame: boolean,
  isSteelLightFrame: boolean,
  isRWFD: boolean,
  isSimpsonYLBF: boolean,
  isTDMF: boolean,
  isJapan: boolean,
  hasConcreteShearWalls: boolean,
  hasMasonryShearWalls: boolean,
  edps: EdpList,
};

type BuildingTypesById = {
  [buildingTypeId: string]: BuildingType,
};

export type BuildingTypesByMaterial = {
  [materialType: string]: Array<BuildingType>
};

export const materialTypes: MaterialTypes = _materialTypes;

export const buildingTypes: Array<BuildingType> = _buildingTypes;

export const buildingTypesById: BuildingTypesById = _buildingTypesById;

export const buildingTypesByMaterial: BuildingTypesByMaterial = _buildingTypesByMaterial;
