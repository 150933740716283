/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { getDownloads, DOWNLOAD_TYPE_VALUES } from '@hbrisk/sp3-risk-model-support/downloads/downloads.js';
import DownloadsList from '#components/pages/Models/Model/support/DownloadsList/index.jsx';

type Props = {|
  modelId: string,
  showDownloads: boolean
|};

const DebugSection = (props: Props): Element<'div'> => {
  const {
    modelId,
    showDownloads,
  } = props;

  const downloadTypes = [DOWNLOAD_TYPE_VALUES.INPUTS, DOWNLOAD_TYPE_VALUES.OUTPUTS];
  const downloads = getDownloads(modelId, downloadTypes);

  return (
    <div id="debugInfo">
      <h2>Debug Information</h2>
      <div className="pt-2">
        <h5>Downloads</h5>
        {showDownloads
          ? (
            <div>
              <DownloadsList downloads={downloads} />
            </div>
          ) : (
            <div>
              No Downloads Available
            </div>
          )}
      </div>
    </div>
  );
};

export default DebugSection;
