// @flow
import type { Element } from 'react';
import React from 'react';
import { SidebarLayout } from '#src/js/components/support/layout/SidebarLayout/index.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';
import DashboardMainContent from '#components/pages/Components/Dashboard/Main/index.jsx';
import DashboardSidebar from '#components/pages/Components/Dashboard/Sidebar/index.jsx';
import DashboardSubNav from '#components/pages/Components/Dashboard/SubNav/index.jsx';
import Page from '#components/support/layout/Page/index.js';
import Header from '#components/support/layout/Header/index.js';

type Props = {
  className?: string,
  includeHeader?: boolean,
};
const ComponentsDashboard = ({
  includeHeader = true,
  className = 'components-dashboard-page',
}: Props): Element<typeof Page> => (
  <Page className={className}>
    {
      includeHeader && <Header />
    }
    <MainPageContainer withRoomForHeader={includeHeader !== false}>
      <SidebarLayout>
        <DashboardSubNav top={includeHeader === false ? '0' : '61px'} />
        <DashboardSidebar top={includeHeader === false ? '56px' : '116px'} />
        <DashboardMainContent top={includeHeader === false ? '35px' : '20px'} />
      </SidebarLayout>
    </MainPageContainer>
  </Page>
);

export default ComponentsDashboard;
