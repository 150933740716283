/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import React from 'react';

const mapItemsToOptions = (items) => items.map((item) => {
  const { id, name, value } = item;
  return (
    <option key={id} value={value}>
      {name}
    </option>
  );
});

export default mapItemsToOptions;
