/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 4/6/17
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';

type Props = {|
  handleClick: Function
|};

const SignOutLink = (props: Props): Element<typeof Button> => {
  const { handleClick } = props;
  return (
    <Button
      color="link"
      className="sign-out-link"
      onClick={handleClick}
    >
      Sign Out
    </Button>
  );
};

export default SignOutLink;
