/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { componentQuestions } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';
import { COMPONENT_GROUP_MODIFIERS_SECTION } from '#constants/models/modelPage/sectionIds.js';
import { componentQuestionVisibilityReducer }
  from '#constants/models/form/model/support/fieldLevelReducers/support/componentQuestions/index.js';

const getComponentQuestionFields = () => componentQuestions.map((q) => ({
  name: q.name,
  defaultVisible: q.isRoot,
  section: COMPONENT_GROUP_MODIFIERS_SECTION,
  reducers: {
    visible: componentQuestionVisibilityReducer(q.name),
  },
}));

export default getComponentQuestionFields;
