import getOr from 'lodash/fp/getOr.js';
import { mapObjIndexed } from 'ramda';

const calculateModelPutPayload = ({ autofilled, visibleFields, values }) => {
  const formInputs = mapObjIndexed((isVisible, key) => {
    const isAutofilled = getOr(false, key, autofilled);
    const value = isAutofilled ? null : getOr(null, key, values);

    return ({
      value,
      visible: isVisible,
      autofilled: isAutofilled,
    });
  }, visibleFields);

  return { formInputs };
};

export default calculateModelPutPayload;
