/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Card, CardBody, CardText, Col, Row,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import RepairTimeTable from './RepairTimeTable/index.js';
import RepairTimePlot from './RepairTimePlot/index.js';

type Props = {|
  heading: string,
  modelId: string,
  IsATC138: string,
  showContent: boolean,
  onBack: Function,
  onNext: Function,
|};

const RepairTimeSection = ({
  heading, modelId, IsATC138, showContent, onBack, onNext,
}: Props): Element<'div'> => {
  const content = (
    <>
      <div className="pt-2 pb-2">
        {
          IsATC138
            ? (
              <h5>
                ATC-138 Recovery Time (Beta) (with selected impeding factors)
                {' '}
                <Tip tipId="atc138Output" />
              </h5>
            ) : (
              <h5>
                REDi Repair Time (2013) (without impeding factors)
                {' '}
                <Tip tipId="rediOutput" />
              </h5>
            )
        }
        <RepairTimePlot modelId={modelId} />
      </div>
      <div className="pt-2 pb-2">
        <RepairTimeTable modelId={modelId} />
      </div>
    </>
  );
  const empty = (
    <Row className="pt-3 pb-3">
      <Col>
        <Card>
          <CardBody>
            <CardText>
              Run your model to view recovery time results.
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
  return (
    <div>
      <h2>
        {heading}
        {' '}
        <Tip tipId="buildingRepairTime" />
      </h2>
      {
        showContent
          ? content
          : empty
      }
      <BackButton onClick={onBack} />
      <NextButton onClick={onNext} />
    </div>
  );
};

export default RepairTimeSection;
