/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import SelectMenu from '#components/pages/Models/Model/sections/support/forms/SelectMenu/SelectMenu.jsx';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';

const mapStateToProps = (_, ownProps) => {
  const {
    name, externalAutofillSource, form, disabled,
  } = ownProps;
  const { makeFieldIsAutofilledSelector } = modelTypesByForm[form];
  const selectFieldIsAutofilled = makeFieldIsAutofilledSelector();
  return (state) => {
    const autofilled = selectFieldIsAutofilled(state, { name: externalAutofillSource || name });
    return ({
      disabled: autofilled || disabled,
    });
  };
};
export default connect(mapStateToProps)(SelectMenu);
