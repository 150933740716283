/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React, { Fragment } from 'react';
import trim from 'lodash/fp/trim.js';
import isEmpty from 'lodash/fp/isEmpty.js';

import { Input } from 'reactstrap';
import { Field } from 'redux-form';
import DatePicker from 'react-datepicker';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';

type RenderDatePicker = {
  id: string,
  disabled: boolean,
  meta: {
    touched: boolean,
    error: string,
  },
  inputClassName: string,
  input: {
    onChange: Function,
    value: string,
  },
};

type Props = {|
  form: string,
  name: string,
  validate?: Function[],
|};

const renderDatePicker = (props: RenderDatePicker): any => {
  const {
    disabled,
    input,
    id,
    meta,
    inputClassName,
  } = props;
  const { value, onChange, ...rest } = input;
  const { touched, error } = meta;
  const hasError = touched && !isEmpty(error);
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <DatePicker
      {...rest}
      id={id}
      selected={!value ? null : new Date(value)}
      dateFormat="MM/dd/yy"
      onChange={onChange}
      disabled={disabled}
      invalid={hasError}
      className={inputClassName}
      customInput={
        <Input />
      }
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

const DatePickerField = ({ validate = [], name, form }: Props): Element<typeof Fragment> => (
  <>
    <Field
      id={name}
      name={name}
      component={renderDatePicker}
      validate={validate}
      normalize={trim}
    />
    <FieldFeedback name={name} form={form} />
  </>
);

export default DatePickerField;
