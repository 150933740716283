/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow

import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const items = [
  { id: 'yield-link-option-1', value: '1', name: '1' },
  { id: 'yield-link-option-2', value: '2', name: '2' },
];

const YieldLinksPerBraceSelectMenu = (
  { form, name }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'yield-links-per-brace-select')}
    includeEmptyOption
  />
);

export default YieldLinksPerBraceSelectMenu;
