/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import PeriodRow from './PeriodRow.jsx';

const mapStateToProps = (_, ownProps) => {
  const { period, form } = ownProps;
  const { makeFormFieldVisibilitySelector } = modelTypesByForm[form];
  const selectFormFieldVisibility = makeFormFieldVisibilitySelector();

  return (state) => ({
    showDir1: selectFormFieldVisibility(state)(`period${period}Dir1`),
    showDir2: selectFormFieldVisibility(state)(`period${period}Dir2`),
  });
};

export default connect(mapStateToProps)(PeriodRow);
