/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

export const ADDITIONAL_NOTES_SECTION = 'additionalNotes';
export const ANALYSIS_OPTIONS_SECTION = 'analysisOptions';
export const BUILDING_MODIFIERS_CHECKLIST_SECTION = 'buildingModifiersChecklist';
export const BUILDING_COMPONENTS_TABLE_SECTION = 'buildingComponentsTable';
export const BUILDING_DESIGN_SECTION = 'buildingDesign';
export const BUILDING_LAYOUT_SECTION = 'buildingLayout';
export const BUILDING_STABILITY_SECTION = 'buildingStability';
export const COMPONENT_CHECKLIST_SECTION = 'componentQuestions';
export const COMPONENT_GROUP_MODIFIERS_SECTION = 'componentModifiers';
export const GROUND_MOTION_SECTION = 'groundMotion';
export const MODEL_AND_SITE_INFO_SECTION = 'modelAndSiteInfo';
export const PRIMARY_BUILDING_INFO_SECTION = 'primaryBuilding';
export const REPAIR_COST_BREAKDOWN_SECTION = 'repairCostBreakdown';
export const REPAIR_COST_BY_INTENSITY_SECTION = 'repairCostByIntensity';
export const REPAIR_COST_EXPOSURE = 'repairCostExposure';
export const REPAIR_TIME_OPTIONS_SECTION = 'repairTimeOptions';
export const REPAIR_TIME_SECTION = 'repairTime';
export const DOWNLOADS_SECTION = 'downloads';
export const SITE_COORDINATES_SECTION = 'siteCoordinates';
export const STRUCTURAL_PROPERTIES_SECTION = 'structuralProperties';
export const STRUCTURAL_RESPONSES_SECTION = 'structuralResponses';
