/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { SELECT_AT_LEAST_ONE_MODEL_TYPE } from '@hbrisk/sp3-risk-model-support/models/errorMessages.js';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { putOrganization } from '#actions/organizations/organizations.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import calculateOrgRequestBody from '#support/organizations/calculateOrgRequestBody.js';
import { EDIT_ORGANIZATION } from '#constants/organizations/form/editOrganization/name.js';
import EditOrganizationForm from './EditOrganizationForm.jsx';

const makeOnSubmit = (orgId) => (values, dispatch) => {
  if (!values.standard && !values.advanced) {
    return Promise.reject(new SubmissionError({ _error: SELECT_AT_LEAST_ONE_MODEL_TYPE }));
  }
  return dispatch(putOrganization(orgId, calculateOrgRequestBody(values)))
    .catch(() => Promise.reject(new SubmissionError({ _error: PROBLEM_WITH_REQUEST })));
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onSubmit: makeOnSubmit(ownProps.organization.uuid),
});

export default connect(undefined, undefined, mergeProps)(reduxForm({
  form: EDIT_ORGANIZATION,
})(EditOrganizationForm));
