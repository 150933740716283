/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { MODELS_TABLE_SORT_CHANGE, SIGN_OUT_USER } from '#constants/actionTypes.js';

const initialState = [
  { id: 'updatedAt', desc: true },
];

const sorted = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case MODELS_TABLE_SORT_CHANGE:
      return payload;
    case SIGN_OUT_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default sorted;
