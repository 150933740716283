/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Header from '#components/support/layout/Header/index.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';
import {
  MainContentArea,
  SidebarLayout,
} from '#components/support/layout/SidebarLayout/index.js';
import Page from '#components/support/layout/Page/index.js';

import SubNav from './SubNav/index.js';
import Router from './support/Router/index.js';
import Sidebar from './Sidebar/index.js';

type Props = {|
  initialize: Function,
  modelHasRun: boolean,
  modelId: string,
  modelIncludesRepairTime: boolean,
  modelType: number,
  ready: boolean,
  includeHeader?: boolean,
|};

const ModelPage = ({
  initialize,
  modelHasRun,
  modelId,
  modelIncludesRepairTime,
  modelType,
  ready,
  includeHeader = true,
}: Props): Element<typeof Page> => {
  useEffect(
    () => initialize(
      modelId,
      modelHasRun,
      modelIncludesRepairTime,
      modelType,
    ),
    [
      modelId,
      modelHasRun,
      modelIncludesRepairTime,
      modelType,
    ]
  );

  return (
    <Page className="model-page">
      { includeHeader && <Header /> }
      <MainPageContainer withRoomForHeader={includeHeader !== false}>
        <SubNav
          modelId={modelId}
          top={includeHeader === false ? '0' : '61px'}
        />
        <SidebarLayout>
          {ready && (
            <Sidebar
              modelId={modelId}
              modelType={modelType}
              top={includeHeader === false ? '61px' : '111px'}
            />
          )}
          {ready && (
            <MainContentArea md="10" top={includeHeader === false ? '61px' : '35px'}>
              <Row>
                <Col md="12">
                  <Router modelId={modelId} modelType={modelType} />
                </Col>
              </Row>
            </MainContentArea>
          )}
        </SidebarLayout>
      </MainPageContainer>
    </Page>
  );
};

export default ModelPage;
