import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import ValidateButton from '#components/pages/Components/Component/Sidebar/ValidateButton/ValidateButton.jsx';
import withCustomHandleSubmit
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withCustomHandleSubmit/index.js';
import { selectIsSyncValid } from '#selectors/ui/form/component.js';
import { selectComponentIsReadOnlyByUuid } from '#selectors/entities/components.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const mapStateToProps = (state, ownProps) => {
  const { [COMPONENT_UUID]: uuid } = ownProps;
  return ({
    isValid: selectIsSyncValid(state),
    show: !selectComponentIsReadOnlyByUuid(state, { [UUID]: uuid }),
  });
};

export default pipe(
  withCustomHandleSubmit,
  connect(mapStateToProps)
)(ValidateButton);
