/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  FETCH_ACTION,
  FETCH_UPLOAD_URL_REQUEST,
  FETCH_UPLOAD_URL_RESPONSE,
} from '#constants/actionTypes.js';

export const createModeShapeAndGetUploadUrl = (modelId, body) => ({
  [FETCH_ACTION]: {
    types: [FETCH_UPLOAD_URL_REQUEST, FETCH_UPLOAD_URL_RESPONSE],
    endpoint: `/model/${modelId}/mode-shape`,
    meta: {
      modelId,
    },
    options: {
      body,
      method: 'POST',
    },
  },
});
