/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CREATE, USER_ID } from '#constants/pathStrings.js';
import CreateUser from './CreateUser/index.jsx';
import EditUser from './EditUser/index.js';
import ViewUsers from './ViewUsers/index.jsx';

const UsersSection = (): Element<typeof Routes> => (
  <Routes>
    <Route index element={<ViewUsers />} />
    <Route path={`${CREATE}`} element={<CreateUser />} />
    <Route path={`:${USER_ID}`} element={<EditUser />} />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);

export default UsersSection;
