/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { combineReducers } from 'redux';
import authenticated from './authenticated.js';
import byId from './byId.js';

export default combineReducers({
  authenticated,
  byId,
});
