/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectStructuralResponseReturnPeriodsByEdpKey,
  selectStructuralResponseDataForDirectionWithLocationLabelsByReturnPeriod,
  maxMedianCeilOfStructuralResponseDirection,
  maxLocationOfStructuralResponseDirection,
} from '#selectors/entities/models.js';
import StructuralResponsePlot from './StructuralResponsePlot.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId, edpKey, direction } = ownProps;
  return {
    returnPeriods: selectStructuralResponseReturnPeriodsByEdpKey(state, { modelId, edpKey }),
    responseDataByReturnPeriod:
      selectStructuralResponseDataForDirectionWithLocationLabelsByReturnPeriod(
        state,
        { modelId, edpKey, direction }
      ),
    maxMedian: maxMedianCeilOfStructuralResponseDirection(state, { modelId, edpKey, direction }),
    maxLocation: maxLocationOfStructuralResponseDirection(state, { modelId, edpKey, direction }),
  };
};

export default connect(mapStateToProps)(StructuralResponsePlot);
