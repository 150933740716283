/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const getOptions = () => {
  const options = [
    { value: 'low', description: 'Low' },
    { value: 'moderate', description: 'Moderate' },
    { value: 'mod_high', description: 'Moderately High' },
    { value: 'high', description: 'High' },
    { value: 'very_high', description: 'Very High' },
  ];

  return options.map((s) => ({
    id: s.value,
    name: s.description,
    value: s.value,
  }));
};

type Props = {
  form: string,
  name: string,
};

const CountrySelectMenu = ({ form, name }: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={getOptions()}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'seismicity-select')}
    includeEmptyOption
  />
);

export default CountrySelectMenu;
