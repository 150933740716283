/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import pipe from 'lodash/fp/pipe.js';

import { connect } from 'react-redux';
import { setCurrentModel } from '#actions/models/model.js';
import { initializeModelPage, resetModelPage } from '#actions/models/modelPage.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import withModelPolling from '#components/support/polling/withModelPolling/index.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { OUTPUTS } from '#constants/models/modelPage/sectionGroupIds.js';
import {
  REPAIR_TIME_OPTIONS_SECTION,
  REPAIR_TIME_SECTION,
} from '#constants/models/modelPage/sectionIds.js';
import {
  selectModelType,
  selectModelHasRun,
  selectModelRunCreatedAt,
} from '#selectors/entities/models.js';
import calculateModelPageInitializationPayload from '#support/models/calculateModelPageInitializationPayload.js';
import ModelPage from './ModelPage.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId } = ownProps.routeParams;
  const modelTypeId = selectModelType(state, { modelId });
  const modelType = modelTypesById[modelTypeId];
  const modelFormName = get('modelFormName', modelType);
  const selectInitialized = get('selectModelPageInitialized', modelType);
  const selectFormValues = get('selectFormValues', modelType);
  const modelIncludesRepairTime = selectFormValues
    ? selectFormValues(state, 'includeRepairTime')
    : undefined;

  const modelHasRun = selectModelHasRun(state, { modelId });
  return ({
    modelId,
    modelType: modelTypeId,
    modelFormName,
    modelHasRun,
    modelIncludesRepairTime,
    ready: selectInitialized ? selectInitialized(state) : false,
    runCreatedAt: selectModelRunCreatedAt(state, { modelId }),
  });
};

const mapDispatchToProps = (dispatch) => ({
  initialize: (
    modelId,
    modelHasRun,
    modelIncludesRepairTime,
    modelTypeId,
  ) => {
    if (modelTypeId) {
      dispatch(setCurrentModel(modelId));
      dispatch(resetModelPage());

      const repairTimeOptionsSection = modelIncludesRepairTime
        ? { [REPAIR_TIME_OPTIONS_SECTION]: modelIncludesRepairTime }
        : {};

      const payload = calculateModelPageInitializationPayload({
        nonDefaultSectionGroupIds: { [OUTPUTS]: modelHasRun },
        nonDefaultSectionIds: {
          [REPAIR_TIME_SECTION]: modelIncludesRepairTime,
          ...repairTimeOptionsSection,
        },
      }, modelTypeId);

      dispatch(initializeModelPage(payload, modelTypeId));
    }
    return () => {
      dispatch(setCurrentModel(null));
      dispatch(resetModelPage());
    };
  },
});

export default pipe(
  withModelPolling,
  connect(mapStateToProps, mapDispatchToProps),
  withRouteParams,
)(ModelPage);
