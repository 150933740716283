import get from 'lodash/fp/get.js';
import React from 'react';
import {
  Button,
  Table,
} from 'reactstrap';
import {
  DAMAGE_STATE_CONSEQUENCES,
  CONSEQUENCE_PROBABILITY,
  CONSEQUENCE_DESCRIPTION,
  CONSEQUENCE_REPAIR_MEASURES,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { DAMAGE_STATE_TYPE_OPTIONS } from '@hbrisk/sp3-risk-model-support/components/form/fields/options/index.js';
import { fieldArraysByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import {
  Fieldset,
  TableField,
  Label,
} from '#components/pages/Components/support/form/index.js';
import AddConsequenceButton
  from '#components/pages/Components/Component/sections/Consequences/Main/renderMainConsequencesTable/AddConsequenceButton/index.js';
import getUnqualifiedFieldName
  from '#support/components/form/getUnqualifiedFieldName.js';
import consequenceNameFromIndexes from '#support/components/consequenceNameFromIndexes.js';
import damageStateNameFromIndex from '#support/components/damageStateNameFromIndex.js';
import { consequencesTd } from '#components/pages/Components/Component/sections/Consequences/Main/renderMainConsequencesTable/index.module.scss';

const fieldsToShow = [
  CONSEQUENCE_PROBABILITY,
  CONSEQUENCE_DESCRIPTION,
  CONSEQUENCE_REPAIR_MEASURES,
];

const INDEX_PATTERN = /(\d+)/g;

const damageStateTypeTextFromValue = (value) => get(
  'text',
  DAMAGE_STATE_TYPE_OPTIONS.find((option) => option.value === value)
);

const renderTable = ({
  fields, readOnly, item, meta,
}) => {
  const damageStateIndex = parseInt(item.match(INDEX_PATTERN)[0], 10);
  const { error } = meta;
  const { itemSchema } = fieldArraysByName[DAMAGE_STATE_CONSEQUENCES];
  const filteredSchema = itemSchema
    .filter((fieldSchema) => fieldsToShow.includes(fieldSchema.name));

  const rows = fields.map((field, consequenceIndex) => {
    const hasSiblings = fields.length > 1;
    return (
      <tr
        key={field}
        className={`damage-state-${damageStateIndex}-consequence-row`}
      >
        <td className={consequencesTd}>
          {consequenceNameFromIndexes(damageStateIndex, consequenceIndex, hasSiblings)}
        </td>
        {
          filteredSchema.map((fieldSchema) => (
            <td className={consequencesTd} key={getUnqualifiedFieldName(field, fieldSchema.name)}>
              <TableField
                item={field}
                field={fieldSchema}
                readOnly={readOnly}
              />
            </td>
          ))
        }
        {
          !readOnly
          && (
            <td className={consequencesTd}>
              <Button
                color="link"
                id={`damageState${damageStateIndex}Consequence${consequenceIndex}DeleteButton`}
                onClick={() => fields.remove(consequenceIndex)}
              >
                Delete
              </Button>
            </td>
          )
        }
      </tr>
    );
  });
  return (
    <>
      <Table
        responsive
        className="consequences-table"
        id={`damageState${damageStateIndex}ConsequencesTable`}
      >
        <thead>
          <tr>
            <th>Consequence</th>
            {
              filteredSchema.map((fieldSchema) => (
                <th key={fieldSchema.name}>
                  <Label field={fieldSchema} tipTargetId={`damageState${damageStateIndex}${fieldSchema.name}TipTarget`} component="span" />
                </th>
              ))
            }
            {
              !readOnly
              && (<th>Action</th>)
            }
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      {
        error
        && (
          <div>
            <span className="text-danger">{error}</span>
          </div>
        )
      }
      {
        !readOnly
        && (
          <AddConsequenceButton fields={fields} damageStateIndex={damageStateIndex} />
        )
      }
    </>
  );
};

const renderMainConsequencesTables = ({ fields, readOnly }) => fields.map((item, index) => {
  const { type } = fields.get(index);
  const dsTypeText = damageStateTypeTextFromValue(type);
  return (
    <Fieldset id={`damageState${index}ConsequencesFieldset`} key={item}>
      <legend>
        {damageStateNameFromIndex(index)}
        {' '}
        (
        {dsTypeText}
        )
      </legend>
      <TableField
        item={item}
        field={fieldArraysByName[DAMAGE_STATE_CONSEQUENCES]}
        render={renderTable}
        readOnly={readOnly}
      />
    </Fieldset>
  );
});

export default renderMainConsequencesTables;
