/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import isEqual from 'lodash/fp/isEqual.js';
import {
  CREATE_USER_RESPONSE,
  FETCH_USER_RESPONSE,
  FETCH_USERS_RESPONSE,
  UPDATE_USER_RESPONSE,
} from '#constants/actionTypes.js';

const initialState = {};

const addOrUpdateUser = (state, action) => {
  const { payload, payload: { uuid } } = action;
  return ({ ...state, [uuid]: payload });
};

const byId = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USERS_RESPONSE: {
      if (action.error) {
        return state;
      }
      return ({ ...state, ...action.payload.entities.users });
    }
    case CREATE_USER_RESPONSE:
    case FETCH_USER_RESPONSE:
    case UPDATE_USER_RESPONSE: {
      if (action.error) return state;
      const { payload, payload: { uuid } } = action;
      return isEqual(state[uuid], payload) ? state : addOrUpdateUser(state, action);
    }
    default:
      return state;
  }
};

export default byId;
