/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import RepairCostAnnualPlot from './RepairCostAnnualPlot/index.js';

type Props = {|
  modelId: string,
|};

const RepairCostAverageAnnual = ({
  modelId,
}: Props): Element<'div'> => (
  <div id="averageAnnualLossBreakdown">
    <h5>
      Average Annual Repair Cost Breakdown
      {' '}
      <Tip tipId="averageAnnualLossBreakdown" />
    </h5>
    <div className="pt-2 pb-2">
      <RepairCostAnnualPlot modelId={modelId} />
    </div>
  </div>
);

export default RepairCostAverageAnnual;
