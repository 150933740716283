/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { COMPONENT_SELECTION_METHOD_CUSTOM } from '#constants/models/componentSelectionMethods.js';
import Main from './Main.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form, modelType } = ownProps;
  const { selectIsAutofilledFormField, selectFormValues } = modelTypesById[modelType];
  return {
    form,
    canAddComponent: !selectIsAutofilledFormField(state, { name: 'componentPopulation' }),
    showCustomWarning: selectFormValues(state, 'componentPopulationMethod') === COMPONENT_SELECTION_METHOD_CUSTOM,
  };
};

export default connect(mapStateToProps)(Main);
