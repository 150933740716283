/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import { repairTimeMethods } from '@hbrisk/sp3-risk-model-support/models/repairTimeMethods.js';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const RepairTimeMethodSelectMenu = ({ form, name }: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={repairTimeMethods}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'repair-time-method-select')}
    includeEmptyOption={false}
  />
);

export default RepairTimeMethodSelectMenu;
