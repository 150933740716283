/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import pipe from 'lodash/fp/pipe.js';
import classNames from 'classnames';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import usStates from './usStates.js';
import canadaTerritories from './canadaTerritories.js';

const isCountryUS = (country) => country === 'US';
const isCountryCA = (country) => country === 'CA';

const getOptions = (states) => states.map((state) => ({
  id: state.abbreviation,
  name: state.name,
  value: state.abbreviation,
}));

const getStates = (country) => {
  if (country === 'US') {
    return usStates;
  }
  if (country === 'CA') {
    return canadaTerritories;
  }
  throw new Error('getStates should not be called with outside of US or CA');
};

type Props = {
  form: string,
  country: string,
};

const StatePicker = (
  { country, form }: Props
): Element<typeof SelectMenu | typeof Field> => {
  const isUS = isCountryUS(country);
  const isCA = isCountryCA(country);
  const isUSorCA = isUS || isCA;
  if (!isUSorCA) {
    return (
      <Field
        form={form}
        id="state"
        name="state"
        type="text"
        component={RenderInput}
        inputClassName={classNames('custom-text', 'state-text')}
      />
    );
  }

  const stateOptions = pipe(
    getStates,
    getOptions,
  )(country);

  return (
    <SelectMenu
      form={form}
      items={stateOptions}
      id="state"
      name="state"
      inputClassName={
        classNames(
          'custom-select',
          'state-select',
          isUS ? 'state-us-select' : '',
          isCA ? 'state-ca-select' : '',
        )
      }
      includeEmptyOption
    />
  );
};

export default StatePicker;
