// @flow
import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import { FormText } from 'reactstrap';
import { buildingTypesById } from '#constants/buildingTypes/index.js';

type Props = {|
  buildingTypeDir1: string,
  buildingTypeDir2: string,
  numberOfStories: number,
|};

const getMessageForDirection = (direction: string, numberOfStories: number) => {
  const buildingType = get(`${direction}.buildingType`, buildingTypesById);
  const maxStories = get(`${direction}.maxStories`, buildingTypesById);
  const calibratedStories = get(`${direction}.calibratedStories`, buildingTypesById);
  const shouldShowWarning = (
    numberOfStories > calibratedStories && numberOfStories <= maxStories
  );

  return shouldShowWarning ? `${calibratedStories} stories for ${buildingType}` : null;
};

const NumberOfStoriesWarning = (props: Props): Element<typeof FormText> | null => {
  const { buildingTypeDir1, buildingTypeDir2, numberOfStories } = props;

  const isSameBuildingType = buildingTypeDir1 === buildingTypeDir2;
  const message1 = getMessageForDirection(buildingTypeDir1, numberOfStories);
  const message2 = getMessageForDirection(buildingTypeDir2, numberOfStories);

  return (
    message1 || message2 ? (
      <FormText id="number_of_stories_warning" color="muted">
        * SP3 automations are only calibrated up to
        {' '}
        {message1}
        {!isSameBuildingType && message2 && ' and '}
        {!isSameBuildingType && message2}
        .
        Going beyond that number of stories, we recommend that you
        upload your own structural responses and structural properties.
      </FormText>
    ) : null
  );
};

export default NumberOfStoriesWarning;
