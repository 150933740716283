/**
 * Owner: Haselton Baker Risk Group, LLC
 * Date: 9/14/18
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import { ListGroup } from 'reactstrap';
import DownloadsListItem from './DownloadsListItem/index.js';
import { downloadsList } from './index.module.scss';

import type { Download } from './DownloadsListItem/DownloadsListItem';

type Props = {|
  downloads: Array<Download>,
|};

const DownloadsList = ({ downloads }: Props): Element<typeof ListGroup> => {
  const listItems = downloads.map((download) => (
    <DownloadsListItem
      download={download}
      key={download.type}
    />
  ));

  return (
    <ListGroup className={classNames(downloadsList, 'downloads-list')}>
      {listItems}
    </ListGroup>
  );
};

export default DownloadsList;
