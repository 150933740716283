/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import unset from 'lodash/fp/unset.js';
import {
  COPY_MODEL_START,
  COPY_MODEL_END,
  CREATE_RUN_REQUEST,
  CREATE_RUN_RESPONSE,
  FETCH_MODEL_REQUEST,
  FETCH_MODEL_RESPONSE,
  FETCH_UPLOAD_URL_REQUEST,
  FETCH_UPLOAD_URL_RESPONSE,
  START_POLLING_MODEL,
  STOP_POLLING_MODEL,
  TOGGLE_MODEL_ARCHIVE_START,
  TOGGLE_MODEL_ARCHIVE_END,
  UPDATE_MODEL_REQUEST,
  UPDATE_MODEL_RESPONSE,
} from '#constants/actionTypes.js';

const metaReducer = (state = {}, action = {}) => {
  const { meta: actionMeta, type } = action;
  const metaById = state;
  switch (type) {
    case FETCH_MODEL_REQUEST: {
      const { modelId, requestId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          validFetchRequests: {
            [requestId]: true,
          },
        },
      };
    }
    case CREATE_RUN_REQUEST:
    case FETCH_UPLOAD_URL_REQUEST:
    case UPDATE_MODEL_REQUEST: {
      const { modelId, requestId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      const updateRequests = modelMeta.updateRequests || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          updateRequests: {
            ...updateRequests,
            [requestId]: true,
          },
          validFetchRequests: {},
        },
      };
    }
    case FETCH_MODEL_RESPONSE: {
      const { modelId, requestId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      const validFetchRequests = modelMeta.validFetchRequests || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          validFetchRequests: unset(requestId, validFetchRequests),
        },
      };
    }
    case CREATE_RUN_RESPONSE:
    case FETCH_UPLOAD_URL_RESPONSE:
    case UPDATE_MODEL_RESPONSE: {
      const { modelId, requestId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      const updateRequests = modelMeta.updateRequests || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          updateRequests: unset(requestId, updateRequests),
        },
      };
    }
    case START_POLLING_MODEL:
    case STOP_POLLING_MODEL: {
      const { modelId, pollingId } = action.payload;
      const modelMeta = metaById[modelId] || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          pollingId,
        },
      };
    }
    case COPY_MODEL_START: {
      const { modelId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          copying: true,
        },
      };
    }
    case COPY_MODEL_END: {
      const { modelId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          copying: false,
        },
      };
    }
    case TOGGLE_MODEL_ARCHIVE_START: {
      const { modelId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          togglingArchive: true,
        },
      };
    }
    case TOGGLE_MODEL_ARCHIVE_END: {
      const { modelId } = actionMeta;
      const modelMeta = metaById[modelId] || {};
      return {
        ...metaById,
        [modelId]: {
          ...modelMeta,
          togglingArchive: false,
        },
      };
    }
    default:
      return metaById;
  }
};

export default metaReducer;
