/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import getOr from 'lodash/fp/getOr.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';

const hasConcreteShearWalls = (buildingType) => getOr(false, `${buildingType}.hasConcreteShearWalls`, buildingTypesById);

export default hasConcreteShearWalls;
