/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import React from 'react';
import classNames from 'classnames';
import TreeItem from '@material-ui/lab/TreeItem/index.js';
import encodeComponentId from '#support/components/encodeComponentId.js';

import { elementRoot } from './index.module.scss';
import { archived as archivedClass } from '#components/pages/Components/Components.module.scss';

type Component = {|
  componentId: string,
  uuid: string,
  name: string,
  archived: boolean,
|};

type Category = {|
  label: string,
  category: string,
  children: Array<Category>,
  className?: string,
|};

type TreeItemData = Category | Component;

type RenderTreeItems = (
  data: TreeItemData,
  isRoot?: boolean,
) => Object;

const getTreeItemClasses = (value: string): string => classNames(
  `tree-item-${encodeComponentId(value)}`,
  'new-tree-label'
);

const renderTreeItems: RenderTreeItems = (data, isRoot) => {
  if (!data.children) {
    const {
      archived,
      componentId,
      uuid,
      name,
    } = data;
    return (
      <TreeItem
        classes={{
          root: elementRoot,
          label: classNames(getTreeItemClasses(componentId), archived ? archivedClass : ''),
        }}
        key={componentId}
        nodeId={uuid}
        label={(
          <div className="pl-2" style={{ textIndent: '-1em' }}>
            <span className="font-weight-bold">{componentId}</span>
            {' - '}
            {name}
            { archived && ' (Archived)' }
          </div>
        )}
      />
    );
  }
  const {
    category,
    label,
    children,
  } = data;
  const childrenRendered = children.map((child) => renderTreeItems(child));
  return (
    !isRoot
      ? (
        <TreeItem
          key={category}
          nodeId={category}
          label={label}
          classes={{ label: getTreeItemClasses(category) }}
        >
          {childrenRendered}
        </TreeItem>
      ) : childrenRendered
  );
};

export default renderTreeItems;
