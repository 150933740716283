import pipe from 'lodash/fp/pipe.js';
import Models from '#components/pages/Models/Models.jsx';
import withComponentsPolling from '#components/support/polling/withComponentsPolling/index.js';
import withComponentCategoriesPolling from '#components/support/polling/withComponentCategoriesPolling/index.js';
import withModelsPolling from '#components/support/polling/withModelsPolling/index.js';
import withAppMaintenanceModePolling from '#components/support/polling/withAppMaintenanceModePolling/index.js';
import withAppVersionPolling from '#components/support/polling/withAppVersionPolling/index.js';
import withAuthUserPolling from '#components/support/polling/withAuthUserPolling/index.js';

export default pipe(
  withAppMaintenanceModePolling,
  withAppVersionPolling,
  withAuthUserPolling,
  withComponentCategoriesPolling,
  withComponentsPolling,
  withModelsPolling
)(Models);
