/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import withSectionFunctionality from '#components/pages/Models/Model/support/Router/withSectionFunctionality/index.js';
import { selectModelHasRun } from '#selectors/entities/models.js';
import DebugSection from './DebugSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId } = ownProps;
  return {
    showDownloads: selectModelHasRun(state, { modelId }),
  };
};

export default connect(mapStateToProps)(withSectionFunctionality(DebugSection));
