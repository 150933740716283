/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import numeral from 'numeral';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { componentExposureBreakdownById } from '#constants/models/componentBreakDownProperties.js';
import { table, totalRow } from './RepairCostExposureTable.module.scss';

type DataRow = {
  componentGroup: string,
  costModifier: number,
  maxRepairPotential: number,
  percentOfBuildingReplacementValue: number,
}

const tableRow = (dataRow: DataRow, showCostModifier): Element<'tr'> => (
  <tr key={`exposureTableRow${dataRow.componentGroup}`} className="text-center">
    <td className="text-left">{componentExposureBreakdownById[dataRow.componentGroup].description}</td>
    { showCostModifier && (
      <td>{numeral(dataRow.costModifier).format('0.0')}</td>
    )}
    <td>{numeral(dataRow.maxRepairPotential).format('$0,0')}</td>
    <td>{numeral(dataRow.percentOfBuildingReplacementValue).format('0.0%')}</td>
  </tr>
);

type Props = {|
  showCostModifier: boolean,
  dataByGroup: {
    [string]: DataRow,
  },
  columnsOrdered: string[]
|};

const RepairCostExposureTable = (props: Props): Element<typeof Table> => {
  const { dataByGroup, columnsOrdered, showCostModifier } = props;
  return (
    <Table striped bordered className={table}>
      <thead>
        <tr>
          <th>
            Component Group
          </th>
          {showCostModifier && (
            <th>
              Cost Modifier
              {' '}
              <Tip tipId="componentGroupHeaderCostModifier" />
            </th>
          )}
          <th>
            Maximum Repair Potential
            {' '}
            <Tip tipId="componentGroupHeaderMaximumRepairPotential" />
          </th>
          <th>
            Percent of Building Replacement Value
            {' '}
            <Tip tipId="componentGroupHeaderPercentOfBuildingReplacementValue" />
          </th>
        </tr>
      </thead>
      <tbody>
        {columnsOrdered.map((col) => tableRow(dataByGroup[col], showCostModifier))}
        <tr className={totalRow}>
          { showCostModifier && (
            <td />
          )}
          <td>Total</td>
          <td>{numeral(dataByGroup.total.maxRepairPotential).format('$0,0')}</td>
          <td>{numeral(dataByGroup.total.percentOfBuildingReplacementValue).format('0.0%')}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default RepairCostExposureTable;
