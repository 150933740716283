import {
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_RESPONSE,
  FETCH_ACTION,
  FETCH_TERMS_REQUEST,
  FETCH_TERMS_RESPONSE,
} from '#constants/actionTypes.js';

export const fetchTerms = () => ({
  [FETCH_ACTION]: {
    types: [FETCH_TERMS_REQUEST, FETCH_TERMS_RESPONSE],
    endpoint: '/terms',
    options: {
      method: 'GET',
    },
  },
});

export const acceptTerms = (userId, termsId) => ({
  [FETCH_ACTION]: {
    types: [ACCEPT_TERMS_REQUEST, ACCEPT_TERMS_RESPONSE],
    endpoint: `/user/${userId}/terms/${termsId}`,
    options: {
      method: 'PUT',
    },
  },
});
