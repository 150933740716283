import {
  COMPONENT_SELECTOR_SET_FILTERS,
  COMPONENT_SELECTOR_SET_EXPANDED,
  COMPONENT_SELECTOR_SET_TREE_DATA,
  COMPONENT_SELECTOR_EXPAND_ALL,
} from '#constants/actionTypes.js';

export const setFilters = (componentSelectorName, filters) => ({
  type: COMPONENT_SELECTOR_SET_FILTERS,
  meta: { componentSelectorName },
  payload: filters,
});

export const setExpandedNodes = (componentSelectorName, expanded) => ({
  type: COMPONENT_SELECTOR_SET_EXPANDED,
  meta: { componentSelectorName },
  payload: expanded,
});

export const setTreeData = (componentSelectorName, { components, categories }) => ({
  type: COMPONENT_SELECTOR_SET_TREE_DATA,
  meta: { componentSelectorName },
  payload: { components, categories },
});

export const expandAllNodes = (componentSelectorName) => ({
  type: COMPONENT_SELECTOR_EXPAND_ALL,
  meta: { componentSelectorName },
  payload: null,
});
