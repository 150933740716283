/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import get from 'lodash/fp/get.js';
import { map } from 'ramda';
import numeral from 'numeral';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import IntensityName from '#components/support/forms/IntensityName/index.js';
import { groundMotion } from './GroundMotionTable.module.scss';

type SaItem = {
  returnPeriod: number,
  saByPeriod: {
    [period: number]: number
  },
}

type RenderDataRows = (
  saData: Array<SaItem>,
  periods: Array<number>
) => Node;

const renderDataRows: RenderDataRows = (
  saData,
  periods
) => saData.map(({ returnPeriod, saByPeriod }) => (
  <tr key={returnPeriod} className="text-center">
    <td className="text-nowrap">
      <IntensityName includeUploads returnPeriod={returnPeriod} />
    </td>
    <td>{returnPeriod}</td>
    { periods.map((period) => (
      <td key={period}>{numeral(get(`${period}`, saByPeriod)).format('0.00')}</td>
    ))}
  </tr>
));

type RenderHeaderPeriodColumns = (periods: Array<number>) => Node;

const renderHeaderPeriodColumns: RenderHeaderPeriodColumns = (periods) => map((period) => (
  <th key={`header-${period}`}>
    { parseFloat(period) === 0
      ? 'PGA'
      : `T=${numeral(period).format('0.0')}`}
  </th>
), periods);

type Props = {|
groundMotionData: {
    sa: Array<SaItem>,
    periods: Array<number>
  }
|}

const GroundMotionTable = (props: Props): Element<typeof Table> => {
  const { groundMotionData: { sa, periods } } = props;
  const numberOfSaValues = periods && periods.length;
  return (
    <Table striped bordered className={groundMotion} responsive>
      <thead>
        <tr>
          <th rowSpan="2">
            Intensity
            <Tip tipId="intensity" />
          </th>
          <th rowSpan="2">Return Period [yrs]</th>
          <th colSpan={numberOfSaValues}>Spectral Accelerations (Geometric Mean) [g]</th>
        </tr>
        <tr>
          { renderHeaderPeriodColumns(periods) }
        </tr>
      </thead>
      <tbody>
        { renderDataRows(sa, periods) }
      </tbody>
    </Table>
  );
};

export default GroundMotionTable;
