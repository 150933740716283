/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Node } from 'react';

import React from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children: Node,
  isAuthorized: boolean,
  redirectTo: string,
};

const RequireAuthorization = ({
  children, isAuthorized, redirectTo,
}: Props): Node => (
  isAuthorized
    ? children
    : <Navigate to={redirectTo} />
);

export default RequireAuthorization;
