/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';

type Props = {|
  returnPeriod: number,
  isDBE: boolean,
  isMCE: boolean,
|};

const IntensityName = ({ returnPeriod, isDBE, isMCE }: Props): Element<'span'> => {
  if (isDBE) {
    return (
      <span>DE</span>
    );
  }
  if (isMCE) {
    return (
      <span>
        MCE
        <sub>R</sub>
      </span>
    );
  }
  let timeYears = 50;
  if ([43, 285].includes(returnPeriod)) {
    timeYears = 30;
  } else if ([108, 712].includes(returnPeriod)) {
    timeYears = 75;
  } else if ([144, 949].includes(returnPeriod)) {
    timeYears = 100;
  }

  const percentValue = Math.round(100 * (1 - Math.exp(-timeYears / returnPeriod)));
  const valueInYears = `${percentValue}% in ${timeYears} yrs`;
  return (
    <span>{valueInYears}</span>
  );
};

export default IntensityName;
