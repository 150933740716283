import fields from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import withCustomHandleSubmit
  from '#components/support/forms/withCustomHandleSubmit/index.js';
import { selectVisibleFields } from '#selectors/ui/form/component.js';

const config = {
  fields,
  selectVisibleFields,
  form: COMPONENT_FORM,
};
export default withCustomHandleSubmit(config);
