// @flow
import type { Element } from 'react';

import React from 'react';
import { Col, Row } from 'reactstrap';
import type { Component } from '#types/Component/index.js';
import DamageStateSummaryTable
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/DamageStateSummaryTable/index.jsx';
import ConsequenceSummaryTable
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/ConsequenceSummaryTable/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import RepairCostSummaryTable
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/RepairCostSummaryTable/index.jsx';
import RepairTimeSummaryTable
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/RepairTimeSummaryTable/index.jsx';

type Props = {
  className?: string | null,
  component: Component,
};

const ComponentSummaryBottom = ({ component, className = null }: Props): Element<typeof Row> => (
  <Row className={className}>
    <Col>
      <fieldset>
        <legend>
          Damage States
        </legend>
        <DamageStateSummaryTable readOnly component={component} />
      </fieldset>

      <fieldset>
        <legend>
          Consequences
        </legend>
        <ConsequenceSummaryTable readOnly component={component} />
      </fieldset>
      <fieldset>
        <legend>
          Repair Cost
          {' '}
          <Tip tipId="repairCostPerUnit" />
        </legend>
        <RepairCostSummaryTable component={component} readOnly />
      </fieldset>
      <fieldset>
        <legend>
          Repair Time
          {' '}
          <Tip tipId="repairTimePerUnit" />
        </legend>
        <RepairTimeSummaryTable component={component} readOnly />
      </fieldset>
    </Col>
  </Row>
);

export default ComponentSummaryBottom;
