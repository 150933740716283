/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import keyBy from 'lodash/fp/keyBy.js';
import {
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
  COLOR_8,
  COLOR_9,
  COLOR_10,
  COLOR_11,
} from '../plotColors.js';

export const componentBreakdown = [
  { id: 'collapse', description: 'Collapse', color: COLOR_2 },
  { id: 'exteriorCladding', description: 'Exterior Cladding', color: COLOR_1 },
  { id: 'interiorFinishes', description: 'Interior Finishes', color: COLOR_6 },
  { id: 'otherNonStructuralComponents', description: 'Other Non-structural', color: COLOR_10 },
  { id: 'partitionWalls', description: 'Partition Walls', color: COLOR_3 },
  { id: 'plumbingAndHvac', description: 'Plumbing and HVAC', color: COLOR_8 },
  { id: 'residualDrift', description: 'Residual Drift', color: COLOR_9 },
  { id: 'structuralComponents', description: 'Structural Components', color: COLOR_4 },
  { id: 'total', description: 'Total' },
];

const componentExposureBreakdown = [
  { id: 'ceilings', description: 'Ceilings', color: COLOR_11 },
  { id: 'elevators', description: 'Elevators', color: COLOR_2 },
  { id: 'exteriorFinishes', description: 'Exterior Finishes', color: COLOR_9 },
  { id: 'exteriorWalls', description: 'Exterior Walls', color: COLOR_1 },
  { id: 'hvac', description: 'HVAC', color: COLOR_5 },
  { id: 'interiorWalls', description: 'Interior Walls', color: COLOR_7 },
  { id: 'lighting', description: 'Lighting', color: COLOR_6 },
  { id: 'otherNonstructural', description: 'Other Non-structural', color: COLOR_10 },
  { id: 'partitionWalls', description: 'Partition Walls', color: COLOR_3 },
  { id: 'piping', description: 'Piping', color: COLOR_8 },
  { id: 'structural', description: 'Structural', color: COLOR_4 },
  { id: 'total', description: 'Total' },
];

export const componentBreakdownById = keyBy('id', componentBreakdown);

export const componentExposureBreakdownById = keyBy('id', componentExposureBreakdown);
