/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType } from 'react';

import React, { Component } from 'react';
import { statusReached } from '@hbrisk/sp3-risk-model-support/models/runStatusHelpers.js';

type InputProps = {
  percentComplete: number,
  progressText: string,
};

type OutputProps = {|
  calculatePercentComplete: Function,
  calculateProgressText: Function,
  desiredStatus: string,
  elapsed: number,
  done: Function,
  status: string,
|};

type State = {
  percentComplete: number,
}

type GetComponent = ComponentType<InputProps> => ComponentType<OutputProps>;

const getComponent: GetComponent = (C) => class extends Component<OutputProps, State> {
  intervalId = null;

  timeoutId = null;

  constructor(props: OutputProps) {
    super(props);
    const {
      calculatePercentComplete, desiredStatus, status, elapsed,
    } = props;
    this.state = {
      percentComplete: calculatePercentComplete(desiredStatus, status, elapsed),
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      const {
        calculatePercentComplete, desiredStatus, done, status, elapsed,
      } = this.props;
      const complete = calculatePercentComplete(desiredStatus, status, elapsed);
      this.setState({
        percentComplete: complete,
      });
      if (statusReached(status, desiredStatus)) {
        clearInterval(this.intervalId);
        this.timeoutId = setTimeout(() => {
          done();
        }, 1500);
      }
    }, 250);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  }

  render() {
    const { percentComplete } = this.state;
    const { calculateProgressText, status } = this.props;
    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <C
        {...this.props}
        percentComplete={percentComplete}
        progressText={calculateProgressText(percentComplete, status)}
      />
    );
    /* eslint-enable react/jsx-props-no-spreading */
  }
};

export default getComponent;
