// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { subNavSnippet } from './index.module.scss';

type Props = {|
  children?: Node,
  className?: string | null,
|};

const SubNavSnippet = ({ children = null, className = null }: Props): Element<'div'> => (
  <div
    className={classNames('float-right', subNavSnippet, className)}
  >
    {children}
  </div>
);

export default SubNavSnippet;
