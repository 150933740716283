/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import curry from 'lodash/fp/curry.js';

const makeformFieldSelector = curry((
  selector,
  form,
  field,
  state
) => get(
  field,
  selector(form)(state)
));

export default makeformFieldSelector;
