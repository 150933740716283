/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import numeral from 'numeral';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import IntensityName from '#components/support/forms/IntensityName/index.js';
import { componentBreakdownById } from '#constants/models/componentBreakDownProperties.js';
import { meanLossBreakdownByIntensity } from './MeanLossBreakdownByIntensityTable.module.scss';

type IntensityLoss = {
    pga: number,
    returnPeriod: number,
};

const tableRow = (values: IntensityLoss, columns: string[]) => {
  const {
    returnPeriod,
  } = values;

  return (
    <tr key={returnPeriod} className="text-center">
      <td className="text-nowrap">
        <IntensityName returnPeriod={returnPeriod} />
      </td>
      <td>{returnPeriod}</td>
      {columns.map((col) => (
        <td key={col}>{numeral(values[col]).format('0.0%')}</td>
      ))}
    </tr>
  );
};

const renderRows = (lossData: IntensityLoss[], columns: string[]) => (
  lossData.map((d) => tableRow(d, columns))
);

type Props = {|
  meanLossData: IntensityLoss[],
  meanLossColumns: string[],
|};

const MeanLossBreakdownByIntensityTable = (props: Props): Element<typeof Table> => {
  const { meanLossData, meanLossColumns } = props;
  return (
    <Table striped bordered className={meanLossBreakdownByIntensity}>
      <thead>
        <tr>
          <th>
            Intensity
            {' '}
            <Tip tipId="intensity" />
          </th>
          <th>
            Return Period
            {' '}
            <Tip tipId="returnPeriods" />
          </th>
          { meanLossColumns.map((x) => (
            <th key={x}>{componentBreakdownById[x].description}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        { renderRows(meanLossData, meanLossColumns) }
      </tbody>
    </Table>
  );
};

export default MeanLossBreakdownByIntensityTable;
