/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { ADD, EDIT, RESPONSE_INDEX } from '#constants/pathStrings.js';
import EditView from './EditView/index.js';
import AddView from './AddView/index.jsx';
import MainView from './MainView/index.jsx';

type Props = {|
  heading: string,
  handleSubmit: Function,
  form: string,
  modelId: string,
  onBack: Function,
  onNext: Function,
|};

const StructuralResponsesSection = (props: Props): Element<'div'> => {
  const {
    heading,
    handleSubmit,
    form,
    modelId,
    onBack,
    onNext,
  } = props;

  return (
    <div>
      <Form noValidate onSubmit={handleSubmit}>
        <Routes>
          <Route
            index
            element={
              (
                <MainView
                  heading={heading}
                  form={form}
                  onBack={onBack}
                  onNext={onNext}
                />
              )
            }
          />
          <Route
            path={`${EDIT}/:${RESPONSE_INDEX}`}
            element={<EditView form={form} modelId={modelId} />}
          />
          <Route
            path={ADD}
            element={<AddView form={form} />}
          />
          <Route path="*" element={<Navigate to="." />} />
        </Routes>
      </Form>
    </div>
  );
};

export default StructuralResponsesSection;
