/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import {
  Col,
  Form,
  FormGroup,
  FormText,
  UncontrolledAlert as Alert,
} from 'reactstrap';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import SubmitButton from '#components/pages/Models/Model/sections/support/buttons/ModelFormSectionSubmitButton/index.jsx';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import ContinueToNextSectionGroupButton
  from '#components/pages/Models/Model/sections/support/buttons/ContinueToNextSectionGroupButton/index.jsx';
import { notes } from './index.module.scss';

type Props = {|
  error: string,
  form: string,
  group: string,
  groupIsLast: boolean,
  handleSubmit: Function,
  heading: string,
  modelId: string,
  modelType: number,
  onBack: Function,
  onNext: Function,
  submitting: boolean,
|};

const AdditionalNotesSection = (props: Props): Element<'div'> => {
  const {
    error,
    form,
    group,
    groupIsLast,
    handleSubmit,
    heading,
    modelId,
    modelType,
    onBack,
    onNext,
    submitting,
  } = props;
  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <FormGroup tag="fieldset">
          <legend>Model Notes</legend>
          <FormGroup row>
            <Col>
              Provide any notes/comments about your model that you wish to capture.
              <Field
                form={form}
                id="notes"
                name="notes"
                type="textarea"
                rows="8"
                component={RenderInput}
                inputClassName={notes}
              />
              <FieldFeedback name="notes" form={form} />
              <FormText color="muted">
                (These will be included in your PDF report)
              </FormText>
            </Col>
          </FormGroup>
        </FormGroup>
        { error && !submitting ? <Alert color="danger">{error}</Alert> : ''}
        <BackButton onClick={onBack} />
        <SubmitButton modelType={modelType} modelId={modelId} />
        {
          !groupIsLast && (
            <ContinueToNextSectionGroupButton
              group={group}
              modelType={modelType}
              onClick={onNext}
            />
          )
        }
      </Form>
    </div>
  );
};

export default AdditionalNotesSection;
