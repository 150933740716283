/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Card,
  CardBody,
  CardText,
  Col,
  Row,
} from 'reactstrap';

import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import RepairCostExposurePlot from './RepairCostExposurePlot/index.js';
import RepairCostExposureTable from './RepairCostExposureTable/index.js';
import RepairCostExposureScaleFactorsApplied from './RepairCostExposureScaleFactorsApplied/index.js';

type Props = {|
  heading: string,
  modelId: string,
  onBack: Function,
  onNext: Function,
  showExposure: boolean,
  modelType: number,
|};

const getContent = (modelId, modelType) => (
  <>
    <div className="pt-2 pb-2">
      <RepairCostExposureScaleFactorsApplied modelId={modelId} />
    </div>
    <h5>Exposure Breakdown by Component Group</h5>
    <div className="pt-2 pb-2">
      <RepairCostExposureTable modelId={modelId} modelType={modelType} />
    </div>
    <div className="pt-2 pb-2">
      <RepairCostExposurePlot modelId={modelId} />
    </div>
  </>
);

const empty = (
  <Row className="pt-3 pb-3">
    <Col>
      <Card>
        <CardBody>
          <CardText>
            Run your model to view exposure results.
          </CardText>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

const RepairCostExposureSection = ({
  heading, modelId, onBack, onNext, showExposure, modelType,
}: Props): Element<'div'> => (
  <div>
    <h2>
      {heading}
      {' '}
      <Tip tipId="repairCostExposureHeader" />
    </h2>
    {
      showExposure ? getContent(modelId, modelType) : empty
    }
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </div>
);

export default RepairCostExposureSection;
