// @flow
import type { Element } from 'react';
import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Table,
} from 'reactstrap';
import snakeCase from 'lodash/fp/snakeCase.js';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import IrregularitySelectMenu from '#components/pages/Models/Model/sections/support/forms/IrregularitySelectMenu/index.jsx';
import YesNoSelectMenu from '#components/pages/Models/Model/sections/support/forms/YesNoSelectMenu/index.jsx';
import ConstructionQualitySelectMenu from './ConstructionQualitySelectMenu/index.jsx';
import StructuralMaterialDeteriorationSelectMenu from './StructuralMaterialDeteriorationSelectMenu/index.jsx';
import { buildingModifierTable } from './BuildingModifiersChecklistSection.module.scss';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
  fieldsVisibility: {
    [string]: boolean,
  }
|};

const BuildingModifiersChecklistSection = (props: Props): Element<'div'> => {
  const {
    heading,
    form,
    onBack,
    onNext,
    fieldsVisibility,
  } = props;

  const areAnyFieldsVisible = (array) => array.some((field) => fieldsVisibility[field]);

  const showSlopingSiteFields = areAnyFieldsVisible([
    'buildingModsFullStoryGradeWLF',
    'buildingModsFullStoryGrade',
  ]);

  const showShortColumnFields = areAnyFieldsVisible([
    'buildingModsColumnRatioLessThanTypical',
    'buildingModsColumnPierSpandrelRatio',
  ]);

  const showSetbackFields = areAnyFieldsVisible([
    'buildingModsSetbackOutboard',
    'buildingModsSetbackInboard',
    'buildingModsSetbackInPlaneLfrs',
  ]);

  const showSplitLevelFields = areAnyFieldsVisible([
    'buildingModsSplitLevel',
  ]);

  const showOtherVerticalIrregularityFields = areAnyFieldsVisible([
    'buildingModsSplitLevel',
  ]);

  const showRedundancyFields = areAnyFieldsVisible([
    'buildingModsRedundancy',
  ]);

  const showSystemSpecificFields = areAnyFieldsVisible([
    'buildingModsFlatPlateAsBeam',
    'buildingModsKBracing',
    'buildingModsCrossGrainOopConn',
    'buildingModsInteriorWallDensity',
  ]);

  const showConstructionQualityFields = areAnyFieldsVisible([
    'buildingModsConstructionQuality',
    'buildingModsNonStructuralConstructionQuality',
    'buildingModsMaterialDeterioration',
  ]);

  const yesNoRow = (name, label, labelClass) => (
    fieldsVisibility[name] ? (
      <tr>
        <td>
          <Label htmlFor={name} className={labelClass || ''}>
            {label}
            {' '}
            <Tip tipId={name} />
          </Label>
        </td>
        <td>
          <YesNoSelectMenu
            form={form}
            name={name}
            includeEmptyOption
            inputClassName={snakeCase(name)}
          />
          <FieldFeedback name={name} form={form} />
        </td>
      </tr>
    ) : null
  );

  const irregularityRow = (name, label, labelClass) => (
    fieldsVisibility[name] ? (
      <tr>
        <td>
          <Label htmlFor={name} className={labelClass || ''}>
            {label}
            {' '}
            <Tip tipId={name} />
          </Label>
        </td>
        <td>
          <IrregularitySelectMenu form={form} name={name} />
          <FieldFeedback name={name} form={form} />
        </td>
      </tr>
    ) : null
  );

  const constructionQualityRow = (name, label, labelClass) => (
    fieldsVisibility[name] ? (
      <tr>
        <td>
          <Label htmlFor={name} className={labelClass || ''}>
            {label}
            {' '}
            <Tip tipId={name} />
          </Label>
        </td>
        <td>
          <ConstructionQualitySelectMenu
            form={form}
            name={name}
            inputClassName={snakeCase(name)}
          />
          <FieldFeedback name={name} form={form} />
        </td>
      </tr>
    ) : null
  );

  const structuralMaterialDeteriorationRow = (name, label, labelClass) => (
    fieldsVisibility[name] ? (
      <tr>
        <td>
          <Label htmlFor={name} className={labelClass || ''}>
            {label}
            {' '}
            <Tip tipId={name} />
          </Label>
        </td>
        <td>
          <StructuralMaterialDeteriorationSelectMenu
            form={form}
            name={name}
            inputClassName={snakeCase(name)}
          />
          <FieldFeedback name={name} form={form} />
        </td>
      </tr>
    ) : null
  );

  const headerRow = (description) => (
    <tr>
      <td colSpan="2" className="pt-4 font-weight-bold">
        {description}
        :
      </td>
    </tr>
  );

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        <FormGroup tag="fieldset" className="pb-0">
          <legend>Vertical Irregularities</legend>
          <Table className={buildingModifierTable}>
            <tbody>
              {headerRow('Soft/Weak Story')}
              {irregularityRow('verticalIrregularity', 'Soft or Weak Story Vertical Irregularity?', 'pl-4')}
              {showSlopingSiteFields && headerRow('Sloping Site')}
              {yesNoRow(
                'buildingModsFullStoryGradeWLF',
                'Full story grade change? (FEMA Type W1 buildings)',
                'pl-4'
              )}
              {yesNoRow(
                'buildingModsFullStoryGrade',
                'Full story grade change? (FEMA Type non-W1 buildings)',
                'pl-4'
              )}
              {showSetbackFields && headerRow('Setback')}
              {yesNoRow(
                'buildingModsSetbackOutboard',
                'Outboard vertical elements of the lateral system at an upper story?',
                'pl-4'
              )}
              {yesNoRow(
                'buildingModsSetbackInboard',
                'Inboard vertical elements of the lateral system at upper stories?',
                'pl-4'
              )}
              {yesNoRow(
                'buildingModsSetbackInPlaneLfrs',
                'In-plane offset of the lateral elements greater their length?',
                'pl-4'
              )}
              {showShortColumnFields && headerRow('Short Column/Pier')}
              {yesNoRow(
                'buildingModsColumnRatioLessThanTypical',
                'Columns with height/depth ratios less than 50% of typical other columns at that level?',
                'pl-4'
              )}
              {yesNoRow(
                'buildingModsColumnPierSpandrelRatio',
                'Column depth less than spandrel depth, or column shortened by infill walls or adjacent floors?',
                'pl-4'
              )}
              {showSplitLevelFields && headerRow('Split Level')}
              {yesNoRow(
                'buildingModsSplitLevel',
                'Split level at one of the floor levels or at the roof?',
                'pl-4'
              )}
              {showOtherVerticalIrregularityFields && headerRow('Other Irregularity')}
              {irregularityRow('buildingModsOtherVerticalIrregularity', 'Other observable vertical irregularity?', 'pl-4')}
            </tbody>
          </Table>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Plan Irregularities</legend>
          <Table className={buildingModifierTable}>
            <tbody>
              {irregularityRow('planTorsion', 'Torsion Irregularity?')}
              {yesNoRow(
                'buildingModsNonParallel',
                'Non-parallel system?',
              )}
              {yesNoRow(
                'buildingModsReentrant',
                'Re-entrant corner projection 25% of plan dimension?',
              )}
              {yesNoRow(
                'buildingModsDiaphramOpen',
                'Diaphragm opening over 50% of the total diaphragm width?',
              )}
              {yesNoRow(
                'buildingModsOopOffset',
                'Exterior beams do not align with the columns in plan?',
              )}
              {irregularityRow('buildingModsOtherPlanIrregularity', 'Other observable plan irregularity?')}
            </tbody>
          </Table>
        </FormGroup>
        {showRedundancyFields && (
          <FormGroup tag="fieldset">
            <legend>Redundancy</legend>
            <Table className={buildingModifierTable}>
              <tbody>
                {yesNoRow(
                  'buildingModsRedundancy',
                  'At least two bays of lateral elements?',
                )}
              </tbody>
            </Table>
          </FormGroup>
        )}
        {showSystemSpecificFields && (
          <FormGroup tag="fieldset">
            <legend>System Specific</legend>
            <Table className={buildingModifierTable}>
              <tbody>
                {yesNoRow(
                  'buildingModsKBracing',
                  'K bracing geometry is visible?',
                )}
                {yesNoRow(
                  'buildingModsFlatPlateAsBeam',
                  'Flat plate serves as the beam in the moment frame?',
                )}
                {yesNoRow(
                  'buildingModsCrossGrainOopConn',
                  'Roof-to-wall ties do not rely on cross-grain bending.',
                )}
                {yesNoRow(
                  'buildingModsInteriorWallDensity',
                  'High interior wall density?',
                )}
              </tbody>
            </Table>
          </FormGroup>
        )}
        {showConstructionQualityFields && (
          <FormGroup tag="fieldset">
            <legend>Construction Quality</legend>
            <Table className={buildingModifierTable}>
              <tbody>
                {constructionQualityRow('buildingModsConstructionQuality', 'Structural Construction Quality')}
                {constructionQualityRow('buildingModsNonStructuralConstructionQuality', 'Non-structural Construction Quality')}
                {structuralMaterialDeteriorationRow('buildingModsMaterialDeterioration', 'Structural Material Deterioration')}
              </tbody>
            </Table>
          </FormGroup>
        )}
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default BuildingModifiersChecklistSection;
