import React from 'react';
import {
  DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
  DAMAGE_STATE_MEDIAN,
  DAMAGE_STATE_BETA,
  DAMAGE_STATE_TYPE,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import DamageStateRowTable
  from '#components/pages/Components/support/tables/DamageStateRowTable/index.js';
import AddDamageStateButton
  from '#components/pages/Components/Component/sections/DamageStates/renderDamageSatesTable/AddDamageStateButton/index.js';

const renderDamageStatesTable = ({ fields, readOnly, meta }) => {
  const itemFields = [
    DAMAGE_STATE_TYPE,
    DAMAGE_STATE_BETA,
    DAMAGE_STATE_MEDIAN,
    DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
  ];
  const { error } = meta;

  return (
    <>
      <DamageStateRowTable
        itemFields={itemFields}
        readOnly={readOnly}
        fields={fields}
      />
      {
        error
        && (
          <div>
            <span className="text-danger">{error}</span>
          </div>
        )
      }
      {
        !readOnly
        && (
          <AddDamageStateButton fields={fields} />
        )
      }
    </>
  );
};

export default renderDamageStatesTable;
