import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';

import { connect } from 'react-redux';
import BaseField
  from '#components/pages/Components/support/form/Field/BaseField/BaseField.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const formState = getOr({ values: { } }, `form.${form}`, state);
  return ({
    state: formState,
    value: get(ownProps.name, formState.values),
  });
};

export default connect(mapStateToProps)(BaseField);
