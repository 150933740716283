/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import type { User } from '#types/User.js';
import EditUserForm from './EditUserForm/index.js';

type Props = {|
  fetchUser: Function,
  user: User,
|};

const EditUser = ({ fetchUser, user }: Props): null | Element<'div'> => {
  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);

  if (!user) return null;

  return (
    <div>
      <Row>
        <Col md="12">
          <h1>Edit User</h1>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <EditUserForm user={user} />
        </Col>
      </Row>
    </div>
  );
};

export default EditUser;
