/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';
import React from 'react';
import moment from 'moment';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import { disabled } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.module.scss';

type CellType = (data: CellData) => Element<'span'>;

const accessor = (data: Object) => {
  const createdAt = get('createdAt', data);
  const date = new Date(createdAt);
  return date.getTime();
};

const Cell: CellType = (data: Object) => {
  const createdAt = get('row.createdAt', data);
  const res = moment(createdAt).format('M/D/YY');
  const archived = get('original.archived', data);
  return (
    <span className={archived ? disabled : ''}>
      {res}
    </span>
  );
};

const column: ColumnType = {
  id: 'createdAt',
  accessor,
  Header: 'Created',
  width: 75,
  Cell,
  additionalClasses: ['text-right'],
};

export default column;
