/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import React, { useState } from 'react';
import Page1 from './ResetPasswordFormPage1/index.js';
import Page2 from './ResetPasswordFormPage2/index.js';
import SuccessPage from './ResetPasswordFormSuccessPage/index.jsx';

const ResetPasswordForm = () => {
  const [formPage, setFormPage] = useState(1);
  const [email, setEmail] = useState(null);

  const nextPage = () => {
    setFormPage(formPage + 1);
  };

  return (
    <div>
      { formPage === 1 && <Page1 setEmail={setEmail} nextPage={nextPage} /> }
      { formPage === 2 && <Page2 email={email} nextPage={nextPage} /> }
      { formPage === 3 && <SuccessPage /> }
    </div>
  );
};

export default ResetPasswordForm;
