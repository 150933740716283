// @flow
import type { Element } from 'react';
import React from 'react';
import { Snippet } from '#components/support/layout/SubNav/index.js';
import SaveStatusItem from '#components/pages/Components/Component/SubNav/Snippet/SaveStatusItem/index.js';

type Props = {
  show: boolean,
};
const ComponentSnippet = ({ show }: Props): Element<typeof Snippet> | null => (
  show
    ? (
      <Snippet>
        <SaveStatusItem />
      </Snippet>
    ) : null
);

export default ComponentSnippet;
