/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';

const withFormValidation = (C) => {
  // We're not sure why, but replacing "let autofilled" with a local const
  // inside of mapStateToProps or replacing "values => validate(autofilled)(values)"
  // with "validate(autofilled)" breaks validation. More specifically, it generates a
  // validation function that whose autofilled fields data does not update as expected
  let autofilled;
  let visibleFields;
  const mapStateToProps = (state, ownProps) => {
    const { modelType } = ownProps;
    const {
      selectAutofilledFormFields, selectFieldsVisibility, validate,
    } = modelTypesById[modelType];
    autofilled = selectAutofilledFormFields(state);
    visibleFields = selectFieldsVisibility(state);
    return ({
      ...ownProps,
      validate: (values) => validate(autofilled, visibleFields, state)(values),
    });
  };
  return connect(mapStateToProps)(C);
};

export default withFormValidation;
