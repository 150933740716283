/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import StatusIcon from './StatusIcon/index.jsx';

type Props = {|
  statusType: any,
  value: string,
|};

const StatusCell = (props: Props): Element<'div'> => {
  const {
    statusType,
    value,
  } = props;
  return (
    <div>
      <div title={value} className="text-center">
        <StatusIcon type={statusType} />
      </div>
    </div>
  );
};

export default StatusCell;
