/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const items = [
  { id: 'Wall', value: 'Wall', name: 'Partial Length Wall' },
  { id: 'OCBF', value: 'OCBF', name: 'Ordinary Concentric Braced Frame' },
  { id: 'SCBF', value: 'SCBF', name: 'Special Concentric Braced Frame' },
  { id: 'BRB', value: 'BRB', name: 'Buckling Restrained Brace' },
];

type Props = {|
  form: string,
  direction: 1 | 2,
|};

const TypeOfResistanceSelectMenu = ({
  form,
  direction,
}: Props): Element<typeof SelectMenu> => {
  const name = `typeOfResistanceDir${direction}`;
  return (
    <SelectMenu
      form={form}
      items={items}
      name={name}
      id={name}
    />
  );
};

export const TypeOfResistanceSelectMenuDir1 = ({
  form,
}: { form: string }): Element<typeof TypeOfResistanceSelectMenu> => (
  <TypeOfResistanceSelectMenu form={form} direction={1} />
);

export const TypeOfResistanceSelectMenuDir2 = ({
  form,
}: { form: string }): Element<typeof TypeOfResistanceSelectMenu> => (
  <TypeOfResistanceSelectMenu form={form} direction={2} />
);
