// @flow
import type { Element } from 'react';

import React from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import config, { GOOGLE_MAPS_API_KEY } from '#support/app/config.js';

const apiKey = config(GOOGLE_MAPS_API_KEY);

type Props = {
  markerPosition: {
    lat: number,
    lng: number,
  },
  handleMarkerDragEnd: Function,
  handleMarkerDrag: Function,
};

const SiteMap = ({
  markerPosition,
  handleMarkerDragEnd,
  handleMarkerDrag,
}: Props): Element<typeof APIProvider> => (
  <APIProvider apiKey={apiKey}>
    <Map
      style={{ width: '100%', height: '350px' }}
      defaultCenter={markerPosition}
      defaultZoom={15}
    >
      <Marker
        draggable
        position={markerPosition}
        onDrag={handleMarkerDrag}
        onDragEnd={handleMarkerDragEnd}
      />
    </Map>
  </APIProvider>
);

export default SiteMap;
