/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { fetchUser } from '#actions/users/users.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import { selectUserById } from '#selectors/entities/users.js';
import EditUser from './EditUser.jsx';

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps.routeParams;
  const user = selectUserById(state, { userId });
  return ({
    user,
  });
};

const mapStateToDispatch = (dispatch, ownProps) => {
  const { userId } = ownProps.routeParams;
  return ({
    fetchUser: () => dispatch(fetchUser(userId)),
  });
};

export default pipe(
  connect(mapStateToProps, mapStateToDispatch),
  withRouteParams,
)(EditUser);
