/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import keyBy from 'lodash/fp/keyBy.js';

const methods = [
  { id: 'sp3', name: 'SP3 Response Prediction Engine' },
  { id: 'asce722', name: 'ASCE 7-22 Design Demands' },
  { id: 'rha', name: 'User Defined Response History Analysis' },
  { id: 'medians', name: 'User Defined Medians' },
];

export const methodById = keyBy('id', methods);

export default methods;
