import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { selectComponentInModelsByUuid } from '#selectors/entities/components.js';
import FragilityInfo from '#components/pages/Components/Component/sections/FragilityInfo/FragilityInfo.jsx';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const lockedWhenInModel = selectComponentInModelsByUuid(state, { [UUID]: componentUuid });
  return ({
    lockIsDirectional: lockedWhenInModel,
    lockDefaultEdp: lockedWhenInModel,
  });
};

export default connect(mapStateToProps)(FragilityInfo);
