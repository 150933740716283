// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const occupancies = [
  { occupancyId: 1, occupancy: 'Commercial Office' },
  { occupancyId: 2, occupancy: 'Education [k-12]: Elementary Schools' },
  { occupancyId: 3, occupancy: 'Education [k-12]: Middle Schools' },
  { occupancyId: 4, occupancy: 'Education [k-12]: High Schools' },
  { occupancyId: 5, occupancy: 'Healthcare' },
  { occupancyId: 6, occupancy: 'Hospitality' },
  { occupancyId: 7, occupancy: 'Multi-Unit Residential' },
  { occupancyId: 8, occupancy: 'Research' },
  { occupancyId: 9, occupancy: 'Retail' },
  { occupancyId: 10, occupancy: 'Warehouse' },
  { occupancyId: 11, occupancy: 'Single-Unit Residential' },
  { occupancyId: 12, occupancy: 'Parking Garage' },
];

const items = occupancies.map((occupancy) => ({
  id: occupancy.occupancyId,
  value: occupancy.occupancyId,
  name: occupancy.occupancy,
}));

type Props = {
  form: string,
};

const OccupancySelectMenu = ({ form }: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id="occupancy"
    name="occupancy"
    className={classNames('custom-select', 'occupancy-select')}
  />
);

export default OccupancySelectMenu;
