/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

const calculateOrgRequestBody = (values) => ({
  active: true,
  name: values.orgName,
  permissions: {
    modelTypes: [
      ...(values.standard ? [1] : []),
      ...(values.advanced ? [2] : []),
    ],
    perRealization: values.allRealizations || false,
    recoveryTime: values.recoveryTime || false,
  },
});

export default calculateOrgRequestBody;
