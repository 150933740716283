/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {|
  original: {
    responseIndex: number,
    edpKey: string,
    name: string,
    errorCount: number,
  },
|};

const LinkCell = (props: Props): Element<'div'> => {
  const {
    original: {
      responseIndex,
      edpKey,
      name,
      errorCount,
    },
  } = props;
  return (
    <div className="text-nowrap">
      <div className="float-left">
        <Link id={edpKey} to={`edit/${responseIndex}`}>
          <span className="pr-2">{name}</span>
        </Link>
      </div>
      <div className="float-left pr-2">
        <Tip tipId={edpKey} />
      </div>
      { errorCount > 0 && (
        <div className="float-left">
          <Badge color="danger">{errorCount}</Badge>
        </div>
      )}
    </div>
  );
};

export default LinkCell;
