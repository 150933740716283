/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import {
  Button, Col, FormGroup, Label, Row,
} from 'reactstrap';
import classNames from 'classnames';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';

type Props = {|
  form: string,
  projects: string[],
|};

const ProjectSelector = (props: Props): Element<typeof FormGroup> => {
  const { projects, form } = props;
  const hasExistingProjects = projects.length !== 0;
  const showNewProjectToggle = hasExistingProjects;
  const [isNew, setIsNew] = React.useState(!hasExistingProjects);
  const newOrExistingText = isNew ? 'Select Existing' : 'Create New';
  const options = projects.map((p) => (
    <option
      key={p}
      value={p}
    >
      {p}
    </option>
  ));
  const optionsComponents = !isNew
    ? (
      <>
        <option />
        {options}
      </>
    ) : null;

  return (
    <FormGroup>
      <Label htmlFor="projectName">
        Project
      </Label>
      <Row>
        <Col lg="9">
          <Field
            form={form}
            id="projectName"
            name="projectName"
            type={isNew ? 'text' : 'select'}
            component={RenderInput}
            inputClassName={classNames('custom-select', 'project-selector')}
          >
            {optionsComponents}
          </Field>
          <FieldFeedback name="projectName" form={form} />
        </Col>
        <Col className="pl-0 pr-0">
          { showNewProjectToggle && (
            <Button
              id={isNew ? 'selectExistingPrjBtn' : 'createNewProjBtn'}
              color="link"
              onClick={() => setIsNew(!isNew)}
              className="pl-0 pr-0"
            >
              {newOrExistingText}
            </Button>
          )}
        </Col>
      </Row>
    </FormGroup>
  );
};

export default ProjectSelector;
