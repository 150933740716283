/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form, FormGroup, Label, Col, Row, UncontrolledAlert as Alert,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import ContinueToNextSectionGroupButton
  from '#components/pages/Models/Model/sections/support/buttons/ContinueToNextSectionGroupButton/index.jsx';
import SubmitButton from '#components/pages/Models/Model/sections/support/buttons/ModelFormSectionSubmitButton/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import YearOfConstructionOrCodeYear from '#components/pages/Models/Model/sections/support/forms/YearOfConstructionOrCodeYear/index.js';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import BuildingTypeSelectMenu from './BuildingTypeSelectMenu/index.jsx';
import OccupancySelectMenu from './OccupancySelectMenu/index.jsx';
import SeismicitySelectMenu from './SeismicitySelectMenu/index.jsx';
import NumberOfStoriesWarning from './NumberOfStoriesWarning/index.js';

type Props = {|
  error: string,
  form: string,
  group: string,
  heading: string,
  modelId: string,
  modelType: number,
  onBack: Function,
  onNext: Function,
  handleSubmit: Function,
  submitting: boolean,
  isJapan: boolean,
  showSeismicity: boolean,
|};

const PrimaryBuildingInfoSection = (props: Props): Element<'div'> => {
  const {
    error,
    form,
    group,
    heading,
    modelId,
    modelType,
    onBack,
    onNext,
    handleSubmit,
    submitting,
    isJapan,
    showSeismicity,
  } = props;

  const buildingTypeField = (name, label) => (
    <FormGroup>
      <Label htmlFor={name}>
        {label}
      </Label>
      <BuildingTypeSelectMenu form={form} id={name} isJapan={isJapan} />
      <FieldFeedback name={name} form={form} />
    </FormGroup>
  );

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <FormGroup tag="fieldset">
          <legend>Building Information</legend>
          <Row>
            <Col lg="9">
              Structural System
              {' '}
              <Tip tipId="structuralSystem" />
            </Col>
          </Row>
          <Row>
            <Col lg="9" className="ml-3">
              {buildingTypeField('buildingTypeDir1', 'Direction 1')}
            </Col>
          </Row>
          <Row>
            <Col lg="9" className="ml-3">
              {buildingTypeField('buildingTypeDir2', 'Direction 2')}
            </Col>
          </Row>
          <FormGroup row>
            <Col lg="5">
              <YearOfConstructionOrCodeYear
                yearOfConstructionName="yearOfConstruction"
                designCodeYearName="designCodeYear"
                form={form}
              />
            </Col>
          </FormGroup>
          {showSeismicity && (
            <>
              <FormGroup row>
                <Col lg="5">
                  <Label htmlFor="seismicityDesign">
                    Design Code Level of Seismicity
                    {' '}
                    <Tip tipId="seismicityDesign" />
                  </Label>
                  <SeismicitySelectMenu form={form} name="seismicityDesign" />
                  <FieldFeedback name="seismicityDesign" form={form} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col lg="4">
                  <Label htmlFor="seismicityShaking">
                    Shaking Level of Seismicity
                    {' '}
                    <Tip tipId="seismicityShaking" />
                  </Label>
                  <SeismicitySelectMenu form={form} name="seismicityShaking" />
                  <FieldFeedback name="seismicityShaking" form={form} />
                </Col>
              </FormGroup>
            </>
          )}
          <FormGroup row className="mb-0">
            <Col lg="4">
              <Label htmlFor="numberOfStories">
                No. Stories
                {' '}
                <Tip tipId="numberOfStories" />
              </Label>
              <Field
                form={form}
                id="numberOfStories"
                name="numberOfStories"
                type="text"
                component={RenderInput}
              />
            </Col>
          </FormGroup>
          <Row className="mt-1 mb-3">
            <Col lg="12">
              { /* FieldFeedback moved for better wrapping */ }
              <FieldFeedback name="numberOfStories" form={form} />
              <NumberOfStoriesWarning form={form} />
            </Col>
          </Row>
          <FormGroup row>
            <Col lg="5">
              <Label htmlFor="occupancy">
                Occupancy
                {' '}
                <Tip tipId="occupancy" />
              </Label>
              <OccupancySelectMenu form={form} />
              <FieldFeedback name="occupancy" form={form} />
            </Col>
          </FormGroup>
        </FormGroup>
        { error && !submitting ? <Alert color="danger">{error}</Alert> : ''}
        <BackButton onClick={onBack} />
        <SubmitButton modelType={modelType} modelId={modelId} />
        <ContinueToNextSectionGroupButton
          group={group}
          modelType={modelType}
          onClick={onNext}
        />
      </Form>
    </div>
  );
};

export default PrimaryBuildingInfoSection;
