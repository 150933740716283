/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import AnalysisOptionsSection from './AnalysisOptionsSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { selectFormFieldVisibility } = modelTypesByForm[form];

  const showNumRealizations = modelTypesByForm[form].fieldsByName.numRealizations !== undefined;
  const showIncludeTimeBasedAnalysis = modelTypesByForm[form]
    .fieldsByName.includeTimeBasedAnalysis !== undefined;

  return {
    showIncludePerRealizationOutput: selectFormFieldVisibility(state)('includePerRealizationOutput'),
    showIncludeRepairTime: selectFormFieldVisibility(state)('includeRepairTime'),
    showNumRealizations,
    showIncludeTimeBasedAnalysis,
    showIncludeUSRCAnalysis: selectFormFieldVisibility(state)('includeUSRC'),
  };
};

export default connect(mapStateToProps)(AnalysisOptionsSection);
