/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import NumberOfStoriesWarning from './NumberOfStoriesWarning.jsx';

const mapStateToProps = (state, ownProps) => {
  const { selectFormValues } = modelTypesByForm[ownProps.form];
  return {
    numberOfStories: parseInt(selectFormValues(state, 'numberOfStories'), 10),
    buildingTypeDir1: selectFormValues(state, 'buildingTypeDir1'),
    buildingTypeDir2: selectFormValues(state, 'buildingTypeDir2'),
  };
};

export default connect(mapStateToProps)(NumberOfStoriesWarning);
