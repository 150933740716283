import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { selectComponentInModelsByUuid } from '#selectors/entities/components.js';
import RecoveryTime from '#components/pages/Components/Component/sections/RecoveryTime/RecoveryTime.jsx';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  return ({
    lockRequiresServiceLocations: selectComponentInModelsByUuid(state, { [UUID]: componentUuid }),
  });
};

export default connect(mapStateToProps)(RecoveryTime);
