/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Component, Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Button, Row } from 'reactstrap';
import { actionRow, actionRowButton } from './index.module.scss';

type ActionRowProps = {|
  children: Node,
  className?: string | null,
|}

export const ActionRow = ({
  children,
  className = null,
}: ActionRowProps): Element<typeof Row> => (
  <Row className={classNames(actionRow, className)}>
    {children}
  </Row>
);

type ActionRowLinkProps = {|
   children: Node,
   className?: string | null,
   onClick?: Function | null,
   tag?: Component<any> | string,
   to?: string | null,
   disabled?: boolean,
|};

export const ActionRowButton = ({
  children,
  className = null,
  onClick = null,
  tag = 'button',
  to = null,
  disabled = false,
}: ActionRowLinkProps): Element<typeof Button> => (
  <Button
    className={classNames(actionRowButton, className)}
    color="link"
    onClick={onClick}
    tag={tag}
    to={to}
    disabled={disabled}
  >
    {children}
  </Button>
);
