/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import groupBy from 'lodash/fp/groupBy.js';
import keyBy from 'lodash/fp/keyBy.js';
import {
  RWFD_EDP_KEYS,
  DEFAULT_EDP_KEYS,
  EDP_CBR,
  EDP_CR,
  EDP_LBR,
} from '@hbrisk/sp3-risk-model-support/edps/edps.js';

export const materialTypes = {
  wood: 'Wood',
  steel: 'Steel',
  reinforcedConcrete: 'Reinforced Concrete',
  reinforcedMasonry: 'Reinforced Masonry',
};

const MIN_DESIGN_CODE_YEAR = 1800;

export const buildingTypes = [
  {
    materialType: 'wood',
    buildingTypeId: 'W1a',
    buildingType: 'WLF: Single Family Dwelling',
    femaType: 'W1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2a',
    buildingType: 'WLF: General',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2b',
    buildingType: 'WLF: Over Podium of RC Walls',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2c',
    buildingType: 'WLF: Over Podium of RC Frame',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W1a_SST',
    buildingType: 'WLF: Single Family Dwelling with Simpson Strong-Tie Strong Frame SMF',
    femaType: 'W1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2a_SST',
    buildingType: 'WLF: General with Simpson Strong-Tie Strong Frame SMF',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W1aB_JPN',
    buildingType: 'WLF: Single Family Dwelling - Braced',
    femaType: 'W1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W1aO_JPN',
    buildingType: 'WLF: Single Family Dwelling - Wall',
    femaType: 'W1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2aB_JPN',
    buildingType: 'WLF: General - Braced',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2aO_JPN',
    buildingType: 'WLF: General - Wall',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2bB_JPN',
    buildingType: 'WLF: Brace Over Podium of RC Walls',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2bO_JPN',
    buildingType: 'WLF: Wall Over Podium of RC Walls',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2cB_JPN',
    buildingType: 'WLF: Brace Over Podium of RC Frame',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'wood',
    buildingTypeId: 'W2cO_JPN',
    buildingType: 'WLF: Wall Over Podium of RC Frame',
    femaType: 'W2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: true,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1a',
    buildingType: 'Steel: Perimeter Frame',
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1d',
    buildingType: 'Steel: Perimeter Frame with Simpson Strong-Tie Yield-Link Connection',
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1c',
    buildingType: 'Steel: Perimeter Frame with SidePlate Connection',
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1e',
    buildingType: 'Steel: Perimeter Frame with DuraFuse Frame Connections',
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1b',
    buildingType: 'Steel: Space Frame',
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2a',
    buildingType: 'Steel: Concentric Braced Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2b',
    buildingType: 'Steel: Eccentric Braced Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, EDP_LBR],
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2c',
    buildingType: 'Steel: BRBF - Generic FEMA P-58',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2d',
    buildingType: 'Steel: BRBF - CoreBrace without Back-up Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2e',
    buildingType: 'Steel: BRBF - CoreBrace with Back-up Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1f',
    buildingType: 'Steel: Taylor Damped Perimeter Moment Frame',
    femaType: 'S1',
    minDesignCodeYear: 2010,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: true,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1g',
    buildingType: 'Steel: Taylor Damped Space Moment Frame',
    femaType: 'S1',
    minDesignCodeYear: 2010,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: true,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2f',
    buildingType: 'Steel: CBF - Simpson Strong-Tie Yield-Link Connection, without Back-up Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: true,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2g',
    buildingType: 'Steel: CBF - Simpson Strong-Tie Yield-Link Connection, with Back-up Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: true,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S3a',
    buildingType: 'Steel: Light-Frame',
    femaType: 'S3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: true,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S3b',
    buildingType: 'Steel: Light-Frame Over Podium of RC Walls',
    femaType: 'S3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 60,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: true,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S3c',
    buildingType: 'Steel: Light-Frame Over Podium of RC Frame',
    femaType: 'S3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: true,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S4a',
    buildingType: 'Steel: RC Shear Wall with Steel Frame',
    femaType: 'S4',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, EDP_CR],
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S5a',
    buildingType: 'Steel: Perimeter Frame with Masonry Infill',
    femaType: 'S5',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: true,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S5b',
    buildingType: 'Steel: Space Frame with Masonry Infill',
    calibratedStories: 45,
    maxStories: 60,
    femaType: 'S5',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    isPodium: false,
    isInfilled: true,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1a_JPN',
    buildingType: 'Steel: Perimeter Frame',
    calibratedStories: 45,
    maxStories: 60,
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S1b_JPN',
    buildingType: 'Steel: Space Frame',
    femaType: 'S1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2a_JPN',
    buildingType: 'Steel: Concentric Braced Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2b_JPN',
    buildingType: 'Steel: Eccentric Braced Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, EDP_LBR],
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2c_JPN',
    buildingType: 'Steel: BRBF - Generic FEMA P-58',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2d_JPN',
    buildingType: 'Steel: BRBF - CoreBrace without Back-up Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S2e_JPN',
    buildingType: 'Steel: BRBF - CoreBrace with Back-up Frame',
    femaType: 'S2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S3a_JPN',
    buildingType: 'Steel: Light-Frame',
    femaType: 'S3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 15,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: true,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S3b_JPN',
    buildingType: 'Steel: Light-Frame Over Podium of RC Walls',
    femaType: 'S3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: true,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'steel',
    buildingTypeId: 'S3c_JPN',
    buildingType: 'Steel: Light-Frame Over Podium of RC Frame',
    femaType: 'S3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 7,
    maxStories: 17,
    isPodium: true,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: true,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C1a',
    buildingType: 'RC: Perimeter Frame',
    femaType: 'C1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C1b',
    buildingType: 'RC: Space Frame',
    femaType: 'C1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C2a',
    buildingType: 'RC: Cantilever Shear Wall',
    femaType: 'C2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, EDP_CR],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C2b',
    buildingType: 'RC: Coupled Shear Wall',
    femaType: 'C2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, EDP_CBR, EDP_CR],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C2c',
    buildingType: 'RC: Shear Wall with RC Frame',
    femaType: 'C2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, EDP_CR],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'PC1a',
    buildingType: 'RC: Precast Concrete Walls with Flexible Diaphragms',
    femaType: 'PC1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 4,
    maxStories: 4,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: true,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, ...RWFD_EDP_KEYS],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'PC2a',
    buildingType: 'RC: PRESS Frame',
    femaType: 'PC2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C3a',
    buildingType: 'RC: Perimeter Frame with Masonry Infill',
    femaType: 'C3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: true,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C3b',
    buildingType: 'RC: Space Frame with Masonry Infill',
    femaType: 'C3',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: true,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C1a_JPN',
    buildingType: 'RC: Perimeter Frame',
    femaType: 'C1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C1b_JPN',
    buildingType: 'RC: Space Frame',
    femaType: 'C1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C2a_JPN',
    buildingType: 'RC: Cantilever Shear Wall',
    femaType: 'C2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, EDP_CR],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C2b_JPN',
    buildingType: 'RC: Coupled Shear Wall',
    femaType: 'C2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, EDP_CBR, EDP_CR],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C2c_JPN',
    buildingType: 'RC: Shear Wall with RC Frame',
    femaType: 'C2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 95,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, EDP_CR],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'PC1a_JPN',
    buildingType: 'RC: Precast Concrete Walls with Flexible Diaphragms',
    femaType: 'PC1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 4,
    maxStories: 4,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: true,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, ...RWFD_EDP_KEYS],
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'C1c_JPN',
    femaType: 'C1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    buildingType: 'RC: Steel Reinforced Concrete Space Frame',
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedConcrete',
    buildingTypeId: 'S4a_JPN',
    buildingType: 'Steel: RC Shear Wall with Steel Frame',
    femaType: 'S4',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 45,
    maxStories: 60,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: true,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, EDP_CR],
  },
  {
    materialType: 'reinforcedMasonry',
    buildingTypeId: 'RM1a',
    buildingType: 'RM: Flexible Diaphragms',
    femaType: 'RM1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 4,
    maxStories: 4,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: true,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: false,
    edps: [...DEFAULT_EDP_KEYS, ...RWFD_EDP_KEYS],
  },
  {
    materialType: 'reinforcedMasonry',
    buildingTypeId: 'RM2a',
    buildingType: 'RM: Rigid Diaphragms',
    femaType: 'RM2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 25,
    maxStories: 25,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: true,
    isJapan: false,
    edps: DEFAULT_EDP_KEYS,
  },
  {
    materialType: 'reinforcedMasonry',
    buildingTypeId: 'RM1a_JPN',
    buildingType: 'RM: Flexible Diaphragms',
    femaType: 'RM1',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 4,
    maxStories: 4,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: true,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: false,
    isJapan: true,
    edps: [...DEFAULT_EDP_KEYS, ...RWFD_EDP_KEYS],
  },
  {
    materialType: 'reinforcedMasonry',
    buildingTypeId: 'RM2a_JPN',
    buildingType: 'RM: Rigid Diaphragms',
    femaType: 'RM2',
    minDesignCodeYear: MIN_DESIGN_CODE_YEAR,
    calibratedStories: 5,
    maxStories: 5,
    isPodium: false,
    isInfilled: false,
    isWoodLightFrame: false,
    isSteelLightFrame: false,
    isRWFD: false,
    isSimpsonYLBF: false,
    isTDMF: false,
    hasConcreteShearWalls: false,
    hasMasonryShearWalls: true,
    isJapan: true,
    edps: DEFAULT_EDP_KEYS,
  },
];

export const buildingTypesById = keyBy('buildingTypeId', buildingTypes);

export const buildingTypesByMaterial = groupBy(
  (x) => x.materialType,
  buildingTypes
);
