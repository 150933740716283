/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { SPEX_SERVICE_SUCCESS, PDF_GEN_SUCCESS } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import { OUTPUTS, PRIMARY_INPUTS, SECONDARY_INPUTS } from '../sectionGroupIds.js';
import {
  ADDITIONAL_NOTES_SECTION,
  ANALYSIS_OPTIONS_SECTION,
  REPAIR_COST_BREAKDOWN_SECTION,
  BUILDING_COMPONENTS_TABLE_SECTION,
  BUILDING_DESIGN_SECTION,
  BUILDING_LAYOUT_SECTION,
  BUILDING_MODIFIERS_CHECKLIST_SECTION,
  BUILDING_STABILITY_SECTION,
  COMPONENT_CHECKLIST_SECTION,
  DOWNLOADS_SECTION,
  GROUND_MOTION_SECTION,
  MODEL_AND_SITE_INFO_SECTION,
  PRIMARY_BUILDING_INFO_SECTION,
  REPAIR_COST_BY_INTENSITY_SECTION,
  REPAIR_COST_EXPOSURE,
  REPAIR_TIME_OPTIONS_SECTION,
  REPAIR_TIME_SECTION,
  SITE_COORDINATES_SECTION,
  STRUCTURAL_PROPERTIES_SECTION,
  STRUCTURAL_RESPONSES_SECTION,
} from '../sectionIds.js';

const sections = [
  {
    id: MODEL_AND_SITE_INFO_SECTION,
    heading: 'Model and Site Info',
    group: PRIMARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: SITE_COORDINATES_SECTION,
    heading: 'Site Coordinates',
    group: PRIMARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: PRIMARY_BUILDING_INFO_SECTION,
    heading: 'Primary Building Info',
    group: PRIMARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: ANALYSIS_OPTIONS_SECTION,
    heading: 'Analysis Options',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: BUILDING_LAYOUT_SECTION,
    heading: 'Building Layout',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: BUILDING_DESIGN_SECTION,
    heading: 'Building Design Info',
    pagerHeading: 'Building Design Information',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: GROUND_MOTION_SECTION,
    heading: 'Ground Motion and Soil Info',
    pagerHeading: 'Ground Motion and Soil Information',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    heading: 'Building Modifiers Checklist',
    pagerHeading: 'Building Modifiers Checklist',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: COMPONENT_CHECKLIST_SECTION,
    heading: 'Building Components Checklist',
    pagerHeading: 'Building Components Checklist',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
    hasSubsections: true,
  },
  {
    id: BUILDING_COMPONENTS_TABLE_SECTION,
    heading: 'Building Components Table',
    pagerHeading: 'Building Components Table',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
    hasSubsections: true,
  },
  {
    id: STRUCTURAL_PROPERTIES_SECTION,
    heading: 'Structural Properties',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: STRUCTURAL_RESPONSES_SECTION,
    heading: 'Structural Responses',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
    hasSubsections: true,
  },
  {
    id: BUILDING_STABILITY_SECTION,
    heading: 'Building Stability',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: REPAIR_TIME_OPTIONS_SECTION,
    heading: 'Recovery Time Options',
    group: SECONDARY_INPUTS,
    defaultVisible: false,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: ADDITIONAL_NOTES_SECTION,
    heading: 'Additional Notes',
    group: SECONDARY_INPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: REPAIR_COST_BY_INTENSITY_SECTION,
    heading: 'Repair Cost - by Intensity',
    group: OUTPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: REPAIR_COST_BREAKDOWN_SECTION,
    heading: 'Repair Cost - Breakdown',
    group: OUTPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: REPAIR_COST_EXPOSURE,
    heading: 'Repair Cost - Exposure',
    group: OUTPUTS,
    defaultVisible: true,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: REPAIR_TIME_SECTION,
    heading: 'Recovery Time',
    group: OUTPUTS,
    defaultVisible: false,
    desiredStatus: SPEX_SERVICE_SUCCESS,
  },
  {
    id: DOWNLOADS_SECTION,
    heading: 'Downloads',
    group: OUTPUTS,
    defaultVisible: true,
    desiredStatus: PDF_GEN_SUCCESS,
  },
];

export default sections;
