import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import InputGroupAddon from '#components/pages/Components/support/form/InputGroupAddon/InputGroupAddon.jsx';

const getAffixText = (affix, values) => (
  typeof affix === 'function'
    ? affix(values)
    : affix
);

const mapStateToProps = (state, ownProps) => {
  const { field: { prefix, suffix, form }, addonType } = ownProps;
  const values = getFormValues(form)(state);
  const affix = addonType === 'prepend' ? prefix : suffix;
  return {
    text: values ? getAffixText(affix, values) : null,
  };
};

export default connect(mapStateToProps)(InputGroupAddon);
