/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
} from 'reactstrap';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import getLocationDefaults from '#components/pages/Models/Model/sections/type2/BuildingComponentsTableSection/support/getLocationDefaults/index.js';
import type { EdpKey } from '#constants/edps/index.js';
import PerformanceGroup from './PerformanceGroup/index.js';

type Props = {|
form: string,
  autofilled: boolean,
  componentId: string,
  componentUuid: string,
  defaultEdp: EdpKey,
  fields: {
    get: Function,
    map: Function,
    push: Function,
    remove: Function,
    length: number,
  },
  meta: {
    error: string,
  }
|}

const renderPerformanceGroups = ({
  autofilled,
  fields,
  form,
  componentId,
  componentUuid,
  defaultEdp,
  meta: {
    error,
  },
}: Props): Element<'div'> => (
  <div>
    <FormGroup tag="fieldset" className="pt-2">
      <div className="pt-0">
        <Row>
          <Col>
            <span className="float-right"><Tip className="float-right" tipId="performanceGroup" /></span>
            <Button
              id="addGroupBtn"
              className="float-right pt-0 pb-0 mb-2"
              color="link"
              onClick={() => fields.push({
                locations: [getLocationDefaults()],
                edp: defaultEdp,
                name: `${componentId} #${fields.length + 1}`,
                serviceLocations: null,
              })}
              disabled={autofilled}
            >
              + Add Group
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {!error && fields.map((performanceGroup, index) => (
              <PerformanceGroup
                key={performanceGroup}
                form={form}
                autofilled={autofilled}
                componentUuid={componentUuid}
                fields={fields}
                performanceGroup={performanceGroup}
                index={index}
              />
            ))}
          </Col>
        </Row>
      </div>
      <div>
        <span className="text-danger">{error}</span>
      </div>
    </FormGroup>
  </div>
);

export default renderPerformanceGroups;
