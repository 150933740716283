/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow
import getOr from 'lodash/fp/getOr.js';
import {
  RUN_STARTED,
  SPEX_SERVICE_SUCCESS,
  SPEX_SERVICE_ERROR,
  SPEX_SERVICE_ERROR_DLQ,
  PDF_GEN_SUCCESS,
  PDF_GEN_ERROR,
  PDF_GEN_ERROR_DLQ,
} from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';

type StatusMap = {
  [string]: {
    desc: string,
    type: string,
  }
}
const statusMap: StatusMap = {
  [RUN_STARTED]: { desc: 'Running Analysis', type: 'loading' },
  [SPEX_SERVICE_SUCCESS]: { desc: 'Creating Downloads', type: 'loading' },
  [SPEX_SERVICE_ERROR]: { desc: 'Error', type: 'error' },
  [SPEX_SERVICE_ERROR_DLQ]: { desc: 'Error', type: 'error' },
  [PDF_GEN_SUCCESS]: { desc: 'Complete', type: 'success' },
  [PDF_GEN_ERROR]: { desc: 'Error', type: 'error' },
  [PDF_GEN_ERROR_DLQ]: { desc: 'Error', type: 'error' },
};

type statusFunc = (status: string) => string;

export const getStatusDesc: statusFunc = (status) => getOr('Unprocessed', `${status}.desc`, statusMap);

export const getStatusType: statusFunc = (status) => getOr('unprocessed', `${status}.type`, statusMap);
