import { componentQuestions, componentQuestionsByName } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';

const findParentName = (name) => {
  const question = componentQuestionsByName[name];
  if (question.isRoot) {
    return undefined;
  }
  const parent = componentQuestions.find((q) => q.answers.some((a) => {
    if (!a.nextQuestions) {
      return false;
    }
    return a.nextQuestions.includes(question.name);
  }));
  return parent.name;
};

export const questionsToParents = componentQuestions.reduce((acc, q) => {
  const { name } = q;
  return q.isRoot
    ? acc
    : { ...acc, [name]: findParentName(name) };
}, {});

const getParentName = (name) => questionsToParents[name];

export default getParentName;
