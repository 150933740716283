/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import {
  FETCH_APP_VERSION_RESPONSE,
} from '#constants/actionTypes.js';
import config, {
  APP_VERSION,
} from '#support/app/config.js';

const initialState = {
  version: config(APP_VERSION),
  isNewVersion: false,
};

const versionReducer = (state = initialState, action = {}) => {
  const { type, payload, error } = action;
  if (!error) {
    if (type === FETCH_APP_VERSION_RESPONSE) {
      const version = payload.commit;
      const isNewVersion = state.version !== version;
      return {
        ...state,
        ...(isNewVersion ? { isNewVersion } : {}),
        version,
      };
    }
  }
  return state;
};

export default versionReducer;
