/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { getFormMeta, getFormSyncErrors } from 'redux-form';
import get from 'lodash/fp/get.js';
import makeformFieldSelector from '#support/utility/form/makeformFieldSelector.js';
import FieldFeedback from './FieldFeedback.jsx';

const getForm = get('form');
const getName = get('name');

const getFieldMeta = makeformFieldSelector(getFormMeta);
const getFieldSyncErrors = makeformFieldSelector(getFormSyncErrors);

const mapStateToProps = (state, ownProps) => {
  const form = getForm(ownProps);
  const name = getName(ownProps);

  const meta = getFieldMeta(form, name, state) || {};
  const messages = getFieldSyncErrors(form, name, state);

  return ({
    messages,
    meta,
  });
};

export default connect(mapStateToProps)(FieldFeedback);
