import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import getComponent from '#components/support/polling/support/withPollingOnMount.jsx';
import { fetchUser } from '#actions/users/users.js';
import { selectAuthUserId } from '#selectors/entities/users.js';

const getContainer = (C) => {
  const mapStateToProps = (state) => ({
    authUserId: selectAuthUserId(state),
  });
  const mapDispatchToProps = (dispatch) => ({
    makePoll: (authUserId) => () => {
      if (authUserId) {
        const POLLING_INTERVAL = 5000;
        dispatch(fetchUser(authUserId));
        const pollingId = setInterval(() => {
          dispatch(fetchUser(authUserId));
        }, POLLING_INTERVAL);

        return () => {
          clearInterval(pollingId);
        };
      }
      return () => {};
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { authUserId } = stateProps;
    const { makePoll } = dispatchProps;
    return ({
      ...ownProps,
      poll: makePoll(authUserId),
    });
  };

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
