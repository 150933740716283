/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const costs = [
  { value: '0.5', description: 'Very Low' },
  { value: '0.75', description: 'Low' },
  { value: '1.0', description: 'Average' },
  { value: '1.25', description: 'High' },
  { value: '2.5', description: 'Very High' },
];

const items = costs.map((cost) => ({
  id: cost.value, name: cost.description, value: cost.value,
}));

const ComponentCostSelectMenu = (props: Props): Element<typeof SelectMenu> => {
  const { form, name } = props;
  return (
    <SelectMenu
      form={form}
      id={name}
      name={name}
      items={items}
      className={classNames('custom-select', 'component-cost-select')}
    />
  );
};

export default ComponentCostSelectMenu;
