/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import getOr from 'lodash/fp/getOr.js';
import { connect } from 'react-redux';
import {
  DEFAULT_EDP,
  IS_DIRECTIONAL,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { YES_NO_VALUES } from '@hbrisk/sp3-risk-model-support/components/app/attributes/values/index.js';
import { edpsByKey } from '#constants/edps/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import renderLocations from './renderLocations.jsx';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const component = selectComponentByUuid(state, { uuid: componentUuid });
  const defaultEdpKey = component ? component[DEFAULT_EDP] : null;
  const locationType = getOr(null, `${defaultEdpKey}.locationType`, edpsByKey);
  const isDirectional = component ? component[IS_DIRECTIONAL] : null;
  return ({
    edpLocationType: locationType,
    isDirectional: isDirectional === YES_NO_VALUES.YES,
  });
};
export default connect(mapStateToProps)(renderLocations);
