/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import Rollbar from 'rollbar';

const rollbarInit = (enabled, environment, accessToken) => {
  const rollbarConfig = {
    accessToken,
    captureUncaught: true,
    enabled,
    payload: {
      environment,
    },
  };
  return Rollbar.init(rollbarConfig);
};

export default rollbarInit;
