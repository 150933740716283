export const NOT = (f) => (state) => {
  const result = f(state);
  return [true, false].includes(result)
    ? !result
    : undefined;
};

export const AND = (...rest) => (state) => {
  if (rest.some((f) => (f(state) === false))) {
    return false;
  }

  if (rest.every((f) => (f(state) === true))) {
    return true;
  }

  return undefined;
};

export const OR = (...rest) => (state) => {
  if (rest.some((f) => (f(state) === true))) {
    return true;
  }
  if (rest.every((f) => (f(state) === false))) {
    return false;
  }
  return undefined;
};
