/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import ModelAndSiteInfoSection from './ModelAndSiteInfoSection.jsx';

const mapStateToProps = (state, ownProps) => ({
  country: modelTypesByForm[ownProps.form].selectFormValues(state, 'country'),
});

export default connect(mapStateToProps)(ModelAndSiteInfoSection);
