import {
  _FORM_ATTRIBUTES,
  COMPONENT_ID,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';

const calculateComponentPutPayload = ({ values }) => {
  const { [COMPONENT_ID]: componentId, ...rest } = values;
  return {
    [_FORM_ATTRIBUTES]: rest,
  };
};

export default calculateComponentPutPayload;
