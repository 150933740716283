/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import config, { GOOGLE_MAPS_API_KEY } from '#support/app/config.js';

const googleGeocodeUrl = (street, city, state, country) => {
  const streetComponent = encodeURIComponent(street);
  const cityComponent = encodeURIComponent(city);
  const stateComponent = encodeURIComponent(state);
  const countryComponent = encodeURIComponent(country);
  return `https://maps.googleapis.com/maps/api/geocode/json?address=${streetComponent},${cityComponent},${stateComponent},${countryComponent}&key=${config(GOOGLE_MAPS_API_KEY)}`;
};

export default googleGeocodeUrl;
