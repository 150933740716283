import inside from 'point-in-polygon';
import usa from './usa.js';
import japan from './japan.js';

const pointInsidePolygon = (lat, lng, polygonJSON, lngOffset = 0) => {
  // hack offset is to deal with 3D spheres to 2D plane (see spex implementation)
  const polygon = polygonJSON.map((x) => [x.lat, x.lng + lngOffset]);
  return inside([lat, lng], polygon);
};

export const coordinatesInsideUS = (lat, lng) => pointInsidePolygon(lat, lng, usa);

export const coordinatesInsideJapan = (lat, lng) => pointInsidePolygon(lat, lng, japan, 360);

export const countryIsUS = (country) => country === 'US';

export const countryIsJapan = (country) => country === 'JP';

export const countryIsUSorJapan = (country) => countryIsUS(country) || countryIsJapan(country);

export const countryIsNotUSorJapan = (country) => !countryIsUSorJapan(country);
