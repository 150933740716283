/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form,
  FormGroup,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import SiteClassSelectMenu from '#components/pages/Models/Model/sections/support/forms/SiteClassSelectMenu/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import GroundMotionMethodSelectMenu from './GroundMotionMethodSelectMenu/index.jsx';
import ReturnPeriodSelectMenu from './ReturnPeriodSelectMenu/index.js';
import GroundMotionTable from './GroundMotionTable/index.js';
import GroundMotionUploader from './GroundMotionUploader/index.js';
import SiteClassRange from './SiteClassRange/index.js';

type Props = {|
  form: string,
  heading: string,
  modelId: string,
  onBack: Function,
  onNext: Function,
  country: string,
  dbe: string,
  mce: string,
  showIncludeDesignIntensities: boolean,
  showDesignIntensities: boolean,
  shouldShowReturnPeriods: boolean,
  shouldShowReturnPeriodDEandMCE: boolean,
  shouldShowUploader: boolean,
  shouldShowGroundMotionTable: boolean,
|};

const GroundMotionSection = (props: Props): Element<'div'> => {
  const {
    heading,
    form,
    modelId,
    onBack,
    onNext,
    country,
    dbe,
    mce,
    showIncludeDesignIntensities,
    showDesignIntensities,
    shouldShowReturnPeriods,
    shouldShowReturnPeriodDEandMCE,
    shouldShowUploader,
    shouldShowGroundMotionTable,
  } = props;

  const shouldShowIntTooltip = !['JP', 'US'].includes(country);
  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        <FormGroup tag="fieldset">
          <legend>Soil Information</legend>
          <GridField
            name="siteClass"
            form={form}
            options={{
              columnSize: 4,
              labelText: 'Site Class',
              ComponentType: SiteClassSelectMenu,
              includeToolTip: true,
            }}
          />
          <SiteClassRange form={form} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>
            Ground Motion Hazard Info
            {' '}
            <Tip tipId={shouldShowIntTooltip ? 'groundMotionInt' : 'groundMotion'} />
          </legend>
          <GridField
            name="groundMotionMethod"
            form={form}
            options={{
              columnSize: 5,
              labelText: 'Source',
              ComponentType: GroundMotionMethodSelectMenu,
              componentProps: {
                country,
              },
            }}
          />
          { showIncludeDesignIntensities && (
            <GridField
              name="includeDesignIntensities"
              form={form}
              options={{
                columnSize: 6,
                labelText: 'Include Design Intensities?',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          )}
          { shouldShowReturnPeriods && (
            <FormGroup tag="fieldset">
              <legend>
                Return Periods
                {' '}
                <Tip tipId="returnPeriods" />
              </legend>
              { showDesignIntensities && (
                <div id="designIntensities" className="pt-2 pb-3">
                  <div>
                    DE :
                    <span className="pl-1 pr-1">{dbe}</span>
                    <Tip tipId="returnPeriodDE" />
                  </div>
                  <div>
                    MCE :
                    <span className="pl-1 pr-1">{mce}</span>
                    <Tip tipId="returnPeriodMCE" />
                  </div>
                </div>
              )}
              <GridField
                name="returnPeriods"
                form={form}
                options={{
                  columnSize: 6,
                  labelText: showDesignIntensities ? 'Additional Return Periods' : 'Return Periods',
                  ComponentType: 'string',
                  includeToolTip: false,
                }}
              />
            </FormGroup>
          )}
          { shouldShowUploader && (
            <GroundMotionUploader
              form={form}
              name="groundMotionUpload"
              modelId={modelId}
              accept=".csv"
              maxBytes={3e6}
            />
          )}
          { shouldShowReturnPeriodDEandMCE && (
            <div id="designIntensityInputs">
              <FormGroup tag="fieldset">
                <legend>
                  Return Periods
                  <Tip tipId="returnPeriodsForGroundMotionUpload" />
                </legend>
                <GridField
                  name="returnPeriodDE"
                  form={form}
                  options={{
                    columnSize: 3,
                    labelText: 'DE',
                    ComponentType: ReturnPeriodSelectMenu,
                    includeToolTip: true,
                    componentProps: {
                      modelId,
                    },
                  }}
                />
                <GridField
                  name="returnPeriodMCE"
                  form={form}
                  options={{
                    columnSize: 3,
                    labelText: 'MCE',
                    ComponentType: ReturnPeriodSelectMenu,
                    includeToolTip: true,
                    componentProps: {
                      modelId,
                    },
                  }}
                />
              </FormGroup>
            </div>
          )}
          { shouldShowGroundMotionTable && (
            <div className="pt-2 pb-2">
              <GroundMotionTable modelId={modelId} />
            </div>
          )}
        </FormGroup>
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default GroundMotionSection;
