/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// eslint-disable-next-line import/extensions
import { confirmResetPassword } from 'aws-amplify/auth';
import { SubmissionError } from 'redux-form';
import {
  COGNITO_LIMIT_EXCEEDED_EXCEPTION,
  COGNITO_CODE_MISMATCH_EXCEPTION,
} from '#constants/users/cognitoErrorCodes.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import ResetPasswordFormPage2 from './ResetPasswordFormPage2.jsx';
import connectPasswordResetFormPage from '../support/connectPasswordResetFormPage.jsx';

const onSubmit = (
  values,
  dispatch,
  nextPage,
  email
) => confirmResetPassword({
  username: email,
  confirmationCode: values.confirmationCode,
  newPassword: values.newPassword,
})
  .then(() => { nextPage(); })
  .catch((error) => {
    const message = [
      COGNITO_CODE_MISMATCH_EXCEPTION,
      COGNITO_LIMIT_EXCEEDED_EXCEPTION,
    ].includes(error.name)
      ? error.message
      : PROBLEM_WITH_REQUEST;
    return Promise.reject(new SubmissionError({ _error: message }));
  });

export default connectPasswordResetFormPage({
  onSubmit,
})(ResetPasswordFormPage2);
