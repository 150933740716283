/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import type { Organization } from '#types/Organization.js';
import EditOrganizationForm from './EditOrganizationForm/index.js';

type Props = {|
  fetchOrg: Function,
  organization: Organization,
|};

const EditOrganization = ({ fetchOrg, organization }: Props): null | Element<'div'> => {
  useEffect(() => {
    if (!organization) {
      fetchOrg();
    }
  }, [organization]);

  if (!organization) return null;

  return (
    <div>
      <Row>
        <Col md="12">
          <h1>Edit Organization</h1>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <EditOrganizationForm organization={organization} />
        </Col>
      </Row>
    </div>
  );
};

export default EditOrganization;
