/**
 * Owner: Haselectedton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import {
  Button, Card, CardBody, CardText, CardTitle, Col, Row,
} from 'reactstrap';
import Header from '#components/support/layout/Header/index.js';
import Main from '#components/support/layout/MainPageContainer/index.jsx';
import Page from '#components/support/layout/Page/index.js';
import { modelTypes } from '#src/js/constants/models/modelTypes/index.js';

type Props = {|
  handleCancelClick: Function,
  handleContinueClick: Function,
  modelTypes: { map: Function },
  includeHeader?: boolean,
|};

const ModelTypesPage = ({
  handleCancelClick,
  handleContinueClick,
  includeHeader = true,
}: Props): Element<typeof Page> => {
  const modelTypeOptions = modelTypes.map((type) => {
    const { description, id, name } = type;
    return (
      <Col key={id} md="6" className="col">
        <Card id={`selectType${id}ModelCard`}>
          <CardBody>
            <CardTitle><strong>{name}</strong></CardTitle>
            <CardText>
              {description}
            </CardText>
            <div>
              <Button id={`createButtonType${id}`} className="btn-continue float-right mt-3" color="primary" onClick={() => handleContinueClick(id)}>Create</Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  });
  return (
    <Page className="model-type-page">
      { includeHeader && <Header /> }
      <Main>
        <Row>
          <Col md="12" className="col">
            <h1>Choose Model Type</h1>
            <p>What type of model would you like to create?</p>
          </Col>
        </Row>
        <Row>
          {modelTypeOptions}
        </Row>
        <Row>
          <Col md="12" className="col">
            <Button className="btn-cancel mt-3" color="link" onClick={handleCancelClick}>Cancel</Button>
          </Col>
        </Row>
      </Main>
    </Page>
  );
};

export default ModelTypesPage;
