/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import React, { useState } from 'react';
import numeral from 'numeral';
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  LineMarkSeries,
  DiscreteColorLegend,
  Hint,
} from 'react-vis';
import { v4 as uuid } from 'uuid';
import { colorLegend, hint } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import CustomAxisLabel from '#components/pages/Models/Model/sections/support/plots/CustomAxisLabel.jsx';
import {
  COLOR_1, COLOR_2, COLOR_3, COLOR_4,
} from '#constants/plotColors.js';
import { ceilCoordinateValue, ceilCoordinateOfAllValues } from '#support/utility/mathFunctions.js';

type IntensityLoss = {
  pga: number,
  returnPeriod: number,
  mean: number,
  median: number,
  '90th': number,
  'sul': number,
};

type Props = {|
  totalLossData: IntensityLoss[]
|};

const Line = (points, color, setTip) => (
  <LineMarkSeries
    key={uuid()}
    color={color}
    data={points}
    onValueMouseOver={(value) => setTip(value)}
    onValueMouseOut={() => setTip(false)}
    markStyle={{ opacity: 0.8 }}
    size={4}
  />
);

const MAPPING = [
  {
    value: 'mean',
    title: 'Mean (SEL)',
    color: COLOR_1,
  },
  {
    value: 'sul',
    title: 'Fitted SUL',
    color: COLOR_2,
  },
  {
    value: 'median',
    title: 'Median',
    color: COLOR_3,
  },
  {
    value: '90th',
    title: 'Counted 90th Percentile',
    color: COLOR_4,
  },
];

const RepairCostByIntensityPlot = (props: Props): Element<'div'> => {
  const { totalLossData } = props;

  const [tipId, setTip] = useState(false);

  const mapData = (val) => totalLossData.map((item) => ({
    x: item.pga,
    y: get(`${val}`, item),
  }));

  const width = 600;
  const height = 400;

  const maxX = ceilCoordinateValue('pga', 1)(totalLossData);
  const maxY = ceilCoordinateOfAllValues(MAPPING.map((f) => f.value), 1, totalLossData);

  return (
    <div id="repairCostByIntensityPlot" style={{ position: 'relative', width: width + 50, height: height + 50 }}>
      <XYPlot
        width={width}
        height={height}
        xDomain={[0, maxX]}
        yDomain={[0, maxY]}
        onMouseLeave={() => setTip(false)}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        { MAPPING.map((item) => Line(mapData(item.value), item.color, setTip)) }

        {tipId ? (
          <Hint value={tipId} className={hint}>
            <div>
              <div>
                PGA:
                {numeral(tipId.x).format('0.00')}
              </div>
              <div>
                Loss Ratio:
                {numeral(tipId.y).format('0%')}
              </div>
            </div>
          </Hint>
        ) : null}

        <DiscreteColorLegend
          className={colorLegend}
          color="grey"
          width={200}
          items={MAPPING.map(({ title, color }) => ({ title, color, strokeWidth: 12 }))}
          style={{
            position: 'absolute',
            top: '15px',
            left: `${width + 25}px`,
          }}
        />
      </XYPlot>
      <CustomAxisLabel title="Normalized Loss Ratio" innerHeight={height} innerWidth={width} />
      <CustomAxisLabel title="PGA [g]" xAxis innerHeight={height} innerWidth={width} />
    </div>
  );
};

export default RepairCostByIntensityPlot;
