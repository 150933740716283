/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { download as downloadObject } from '#actions/models/downloads.js';
import DownloadsListItem from './DownloadsListItem.jsx';

const mapDispatchToProps = (dispatch, ownProps) => {
  const { download } = ownProps;
  return ({
    handleClick: () => {
      dispatch(downloadObject(download));
    },
  });
};

export default connect(undefined, mapDispatchToProps)(DownloadsListItem);
