import { connect } from 'react-redux';
import get from 'lodash/fp/get.js';
import { getFormValues } from 'redux-form';
import SiteClassRange from './SiteClassRange.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;

  return ({
    siteClass: get('siteClass', getFormValues(form)(state)),
  });
};

export default connect(mapStateToProps)(SiteClassRange);
