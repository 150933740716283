import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import Snippet from '#components/pages/Components/Component/SubNav/Snippet/Snippet.jsx';
import { selectComponentIsReadOnlyByUuid } from '#selectors/entities/components.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const mapStateToProps = (state, ownProps) => {
  const { [COMPONENT_UUID]: uuid } = ownProps;
  return {
    show: !selectComponentIsReadOnlyByUuid(state, { [UUID]: uuid }),
  };
};

export default connect(mapStateToProps)(Snippet);
