/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { createSelector } from 'reselect';

const selectModelsTable = (state) => state.ui.modelsTable;

export const selectModelsTableFilters = createSelector(
  selectModelsTable,
  (table) => table.filters,
);

export const selectModelsTableSorted = createSelector(
  selectModelsTable,
  (table) => table.sorted,
);
