/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import PrimaryBuildingInfoSection from './PrimaryBuildingInfoSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { selectIsCountryJapan, selectFormFieldVisibility } = modelTypesByForm[ownProps.form];
  return {
    isJapan: selectIsCountryJapan(state),
    showSeismicity: selectFormFieldVisibility(state)('seismicityDesign'),
  };
};

export default connect(mapStateToProps)(PrimaryBuildingInfoSection);
