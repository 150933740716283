/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectRunExposureBreakdown,
  selectRunExposureBreakdownColumnsOrdered,
} from '#selectors/entities/models.js';
import { MODEL_TYPE_1_ID } from '#src/js/constants/models/modelTypes/index.js';
import RepairCostExposureTable from './RepairCostExposureTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const dataByGroup = selectRunExposureBreakdown(state, ownProps);
  const columnsOrdered = selectRunExposureBreakdownColumnsOrdered(state, ownProps);
  const { modelType } = ownProps;

  return {
    showCostModifier: modelType === MODEL_TYPE_1_ID,
    dataByGroup,
    columnsOrdered,
  };
};

export default connect(mapStateToProps)(RepairCostExposureTable);
