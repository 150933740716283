// @flow
import type { ComponentType } from 'react';

import React from 'react';
import { reduxForm } from 'redux-form';
import { RESET_PASSWORD_FORM } from '#constants/users/form/resetPassword/name.js';

type Options = {
  onSubmit: Function,
};

type Props = {|
  email: | null,
  error?: string | null,
  form: string,
  nextPage: Function,
  submitting: boolean,
  setEmail: Function | null,
|};

type ConnectPasswordResetFormPage = (Options) => (ComponentType<Props>) => ComponentType<Props>

const connectPasswordResetFormPage: ConnectPasswordResetFormPage = (
  options
) => (FormPage) => ({
  email,
  error = null,
  form,
  nextPage,
  setEmail,
  submitting,
}: Props) => {
  const ConnectedFormPage = (
    reduxForm({
      onSubmit: (values, dispatch) => options.onSubmit(
        values,
        dispatch,
        nextPage,
        email,
        setEmail
      ),
      form: RESET_PASSWORD_FORM,
      forceUnregisterOnUnmount: true,
    })(FormPage)
  );
  return (
    <ConnectedFormPage
      error={error}
      form={form}
      submitting={submitting}
    />
  );
};

export default connectPasswordResetFormPage;
