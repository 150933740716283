/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { SPEX_SERVICE_SUCCESS } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import { statusReached } from '@hbrisk/sp3-risk-model-support/models/runStatusHelpers.js';
import { withProgressCardFunctionality } from '#components/pages/Models/Model/support/Router/withSectionFunctionality/withSectionLevelStatusIndication/support/ProgressCard/index.js';
import RunProgressCard from './RunProgressCard.jsx';

const calculatePercentComplete = (desiredStatus, status, elapsed) => {
  if (statusReached(status, desiredStatus)) {
    return 100;
  }
  const lower = 1;
  const upper = 98;
  const next = Math.round(elapsed / 250) + lower;
  return (next < upper) ? Math.max(next, lower) : upper - 1;
};

const calculateProgressText = (percentComplete) => {
  if (percentComplete >= 25 && percentComplete < 100) {
    return 'Updating and running model ...';
  }
  if (percentComplete === 100) {
    return 'Model complete ...';
  }
  return '';
};

export default withProgressCardFunctionality({
  calculatePercentComplete,
  calculateProgressText,
  desiredStatus: SPEX_SERVICE_SUCCESS,
})(RunProgressCard);
