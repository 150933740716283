import { touch } from 'redux-form';
import calculateFieldArrayFieldNames from '#support/utility/form/calculateFieldArrayFieldNames/index.js';

export const touchAll = (fields, form, values) => {
  const toTouch = fields.reduce(
    (acc, field) => {
      const { itemSchema, name, type } = field;
      return type === 'FieldArray'
        ? [...acc, ...calculateFieldArrayFieldNames(name, itemSchema, values)]
        : [...acc, name];
    },
    []
  );
  return touch(form, ...toTouch);
};
