/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { fetchOrgs } from '#actions/organizations/organizations.js';
import { selectOrgs } from '#selectors/entities/organizations.js';
import OrgSelectMenu from './OrgSelectMenu.jsx';

const mapStateToProps = (state) => ({
  orgs: selectOrgs(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs),
});

const OrgsSelectMenuContainer = connect(mapStateToProps, mapDispatchToProps)(OrgSelectMenu);

export default OrgsSelectMenuContainer;
