/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';

type Props = {|
  form: string,
  name: string,
|};

const optionsList = [
  { value: 'ordinary', description: 'Ordinary' },
  { value: 'intermediate', description: 'Intermediate' },
  { value: 'special', description: 'Special' },
];

const BuildingDetailLevelSelectMenu = (props: Props): Element<typeof Field> => {
  const { form, name } = props;

  const options = optionsList.map((item) => (
    <option
      key={item.value}
      value={item.value}
    >
      {item.description}
    </option>
  ));
  return (
    <Field
      form={form}
      id={name}
      name={name}
      type="select"
      component={RenderInput}
      inputClassName={classNames('custom-select', 'building-detail-level-select')}
    >
      <option />
      {options}
    </Field>
  );
};

export default BuildingDetailLevelSelectMenu;
