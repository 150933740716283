import { connect } from 'react-redux';
import Field from '#components/pages/Components/support/form/Field/Field.jsx';
import getUnqualifiedFiledName
  from '#support/components/form/getUnqualifiedFieldName.js';
import { selectFieldIsVisible, selectFieldValue } from '#selectors/ui/form/component.js';

const mapStateToProps = (state, ownProps) => {
  const { item, field } = ownProps;
  const { name } = field;
  const unqualifiedName = getUnqualifiedFiledName(item, name);
  return {
    unqualifiedName,
    value: selectFieldValue(state, { name: unqualifiedName }),
    visible: selectFieldIsVisible(state, { name: unqualifiedName }),
  };
};
export default connect(mapStateToProps)(Field);
