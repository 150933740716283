/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import structuralResponseMethods from '#constants/models/structuralResponseMethods/index.js';

type Props = {|
  exclude?: Array<string>,
  form: string,
  name: string,
|};

const allMethods = structuralResponseMethods.map(({ id, name }) => ({
  id,
  name,
  value: id,
}));

const StructuralResponseSourceSelectMenu = ({
  exclude = [],
  form,
  name,
}: Props): Element<typeof SelectMenu> => {
  const methods = allMethods.filter((m) => !exclude.includes(m.id));
  return (
    <SelectMenu
      form={form}
      items={methods}
      id={name}
      name={name}
      includeEmptyOption
    />
  );
};

export default StructuralResponseSourceSelectMenu;
