/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import isWoodLightFrame from '#support/models/buildingType/isWoodLightFrame.js';
import isSteelLightFrame from '#support/models/buildingType/isSteelLightFrame.js';

const isWoodOrSteelLightFrame = (buildingType) => (
  isWoodLightFrame(buildingType)
  || isSteelLightFrame(buildingType)
);

export default isWoodOrSteelLightFrame;
