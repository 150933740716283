// @flow
import type { Element } from 'react';
import React from 'react';
import { ActionRowButton } from '#components/support/layout/ActionRow/index.jsx';

type Props = {
  showArchive: boolean,
  toggleArchived: Function,
  toggling: boolean,
};

const ArchiveComponentButton = ({
  showArchive, toggleArchived, toggling,
}: Props): Element<typeof ActionRowButton | 'span'> => {
  const text = showArchive ? 'Archive' : 'Restore';
  return <ActionRowButton tag="a" disabled={toggling} onClick={toggleArchived}>{text}</ActionRowButton>;
};

export default ArchiveComponentButton;
