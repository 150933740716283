import { connect } from 'react-redux';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import ComponentSummary from './ComponentSummary.jsx';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const component = selectComponentByUuid(
    state,
    { uuid: componentUuid, options: { includeFormInputs: true } }
  );
  return {
    component,
  };
};

export default connect(mapStateToProps)(ComponentSummary);
