// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  DAMAGE_STATES,
  CAPACITY_UNIT,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { fieldsByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import {
  Fieldset, Field, FormGroup, Col,
} from '#components/pages/Components/support/form/index.js';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import renderDamageSatesTable from '#components/pages/Components/Component/sections/DamageStates/renderDamageSatesTable/index.jsx';

const DamageStates = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form noValidate>
    <Fieldset>
      <legend>Damage States</legend>
      <Field
        field={fieldsByName[DAMAGE_STATES]}
        render={renderDamageSatesTable}
        readOnly={readOnly}
        excludeLabel
        excludeInputGroup
      />
    </Fieldset>
    <Fieldset>
      <legend>Capacity</legend>
      <FormGroup fields={[CAPACITY_UNIT]} row>
        <Col lg="6">
          <Field field={fieldsByName[CAPACITY_UNIT]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default DamageStates;
