/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import kebabCase from 'lodash/fp/kebabCase.js';
import fileDownload from 'js-file-download';
import {
  FETCH_ACTION,
  FETCH_DOWNLOAD_BLOB_REQUEST,
  FETCH_DOWNLOAD_BLOB_RESPONSE,
  FETCH_DOWNLOAD_URL_REQUEST,
  FETCH_DOWNLOAD_URL_RESPONSE,
} from '#constants/actionTypes.js';

const fetchDownloadUrl = (endpoint) => ({
  [FETCH_ACTION]: {
    types: [FETCH_DOWNLOAD_URL_REQUEST, FETCH_DOWNLOAD_URL_RESPONSE],
    endpoint,
    dataTransform: (json) => ({ url: json.url }),
  },
});

const fetchDownloadBlob = (endpoint) => ({
  [FETCH_ACTION]: {
    types: [FETCH_DOWNLOAD_BLOB_REQUEST, FETCH_DOWNLOAD_BLOB_RESPONSE],
    withAuth: false,
    endpoint,
    dataTransform: (blob) => ({ blob }),
  },
});

export const download = (file) => (dispatch) => {
  const { path, name, extension } = file;
  dispatch(fetchDownloadUrl(path))
    .then((a) => dispatch(fetchDownloadBlob(a.payload.url)))
    .then((a) => fileDownload(a.payload.blob, `${kebabCase(name)}.${extension}`));
};
