/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { selectIsMaintenanceMode } from '#selectors/ui/app.js';
import { selectAuthUserTokenIsSet } from '#selectors/entities/users.js';
import RequireAuthentication from './RequireAuthentication.jsx';

const mapStateToProps = (state) => ({
  isAuthenticated: selectAuthUserTokenIsSet(state),
  isMaintenanceMode: selectIsMaintenanceMode(state),
});

export default connect(mapStateToProps)(RequireAuthentication);
