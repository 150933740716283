// @flow
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import type { Element, Node } from 'react';

import { breadCrumb } from './index.module.scss';

type Props = {
  children: Node,
  className?: string | null,
  to?: string | null,
};

const BreadCrumb = ({ children, className = null, to = null }: Props): Element<typeof Link> | Element<'span'> => {
  const classes = classNames(breadCrumb, 'bread-crumb', className, 'text-truncate');
  const style = { display: 'inline-block', maxWidth: '300px' };
  return (
    to
      ? <Link style={style} className={classes} to={to}>{children}</Link>
      : <span style={style} className={classes}>{children}</span>
  );
};

export default BreadCrumb;
