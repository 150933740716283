/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { fetchModel, postModel } from '#actions/models/model.js';
import withNavigate from '#components/support/routing/withNavigate/index.jsx';
import {
  selectAuthUserHasPerRealizationAccess,
  selectAuthUserHasRecoveryTimeAccess,
  selectAuthUserId,
  selectAuthUserOrgId,
  selectAuthUserPermissions,
} from '#selectors/entities/users.js';
import calculateModelPostPayload from '#src/js/support/models/calculateNewModelPostPayload/index.js';
import { MODELS } from '#constants/pathStrings.js';
import ModelTypesPage from './ModelTypesPage.jsx';

const mapStateToProps = (state) => ({
  makePerRealizationOptionVisible: selectAuthUserHasPerRealizationAccess(state),
  makeIncludeRepairTimeOptionVisible: selectAuthUserHasRecoveryTimeAccess(state),
  userId: selectAuthUserId(state),
  permissions: selectAuthUserPermissions(state),
  orgId: selectAuthUserOrgId(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { navigate } = ownProps;

  return ({
    handleCancelClick: () => {
      navigate(`/${MODELS}`);
    },
    getHandleContinueClick: (
      permissions,
      userId,
      orgId
    ) => (type) => (
      dispatch(postModel(calculateModelPostPayload(
        type,
        userId,
        orgId,
        permissions,
      )))
        .then((data) => dispatch(fetchModel(data.payload.uuid)))
        .then((data) => {
          navigate(`../${data.payload.uuid}`);
        })
    ),
  });
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    permissions,
    userId,
    orgId,
  } = stateProps;
  const { getHandleContinueClick } = dispatchProps;
  return {
    ...dispatchProps,
    handleContinueClick: getHandleContinueClick(
      permissions,
      userId,
      orgId
    ),
    ...ownProps,
  };
};

export default pipe(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withNavigate,
)(ModelTypesPage);
