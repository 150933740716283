/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import buildingDesignRiskCategories from '#constants/models/buildingDesignRiskCategories.js';

type Props = {|
  form: string,
  name: string,
  onChange: Function,
|};

const RiskCategorySelectMenu = ({ form, name, onChange }: Props): Element<typeof Field> => {
  const riskCategoryOptions = buildingDesignRiskCategories.map((riskCategory) => (
    <option
      key={riskCategory.riskCategoryDesc}
      value={riskCategory.riskCategory}
    >
      {riskCategory.riskCategoryDesc}
    </option>
  ));
  return (
    <Field
      form={form}
      id={name}
      name={name}
      type="select"
      component={RenderInput}
      inputClassName={classNames('custom-select', 'risk-category-select')}
      onChange={onChange}
    >
      <option />
      {riskCategoryOptions}
    </Field>
  );
};

export default RiskCategorySelectMenu;
