import pipe from 'lodash/fp/pipe.js';
import withAuthUserPolling from '#components/support/polling/withAuthUserPolling/index.js';
import withAppMaintenanceModePolling from '#components/support/polling/withAppMaintenanceModePolling/index.js';
import withAppVersionPolling from '#components/support/polling/withAppVersionPolling/index.js';
import withOrgsPolling from '#components/support/polling/withOrgsPolling/index.js';
import withUsersPolling from '#components/support/polling/withUsersPolling/index.js';
import Admin from './Admin.jsx';

export default pipe(
  withAppMaintenanceModePolling,
  withAppVersionPolling,
  withAuthUserPolling,
  withOrgsPolling,
  withUsersPolling
)(Admin);
