/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { combineReducers } from 'redux';
import all from './all.js';
import byId from './byId.js';

export default combineReducers({
  all,
  byId,
});
