// @flow
import type { Element } from 'react';

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '#components/pages/Components/Dashboard/index.jsx';
import Component from '#components/pages/Components/Component/index.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const Components = (): Element<typeof Routes> => (
  <Routes>
    <Route path="/" element={<Dashboard includeHeader={false} />} />
    <Route path={`:${COMPONENT_UUID}/`} element={<Dashboard includeHeader={false} />} />
    <Route path={`:${COMPONENT_UUID}/*`} element={<Component includeHeader={false} />} />
    <Route path="*" element={<Dashboard includeHeader={false} />} />
  </Routes>
);

export default Components;
