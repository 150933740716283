/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import isEqual from 'lodash/fp/isEqual.js';
import { SIGN_OUT_USER } from '#constants/actionTypes.js';
import byId from './byId.js';
import current from './current.js';
import meta from './meta/index.js';

const initialState = {
  byId: {},
  current: null,
  meta: {
    byId: {},
    all: {},
  },
};

const models = (state = initialState, action = {}) => {
  const { type } = action;
  if (type === SIGN_OUT_USER) {
    return initialState;
  }
  const nextState = {
    byId: byId(state, action),
    current: current(state, action),
    meta: meta(state.meta, action),
  };
  return isEqual(state, nextState)
    ? state
    : nextState;
};
export default models;
/* eslint-disable default-param-last */
/* eslint-enable default-param-last */
