/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';

const currentTerms = (state) => state.entities.terms.current;

export const selectCurrentTermsId = createSelector(
  currentTerms,
  (current) => get('uuid', current),
);

export const selectCurrentTermsContent = createSelector(
  currentTerms,
  (current) => get('content', current),
);
