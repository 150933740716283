/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { signOutUser } from '#actions/users/users.js';
import SignOutLink from './SignOutLink.jsx';

const mapDispatchToProps = (dispatch) => ({
  handleClick: (e) => {
    e.preventDefault();
    dispatch(signOutUser());
  },
});

export default connect(undefined, mapDispatchToProps)(SignOutLink);
