// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import getSiteClassRange from '#support/models/getSiteClassRange.js';

type Props = {|
  siteClass: string,
|};

const SiteClassRange = (props: Props): Element<typeof Row> => {
  const { siteClass } = props;
  const text = siteClass !== undefined
    ? (
      <div className="pt-2 pb-3">
        Vs30 Range:
        {' '}
        {getSiteClassRange(siteClass)}
        {' '}
        <Tip tipId="vs30" />
      </div>
    )
    : null;
  return (
    <Row>
      <Col lg="4">
        {text}
      </Col>
    </Row>
  );
};

export default SiteClassRange;
