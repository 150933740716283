/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import Router from '#components/pages/Models/Model/support/Router/Router.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelType } = ownProps;
  const {
    selectActiveSectionGroups,
    selectActiveSectionsInActiveGroups,
    modelFormName,
  } = modelTypesById[modelType];

  return ({
    sections: selectActiveSectionsInActiveGroups(state),
    sectionGroups: selectActiveSectionGroups(state),
    modelFormName,
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelType } = ownProps;
  const { modelFormName } = modelTypesById[modelType];
  return ({
    handleUnmount: () => {
      dispatch(destroy(modelFormName));
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
