/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/fp/debounce.js';
import isEqual from 'lodash/fp/isEqual.js';
import omit from 'lodash/fp/omit.js';
import TreeView from '@material-ui/lab/TreeView/index.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore.js';
import ChevronRightIcon from '@material-ui/icons/ChevronRight.js';
import { pick } from 'ramda';
import React, { useState, useEffect, memo } from 'react';
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { COMPONENT_ID, UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import renderTreeItems from '#components/pages/Components/support/ComponentSelector/renderTreeItems.jsx';
import { dropDownButton, treeViewClass, includeArchivedGroup } from '#components/pages/Components/support/ComponentSelector/index.module.scss';

const areEqual = (prevProps, nextProps) => {
  const prev = pick(['expanded', 'selected', 'componentTreeData'], prevProps);
  const next = pick(['expanded', 'selected', 'componentTreeData'], nextProps);
  const is = isEqual(prev, next);
  return is;
};
/* eslint-disable react/jsx-props-no-spreading */
const MemoizedTreeView = memo((props) => (
  <TreeView {...omit(['componentTreeData'], props)} />
), areEqual);
/* eslint-enable react/jsx-props-no-spreading */

type Props = {|
  categories: Array<Object>,
  collapsable: boolean,
  components: Array<Object>,
  expandAllNodes: Function,
  expandedNodes: Array<string>,
  filters: Object,
  onSelect: Function,
  selected: Object,
  setExpandedNodes: Function,
  setFilters: Function,
  setTreeData: Function,
  showIncludeArchivedToggle: boolean,
  treeData: Object,
  additionalTreeViewClasses?: string | null,
|};

const ComponentSelector = ({
  categories,
  collapsable,
  components,
  expandAllNodes,
  expandedNodes,
  filters,
  onSelect,
  selected,
  setExpandedNodes,
  setFilters,
  setTreeData,
  showIncludeArchivedToggle,
  treeData,
  additionalTreeViewClasses = null,
}: Props): Element<typeof FormGroup> => {
  const [treeVisible, setTreeVisible] = useState(!selected);
  const [searchText, setSearchText] = useState('');
  const [includeArchived, setIncludeArchived] = useState(false);

  useEffect(() => {
    setSearchText(filters.searchText);
    setIncludeArchived(filters.includeArchived);
  }, []);

  useEffect(() => {
    setTreeData();
  }, [filters, components, categories]);

  const search = (val) => {
    expandAllNodes();
    setFilters({ searchText: val });
  };

  const debouncedSearch = debounce(500, search);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncedSearch(value);
  };

  const handleIncludeArchivedChange = (e) => {
    const { checked } = e.target;
    setIncludeArchived(checked);
    setFilters({ includeArchived: checked });
  };

  const handleExpandAll = () => {
    expandAllNodes();
  };

  const handleCollapseAll = () => {
    setExpandedNodes([]);
  };

  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleSelect = (event, nodeId) => {
    if (!treeData.expandableNodes.includes(nodeId)) {
      onSelect(nodeId);
      setTreeVisible(false);
    }
  };

  const baseItem = {
    category: 'Components',
    label: 'Components',
    className: 'hidden',
    children: treeData.tree,
  };

  const baseComponentSelector = (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Input
              id="searchBox"
              name="searchBox"
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="7" md="12" xl="7">
            <Button id="expandAll" color="link" onClick={handleExpandAll}>
              Expand All
            </Button>
            <Button id="collapseAll" color="link" onClick={handleCollapseAll}>
              Collapse All
            </Button>
          </Col>
          {
            showIncludeArchivedToggle
            && (
              <Col size="auto">
                <FormGroup className={includeArchivedGroup}>
                  <Input
                    id="includeArchived"
                    name="includeArchived"
                    type="checkbox"
                    checked={includeArchived}
                    onChange={handleIncludeArchivedChange}
                  />
                  <Label for="includeArchived">Include Archived?</Label>
                </FormGroup>
              </Col>
            )
          }
        </Row>
        <Row>
          <Col>
            <MemoizedTreeView
              componentTreeData={treeData}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expandedNodes}
              selected={selected ? selected[UUID] : ''}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              className={classNames(treeViewClass, additionalTreeViewClasses)}
            >
              {renderTreeItems(baseItem, true)}
            </MemoizedTreeView>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const toggleTreeVisible = () => setTreeVisible(!treeVisible);
  const collapsableComponentSelector = (
    <>
      <Input
        id="treeToggle"
        name="treeToggle"
        type="text"
        className={`custom-select text-left ${dropDownButton}`}
        readOnly
        onClick={toggleTreeVisible}
        role="button"
        value={selected ? selected[COMPONENT_ID] : ''}
      />
      <Collapse
        isOpen={treeVisible}
      >
        {baseComponentSelector}
      </Collapse>
    </>
  );

  return (
    <FormGroup>
      <div>
        <Label for="treeToggle">Select a Component</Label>
      </div>
      <div>
        {
          collapsable
            ? collapsableComponentSelector
            : baseComponentSelector
        }
      </div>
    </FormGroup>
  );
};

export default ComponentSelector;
