import damageStateNameFromIndex from '#support/components/damageStateNameFromIndex.js';

const consequenceNameFromIndexes = (i, j, hasSiblings) => {
  const ds = damageStateNameFromIndex(i);
  return hasSiblings
    ? `${ds}${String.fromCharCode(97 + j)}`
    : ds;
};

export default consequenceNameFromIndexes;
