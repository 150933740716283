/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import RequireAuthorization from '#components/support/routing/RequireAuthorization/index.jsx';
import { SIGN_IN } from '#constants/pathStrings.js';
import RequireMaintenanceOff from '#components/support/routing/RequireMaintenanceOff/index.js';

type Props = {
  children: Node,
  isAuthenticated: boolean,
};

const RequireAuthentication = ({
  children,
  isAuthenticated,
}: Props): Element<typeof RequireAuthorization> => (
  <RequireMaintenanceOff>
    <RequireAuthorization
      isAuthorized={isAuthenticated}
      redirectTo={`/${SIGN_IN}`}
    >
      {children}
    </RequireAuthorization>
  </RequireMaintenanceOff>
);

export default RequireAuthentication;
