import { reduxForm, SubmissionError } from 'redux-form';
import { SELECT_AT_LEAST_ONE_MODEL_TYPE } from '@hbrisk/sp3-risk-model-support/models/errorMessages.js';
import { postOrganization } from '#actions/organizations/organizations.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import calculateOrgRequestBody from '#support/organizations/calculateOrgRequestBody.js';
import { CREATE_ORGANIZATION } from '#constants/organizations/form/createOrganization/name.js';
import CreateOrganizationForm from './CreateOrganizationForm.jsx';

const onSubmit = (values, dispatch) => {
  if (!values.standard && !values.advanced) {
    return Promise.reject(new SubmissionError({ _error: SELECT_AT_LEAST_ONE_MODEL_TYPE }));
  }
  return dispatch(postOrganization(calculateOrgRequestBody(values)))
    .catch(() => Promise.reject(new SubmissionError({ _error: PROBLEM_WITH_REQUEST })));
};

export default reduxForm({
  onSubmit,
  form: CREATE_ORGANIZATION,
})(CreateOrganizationForm);
