/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import _methods, { methodById as _methodById } from '#constants/models/structuralResponseMethods/structuralResponseMethods.js';

export type StructuralResponseMethodId = 'sp3' | 'asce722' | 'rha' | 'medians';

type StructuralResponseMethod = {
  id: StructuralResponseMethodId,
  name: string,
};

const methods: Array<StructuralResponseMethod> = _methods;

type StructuralResponseMethodsById = {
  [id: StructuralResponseMethodId]: StructuralResponseMethod,
};
export const methodById: StructuralResponseMethodsById = _methodById;

export default methods;
