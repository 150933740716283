export const ADD = 'add';
export const ADMIN = 'admin';
export const COMPONENT = 'component';
export const COMPONENT_UUID = 'componentUuid';
export const COMPONENTS = 'components';
export const CAPACITY_CALCULATION = 'capacity-calculation';
export const COMPONENT_GROUPING = 'component-grouping';
export const CONSEQUENCES = 'consequences';
export const DAMAGE_STATES = 'damage-states';
export const EDP_KEY = 'edpKey';
export const GENERAL = 'general';
export const FRAGILITY_INFO = 'fragility-info';
export const CREATE = 'create';
export const DEBUG = 'debug';
export const EDIT = 'edit';
export const LIFE_SAFETY = 'life-safety';
export const MAINTENANCE = 'maintenance';
export const MODEL_ID = 'modelId';
export const MODELS = 'models';
export const MODEL_TYPES = 'model-types';
export const ORGANIZATION = 'organization';
export const OTHER = 'other';
export const RESET_PASSWORD = 'reset-password';
export const SIGN_IN = 'sign-in';
export const ORG_ID = 'orgId';
export const OUTPUTS = 'outputs';
export const RECOVERY_TIME = 'recovery-time';
export const REPAIR_COST = 'repair-cost';
export const REPAIR_TIME = 'repair-time';
export const REPAIR_COST_BY_INTENSITY = 'repair-cost-by-intensity';
export const RESPONSE_INDEX = 'responseIndex';
export const SYSTEM_SPECIFIC_INFO = 'system-specific-info';
export const USER = 'user';
export const USER_ID = 'userId';
export const WINDOW = 'window';
export const MODEL_USAGE = 'model-usage';
