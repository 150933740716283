// @flow
import type { Element } from 'react';

import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { formatWithCommas, normalizeWithCommas } from '@hbrisk/sp3-risk-model-support/utility/form/withCommas.js';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
  showIncludeRepairTime: boolean,
  showIncludePerRealizationOutput: boolean,
  showNumRealizations: boolean,
  showIncludeTimeBasedAnalysis: boolean,
  showIncludeUSRCAnalysis: boolean,
|};

const AnalysisOptionsSection = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    onBack,
    onNext,
    showIncludeRepairTime,
    showIncludePerRealizationOutput,
    showNumRealizations,
    showIncludeTimeBasedAnalysis,
    showIncludeUSRCAnalysis,
  } = props;

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        { showIncludeRepairTime && (
          <FormGroup tag="fieldset">
            <legend>Upgrade Options</legend>
            <GridField
              name="includeRepairTime"
              form={form}
              options={{
                columnSize: 4,
                labelText: 'Include Recovery Time?',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          </FormGroup>
        )}
        <FormGroup tag="fieldset">
          <legend>General Options</legend>
          { showNumRealizations && (
            <GridField
              name="numRealizations"
              form={form}
              options={{
                columnSize: 4,
                labelText: 'Number of Earthquake Simulations',
                ComponentType: 'text',
                includeToolTip: true,
                format: formatWithCommas,
                normalize: normalizeWithCommas,
              }}
            />
          )}
          { showIncludePerRealizationOutput && (
            <GridField
              name="includePerRealizationOutput"
              form={form}
              options={{
                columnSize: 6,
                labelText: 'Include Per-Realization Outputs?',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          )}
          <GridField
            name="includeCollapse"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Include Collapse?',
              ComponentType: 'checkbox',
              includeToolTip: true,
            }}
          />
          <GridField
            name="includeResidualDrift"
            form={form}
            options={{
              columnSize: 9,
              labelText: 'Include Residual Drift Demolition?',
              ComponentType: 'checkbox',
              includeToolTip: true,
            }}
          />
          { showIncludeUSRCAnalysis && (
            <GridField
              name="includeUSRC"
              form={form}
              options={{
                columnSize: 6,
                labelText: 'Include a USRC Rating?',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          )}
          { showIncludeTimeBasedAnalysis && (
            <GridField
              name="includeTimeBasedAnalysis"
              form={form}
              options={{
                columnSize: 6,
                labelText: 'Include Time-Based Analysis?',
                ComponentType: 'checkbox',
                includeToolTip: true,
              }}
            />
          )}
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Cost Multipliers</legend>
          <GridField
            name="regionCostMultiplier"
            form={form}
            options={{
              columnSize: 4,
              labelText: 'Region Cost Multiplier',
              ComponentType: 'text',
              includeToolTip: true,
            }}
          />
          <GridField
            name="dateCostMultiplier"
            form={form}
            options={{
              columnSize: 4,
              labelText: 'Date Cost Multiplier',
              ComponentType: 'text',
              includeToolTip: true,
            }}
          />
          <GridField
            name="occupancyCostMultiplier"
            form={form}
            options={{
              columnSize: 4,
              labelText: 'Occupancy Cost Multiplier',
              ComponentType: 'text',
              includeToolTip: true,
            }}
          />
        </FormGroup>
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default AnalysisOptionsSection;
