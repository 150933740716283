/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { PRIMARY_INPUTS, SECONDARY_INPUTS, OUTPUTS } from '../sectionGroupIds.js';

export default [
  {
    id: PRIMARY_INPUTS,
    heading: 'Primary Inputs',
    defaultVisible: true,
  },
  {
    id: SECONDARY_INPUTS,
    heading: 'Risk Analysis Details',
    defaultVisible: true,
  },
  {
    id: OUTPUTS,
    heading: 'Risk Analysis Results',
    defaultVisible: false,
  },
];
