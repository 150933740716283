/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import isUndefined from 'lodash/fp/isUndefined.js';

import { connect } from 'react-redux';
import { arrayPush } from 'redux-form';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import FloorPlanFieldSet from './FloorPlanFieldset.jsx';

const floorPlanFieldArrayName = 'floorPlanByStory';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const { fieldsByName, selectFormFieldVisibility } = modelTypesByForm[form];
  const modelTypeSupportsDefineByStory = !isUndefined(get(floorPlanFieldArrayName, fieldsByName));
  return {
    defineByStory:
      modelTypeSupportsDefineByStory
      && !!selectFormFieldVisibility(state)(floorPlanFieldArrayName),
    supportDefineByStory: modelTypeSupportsDefineByStory,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { form } = ownProps;
  const { fieldsByName } = modelTypesByForm[form];
  const modelTypeSupportsDefineByStory = !isUndefined(get(floorPlanFieldArrayName, fieldsByName));
  return {
    afterToggleOn: () => {
      if (modelTypeSupportsDefineByStory) {
        dispatch(arrayPush(form, floorPlanFieldArrayName, {}));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanFieldSet);
