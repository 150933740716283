/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Button, Spinner } from 'reactstrap';
/* eslint-disable import/extensions */
import { FaTrash, FaTrashRestore } from 'react-icons/fa';
import { IoMdCopy } from 'react-icons/io';
/* eslint-enable import/extensions */
import { loading } from './ActionCell.module.scss';

type Props = {|
  uuid: string,
  archived: boolean,
  handleArchiveToggle: Function,
  handleCopy: Function,
  isCopying: boolean,
  isTogglingArchive: boolean,
|};

const ActionCell = (props: Props): Element<typeof Spinner | typeof Fragment> => {
  const {
    uuid,
    handleCopy,
    archived,
    handleArchiveToggle,
    isCopying,
    isTogglingArchive,
  } = props;

  if (isCopying || isTogglingArchive) {
    return (
      <Spinner
        id={`actionSpinner-${uuid}`}
        className={loading}
        color="primary"
        title={isCopying ? 'Copying...' : 'Archiving...'}
      />
    );
  }
  return (
    <>
      <Button
        id={`copyButton-${uuid}`}
        color="link"
        className="p-1"
        title="Copy Model"
        onClick={handleCopy}
        disabled={archived}
      >
        <IoMdCopy size="1em" />
      </Button>
      <Button
        id={`toggleArchiveButton-${uuid}`}
        color="link"
        className="p-1"
        title={archived ? 'Restore Model' : 'Archive Model'}
        onClick={handleArchiveToggle}
      >
        { archived ? <FaTrashRestore size="1em" /> : <FaTrash size="1em" /> }
      </Button>
    </>
  );
};

export default ActionCell;
