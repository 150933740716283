/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Node } from 'react';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { MAINTENANCE } from '#constants/pathStrings.js';

type Props = {
  children: Node,
  isMaintenanceMode: boolean,
};

const RequireMaintenanceOff = ({
  children,
  isMaintenanceMode,
}: Props): Node => {
  const path = useLocation().pathname;
  return (
    isMaintenanceMode
      ? <Navigate to={`/${MAINTENANCE}?redirectPath=${path}`} />
      : children
  );
};

export default RequireMaintenanceOff;
