/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import React from 'react';
import numeral from 'numeral';
import {
  DiscreteColorLegend,
  RadialChart,
} from 'react-vis';
import { colorLegend } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import { componentExposureBreakdownById } from '#constants/models/componentBreakDownProperties.js';

type RepairCostExposureBreakdown = {
  [string]: {
    componentGroup: string,
    maxRepairPotential: number,
    percentOfBuildingReplacementValue: number,
  }
};

type Props = {|
  data: RepairCostExposureBreakdown,
  fieldsSortOrder: string[],
|};

const RepairCostExposurePlot = (props: Props): Element<'div'> => {
  const { data, fieldsSortOrder } = props;

  const width = 350;
  const height = 350;

  const pieSlice = (field) => {
    const angle = Math.round((
      data[field].maxRepairPotential / data.total.maxRepairPotential
    ) * 100);
    const label = angle > 5
      ? numeral(data[field].percentOfBuildingReplacementValue).format('0.0%')
      : '';
    const color = get(`${field}.color`, componentExposureBreakdownById);
    const res = {
      angle,
      label,
      color,
    };
    return res;
  };

  const legend = (field) => {
    const { description, color } = componentExposureBreakdownById[field];
    const absolute = data[field].percentOfBuildingReplacementValue;
    const title = `${description} (${numeral(absolute).format('0.0%')})`;
    return { title, color, strokeWidth: 12 };
  };

  return (
    <div
      id="repairCostExposurePlot"
      style={{ position: 'relative', width: width + 50, height: height + 50 }}
    >
      <div className="pt-2">
        <RadialChart
          width={width}
          height={height}
          showLabels
          labelsRadiusMultiplier={0.7}
          labelsStyle={{ fontSize: 12 }}
          colorType="literal"
          data={fieldsSortOrder.map(pieSlice)}
        >
          <DiscreteColorLegend
            className={colorLegend}
            color="grey"
            width={225}
            items={fieldsSortOrder.map(legend)}
            style={{
              position: 'absolute',
              top: '15px',
              left: `${width + 25}px`,
            }}
          />
        </RadialChart>
      </div>
    </div>
  );
};

export default RepairCostExposurePlot;
