// @flow
import type { Element } from 'react';
import React from 'react';
import { SubNav } from '#components/support/layout/SubNav/index.js';

type Props = {
  top?: string,
};

const ComponentsDashboardSubNav = ({ top = '61px' }: Props): Element<typeof SubNav> => (
  <SubNav height="56px" top={top}>
    <h1>Components</h1>
  </SubNav>
);

export default ComponentsDashboardSubNav;
