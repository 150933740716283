/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Card, CardBody, FormGroup, FormText, Row, Col,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import ModelComponentsTable from './ModelComponentsTable/index.js';
import ComponentPopulationMethodSelectMenu
  from './ComponentPopulationMethodSelectMenu.jsx';

type Props = {|
  heading: string,
  form: string,
  onBack: Function,
  onNext: Function,
  canAddComponent: boolean,
  showCustomWarning: boolean,
|};

const MainView = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    onBack,
    onNext,
    canAddComponent,
    showCustomWarning,
  } = props;
  return (
    <div>
      <h2>{heading}</h2>
      <FormGroup tag="fieldset">
        <legend>Component Selection Method</legend>
        <GridField
          name="componentPopulationMethod"
          form={form}
          options={{
            columnSize: 11,
            labelText: 'Selection Method',
            ComponentType: ComponentPopulationMethodSelectMenu,
            includeToolTip: true,
          }}
        />
        { showCustomWarning && (
          <FormText id="userDefinedWarning" color="muted">
            * Building Component inventory will not automatically update to
            reflect other model changes.
            {' '}
            <Tip tipId="componentPopulationMethodCustomWarning" />
          </FormText>
        )}
      </FormGroup>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="float-right">
                {canAddComponent && (
                  <Button id="addComponent" className="pr-0" to="add" color="link" tag={Link}>+ Add Component</Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ModelComponentsTable form={form} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div>
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </div>
    </div>
  );
};

export default MainView;
