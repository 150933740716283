// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Col,
  Form,
  FormText,
  FormGroup,
  Row,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import YesNoSelectMenu
  from '#components/pages/Models/Model/sections/support/forms/YesNoSelectMenu/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import FundingSourceSelectMenu from '#components/pages/Models/Model/sections/support/forms/FundingSourceSelectMenu/index.jsx';
import RepairTimeMethodSelectMenu from './RepairTimeMethodSelectMenu/index.jsx';
import ContractorRelationshipSelectMenu from './ContractorRelationshipSelectMenu/index.jsx';
import DamageCorrelationModelSelectMenu from './DamageCorrelationModelSelectMenu/index.jsx';
import EquipmentPrequalifiedDesignCodeYearSelectMenu from './EquipmentPrequalifiedDesignCodeYearSelectMenu/index.jsx';

type Props = {|
  form: string,
  handleSubmit: Function,
  heading: string,
  onBack: Function,
  onNext: Function,
  showDefaultATC138Fields: boolean,
  showIsDenseUrbanArea: boolean,
  showMaxWalkableStory: boolean,
  showEquipmentPrequalified: boolean,
  showPrequalifiedDesignCodeYear: boolean,
  showPrequalifiedDetails: boolean,
  showIncludeImpedanceLongLeadTimeWarning: boolean,
|};

const BuildingStabilitySection = (props: Props): Element<'div'> => {
  const {
    handleSubmit,
    heading,
    form,
    onBack,
    onNext,
    showDefaultATC138Fields,
    showIsDenseUrbanArea,
    showMaxWalkableStory,
    showEquipmentPrequalified,
    showPrequalifiedDesignCodeYear,
    showPrequalifiedDetails,
    showIncludeImpedanceLongLeadTimeWarning,
  } = props;

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <GridField
          name="repairTimeMethod"
          form={form}
          options={{
            columnSize: 6,
            labelText: 'Recovery Time Method',
            ComponentType: RepairTimeMethodSelectMenu,
            includeToolTip: true,
          }}
        />
        <FormGroup tag="fieldset">
          <legend>General</legend>
          <GridField
            name="buildingReplacementTime"
            form={form}
            options={{
              columnSize: 4,
              labelText: 'Building Replacement Time',
              ComponentType: 'text',
              includeToolTip: true,
              inputGroup: {
                addonType: 'append',
                addonText: 'days',
              },
            }}
          />
          {showDefaultATC138Fields && (
          <GridField
            name="damageCorrelationModel"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Statistical Damage Correlation Model',
              ComponentType: DamageCorrelationModelSelectMenu,
              includeToolTip: true,
            }}
          />
          )}
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>
            Factors Delaying the Start of Repairs
            {' '}
            <Tip tipId="factorsDelayingTheStartOfRepairs" />
          </legend>
          <GridField
            name="includeImpedanceInspection"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Inspection',
              ComponentType: 'checkbox',
            }}
          />
          <GridField
            name="includeImpedanceFinancing"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Financing',
              ComponentType: 'checkbox',
            }}
          />
          <GridField
            name="includeImpedancePermitting"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Permitting',
              ComponentType: 'checkbox',
            }}
          />
          <GridField
            name="includeImpedanceEngineering"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Engineering Mobilization and Design',
              ComponentType: 'checkbox',
            }}
          />
          <GridField
            name="includeImpedanceContractor"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Contractor Mobilization',
              ComponentType: 'checkbox',
            }}
          />
          { showDefaultATC138Fields && (
            <>
              <GridField
                name="includeImpedanceLongLeadTime"
                form={form}
                options={{
                  columnSize: 6,
                  labelText: 'Long Lead Time',
                  ComponentType: 'checkbox',
                  includeToolTip: true,
                }}
              />
              { showIncludeImpedanceLongLeadTimeWarning && (
                <FormText className="mt-0 pt-0 pl-4" id="includeImpedanceLongLeadTimeWarning" color="muted">
                  * The official ATC-138 recommendation is to include Long Lead Time
                </FormText>
              )}
            </>
          )}
        </FormGroup>

        <FormGroup tag="fieldset">
          <legend>
            Mitigation Factors
            {' '}
            <Tip tipId="mitigationFactors" />
          </legend>
          <GridField
            name="mitigationInspector"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Inspector on Retainer',
              ComponentType: 'checkbox',
              includeToolTip: true,
            }}
          />
          <GridField
            name="mitigationEngineer"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Engineer on Retainer',
              ComponentType: 'checkbox',
              includeToolTip: true,
            }}
          />
          <GridField
            name="mitigationContractor"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Contractor Relationship',
              ComponentType: ContractorRelationshipSelectMenu,
              includeToolTip: true,
            }}
          />
          <GridField
            name="mitigationFundingSource"
            form={form}
            options={{
              columnSize: 6,
              labelText: 'Funding Source',
              ComponentType: FundingSourceSelectMenu,
              includeToolTip: true,
            }}
          />
          {
            showDefaultATC138Fields
            && (
            <GridField
              name="cashOnHand"
              form={form}
              options={{
                columnSize: 4,
                labelText: 'Cash on Hand',
                ComponentType: 'text',
                inputGroup: {
                  addonType: 'append',
                  addonText: '%',
                },
                includeToolTip: true,
              }}
            />
            )
          }
        </FormGroup>
        {
          showDefaultATC138Fields
          && (
            <FormGroup tag="fieldset" id="atc138FunctionalRecoveryOptions">
              <legend>ATC-138 Functional Recovery (Beta) Options</legend>
              <FormGroup tag="fieldset">
                <legend>HVAC</legend>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Ventilation
                    </Col>
                  </Row>
                  <GridField
                    name="needVentilationForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-ventilation-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needVentilationForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-ventilation-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Cooling
                    </Col>
                  </Row>
                  <GridField
                    name="needCoolingForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-cooling-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needCoolingForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-cooling-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Heating
                    </Col>
                  </Row>
                  <GridField
                    name="needHeatingForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-heating-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needHeatingForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-heating-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Exhaust
                    </Col>
                  </Row>
                  <GridField
                    name="needExhaustForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-exhaust-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needExhaustForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-exhaust-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Plumbing</legend>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Potable Water
                    </Col>
                  </Row>
                  <GridField
                    name="needPotableWaterForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-potable-water-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needPotableWaterForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-potable-water-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Sanitation Plumbing
                    </Col>
                  </Row>
                  <GridField
                    name="needSanitationPlumbingForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-sanitation-plumbing-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needSanitationPlumbingForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-sanitation-plumbing-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Electrical</legend>
                <FormGroup>
                  <Row>
                    <Col lg="4" className="font-weight-bold">
                      Electrical Power
                    </Col>
                  </Row>
                  <GridField
                    name="needElectricalPowerForReoccupancy"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Occupancy?',
                      componentProps: {
                        inputClassName: 'need-electrical-power-for-reoccupancy-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                  <GridField
                    name="needElectricalPowerForFunction"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Required for Function?',
                      componentProps: {
                        inputClassName: 'need-electical-power-for-function-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: false,
                    }}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Elevator</legend>
                <GridField
                  name="needElevatorForFunction"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Need Elevator for Function?',
                    componentProps: {
                      inputClassName: 'need-elevator-for-function-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
                {
                  showMaxWalkableStory
                  && (
                  <GridField
                    name="maxWalkableStory"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'Max Walkable Story',
                      ComponentType: 'text',
                      includeToolTip: true,
                    }}
                  />
                  )
                }
                <GridField
                  name="useUpdatedElevatorComponents"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Use Updated Elevator Components?',
                    ComponentType: YesNoSelectMenu,
                    componentProps: {
                      includeEmptyOption: false,
                    },
                    includeToolTip: true,
                  }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Temporary Repairs</legend>
                <GridField
                  name="allowTemporaryRepairs"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Allow Temporary Repairs to Regain Occupancy/Function?',
                    componentProps: {
                      inputClassName: 'allow-temporary-repairs-to-regain-function-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
                <GridField
                  name="allowTemporaryShoring"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Allow Temporary Shoring to Regain Occupancy/Function?',
                    componentProps: {
                      inputClassName: 'allow-temporary-shoring-to-regain-function-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Fire</legend>
                <GridField
                  name="allowFireWatchToBeSufficient"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Allow Fire Watch to be Sufficient for Occupancy?',
                    componentProps: {
                      inputClassName: 'allow-fire-watch-to-regain-reoccupancy-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Egress</legend>
                <GridField
                  name="percentOfExitsRequired"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Percentage of the Exits Required for Occupancy?',
                    componentProps: {
                      inputClassName: 'percentage-of-exits-for-occupancy-text',
                    },
                    inputGroup: {
                      addonType: 'append',
                      addonText: '%',
                    },
                    ComponentType: 'text',
                    includeToolTip: true,
                  }}
                />
                <GridField
                  name="minimumNumberOfExitsRequired"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Minimum Number of Exits Required for Occupancy?',
                    componentProps: {
                      inputClassName: 'minimum-number-of-exits-required-for-occupancy-text',
                    },
                    ComponentType: 'text',
                    includeToolTip: true,
                  }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Smart Components</legend>
                <GridField
                  name="useSmartPipingComponents"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Use "Smart" Piping Components?',
                    componentProps: {
                      includeEmptyOption: false,
                      inputClassName: 'use-smart-piping-components-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
                <GridField
                  name="useSmartEquipmentComponents"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Use "Smart" Equipment Components?',
                    componentProps: {
                      includeEmptyOption: false,
                      inputClassName: 'use-smart-equipment-components-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
                {showEquipmentPrequalified && (
                  <GridField
                    name="equipmentPrequalified"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'Is the Equipment Prequalified?',
                      componentProps: {
                        inputClassName: 'is-equipment-prequalified-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: true,
                    }}
                  />
                )}
                {showPrequalifiedDesignCodeYear && (
                  <GridField
                    name="prequalifiedDesignCodeYear"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'For what Design Code was the Equipment Prequalified?',
                      componentProps: {
                        inputClassName: 'prequalified-design-code-year-select',
                      },
                      ComponentType: EquipmentPrequalifiedDesignCodeYearSelectMenu,
                      includeToolTip: true,
                    }}
                  />
                )}
                {showPrequalifiedDetails && (
                  <>
                    <GridField
                      name="prequalifiedAccGround"
                      form={form}
                      options={{
                        columnSize: 4,
                        labelText: 'Prequalification Acceleration at Ground Level',
                        componentProps: {
                          inputClassName: 'prequalified-acc-ground-text',
                        },
                        ComponentType: 'text',
                        includeToolTip: true,
                      }}
                    />
                    <GridField
                      name="prequalifiedAccRoof"
                      form={form}
                      options={{
                        columnSize: 4,
                        labelText: 'Prequalification Acceleration at Roof Level',
                        componentProps: {
                          inputClassName: 'prequalified-acc-roof-text',
                        },
                        ComponentType: 'text',
                        includeToolTip: true,
                      }}
                    />
                  </>
                )}
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Flooding</legend>
                <GridField
                  name="includeFloodingImpact"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Include Flooding Consequences?',
                    componentProps: {
                      includeEmptyOption: false,
                      inputClassName: 'include-flooding-consequences-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <legend>Surge Demand</legend>
                <GridField
                  name="includeSurgeDemand"
                  form={form}
                  options={{
                    columnSize: 4,
                    labelText: 'Include Surge Demand?',
                    componentProps: {
                      inputClassName: 'include-surge-demand-select',
                    },
                    ComponentType: YesNoSelectMenu,
                    includeToolTip: true,
                  }}
                />
                {
                  showIsDenseUrbanArea
                  && (
                  <GridField
                    name="isDenseUrbanArea"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'Is Dense Urban Area?',
                      componentProps: {
                        inputClassName: 'is-dense-urban-area-select',
                      },
                      ComponentType: YesNoSelectMenu,
                      includeToolTip: true,
                    }}
                  />
                  )
                }
              </FormGroup>
            </FormGroup>
          )
        }
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default BuildingStabilitySection;
