/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 5/30/18
 * Copyright All Rights Reserved
 */

import { combineReducers } from 'redux';
import entities from './entities/index.js';
import form from './ui/form/index.js';
import ui from './ui/index.js';

const rootReducer = combineReducers({
  form,
  entities,
  ui,
});
export default rootReducer;
