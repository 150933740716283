/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType } from 'react';

import React from 'react';
import { useNavigate } from 'react-router-dom';

/* Higher order component to inject react router navigation into a component */

function withNavigate<EnhancedComponentProps>(
  Component: ComponentType<{|...EnhancedComponentProps, navigate: Function|}>
): ComponentType<EnhancedComponentProps> {
  return (props: EnhancedComponentProps) => {
    const navigate = useNavigate();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component navigate={navigate} {...props} />;
  };
}

export default withNavigate;
