/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';
import React from 'react';
import type { EdpKey } from '#constants/edps/index.js';

import { edpsByKey } from '#constants/edps/index.js';

type Props = {|
  method: string,
  edpKey: string
|};

const sampleS3Prefix = 'https://s3-us-west-2.amazonaws.com/static-assets.hbrisk.com/products/design/samples/structural-responses';

const getDownloadUrl = (method, locationType) => `${sampleS3Prefix}/sample-${method}-${locationType}-upload.csv`;

const DownloadSampleLink = ({
  method, edpKey,
}: Props): Element<'a'> => {
  const edpKeyCasted = ((edpKey: any): EdpKey);
  const { locationType } = edpsByKey[edpKeyCasted];
  const downloadUrl = getDownloadUrl(method, locationType);
  return (
    <a
      id="sampleDownload"
      href={downloadUrl}
      download
    >
      Sample Download
    </a>
  );
};

export default DownloadSampleLink;
