/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { autofillModelFormField, unautofillModelFormField } from '#actions/models/modelForm.js';
import { currentModelId } from '#selectors/entities/models.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';

const withFieldPopulationToggling = (C) => {
  const mapStateToProps = (_, ownProps) => {
    const { fieldName, form } = ownProps;
    const { makeFieldIsAutofilledSelector, makeAutofilledValueSelector } = modelTypesByForm[form];
    const selectFieldIsAutofilled = makeFieldIsAutofilledSelector();
    const selectAutofilledValue = makeAutofilledValueSelector();
    return (state) => {
      const modelId = currentModelId(state);
      const modelValue = selectAutofilledValue(state, { modelId, name: fieldName });
      const autofilled = selectFieldIsAutofilled(state, { name: fieldName });
      return ({
        autofilled,
        modelValue,
      });
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    makeHandleToggle: (
      form,
      fieldName,
      modelValue,
    ) => (on) => () => {
      if (on) {
        dispatch(autofillModelFormField(form, fieldName, modelValue));
      } else {
        dispatch(unautofillModelFormField(form, fieldName));
      }
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fieldName, form } = ownProps;
    const { autofilled, modelValue } = stateProps;
    const { makeHandleToggle } = dispatchProps;
    return {
      ...ownProps,
      autofilled,
      fieldName,
      handleToggle: makeHandleToggle(
        form,
        fieldName,
        modelValue,
      ),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default withFieldPopulationToggling;
