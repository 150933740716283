/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const options = [
  { id: 'none', name: 'None', value: 'none' },
  { id: 'good', name: 'Good', value: 'good' },
  { id: 'retainer', name: 'Retainer', value: 'retainer' },
];

type Props = {|
  form: string,
  name: string,
|};

const ContractorRelationshipSelectMenu = (
  { form, name }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={options}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'contractor-relationship-select')}
    includeEmptyOption
  />
);

export default ContractorRelationshipSelectMenu;
