// @flow
import type { Element } from 'react';

import React from 'react';
import { InputGroupAddon as BaseAddon } from 'reactstrap';

type Props = {
  addonType: 'string',
  text: 'string',
  tag: 'string' | Function,
  className: 'string',
};

const InputGroupAddon = ({
  addonType, text, tag, className,
}: Props): Element<typeof BaseAddon> => (
  <BaseAddon
    addonType={addonType}
    tag={tag}
    className={className}
  >
    {text}
  </BaseAddon>
);

export default InputGroupAddon;
