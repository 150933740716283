/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import {
  FETCH_APP_MAINTENANCE_MODE_RESPONSE,
  SET_MAINTENANCE_OVERRIDE,
} from '#constants/actionTypes.js';

const initialState = {
  on: false,
  override: false,
};

const maintenanceReducer = (state = initialState, action = {}) => {
  const { type, payload, error } = action;
  if (!error) {
    if (type === FETCH_APP_MAINTENANCE_MODE_RESPONSE) {
      const { on } = payload;
      if (on !== state.on) {
        return {
          ...state,
          on,
        };
      }
    }
    if (type === SET_MAINTENANCE_OVERRIDE) {
      const { override } = payload;
      return {
        ...state,
        override,
      };
    }
  }
  return state;
};

export default maintenanceReducer;
