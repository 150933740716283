/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import isFloor from '#support/models/isFloor.js';

const getLocationLabel = (location, locationType, maxLocation) => {
  if (isFloor(locationType)) {
    if (location === 1) {
      return 'Ground';
    }
    if (location === maxLocation) {
      return 'Roof';
    }
  }
  return `${location}`;
};

export default getLocationLabel;
