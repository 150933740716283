/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import TermsOfService from './TermsOfService/index.js';
import { page } from './index.module.scss';

type Props = {|
  children?: Node,
  className?: string | null,
  hasNewAppVersion: boolean,
  isAppMaintenanceMode: boolean,
  userNeedsTermsOfService: boolean,
|};

const NewAppVersionModal = (
  <Modal
    isOpen
    backdrop
    centered
    fullscreen="lg"
    size="md"
  >
    <ModalBody>
      <span className="pr-3">A new version of SP3 is available.</span>
      <Button
        color="primary"
        onClick={() => window.location.reload(true)}
      >
        Refresh
      </Button>
    </ModalBody>
  </Modal>
);

const showModalComponentsIfNeeded = (
  hasNewAppVersion,
  userNeedsTermsOfService,
  isAppMaintenanceMode
) => {
  if (hasNewAppVersion && !isAppMaintenanceMode) {
    return NewAppVersionModal;
  }
  if (userNeedsTermsOfService) {
    return <TermsOfService />;
  }
  return null;
};

const Page = ({
  children = null,
  className = null,
  hasNewAppVersion,
  isAppMaintenanceMode,
  userNeedsTermsOfService,
}: Props): Element<'div'> => (
  <div className={classNames(className, page)}>
    <div>
      {showModalComponentsIfNeeded(
        hasNewAppVersion,
        userNeedsTermsOfService,
        isAppMaintenanceMode
      )}
    </div>
    {children}
  </div>
);

export default Page;
