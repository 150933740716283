/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import { getStatusDesc } from '#components/pages/Models/Dashboard/ModelsTable/support/statusHelpers.js';
import ActionCell from './ActionCell/index.js';

type CellType = (data: CellData) => Element<typeof ActionCell>;

const accessor = (data: Object) => {
  const status = get('currentRun.status', data);
  return getStatusDesc(status);
};

const Cell: CellType = (data: Object) => {
  const uuid = get('original.uuid', data);
  const archived = get('original.archived', data);
  return (
    <ActionCell
      uuid={uuid}
      archived={archived}
    />
  );
};

const column: ColumnType = {
  id: 'action',
  accessor,
  Header: 'Action',
  width: 65,
  sortable: false,
  additionalClasses: ['text-center'],
  Cell,
};

export default column;
