/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import uniq from 'lodash/fp/uniq.js';
import { componentQuestions } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';

const getImmediateDescendants = (name) => {
  const question = componentQuestions.find((f) => f.name === name);
  const { answers } = question;
  return uniq(answers.reduce(
    (a, answer) => (
      answer.nextQuestions === null
        ? a
        : [...a, ...answer.nextQuestions]
    ),
    []
  ));
};

export default getImmediateDescendants;
