import sumBy from 'lodash/fp/sumBy.js';
import { getRange } from '@hbrisk/sp3-risk-model-support/models/locationSpecifier/index.js';

const componentRollup = (isFloor, numberOfStories, performanceGroup) => {
  const { locations } = performanceGroup;

  return locations.reduce((acc, curr) => {
    const quantityDir1 = parseFloat(curr.quantityDir1) || 0;
    const quantityDir2 = parseFloat(curr.quantityDir2) || 0;
    const quantityNonDir = parseFloat(curr.quantityNonDir) || 0;
    const { error, range } = getRange(isFloor, numberOfStories, curr.locationSpecifier);
    const quantity = sumBy(
      () => quantityDir1 + quantityDir2 + quantityNonDir,
      range,
    );

    return {
      error: acc.error || error,
      quantity: acc.quantity + quantity,
      locations: [
        ...acc.locations,
        ...(quantity > 0 ? range : []),
      ],
    };
  }, { quantity: 0, locations: [] });
};

export default componentRollup;
