/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectOrgs } from '#selectors/entities/organizations.js';
import ViewOrganizationsTable from './ViewOrganizationsTable.jsx';

const mapStateToProps = (state) => ({
  data: selectOrgs(state),
});

const ViewOrganizationsContainer = connect(mapStateToProps)(ViewOrganizationsTable);

export default ViewOrganizationsContainer;
