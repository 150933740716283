/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';

type Props = {|
messages?: ?(string | Array<string>),
  type?: string,
  meta: {
    touched?: boolean,
  },
  name: string,
|}

const FieldFeedback = ({
  messages = null, type = 'error', name, meta: { touched },
}: Props): null | Element<'div'> => {
  const showErrors = touched && messages;
  const feedbackClassName = type === 'error' ? 'text-danger' : '';

  const errors = Array.isArray(messages)
    ? messages.map((message) => <div key={message}>{message}</div>)
    : messages;

  return (
    showErrors
      ? <div id={`${name}-feedback`} className={feedbackClassName}>{errors}</div>
      : null
  );
};

export default FieldFeedback;
