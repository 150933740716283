/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Col, Row } from 'reactstrap';
import Footer from '#components/support/layout/Footer/index.jsx';
import Header from '#components/support/layout/Header/index.js';
import Main from '#src/js/components/support/layout/MainPageContainer/index.jsx';
import MainHeadingRow from '#components/support/layout/MainHeadingRow/index.jsx';
import Page from '#components/support/layout/Page/index.js';
import ResetPasswordForm from './ResetPasswordForm/index.jsx';

const ResetPasswordPage = (): Element<typeof Row> => (
  <Page className="reset-password-page">
    <Header inApp={false} />
    <Main>
      <MainHeadingRow>
        <h1>Reset Password</h1>
      </MainHeadingRow>
      <Row>
        <Col sm="8" className="col">
          <ResetPasswordForm />
        </Col>
      </Row>
    </Main>
    <Footer />
  </Page>
);

export default ResetPasswordPage;
