/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import numeral from 'numeral';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import IntensityName from '#components/support/forms/IntensityName/index.js';
import { repairCostByIntensity } from './RepairCostByIntensityTable.module.scss';

type IntensityLoss = {
    pga: number,
    returnPeriod: number,
    mean: number,
    median: number,
    '90th': number,
    'sul': number,
    saOverVUltDir1: number,
    saOverVUltDir2: number,
};

const tableRow = (props: IntensityLoss) => {
  const {
    returnPeriod,
    pga,
    mean,
    median,
    sul,
    saOverVUltDir1,
    saOverVUltDir2,
  } = props;

  return (
    <tr key={returnPeriod} className="text-center">
      <td className="text-nowrap">
        <IntensityName returnPeriod={returnPeriod} />
      </td>
      <td>{returnPeriod}</td>
      <td>{numeral(pga).format('0,0.00')}</td>
      <td>{numeral(mean).format('0,0%')}</td>
      <td>{numeral(sul).format('0,0%')}</td>
      <td>{numeral(median).format('0,0%')}</td>
      <td>{numeral(props['90th']).format('0,0%')}</td>
      <td>{numeral(saOverVUltDir1).format('0.00')}</td>
      <td>{numeral(saOverVUltDir2).format('0.00')}</td>
    </tr>
  );
};

const renderRows = (lossData: IntensityLoss[]) => lossData.map((d) => tableRow(d));

type Props = {|
  totalLossData: IntensityLoss[],
|};

const RepairCostByIntensityTable = (props: Props): Element<typeof Table> => {
  const { totalLossData } = props;
  return (
    <Table id="repairCostByIntensityTable" striped bordered className={repairCostByIntensity}>
      <thead>
        <tr>
          <th rowSpan="2">
            Intensity
            {' '}
            <Tip tipId="intensity" />
          </th>
          <th rowSpan="2">
            Return Period
            {' '}
            <Tip tipId="returnPeriods" />
          </th>
          <th rowSpan="2">
            PGA
            {' '}
            <Tip tipId="pga" />
            {' '}
            [g]
          </th>
          <th rowSpan="2">
            Mean (SEL)
            <Tip tipId="meanLoss" />
            {' '}
            [%]
          </th>
          <th rowSpan="2">
            Fitted SUL
            {' '}
            <Tip tipId="fittedSUL" />
            {' '}
            [%]
          </th>
          <th rowSpan="2">
            Median
            {' '}
            <Tip tipId="medianLoss" />
            {' '}
            [%]
          </th>
          <th rowSpan="2">
            Counted 90th Percentile
            {' '}
            <Tip tipId="n90thPercentileLoss" />
            {' '}
            [%]
          </th>
          <th colSpan="2" className="text-nowrap">
            S
            <sub>a</sub>
            (T
            <sub>1</sub>
            )/
            v
            <sub>ult</sub>
            {' '}
            <Tip tipId="saOverVUlt" />
          </th>
        </tr>
        <tr>
          <th className="text-nowrap">Dir 1</th>
          <th className="text-nowrap">Dir 2</th>
        </tr>
      </thead>
      <tbody>
        { renderRows(totalLossData) }
      </tbody>
    </Table>
  );
};

export default RepairCostByIntensityTable;
