// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  Row,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import CollapseDefinitionMethodSelectMenu from './CollapseDefinitionMethodSelectMenu/index.js';

type Props = {|
  form: string,
  handleSubmit: Function,
  heading: string,
  onBack: Function,
  onNext: Function,
  showFema154Score: Boolean,
  showProbCollapseMce: Boolean,
  showProbCollapseMceRO: Boolean,
  showCollapseMedian: Boolean,
  showCollapseMedianRO: Boolean,
  showCollapseBeta: Boolean,
  probCollapseMce: number,
  collapseCapacityMedian: number,
  showMessage: Boolean,
|};

const BuildingStabilitySection = (props: Props): Element<'div'> => {
  const {
    handleSubmit,
    heading,
    form,
    onBack,
    onNext,
    showFema154Score,
    showProbCollapseMce,
    showProbCollapseMceRO,
    showCollapseMedian,
    showCollapseMedianRO,
    showCollapseBeta,
    probCollapseMce,
    collapseCapacityMedian,
    showMessage,
  } = props;

  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate onSubmit={handleSubmit}>
        {
          showMessage
          && (
            <FormText color="muted" className="pb-2">
              *Because &ldquo;Include collapse&rdquo; is not selected in &ldquo;Analysis
              Options&rdquo;, parameters in this section will not affect your analysis results
            </FormText>
          )
        }
        <FormGroup tag="fieldset">
          <legend>Collapse Information</legend>
          <GridField
            name="collapseDefinitionMethod"
            form={form}
            options={{
              columnSize: 4,
              labelText: 'Collapse Definition Method',
              ComponentType: CollapseDefinitionMethodSelectMenu,
              componentProps: {
                form,
              },
            }}
          />
        </FormGroup>
        <Card>
          <CardBody>
            <Row>
              <Col>
                { showFema154Score && (
                  <GridField
                    name="fema154Score"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'FEMA P-154 Score',
                      ComponentType: 'text',
                      includeToolTip: true,
                    }}
                  />
                )}
                { showProbCollapseMce && (
                  <GridField
                    name="probCollapseMce"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'Probability of Collapse at MCE Intensity',
                      ComponentType: 'text',
                      includeToolTip: true,
                      inputGroup: {
                        addonType: 'append',
                        addonText: '%',
                      },
                    }}
                  />
                )}
                { showProbCollapseMceRO && (
                  <div id="probCollapseMceRO" className="pt-2 pb-3">
                    <div>
                      Probability of Collapse at MCE Intensity
                      {' '}
                      <Tip tipId="probCollapseMce" />
                    </div>
                    { probCollapseMce !== null && (
                      <div>
                        {probCollapseMce}
                        {' '}
                        %
                      </div>
                    )}
                  </div>
                )}
                { showCollapseMedian && (
                  <GridField
                    name="collapseCapacityMedian"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'Median Collapse Capacity',
                      ComponentType: 'text',
                      includeToolTip: true,
                      inputGroup: {
                        addonType: 'append',
                        addonText: 'g',
                      },
                    }}
                  />
                )}
                { showCollapseMedianRO && (
                  <div id="collapseCapacityMedianRO" className="pt-2 pb-3">
                    <div>
                      Median Collapse Capacity
                      {' '}
                      <Tip tipId="collapseCapacityMedian" />
                    </div>
                    { collapseCapacityMedian !== null && (
                      <div>
                        {collapseCapacityMedian}
                        {' '}
                        g
                      </div>
                    )}
                  </div>
                )}
                { showCollapseBeta && (
                  <GridField
                    name="collapseCapacityBeta"
                    form={form}
                    options={{
                      columnSize: 4,
                      labelText: 'Beta (dispersion)',
                      ComponentType: 'text',
                      includeToolTip: true,
                    }}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>

        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default BuildingStabilitySection;
