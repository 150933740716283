/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { change } from 'redux-form';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { fetchModel } from '#actions/models/model.js';
import { uploadToUrl } from '#actions/models/uploads.js';
import { createModeShapeAndGetUploadUrl } from '#actions/models/modeShapes.js';
import { selectModeShapeIsProcessing } from '#selectors/entities/models.js';
import ModeShapeUploader from './ModeShapeUploader.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form, modelId, name } = ownProps;
  const { selectFormValues } = modelTypesByForm[form];
  const method = selectFormValues(state, 'modeShapeMethod');
  const value = selectFormValues(state, name);
  const isUploading = selectModeShapeIsProcessing(state, { modelId });
  return {
    value,
    isUploading,
    method,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    form,
    modelId,
    name,
  } = ownProps;
  return {
    handleUpload: (file) => {
      dispatch(change(form, name, null));
      dispatch(createModeShapeAndGetUploadUrl(modelId, { method: 'upload' }))
        .then((data) => {
          const { url, uuid } = data.payload;
          dispatch(fetchModel(modelId))
            .then(() => {
              dispatch(change(form, name, uuid));
            });
          dispatch(uploadToUrl(file, url));
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModeShapeUploader);
