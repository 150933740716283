/**
 * Owner: Haselton Baker Risk Group, LLC
 * Product: SP3 - SP3-Risk-Model-U
 * Date: 2/4/22
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form,
  UncontrolledAlert as Alert,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import ComponentQuestions from './ComponentQuestions/index.js';

type ComponentChecklistProps = {|
  error: string,
  form: string,
  handleSubmit: Function,
  heading: string,
  onBack: Function,
  onNext: Function,
  submitting: boolean,
|};

const ComponentChecklistSection = (props: ComponentChecklistProps): Element<'div'> => {
  const {
    error,
    form,
    handleSubmit,
    heading,
    onBack,
    onNext,
    submitting,
  } = props;

  return (
    <div>
      <h2>
        {heading}
        {' '}
        <Tip tipId="buildingComponentsChecklist" />
      </h2>
      <Form noValidate onSubmit={handleSubmit}>
        <ComponentQuestions form={form} />
        { error && !submitting ? <Alert color="danger">{error}</Alert> : ''}
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default ComponentChecklistSection;
