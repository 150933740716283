// @flow
import type { Element, Node } from 'React';

import React from 'react';
import { FormGroup as Group } from 'reactstrap';

type Props = {
  children: Node,
  row: boolean,
  check: boolean,
  inline: boolean,
  disabled: boolean,
  tag: string,
  className: string,
  cssModule: Object,
  visible: boolean,
}

const FormGroup = ({
  row, check, inline, disabled, tag, className, cssModule, visible, children,
}: Props): Element<typeof Group> | null => {
  if (visible === false) {
    return null;
  }
  return (
    <Group
      row={row}
      check={check}
      inline={inline}
      disabled={disabled}
      tag={tag}
      className={className}
      cssModule={cssModule}
    >
      {children}
    </Group>
  );
};

export default FormGroup;
