import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import { keys } from 'ramda';
import {
  getFormMeta,
  getFormSyncErrors,
  getFormValues,
  getFormInitialValues,
} from 'redux-form';
import { createSelector } from 'reselect';
import {
  DAMAGE_STATE_CONSEQUENCES,
  DAMAGE_STATES,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import {
  CONSEQUENCES_MAX,
  countConsequences,
} from '@hbrisk/sp3-risk-model-support/components/form/validation/fieldValidators/index.js';
import createDeepEqualSelector from '#selectors/support/createDeepEqualSelector.js';
import getTouchedFromMeta from '#support/utility/form/getTouchedFromMeta/index.js';
import isInvisible from '#selectors/ui/form/support/isVisible.js';
import makeSelectVisibleFields from '#selectors/ui/form/support/makeSelectVisibleFields.js';
import makeSelectAutofilled from '#selectors/ui/form/support/makeSelectAutofilled.js';

export const selectFormState = (state) => state.form[COMPONENT_FORM];

const selectFieldSchema = (_, props) => props.fieldSchema;
const selectFieldName = (_, props) => props.name;
const selectFieldNames = (_, props) => props.fields;

export const selectFormInitialized = createSelector(
  getFormInitialValues(COMPONENT_FORM),
  (initial) => initial !== undefined,
);

export const selectVisibleFields = makeSelectVisibleFields(COMPONENT_FORM);

export const selectAutofilled = makeSelectAutofilled(COMPONENT_FORM);

export const selectInvisibleFields = createSelector(
  selectFormState,
  (visibleFields) => keys(visibleFields).filter((field) => isInvisible(field, visibleFields))
);

export const selectTouchedFields = createDeepEqualSelector(
  getFormMeta(COMPONENT_FORM),
  getTouchedFromMeta
);

export const selectSomeConsequenceHasVisibleInstancesOfFieldSchema = createSelector(
  selectFieldSchema,
  selectVisibleFields,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES].some(
        (damageState) => damageState[DAMAGE_STATE_CONSEQUENCES].some(
          (consequence) => consequence[schema.name] === true
        )
      );
    }
    return null;
  },
);

export const selectSomeDamageStateHasVisibleInstancesOfFieldSchema = createSelector(
  selectFieldSchema,
  selectVisibleFields,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES]
        .some((damageState) => damageState[schema.name] === true);
    }
    return null;
  }
);

export const selectFieldIsVisible = createSelector(
  selectFieldName,
  selectVisibleFields,
  (name, visibleFields) => !!get(name, visibleFields)
);

export const selectFieldValue = createSelector(
  selectFieldName,
  getFormValues(COMPONENT_FORM),
  (name, values) => !!get(name, values)
);

export const selectAtLeastOneFieldInArrayIsVisible = createSelector(
  selectFieldNames,
  selectVisibleFields,
  (fields, visibleFields) => (
    (visibleFields && (Array.isArray(fields) && fields.length > 0))
      ? fields.some(
        (field) => get(field, visibleFields) !== false
      )
      : true
  )
);

export const selectDamageStateMaxExceeded = createSelector(
  getFormValues(COMPONENT_FORM),
  (values) => {
    const damageStates = getOr([], DAMAGE_STATES, values);
    return damageStates.length >= CONSEQUENCES_MAX;
  },
);

export const selectConsequenceMaxExceeded = createSelector(
  getFormValues(COMPONENT_FORM),
  (values) => {
    const damageStates = getOr([], DAMAGE_STATES, values);
    return countConsequences(damageStates) >= CONSEQUENCES_MAX;
  },
);

export const selectIsSyncValid = createSelector(
  getFormSyncErrors(COMPONENT_FORM),
  (syncErrors) => Object.keys(syncErrors).length < 1
);
