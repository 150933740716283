// @flow
import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import {
  DAMAGE_STATE_CONSEQUENCES,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { fieldArraysByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import type { TableProps } from '#components/pages/Components/support/tables/support/types/tableProps';
import TableHead
  from '#components/pages/Components/support/tables/ConsequencePropertyRowTable/TableHead/index.jsx';
import TableRow
  from '#components/pages/Components/support/tables/ConsequencePropertyRowTable/Row/index.js';

/* eslint-disable react/destructuring-assignment, react/require-default-props */

// In this component we purposefully avoid destructuring props so that flow can properly
// handle TableProps as union type
const ConsequencePropertyRowTable = (props: TableProps): Element<typeof Table> => {
  const { itemSchema } = fieldArraysByName[DAMAGE_STATE_CONSEQUENCES];
  const filteredItemSchema = itemSchema
    .filter((fieldSchema) => (props.itemFields.includes(fieldSchema.name)));

  return (
    <Table responsive>
      {
        props.entityMode
          ? <TableHead entityMode={props.entityMode} component={props.component} />
          : <TableHead entityMode={props.entityMode || false} fields={props.fields} />
      }
      <tbody>
        {
          filteredItemSchema.map((fieldSchema) => (
            props.entityMode
              ? (
                <TableRow
                  entityMode={props.entityMode}
                  component={props.component}
                  fieldSchema={fieldSchema}
                  key={fieldSchema.name}
                />
              ) : (
                <TableRow
                  entityMode={props.entityMode || false}
                  fields={props.fields}
                  readOnly={props.readOnly}
                  fieldSchema={fieldSchema}
                  key={fieldSchema.name}
                />
              )
          ))
        }
      </tbody>
    </Table>
  );
};

export default ConsequencePropertyRowTable;
