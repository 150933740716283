/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import getMinDesignCodeYearForBuildingType from '#support/models/buildingType/getMinDesignCodeYearForBuildingType.js';

const getMinYearOfConstructionForBuildingType = (buildingType) => {
  const minDesignCodeYear = getMinDesignCodeYearForBuildingType(buildingType);
  if (minDesignCodeYear === 1800) {
    return minDesignCodeYear;
  }
  return minDesignCodeYear + 5; // approximation is good enough for validation
};

export default getMinYearOfConstructionForBuildingType;
