/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';
import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { MODELS } from '#constants/pathStrings.js';
import Header from '#components/support/layout/Header/index.js';
import Page from '#components/support/layout/Page/index.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';

type Props = {
  isMaintenanceMode: boolean,
  setMaintenanceOverride: (boolean) => void,
};

const MaintenancePage = (
  { isMaintenanceMode, setMaintenanceOverride }: Props
): Element<typeof Page> => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const override = searchParams.get('override');
    if (override !== null) {
      setMaintenanceOverride(override === 'true');
    }
  }, [searchParams]);

  const redirectPath = searchParams.get('redirectPath') || `/${MODELS}`;

  return isMaintenanceMode ? (
    <Page className="maintenance-page">
      <Header showLinks={false} />
      <MainPageContainer>
        <h3 className="pt-4">
          SP3 is currently down for maintenance.
        </h3>
        <div className="pt-2">
          We expect to be back up soon. Thank you for your patience.
        </div>
      </MainPageContainer>
    </Page>
  ) : <Navigate to={redirectPath} />;
};

export default MaintenancePage;
