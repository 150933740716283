/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectMeanLossBreakdownPercentFor475ReturnPeriod, selectMeanLossPercentBreakdownColumnsOrderedFor475ReturnPeriod } from '#selectors/entities/models.js';
import RepairCostAt475ReturnPeriodPlot from './RepairCostAt475ReturnPeriodPlot.jsx';

const mapStateToProps = (state, ownProps) => {
  const loss = selectMeanLossBreakdownPercentFor475ReturnPeriod(state, ownProps);
  const fieldsSortOrder = (
    selectMeanLossPercentBreakdownColumnsOrderedFor475ReturnPeriod(state, ownProps)
  );
  return ({
    loss,
    fieldsSortOrder,
  });
};

export default connect(mapStateToProps)(RepairCostAt475ReturnPeriodPlot);
