/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType } from 'react';

import React from 'react';
import { useParams } from 'react-router-dom';

/* Higher order component to inject url parameters into a component */

function withRouteParams<EnhancedComponentProps>(
  Component: ComponentType<{|...EnhancedComponentProps, routeParams: {}|}>
): ComponentType<EnhancedComponentProps> {
  return (props: EnhancedComponentProps) => {
    const routeParams = useParams();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component routeParams={routeParams} {...props} />;
  };
}

export default withRouteParams;
