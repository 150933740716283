/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { COMPONENT_UUID } from '#constants/pathStrings.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import ComponentSelector from '#components/pages/Components/support/ComponentSelector/index.js';

const mapStateToProps = (state, ownProps) => {
  const { routeParams } = ownProps;
  const selectedUuid = get(COMPONENT_UUID, routeParams);
  return {
    selectedUuid,
  };
};

export default pipe(
  connect(mapStateToProps),
  withRouteParams,
)(ComponentSelector);
