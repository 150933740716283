/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectMeanLossPercentBreakdownByReturnPeriod,
  selectMeanLossPercentBreakdownColumnsOrderedArray,
} from '#selectors/entities/models.js';
import MeanLossBreakdownByIntensityTable from './MeanLossBreakdownByIntensityTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const meanLossData = selectMeanLossPercentBreakdownByReturnPeriod(state, ownProps);
  const meanLossColumns = selectMeanLossPercentBreakdownColumnsOrderedArray(state, ownProps);
  return ({
    meanLossData,
    meanLossColumns,
  });
};

export default connect(mapStateToProps)(MeanLossBreakdownByIntensityTable);
