/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { PDF_GEN_SUCCESS, RUN_STARTED, SPEX_SERVICE_SUCCESS } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import { statusReached } from '@hbrisk/sp3-risk-model-support/models/runStatusHelpers.js';
import { withProgressCardFunctionality } from '#components/pages/Models/Model/support/Router/withSectionFunctionality/withSectionLevelStatusIndication/support/ProgressCard/index.js';
import DownloadsProgressCard from './DownloadsProgressCard.jsx';

const calculatePercentComplete = (desiredStatus, status, elapsed) => {
  if (statusReached(status, desiredStatus)) {
    return 100;
  }
  const options = {
    RUN_STARTED: {
      lower: 1,
      upper: 50,
      rate: 500,
    },
    SPEX_SERVICE_SUCCESS: {
      lower: 50,
      upper: 99,
      rate: 2000,
    },
  };
  const { lower, upper, rate } = options[status];
  const next = Math.round(elapsed / rate) + lower;
  return (next < upper) ? Math.max(next, lower) : upper - 1;
};

const calculateProgressText = (percentComplete, status) => {
  if (percentComplete < 25) {
    return '';
  }
  switch (status) {
    case RUN_STARTED:
      return 'Updating and running model ...';
    case SPEX_SERVICE_SUCCESS:
      return 'Creating downloads ...';
    case PDF_GEN_SUCCESS:
      return 'Downloads ready ...';
    default:
      return '';
  }
};

export default withProgressCardFunctionality({
  calculatePercentComplete,
  calculateProgressText,
  desiredStatus: PDF_GEN_SUCCESS,
})(DownloadsProgressCard);
