/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';
// eslint-disable-next-line import/extensions
import { IoMdClose } from 'react-icons/io';
import classNames from 'classnames';

type Props = {|
  index: number,
  handleDelete: Function,
  isRequired: boolean,
|};

const ActionCell = (props: Props): Element<'div'> | null => {
  const {
    index,
    handleDelete,
    isRequired,
  } = props;

  const deleteButton = (
    <div className="text-center" title="Delete">
      <Button
        id={`${index}DeleteButton`}
        color="link"
        onClick={handleDelete}
        disabled={isRequired}
        className={classNames('p-0', 'delete-button')}
      >
        <IoMdClose size="1em" />
      </Button>
    </div>
  );
  return isRequired ? null : deleteButton;
};

export default ActionCell;
