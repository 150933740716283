// @flow
import type { Element } from 'react';
import React from 'react';
import type {
  Option,
  OptionsProp,
  PossiblyGroupedOptions,
  State,
  StrictOption,
  StrictOptionGroup,
} from '#components/pages/Components/support/types/options';

type GetDynamicOptions = (
  OptionsProp,
  State
) => (PossiblyGroupedOptions);

const getDynamicOptions: GetDynamicOptions = (options, state) => {
  if (typeof options === 'function') {
    return state && state.values ? options(state) : [];
  }
  return options;
};

type ToStrictOption = (Option) => StrictOption;

const toStrictOption: ToStrictOption = (item) => {
  if (typeof item === 'string') {
    return {
      value: item,
      text: item,
    };
  }
  return item;
};

type GetNormalizedOptions = (
  OptionsProp,
  State
) => Array<StrictOption | StrictOptionGroup>;
const getNormalizedOptions: GetNormalizedOptions = (options, state) => {
  const optionsData = getDynamicOptions(options, state);
  return optionsData.map((item) => {
    if (typeof item !== 'string' && item.options) {
      return {
        label: item.label,
        options: item.options.map(toStrictOption),
      };
    }
    return toStrictOption(item);
  });
};

type GetSelectedOption = (
  OptionsProp,
  string,
  State
) => StrictOption | void;

const getSelectedOption: GetSelectedOption = (options, value, state) => {
  const normalizedOptions = getNormalizedOptions(options, state);
  return normalizedOptions.reduce((acc, item) => {
    if (item.options) {
      const result = item.options.find((o) => (o.value !== undefined && o.value === value));
      return result || acc;
    }
    return item.value !== undefined && item.value === value
      ? item
      : acc;
  }, undefined);
};

type OptionElement = Element<'option'>
type OptionGroupElement = Element<'optgroup'>;

type GetOptionElement = (StrictOption) => OptionElement;
const getOptionElement: GetOptionElement = (option) => ((option.value)
  ? (
    <option
      value={option.value}
      key={`${option.value}-${option.text}`}
    >
      {option.text}
    </option>
  ) : <option />);

type GetOptionElements = (OptionsProp, State) => Array<OptionElement | OptionGroupElement>;
export const getOptionElements: GetOptionElements = (options, state) => {
  const normalizedOptions = getNormalizedOptions(options, state);
  return normalizedOptions.map(
    (item) => {
      if (item.options) {
        return (
          <optgroup label={item.label} key={item.label}>
            {
              item.options.map(getOptionElement)
            }
          </optgroup>
        );
      }
      return getOptionElement(item);
    }
  );
};

type GetSelectedOptionText = (OptionsProp, string, State) => string;
export const getSelectedOptionText: GetSelectedOptionText = (options, value, state) => {
  const selectedOption = getSelectedOption(options, value, state);
  return selectedOption && selectedOption.text
    ? selectedOption.text
    : '';
};
