/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React, { useState } from 'react';
import numeral from 'numeral';
import isInteger from 'lodash/fp/isInteger.js';
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  LineSeries,
  MarkSeries,
  DiscreteColorLegend,
  Hint,
} from 'react-vis';
import { colorLegend, hint } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import { COLORS } from '#constants/plotColors.js';
import getLocationLabel from '#support/models/locationHelpers/getLocationLabel.js';
import CustomAxisLabel, { defaultLeftOffset } from '#components/pages/Models/Model/sections/support/plots/CustomAxisLabel.jsx';

type ModeShape = {
  floor: number,
  mode: number,
  value: number,
};

type ModeShapeDataByMode = {
  [number]: ModeShape[]
}

const mapDataToCoordinates = (modeData) => modeData.map((item) => ({
  x: item.value,
  y: item.floor,
}));

const lines = (modeShapeDataByMode, modes) => modes.map((mode) => (
  <LineSeries
    key={mode}
    color={COLORS[mode - 1]}
    data={mapDataToCoordinates(modeShapeDataByMode[mode])}
  />
));

const marks = (modeShapeDataByMode, modes, setTip) => modes.map((mode) => (
  <MarkSeries
    key={mode}
    color={COLORS[mode - 1]}
    data={mapDataToCoordinates(modeShapeDataByMode[mode])}
    onValueMouseOver={(value) => setTip(value)}
    onValueMouseOut={() => setTip(false)}
    markStyle={{ opacity: 0.8 }}
    size={3}
  />
));

type Props = {|
  modeShapeDataByMode: ModeShapeDataByMode,
  maxModeValue: number,
  floors: number[],
  modes: number[],
|};

const ModeShapePlot = (props: Props): Element<'div'> => {
  const {
    modeShapeDataByMode,
    maxModeValue,
    floors,
    modes,
  } = props;

  const [tipId, setTip] = useState(false);

  const width = 600;
  const height = 400;
  const leftMargin = 70;
  const xAxisTitle = 'Mode Shapes';

  const xAxisFormat = (val) => numeral(val).format('0.00');
  const yAxisFormat = (floor) => {
    const location = getLocationLabel(
      floor,
      'floor',
      floors.length
    );
    return isInteger(location)
      ? numeral(floor).format('0')
      : location;
  };

  const legendItems = modes.map((mode) => ({
    title: (
      <span>
        Mode
        {' '}
        {mode}
      </span>
    ),
    color: COLORS[mode - 1],
    strokeWidth: 12,
  }));

  return (
    <div
      className="mode-shape-plot"
      style={{ position: 'relative', width: width + 50, height: height + 50 }}
    >
      <XYPlot
        width={width}
        height={height}
        xDomain={[-maxModeValue, maxModeValue]}
        onMouseLeave={() => setTip(false)}
        margin={{ left: leftMargin }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickFormat={xAxisFormat}
        />
        <YAxis
          tickValues={floors}
          tickFormat={yAxisFormat}
        />

        {lines(modeShapeDataByMode, modes)}

        {marks(modeShapeDataByMode, modes, setTip)}

        {tipId ? (
          <Hint value={tipId} className={hint}>
            <div>
              <div>
                Floor:
                {yAxisFormat(tipId.y)}
              </div>
              <div>
                Value:
                {xAxisFormat(tipId.x)}
              </div>
            </div>
          </Hint>
        ) : null}

        <DiscreteColorLegend
          className={colorLegend}
          color="grey"
          width={200}
          items={legendItems}
          style={{
            position: 'absolute',
            top: '15px',
            left: `${width}px`,
          }}
        />
      </XYPlot>
      <CustomAxisLabel
        title="Floors"
        innerHeight={height}
        innerWidth={width}
        leftOffset={leftMargin + defaultLeftOffset + -15}
      />
      <CustomAxisLabel
        title={xAxisTitle}
        xAxis
        innerHeight={height}
        innerWidth={width}
      />
    </div>
  );
};

export default ModeShapePlot;
