/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const items = [
  { id: 'ylbf-brace-config-option-diagonal', value: 'diagonal', name: 'Diagonal' },
  { id: 'ylbf-brace-config-option-chevron', value: 'chevron', name: 'Chevron' },
  { id: 'tdmf-brace-config-option-1_story_x', value: '1_story_x', name: 'Single-Story X' },
];

const YlbfBraceConfigurationSelectMenu = (
  { form, name }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'ylbf-brace-configuration-select')}
    includeEmptyOption
  />
);

export default YlbfBraceConfigurationSelectMenu;
