import { pipe } from 'ramda';
import { reduxForm } from 'redux-form';
import { COMPONENT_FORM } from '@hbrisk/sp3-risk-model-support/components/form/name.js';
import withInitialization
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withInitialization/index.js';
import withValidation
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withValidation/index.js';
import withCustomHandleSubmit
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withCustomHandleSubmit/index.js';

const withComponentForm = (C) => pipe(
  withInitialization,
  withCustomHandleSubmit,
  reduxForm({
    destroyOnUnmount: false,
    form: COMPONENT_FORM,
    onSubmit: () => {},
  }),
  withValidation,
)(C);

export default withComponentForm;
