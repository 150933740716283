/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { selectModelRunCreatedAt } from '#selectors/entities/models.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import ModelPageSubNavSnippet from '#components/pages/Models/Model/SubNav/Snippet/Snippet.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId, modelType } = ownProps;
  const modelTypeName = modelTypesById[modelType].name;
  return ({
    modelTypeName,
    runCreatedAt: selectModelRunCreatedAt(state, { modelId }),
  });
};
export default connect(mapStateToProps)(ModelPageSubNavSnippet);
