/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { getModelDownloadsData } from '#selectors/entities/models.js';
import DownloadsList from '#components/pages/Models/Model/support/DownloadsList/index.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId } = ownProps;
  return {
    downloads: getModelDownloadsData(state, { modelId }),
  };
};

export default connect(mapStateToProps)(DownloadsList);
