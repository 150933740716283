/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { setMaintenanceOverride } from '#actions/app/maintenance.js';
import { selectIsMaintenanceMode } from '#selectors/ui/app.js';
import Maintenance from './Maintenance.jsx';
import withAppMaintenanceModePolling from '#components/support/polling/withAppMaintenanceModePolling/index.js';
import withAppVersionPolling from '#components/support/polling/withAppVersionPolling/index.js';

const mapStateToProps = (state) => ({
  isMaintenanceMode: selectIsMaintenanceMode(state),

});

const mapDispatchToProps = (dispatch) => ({
  setMaintenanceOverride: (override) => {
    dispatch(setMaintenanceOverride(override));
  },
});

export default pipe(
  withAppMaintenanceModePolling,
  withAppVersionPolling,
  connect(mapStateToProps, mapDispatchToProps)
)(Maintenance);
