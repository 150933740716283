import get from 'lodash/fp/get.js';
import keyBy from 'lodash/fp/keyBy.js';

import siteClasses from '#constants/models/siteClasses.js';

const siteClassesByValue = keyBy('value', siteClasses);

const getSiteClassRange = (value) => get(`${value}.range`, siteClassesByValue);

export default getSiteClassRange;
