import { map, pipe } from 'ramda';
import difference from 'lodash/fp/difference.js';
import every from 'lodash/fp/every.js';
import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import includes from 'lodash/fp/includes.js';
import isArray from 'lodash/fp/isArray.js';
import isEmpty from 'lodash/fp/isEmpty.js';
import range from 'lodash/fp/range.js';
import replace from 'lodash/fp/replace.js';
import size from 'lodash/fp/size.js';
import split from 'lodash/fp/split.js';
import uniq from 'lodash/fp/uniq.js';
import validator from 'validator';
import { commaSeparatedList } from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import {
  coordinatesInsideJapan,
  coordinatesInsideUS,
  countryIsJapan,
  countryIsUS,
} from '#support/models/localeHelpers/index.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';
import isLightFrameOrRWFD from '#support/models/buildingType/isLightFrameOrRWFD/index.js';
import {
  currentModelId,
  selectModeShapeFloors,
  selectModeShapeModes,
  selectUploadedGroundMotionErrors,
  selectUploadedModeShapeErrors,
} from '#selectors/entities/models.js';
import getMinDesignCodeYearForBuildingType
  from '#support/models/buildingType/getMinDesignCodeYearForBuildingType.js';
import getDesignCodeYearDescFromDesignCodeYear
  from '#support/models/getDesignCodeYearDescFromDesignCodeYear.js';
import getMinYearOfConstructionForBuildingType
  from '#support/models/buildingType/getMinYearOfConstructionForBuildingType.js';

export * from '#support/models/form/modelForm/validators/validateFloorPlanByStoryFieldArray/index.js';
export * from '#support/models/form/modelForm/validators/componentPopulation/index.js';
export * from '#support/models/form/modelForm/validators/validateStructuralResponsesFieldArray/index.js';

export const commaSeparatedListOfReturnPeriods = commaSeparatedList('Must be a comma separated list of return periods');

const { isInt } = validator;

const getArrOfStringsFromCommaSepString = pipe(
  replace(/\s/g, ''),
  split(','),
);

const getArrOfIntsFromCommaSepString = pipe(
  getArrOfStringsFromCommaSepString,
  map((x) => parseInt(x, 10))
);

const isUniq = (arr) => isArray(arr) && arr.length === uniq(arr).length;

export const allIReturnPeriodsAreIntegersBetween1And99999 = pipe(
  getArrOfStringsFromCommaSepString,
  every((val) => isInt(val, { min: 1, max: 99999 })),
  (res) => (res ? undefined : 'Return Periods must be integers between 1 and 99999'),
);

export const noDuplicateReturnPeriods = pipe(
  getArrOfIntsFromCommaSepString,
  isUniq,
  (res) => (res ? undefined : 'Must not have duplicate return periods'),
);

export const returnPeriodsInclude475 = pipe(
  getArrOfIntsFromCommaSepString,
  includes(475),
  (res) => (res ? undefined : 'Must include 475 year return period'),
);

export const mustHaveFewerThan20ReturnPeriods = pipe(
  getArrOfIntsFromCommaSepString,
  (res) => (size(res) <= 20 ? undefined : 'Must have less than 20 return periods'),
);

export const mustHaveAtLeast2ReturnPeriods = pipe(
  getArrOfIntsFromCommaSepString,
  (res) => (size(res) >= 2 ? undefined : 'Must have at least 2 return periods'),
);

export const mceGreaterThanDE = (val, values) => {
  const { returnPeriodDE } = values;
  return parseInt(val, 10) > parseInt(returnPeriodDE, 10)
    ? undefined
    : 'MCE must be greater than than DE';
};

export const coordinatesAreInUS = (val, allValues) => {
  const { lat, lng } = allValues;
  return coordinatesInsideUS(lat, lng) ? undefined : 'Site must be within the United States';
};

export const countryAndCoordsMatchUSorJapan = (val, allValues) => {
  const { lat, lng, country } = allValues;
  const isUSmismatch = countryIsUS(country) && !coordinatesInsideUS(lat, lng);
  const isJPmismatch = countryIsJapan(country) && !coordinatesInsideJapan(lat, lng);
  if (isUSmismatch) {
    return 'Coordinates are not in the bounds of the selected country (United States)';
  }
  if (isJPmismatch) {
    return 'Coordinates are not in the bounds of the selected country (Japan)';
  }
  return undefined;
};

export const emptyOrInt1ToNumberOfStories = (val, values) => {
  const { numberOfStories } = values;
  if (isEmpty(val)) {
    return undefined;
  }
  const min = 1;
  const message = `Must be an integer between ${min} and the number of stories in the building`;
  if (!numberOfStories) {
    return isInt(`${val}`) ? undefined : message;
  }
  return isInt(`${val}`, { min, max: parseInt(numberOfStories, 10) }) ? undefined : message;
};

const emptyOrDateAfter = (
  actualYear,
  minYear,
  maxYear,
  message,
) => {
  if (actualYear === undefined || isInt(`${actualYear}`, { min: minYear, max: maxYear })) {
    return false;
  }
  return message;
};

export const yearAfterConstructionOrCodeYear = (val, allValues) => {
  const { designCodeYear, yearOfConstruction } = allValues;
  const thisYear = new Date().getFullYear();
  const msg = designCodeYear
    ? `${designCodeYear} (Design Code)`
    : `${yearOfConstruction} (Year of Construction)`;

  return emptyOrDateAfter(
    isEmpty(val) ? undefined : parseInt(val, 10),
    parseInt(designCodeYear || yearOfConstruction, 10),
    thisYear,
    `Retrofit Year must between ${msg} and ${thisYear} (this year)`
  );
};

export const maxStoriesForBuildingType = (val, allValues) => {
  const noStories = !isEmpty(val) && parseInt(val, 10);
  const buildingTypeDir1 = get('buildingTypeDir1', allValues);
  const buildingTypeDir2 = get('buildingTypeDir2', allValues);

  if (noStories && buildingTypeDir1 && buildingTypeDir2) {
    const {
      maxStories: maxStoriesDir1,
      buildingType: buildingTypeDescDir1,
    } = buildingTypesById[buildingTypeDir1];
    const {
      maxStories: maxStoriesDir2,
      buildingType: buildingTypeDescDir2,
    } = buildingTypesById[buildingTypeDir2];

    if (noStories > maxStoriesDir1) {
      return `Maximum Stories for ${buildingTypeDescDir1} is ${maxStoriesDir1}`;
    }
    if (noStories > maxStoriesDir2) {
      return `Maximum Stories for ${buildingTypeDescDir2} is ${maxStoriesDir2}`;
    }
  }
  return undefined;
};

export const buildingTypeByDir = (dir1Field) => (val, allValues) => {
  const dir1 = get(dir1Field, allValues);
  const dir2 = val;
  if (dir2 && dir1) {
    const {
      isPodium: dir1isPodium,
      isRWFD: dir1isRWFD,
    } = buildingTypesById[dir1];

    const {
      isPodium: dir2isPodium,
      isRWFD: dir2isRWFD,
    } = buildingTypesById[dir2];

    return (dir1isPodium === dir2isPodium) && (dir1isRWFD === dir2isRWFD)
      ? undefined
      : 'Structural System is incompatible with Direction 1';
  }
  return undefined;
};

const maxRealizationsForNumberOfStories = (numberOfStories) => {
  if (numberOfStories <= 30) {
    return 10000;
  }
  if (numberOfStories <= 40) {
    return 7500;
  }
  if (numberOfStories <= 60) {
    return 5000;
  }
  return 2500; // greater than 60
};

export const emptyOrMaxRealizationsByStory = (value, allValues) => {
  const numberOfStories = parseInt(getOr(null, 'numberOfStories', allValues), 10);
  const maxRealizations = maxRealizationsForNumberOfStories(numberOfStories);

  if (isEmpty(value) || value <= maxRealizations) {
    return undefined;
  }
  return `Maximum Realizations for ${numberOfStories} stories is ${maxRealizations}`;
};

export const period2 = (dir) => (val, allValues) => {
  const period1DirValue = get(`period1Dir${dir}`, allValues);
  return val < period1DirValue
    ? undefined : 'Must be less than T\u2081';
};

export const period3 = (dir) => (val, allValues) => {
  const period2DirValue = get(`period2Dir${dir}`, allValues);
  return val < period2DirValue ? undefined : 'Must be less than T\u2082';
};

export const canUploadModeShape = (modeShapeMethod, values) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = values;

  const isValid = modeShapeMethod !== 'upload' || (modeShapeMethod === 'upload' && !(
    isLightFrameOrRWFD(buildingTypeDir1)
    || isLightFrameOrRWFD(buildingTypeDir2)
  ));

  return isValid
    ? undefined
    : 'Can\'t upload Mode Shapes when either "Structural System" is Wood Light Frame, Steel Light Frame, or Rigid Wall Flexible Diaphragm';
};

export const noBackendGroundMotionUploadErrors = (value, values, state) => {
  const modelId = currentModelId(state);
  const backendErrors = selectUploadedGroundMotionErrors(state, { modelId });
  if (!Array.isArray(backendErrors)) {
    return undefined;
  }
  return backendErrors.length > 0 ? backendErrors : undefined;
};

export const noBackendModeShapeUploadErrors = (value, values, state) => {
  const modelId = currentModelId(state);
  const backendErrors = selectUploadedModeShapeErrors(state, { modelId });
  if (!Array.isArray(backendErrors)) {
    return undefined;
  }
  return backendErrors.length > 0 ? backendErrors : undefined;
};

export const hasExpectedModeShapeFloors = (value, values, state) => {
  const modelId = currentModelId(state);
  const uploadFloors = selectModeShapeFloors(
    state,
    { modelId, direction: 1 }
  );
  const numberOfStories = parseInt(getOr(null, 'numberOfStories', values), 10);

  if (!Array.isArray(uploadFloors) || uploadFloors.length < 1 || Number.isNaN(numberOfStories)) {
    return undefined;
  }

  const expectedNumberOfFloors = numberOfStories + 1;
  const expectedFloors = [...Array(expectedNumberOfFloors).keys()].map((v) => v + 1);

  const missing = difference(expectedFloors, uploadFloors);
  const unexpected = difference(uploadFloors, expectedFloors);

  const missingErrors = missing.length > 0
    ? [`Missing floors in uploaded file: ${missing.join(', ')}`]
    : [];

  const unexpectedErrors = unexpected.length > 0
    ? [`Unexpected floors in uploaded file: ${unexpected.join(', ')}`]
    : [];

  const errors = [
    ...missingErrors,
    ...unexpectedErrors,
  ];
  return errors.length > 0 ? errors : undefined;
};

export const hasExpectedModeShapeModes = (value, values, state) => {
  const modelId = currentModelId(state);
  const uploadModes = selectModeShapeModes(
    state,
    { modelId, direction: 1 }
  );
  const numberOfStories = parseInt(getOr(null, 'numberOfStories', values), 10);

  if (!Array.isArray(uploadModes) || uploadModes.length < 1 || Number.isNaN(numberOfStories)) {
    return undefined;
  }

  const expectedNumberOfModes = Math.min(numberOfStories, 3);
  const expectedModes = range(1, expectedNumberOfModes + 1);
  const missing = difference(expectedModes, uploadModes);
  const unexpected = difference(uploadModes, expectedModes);

  const missingErrors = missing.length > 0
    ? [`Missing modes in uploaded file: ${missing.join(', ')}`]
    : [];

  const unexpectedErrors = unexpected.length > 0
    ? [`Unexpected modes in uploaded file: ${unexpected.join(', ')}`]
    : [];

  const errors = [
    ...missingErrors,
    ...unexpectedErrors,
  ];
  return errors.length > 0 ? errors : undefined;
};

export const greaterThanMinCodeYear = (value, allValues) => {
  const { buildingTypeDir1, buildingTypeDir2 } = allValues;

  if (!buildingTypeDir1 || !buildingTypeDir2) {
    return undefined;
  }

  const minCodeYearDir1 = getMinDesignCodeYearForBuildingType(buildingTypeDir1);
  const minCodeYearDir2 = getMinDesignCodeYearForBuildingType(buildingTypeDir2);

  const { buildingType: buildingTypeDesc1 } = buildingTypesById[buildingTypeDir1];
  const { buildingType: buildingTypeDesc2 } = buildingTypesById[buildingTypeDir2];

  if (value < minCodeYearDir1) {
    const minCodeYearDir1Desc = getDesignCodeYearDescFromDesignCodeYear(minCodeYearDir1);
    return `Must be ${minCodeYearDir1Desc} or newer for ${buildingTypeDesc1}`;
  }

  if (value < minCodeYearDir2) {
    const minCodeYearDir2Desc = getDesignCodeYearDescFromDesignCodeYear(minCodeYearDir2);
    return `Must be ${minCodeYearDir2Desc} or newer for ${buildingTypeDesc2}`;
  }

  return undefined;
};

export const greaterThanMinYearOfConstruction = (value, allValues) => {
  const minYearOfConstruction = 1800;
  const { buildingTypeDir1, buildingTypeDir2 } = allValues;

  if (!buildingTypeDir1 || !buildingTypeDir2) {
    return undefined;
  }

  const minDir1 = getMinYearOfConstructionForBuildingType(buildingTypeDir1);
  const minDir2 = getMinYearOfConstructionForBuildingType(buildingTypeDir2);

  const { buildingType: buildingTypeDesc1 } = buildingTypesById[buildingTypeDir1];
  const { buildingType: buildingTypeDesc2 } = buildingTypesById[buildingTypeDir2];

  if (value < minYearOfConstruction) {
    return `Must be at least ${minYearOfConstruction}`;
  }

  if (value < minDir1) {
    return `Must be at least ${minDir1} for ${buildingTypeDesc1}`;
  }

  if (value < minDir2) {
    return `Must be at least ${minDir2} for ${buildingTypeDesc2}`;
  }

  return undefined;
};
