/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelsUniqueProjects } from '#selectors/entities/models.js';
import ProjectSelector from './ProjectSelector.jsx';

const mapStateToProps = (state) => {
  const projects = modelsUniqueProjects(state);
  return {
    projects,
  };
};

export default connect(mapStateToProps)(ProjectSelector);
