/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow

import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const items = [
  { id: 'brace-exposure-exposed', value: 'exposed', name: 'Fully Accessible' },
  { id: 'brace-exposure-in-wall', value: 'in_wall', name: 'In Wall' },
];

const BraceExposureSelectMenu = (
  { form, name }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'brace-exposure-select')}
    includeEmptyOption
  />
);

export default BraceExposureSelectMenu;
