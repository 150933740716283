// @flow
import type { Element } from 'react';

import React from 'react';
import { Row, Col } from 'reactstrap';
import Header from '#components/support/layout/Header/index.js';
import Main from '#components/support/layout/MainPageContainer/index.jsx';
import Page from '#components/support/layout/Page/index.js';
import ModelsTable from '#components/pages/Models/Dashboard/ModelsTable/index.js';
import CreateModelButton from '#components/pages/Models/Dashboard/CreateModelButton/index.js';
import { ActionRow } from '#components/support/layout/ActionRow/index.jsx';
import ModelsFilterForm from './ModelsFilterForm/index.js';

type Props = {
  includeHeader?: boolean,
};

const ModelsPage = ({ includeHeader = true }: Props): Element<typeof Page> => (
  <Page className="models-dashboard-page">
    { includeHeader && <Header /> }
    <Main withRoomForHeader={includeHeader !== false}>
      <Row>
        <Col md="12" className="col">
          <h1>Models</h1>
        </Col>
      </Row>
      <ActionRow>
        <Col md="12" className="col">
          <CreateModelButton
            color="link"
            className="float-right"
          >
            Create Model +
          </CreateModelButton>
        </Col>
      </ActionRow>
      <Row>
        <Col md="12" className="col">
          <ModelsFilterForm />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="col">
          <ModelsTable />
        </Col>
      </Row>
    </Main>
  </Page>
);

export default ModelsPage;
