/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { ProgressCard } from '../support/ProgressCard/index.js';

type Props = {|
  percentComplete: number,
  progressText: string,
|};

const RunProgressCard = ({
  percentComplete,
  progressText,
}: Props): Element<typeof ProgressCard> => (
  <ProgressCard
    cardText="We are updating and running your model. It will be available again shortly."
    cardTitle="Updating and Running Model"
    percentComplete={percentComplete}
    progressText={progressText}
  />
);

export default RunProgressCard;
