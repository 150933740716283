/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Card, CardText, CardTitle, Progress,
} from 'reactstrap';

type Props = {|
  percentComplete: number,
  progressText: string,
  cardText: string,
  cardTitle: string
|};

const ProgressCard = ({
  percentComplete, progressText, cardText, cardTitle,
}: Props): Element<typeof Card> => (
  <Card body className="model-progress-card">
    <CardTitle>{cardTitle}</CardTitle>
    <div>
      <CardText>
        {cardText}
      </CardText>
      <div className="text-center model-progress-card-percent mb-2">{`${percentComplete}%`}</div>
      <Progress animated color="primary" value={percentComplete}>{progressText}</Progress>
    </div>
  </Card>
);

export default ProgressCard;
