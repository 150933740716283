/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Container } from 'reactstrap';
import {
  withRoomForHeader as withRoom,
  withoutRoomForHeader as withoutRoom,
} from './index.module.scss';

type Props = {|
  children: Node,
  className?: string | null,
  withRoomForHeader?: boolean,
|};

const MainPageContainer = ({
  children,
  className = null,
  withRoomForHeader = true,
}: Props): Element<typeof Container> => {
  const containerClasses = classNames(
    withRoomForHeader ? withRoom : withoutRoom,
    className
  );
  return (
    <Container className={containerClasses}>
      {children}
    </Container>
  );
};

export default MainPageContainer;
