/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectCurrentTermsContent } from '#selectors/entities/terms.js';
import { fetchTerms } from '#actions/app/terms.js';
import TermsOfService from './TermsOfService.jsx';

const mapStateToProps = (state) => ({
  markdownContent: selectCurrentTermsContent(state),
});

const mapDispatchToProps = (dispatch) => ({
  initializeTerms: () => {
    dispatch(fetchTerms());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);
