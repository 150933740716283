// @flow
import type { Element } from 'react';

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page from '#components/support/layout/Page/index.js';
import Dashboard from '#components/pages/Models/Dashboard/index.jsx';
import Model from '#components/pages/Models/Model/index.js';
import ModelTypes from '#components/pages/Models/ModelTypes/index.js';

import { MODEL_ID, MODEL_TYPES } from '#constants/pathStrings.js';

const Models = (): Element<typeof Page> => (
  <Routes>
    <Route path="/" element={<Dashboard includeHeader={false} />} />
    <Route path={`${MODEL_TYPES}`} element={<ModelTypes includeHeader={false} />} />
    <Route path={`:${MODEL_ID}/*`} element={<Model includeHeader={false} />} />
    <Route path="*" element={<Dashboard includeHeader={false} />} />
  </Routes>
);

export default Models;
