/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import maxBy from 'lodash/fp/maxBy.js';
import { curry, pipe } from 'ramda';
import numeral from 'numeral';

/**
 * Given precision and x  - returns the ceiling of the x at the precision
 *
 * Examples:
 *  ceil(1, 2.33) => 2.4
 *  ceil(2, 2.653) => 2.66
 *  ceil(1, 2.6) => 2.6
 */
export const ceil = curry((prec, x) => Math.ceil(x * (10 ** prec)) / ((10 ** prec)));

/**
 * Given val and precision
 * returns a function that upon calling (with data param)
 * will return the maximum ceil key within the array values at data.key
 */
export const ceilCoordinateValue = (key, precision) => pipe(
  maxBy((item) => get(key, item)),
  get(key),
  ceil(precision),
);

/**
 * Given keys, precision, and data
 * Returns the maximum maxCoordinateValue for the keys array
 */
export const ceilCoordinateOfAllValues = (
  keys,
  precision,
  data,
) => keys.reduce((acc, curr) => {
  const currMax = ceilCoordinateValue(curr, precision)(data);
  return Math.max(currMax, acc);
}, 0);

/**
 * Displays days in days, weeks, or months based on number of days
 */
export const displayDaysFormat = (days) => {
  if (days < 14.5) {
    return `${numeral(days).format('0')} days`;
  } if (days <= 7 * 8) {
    return `${numeral(days / 7).format('0.0')} weeks`;
  }
  return `${numeral(days / 30).format('0.0')} months`;
};
