/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import {
  START_POLLING_MODEL,
  STOP_POLLING_MODEL,
} from '#constants/actionTypes.js';
import {
  selectModelIsFetching,
  selectModelIsUpdating,
  selectModelPollingId,
} from '#selectors/entities/models.js';
import { fetchModel } from '#actions/models/model.js';

const dispatchFetchIfNotSyncing = (getState, dispatch, modelId) => {
  const state = getState();
  const isUpdating = selectModelIsUpdating(state, { modelId });
  const isFetching = selectModelIsFetching(state, { modelId });
  if (!isUpdating && !isFetching) {
    dispatch(fetchModel(modelId));
  }
};

const modelPollerMiddleWare = (store) => (next) => (action) => {
  const { type, payload } = action;
  const { dispatch, getState } = store;
  if (![START_POLLING_MODEL, STOP_POLLING_MODEL].includes(type)) {
    return next(action);
  }
  const { modelId } = payload;
  const state = getState();
  if (type === START_POLLING_MODEL) {
    dispatchFetchIfNotSyncing(getState, dispatch, modelId);
    const POLLING_INTERVAL = 5000;
    const pollingId = setInterval(() => {
      dispatchFetchIfNotSyncing(getState, dispatch, modelId);
    }, POLLING_INTERVAL);
    const nextAction = {
      ...action,
      payload: { ...payload, pollingId },
    };
    return next(nextAction);
  }
  // otherwise STOP_POLLING_MODEL
  const pollingId = selectModelPollingId(state, { modelId });
  clearInterval(pollingId);
  return next(action);
};

export default modelPollerMiddleWare;
