import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import store from '#src/js/store/index.js';
import getComponent from '#components/support/polling/support/withPollingOnMount.jsx';
import { fetchModels } from '#actions/models/model.js';
import { selectAuthUserOrgId } from '#selectors/entities/users.js';
import { selectFetchingModels } from '#selectors/entities/models.js';

const getContainer = (C) => {
  const mapStateToProps = (state) => {
    const authUserOrgId = selectAuthUserOrgId(state);
    return ({
      authUserOrgId,
    });
  };

  const mapDispatchToProps = (dispatch) => ({
    makePoll: (orgId) => () => {
      const POLLING_INTERVAL = 5000;
      let alreadyFetchingModels = selectFetchingModels(store.getState());
      if (orgId && !alreadyFetchingModels) {
        dispatch(fetchModels(orgId));
      }
      const pollingId = setInterval(() => {
        alreadyFetchingModels = selectFetchingModels(store.getState());
        if (orgId && !alreadyFetchingModels) {
          dispatch(fetchModels(orgId));
        }
      }, POLLING_INTERVAL);

      return () => {
        clearInterval(pollingId);
      };
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { authUserOrgId } = stateProps;
    const { makePoll } = dispatchProps;
    return {
      ...ownProps,
      poll: makePoll(authUserOrgId),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
