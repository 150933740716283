/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import sortBy from 'lodash/fp/sortBy.js';
import reverse from 'lodash/fp/reverse.js';
import pipe from 'lodash/fp/pipe.js';
import React from 'react';
import {
  DiscreteColorLegend,
  RadialChart,
} from 'react-vis';
import { colorLegend } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import { componentBreakdownById } from '#constants/models/componentBreakDownProperties.js';

type RepairCostAnnual = {
  total: number,
  structuralComponents: number,
  plumbingAndHvac: number,
  exteriorCladding: number,
  interiorFinishes: number,
  collapse: number,
  otherNonStructuralComponents: number,
  residualDrift: number,
  partitionWalls: number,
};

type Props = {|
  absoluteAnnualLoss: RepairCostAnnual,
  percentAnnualLoss: RepairCostAnnual,
|};

const formatCurrency = (value, precision = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  return formatter.format(value);
};

const RepairCostAnnualPlot = (props: Props): Element<'div'> => {
  const { absoluteAnnualLoss, percentAnnualLoss } = props;

  const annualLossTotalFormatted = formatCurrency(absoluteAnnualLoss.total);

  const width = 350;
  const height = 350;

  const pieSlice = (field) => {
    const angle = Math.round(percentAnnualLoss[field]);
    const label = percentAnnualLoss[field] > 5
      ? `${Math.round(percentAnnualLoss[field])}%`
      : '';
    const color = get(`${field}.color`, componentBreakdownById);
    return {
      angle,
      label,
      color,
    };
  };

  const legend = (field) => {
    const { description, color } = componentBreakdownById[field];
    const absolute = formatCurrency(absoluteAnnualLoss[field]);
    const title = `${description} (${absolute})`;
    return { title, color, strokeWidth: 12 };
  };

  const fields = [
    'structuralComponents',
    'exteriorCladding',
    'partitionWalls',
    'otherNonStructuralComponents',
    'plumbingAndHvac',
    'interiorFinishes',
    'collapse',
    'residualDrift',
  ];

  const fieldsOrderedByPercentAnnualLoss = pipe(
    sortBy((item) => percentAnnualLoss[item]),
    reverse,
  )(fields);

  return (
    <div style={{ position: 'relative', width: width + 50, height: height + 50 }}>
      <div>
        The expected annual loss for this building is:
        {' '}
        {annualLossTotalFormatted}
        .
      </div>
      <div className="pt-2">
        <RadialChart
          width={width}
          height={height}
          showLabels
          labelsRadiusMultiplier={0.7}
          labelsStyle={{ fontSize: 12 }}
          colorType="literal"
          data={fieldsOrderedByPercentAnnualLoss.map(pieSlice)}
        >
          <DiscreteColorLegend
            className={colorLegend}
            color="grey"
            width={225}
            items={fieldsOrderedByPercentAnnualLoss.map(legend)}
            style={{
              position: 'absolute',
              top: '15px',
              left: `${width + 25}px`,
            }}
          />
        </RadialChart>
      </div>
    </div>
  );
};

export default RepairCostAnnualPlot;
