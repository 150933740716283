// /**
//  * Owner: Haselton Baker Risk Group, LLC
// //  * Date: 2022-02-14
//  * Copyright All Rights Reserved
//  */
import { connect } from 'react-redux';

import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import ComponentQuestionGroup from './ComponentQuestions.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const {
    selectVisibleRootComponentQuestionSubGroupsByGroup,
    selectVisibleRootComponentQuestionsByGroupSubGroup,
    selectFormFieldVisibility,
  } = modelTypesByForm[form];

  const getSubGroups = selectVisibleRootComponentQuestionSubGroupsByGroup(state);
  const getQuestions = selectVisibleRootComponentQuestionsByGroupSubGroup(state);
  const showPercentGlazing = selectFormFieldVisibility(state)('percentGlazing');
  const showPercentOfPartitionsAreFireBrake = selectFormFieldVisibility(state)('percentOfPartitionsAreFireBrake');
  const showConcreteShearWallsLoadBearing = selectFormFieldVisibility(state)('concreteShearWallsLoadBearing');
  const showMasonryShearWallsLoadBearing = selectFormFieldVisibility(state)('masonryShearWallsLoadBearing');

  return {
    getSubGroups,
    getQuestions,
    showPercentGlazing,
    showPercentOfPartitionsAreFireBrake,
    showConcreteShearWallsLoadBearing,
    showMasonryShearWallsLoadBearing,
  };
};

export default connect(mapStateToProps)(ComponentQuestionGroup);
