/**
 * Owner: Haselton Baker Risk Group, LLC
 * Date: 9/14/18
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button, ListGroupItem } from 'reactstrap';

export type Download = {|
  type: string,
  name: string,
  path: string,
  extension: string,
|};

type Props = {|
  handleClick: Function,
  download: Download,
|};

const DownloadsListItem = ({ handleClick, download }: Props): Element<typeof ListGroupItem> => (
  <ListGroupItem className="downloads-list-item">
    {download.name}
    <Button
      onClick={handleClick}
      color="link"
      className="float-right"
    >
      Download
    </Button>
  </ListGroupItem>
);

export default DownloadsListItem;
