/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const items = [
  { id: 'yes', name: 'Yes', value: 'yes' },
  { id: 'no', name: 'No', value: 'no' },
];

type Props = {|
  form: string,
  name: string,
  includeEmptyOption: boolean,
  inputClassName: string,
|};

const YesNoSelectMenu = ({
  form,
  name,
  includeEmptyOption,
  inputClassName,
}: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', inputClassName)}
    includeEmptyOption={includeEmptyOption}
  />
);

export default YesNoSelectMenu;
