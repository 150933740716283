/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { arrayRemove } from 'redux-form';
import Action from './ActionCell.jsx';

const mapDispatchToProps = (dispatch, ownProps) => {
  const { index, form } = ownProps;
  return {
    handleDelete: () => {
      dispatch(arrayRemove(form, 'structuralResponses', index));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(Action);
