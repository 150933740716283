/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { ProgressCard } from '../support/ProgressCard/index.js';

type Props = {
  percentComplete: number,
  progressText: string,
}
const DownloadsProgressCard = ({
  percentComplete,
  progressText,
}: Props): Element<typeof ProgressCard> => (
  <ProgressCard
    cardTitle="Creating Downloads"
    cardText="We are running your model and creating your downloads. Your downloads will be be available shortly."
    percentComplete={percentComplete}
    progressText={progressText}
  />
);
export default DownloadsProgressCard;
