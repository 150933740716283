/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import { disabled } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.module.scss';

type CellType = (data: CellData) => Element<'span'>;

const Cell: CellType = (data) => {
  const archived = get('original.archived', data);
  const value = get('value', data);
  return (
    <span className={archived ? disabled : ''}>
      {value}
    </span>
  );
};

const column: ColumnType = {
  id: 'projectName',
  accessor: 'projectName',
  Header: 'Project',
  width: 180,
  Cell,
  additionalClasses: ['text-wrap'],
};

export default column;
