const byField = (bySlice) => {
  const { autofilled, values, visibleFields } = bySlice;
  return Object.keys(visibleFields).reduce((acc, name) => ({
    ...acc,
    [name]: {
      autofilled: autofilled[name],
      value: values[name],
      visible: visibleFields[name],
    },
  }), {});
};

export default byField;
