// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Row, Nav, NavItem,
} from 'reactstrap';
import { UUID, IS_SMART } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { ActionRow, ActionRowButton } from '#components/support/layout/ActionRow/index.jsx';
import type { Component } from '#types/Component/index.js';
import ComponentSummaryTop
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryTop/index.jsx';
import ComponentSummaryBottom
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/index.jsx';
import CreateComponentButton from '#components/pages/Components/Dashboard/Sidebar/CreateComponentButton/index.js';
import ArchiveComponentButton
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ArchiveComponentButton/index.js';
import { archived } from '#components/pages/Components/Components.module.scss';
import Tip from '#components/support/tooltips/Tip/index.jsx';

const NavDivider = () => (<NavItem className="ml-2">|</NavItem>);

type Props = {
  component: Component,
  showCopyButton?: boolean,
  showArchiveToggle?: boolean,
};
const ComponentSummaryView = ({
  component,
  showCopyButton = false,
  showArchiveToggle = false,
}: Props): Element<typeof Row> | Element<typeof Fragment> => {
  if (!component) {
    return (
      <Row>
        <Col>
          <Card className="align-middle text-lg" style={{ minHeight: '800px', textAlign: 'center' }}>
            <CardBody className="text-muted text-lg" style={{ fontSize: '130%', paddingTop: '50%' }}>
              Select a component
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  const isComponentSmart = component[IS_SMART];
  return (
    <div className={component.archived && archived}>
      <ActionRow>
        <Col>
          <Nav className="float-right">
            {
              showCopyButton && (
                <>
                  <NavItem>
                    <CreateComponentButton
                      sourceComponentUuid={component[UUID]}
                      className="copy-component-button"
                      disabled={isComponentSmart}
                    >
                      Copy
                    </CreateComponentButton>
                  </NavItem>
                  { isComponentSmart && (
                    <Tip tipId="copySmartComponent" className="pl-1" />
                  )}
                  <NavDivider />
                </>
              )
            }
            {showArchiveToggle && (
              <>
                <NavItem>
                  <ArchiveComponentButton componentUuid={component[UUID]} className="float-right" />
                </NavItem>
                <NavDivider />
              </>
            )}
            <NavItem>
              <ActionRowButton tag={Link} to="general" className="float-right">Full Details</ActionRowButton>
            </NavItem>
          </Nav>
        </Col>
      </ActionRow>
      <ComponentSummaryTop component={component} />
      <ComponentSummaryBottom component={component} />
    </div>
  );
};

export default ComponentSummaryView;
