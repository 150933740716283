import debounce from 'lodash/fp/debounce.js';
import get from 'lodash/fp/get.js';
import { createSelector } from 'reselect';
import { getFormInitialValues, getFormValues, updateSyncErrors } from 'redux-form';
import makeFormValidator from '@hbrisk/sp3-risk-model-support/utility/form/validation/makeFormValidator/index.js';
import makeSelectAutofilled from '#selectors/ui/form/support/makeSelectAutofilled.js';
import makeSelectVisibleFields from '#selectors/ui/form/support/makeSelectVisibleFields.js';
import {
  HIDE_FIELD,
  INITIALIZE_FORM,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_ARRAY_UNSHIFT,
  REDUX_FORM_CHANGE,
  SHOW_FIELD,
} from '#constants/actionTypes.js';

const saveTypes = [
  HIDE_FIELD,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_ARRAY_UNSHIFT,
  REDUX_FORM_CHANGE,
  SHOW_FIELD,
];

const validationTypes = [
  ...saveTypes,
  INITIALIZE_FORM,
];

const makeFormMiddleware = ({
  form,
  fields,
  saveActionCreator,
  selectCurrentUuid,
  calculatePayload,
  saveDebounceInterval,
  validateDebounceInterval,
  mapStateToParams,
  extensions,
}) => {
  const save = (store) => {
    const { dispatch, getState } = store;
    const state = getState();
    const uuid = selectCurrentUuid(state);
    const values = getFormValues(form)(state);
    const autofilled = makeSelectAutofilled(form)(state);
    const visibleFields = makeSelectVisibleFields(form)(state);
    const rest = typeof mapStateToParams === 'function' ? mapStateToParams(state) : {};
    const payload = calculatePayload({
      values,
      autofilled,
      visibleFields,
      ...rest,
    });
    dispatch(saveActionCreator({ uuid, payload, ...rest }));
  };
  const saveWithDebounce = debounce(saveDebounceInterval || 2000, save);

  const validate = (store) => {
    const { dispatch, getState } = store;
    const state = getState();
    const values = getFormValues(form)(state);
    const autofilled = makeSelectAutofilled(form)(state);
    const visibleFields = makeSelectVisibleFields(form)(state);
    const syncErrors = makeFormValidator(fields)(autofilled, visibleFields, state, values);
    dispatch(updateSyncErrors(form, syncErrors));
  };

  const validateWithDebounce = debounce(validateDebounceInterval || 500, validate);

  const selectFormInitialized = createSelector(
    (state) => getFormInitialValues(form)(state),
    (initial) => initial !== undefined,
  );
  return (store) => (next) => (action) => {
    const result = next(action);
    const { type } = action;

    const actionForm = get('meta.form', action);
    const { getState } = store;
    const state = getState();
    const initialized = selectFormInitialized(state);
    if (
      form === actionForm
      && saveTypes.includes(type)
      && initialized
    ) {
      saveWithDebounce(store);
    }
    if (
      form === actionForm
      && validationTypes.includes(type)
      && initialized
    ) {
      validateWithDebounce(store);
    }
    if (Array.isArray(extensions) && initialized) {
      extensions.forEach((extension) => {
        extension({
          store,
          action,
          form,
          validate,
        });
      });
    }
    return result;
  };
};

export default makeFormMiddleware;
