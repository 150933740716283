/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { head } from 'ramda';
import { connect } from 'react-redux';
import { fetchModel, postModel } from '#actions/models/model.js';
import withNavigate from '#components/support/routing/withNavigate/index.jsx';
import withLocation from '#components/support/routing/withLocation/index.jsx';
import {
  selectAuthUserModelTypes,
  selectAuthUserId,
  selectAuthUserOrgId,
  selectAuthUserPermissions,
} from '#selectors/entities/users.js';
import calculateModelPostPayload from '#src/js/support/models/calculateNewModelPostPayload/index.js';
import { MODEL_TYPES } from '#constants/pathStrings.js';
import CreateModelButton from './CreateModelButton.jsx';

const mapStateToProps = (state) => ({
  userModelTypes: selectAuthUserModelTypes(state),
  userPermissions: selectAuthUserPermissions(state),
  userId: selectAuthUserId(state),
  orgId: selectAuthUserOrgId(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { navigate, location } = ownProps;
  const { search } = location;
  const allow = search ? new URLSearchParams(search).get('allow') : 'false';
  const makeHandleClick = (
    userModelTypes,
    userId,
    userPermissions,
    orgId
  ) => () => {
    if (allow === 'true' || userModelTypes.length > 1) {
      navigate(`${MODEL_TYPES}`);
    } else {
      dispatch(postModel(calculateModelPostPayload(
        head(userModelTypes),
        userId,
        orgId,
        userPermissions,
      )))
        .then((data) => dispatch(fetchModel(data.payload.uuid)))
        .then((data) => {
          navigate(`${data.payload.uuid}`);
        });
    }
  };

  return {
    makeHandleClick,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    userModelTypes,
    userId,
    userPermissions,
    orgId,
  } = stateProps;
  const { makeHandleClick } = dispatchProps;
  return ({
    ...ownProps,
    handleClick: makeHandleClick(
      userModelTypes,
      userId,
      userPermissions,
      orgId
    ),
  });
};

const CreateModelButtonContainer = pipe(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withNavigate,
  withLocation
)(CreateModelButton);

export default CreateModelButtonContainer;
