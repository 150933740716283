import { connect } from 'react-redux';
import { toggleComponentArchived } from '#actions/components/components.js';
import ArchiveComponentButton
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ArchiveComponentButton/ArchiveComponentButton.jsx';
import { selectComponentArchivedByUuid, selectCurrentComponentTogglingArchived } from '#selectors/entities/components.js';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const archived = selectComponentArchivedByUuid(state, { uuid: componentUuid });
  const toggling = selectCurrentComponentTogglingArchived(state);
  return {
    toggling,
    archived,
    showArchive: archived !== true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  makeToggleArchived: (uuid, nextArchived) => () => {
    dispatch(toggleComponentArchived(uuid, nextArchived));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { archived } = stateProps;
  const { makeToggleArchived } = dispatchProps;
  const { componentUuid } = ownProps;
  return ({
    ...stateProps,
    toggleArchived: makeToggleArchived(componentUuid, !archived),
  });
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArchiveComponentButton);
