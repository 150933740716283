/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import ModelComponentsTable from './ModelComponentsTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const { selectFormSyncErrors, selectModelComponentTableData } = modelTypesByForm[ownProps.form];
  return ({
    data: selectModelComponentTableData(state),
    error: selectFormSyncErrors(state, { path: 'componentPopulation._error' }),
  });
};

const ModelComponentsTableContainer = connect(mapStateToProps)(ModelComponentsTable);

export default ModelComponentsTableContainer;
