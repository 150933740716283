/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { sortChange } from '#actions/models/modelsTable.js';
import { modelsFiltered } from '#selectors/entities/models.js';
import { selectModelsTableFilters, selectModelsTableSorted } from '#selectors/ui/modelsTable.js';
import ModelsTable from './ModelsTable.jsx';

const mapStateToProps = (state) => {
  const filters = selectModelsTableFilters(state);
  const data = modelsFiltered(state, { ...filters });
  const sorted = selectModelsTableSorted(state);

  return {
    data,
    sorted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSortedChange: (sorted) => {
    dispatch(sortChange(sorted));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelsTable);
