/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import capitalize from 'lodash/fp/capitalize.js';
import { Table } from 'reactstrap';
import numeral from 'numeral';
import IntensityName from '#components/support/forms/IntensityName/index.js';
import { edpsByKey } from '#constants/edps/index.js';

import type { EdpKey } from '#constants/edps/index.js';
import { structuralResponseTable } from './StructuralResponseTable.module.scss';

type Responses = {
  returnPeriod: number,
  median: number,
};

type LocationResponse = {
  location: number,
  locationLabel: string,
  responses: Array<Responses>,
};

type ResponseDataByLocation = Array<LocationResponse>;

type Props = {|
  edpKey: EdpKey,
  responseDataByLocation: ResponseDataByLocation,
  returnPeriods: Array<number>,
|};

const StructuralResponseTable = (props: Props): Element<typeof Table> => {
  const {
    edpKey,
    responseDataByLocation,
    returnPeriods,
  } = props;

  const { locationType, isPercent } = edpsByKey[edpKey];
  const medianFormat = `0.00${isPercent ? '%' : ''}`;

  return (
    <Table striped bordered className={structuralResponseTable} responsive>
      <thead>
        <tr>
          <th>{capitalize(locationType)}</th>
          {returnPeriods && returnPeriods.map((returnPeriod) => (
            <th key={returnPeriod}>
              <IntensityName includeUploads returnPeriod={returnPeriod} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {responseDataByLocation
          && responseDataByLocation.map(({ location, locationLabel, responses }) => (
            <tr key={location}>
              <td key={`${location}-label`}>{locationLabel}</td>
              {responses.map((row) => (
                <td key={`${location}-${row.returnPeriod}`}>
                  {numeral(row.median).format(medianFormat)}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default StructuralResponseTable;
