/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import pick from 'lodash/fp/pick.js';
import { currentModelId, selectRunOutputDataForReturnPeriod, selectModelType } from '#selectors/entities/models.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import groundMotionIsUploadMethod from '#support/models/groundMotion/groundMotionIsUploadMethod.js';
import IntensityName from './IntensityName.jsx';

const mapStateToProps = (state, ownProps) => {
  const modelId = currentModelId(state);
  const modelTypeId = selectModelType(state, { modelId });
  const { selectFormValues } = modelTypesById[modelTypeId];
  const { returnPeriod, includeUploads } = ownProps;
  const { returnPeriodDE, returnPeriodMCE, groundMotionMethod } = selectFormValues(
    state,
    'returnPeriodDE',
    'returnPeriodMCE',
    'groundMotionMethod'
  );
  const isGroundMotionUpload = groundMotionIsUploadMethod(groundMotionMethod);

  if (includeUploads && isGroundMotionUpload) {
    return {
      isDBE: parseInt(returnPeriodDE, 10) === returnPeriod,
      isMCE: parseInt(returnPeriodMCE, 10) === returnPeriod,
    };
  }
  return pick(['isDBE', 'isMCE'], selectRunOutputDataForReturnPeriod(state, { returnPeriod, modelId }));
};

export default connect(mapStateToProps)(IntensityName);
