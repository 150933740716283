/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Button, Container, Row, Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import StructuralResponsesTable from './Table/index.js';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
|};

const StructuralResponseTableView = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    onBack,
    onNext,
  } = props;
  return (
    <Container>
      <Row>
        <Col>
          <h2>{heading}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="float-right">
            <Button id="addResponse" className="pr-0" to="add" color="link" tag={Link}>+ Add Response</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <StructuralResponsesTable form={form} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pt-3">
            <BackButton onClick={onBack} />
            <NextButton onClick={onNext} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StructuralResponseTableView;
