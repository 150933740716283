/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import RepairCostAt475ReturnPeriodPlot from './RepairCostAt475ReturnPeriodPlot/index.js';

type Props = {|
  modelId: string,
|};

const RepairCostAverageAnnual = ({
  modelId,
}: Props): Element<'div'> => (
  <div id="scenarioExpectedLossBreakdown">
    <h5>
      Scenario Expected Loss (SEL) Breakdown
    </h5>
    <div className="pt-2 pb-2">
      <RepairCostAt475ReturnPeriodPlot modelId={modelId} />
    </div>
  </div>
);

export default RepairCostAverageAnnual;
