/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm, MODEL_TYPE_1_ID } from '#src/js/constants/models/modelTypes/index.js';
import RepairCostBreakdownSection from './RepairCostBreakdownSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form, modelType } = ownProps;
  const { selectFormValues } = modelTypesByForm[form];

  const showAverageAnnual = (
    !!selectFormValues(state, 'includeTimeBasedAnalysis')
    || modelType === MODEL_TYPE_1_ID
  );

  return ({
    showAverageAnnual,
  });
};

export default connect(mapStateToProps)(RepairCostBreakdownSection);
