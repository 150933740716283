/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';

const calculateFieldArrayFieldNames = (path, itemSchema, values) => {
  const fieldArrayValues = get(path, values);
  const fieldArrayLength = Array.isArray(fieldArrayValues) ? fieldArrayValues.length : 0;
  const schemaLength = itemSchema.length;
  let results = [];
  if (!fieldArrayLength) {
    return results;
  }
  for (let i = 0; i < fieldArrayLength; i += 1) {
    for (let j = 0; j < schemaLength; j += 1) {
      const itemField = itemSchema[j];
      if (!itemField.itemSchema) {
        results = [...results, `${path}[${i}].${itemField.name}`];
      } else {
        const nextPath = `${path}[${i}].${itemField.name}`;
        results = [
          ...results,
          ...calculateFieldArrayFieldNames(
            nextPath,
            itemField.itemSchema,
            values
          ),
        ];
      }
    }
  }
  return results;
};

export default calculateFieldArrayFieldNames;
