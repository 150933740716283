/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectRunRegionCostMultiplier,
  selectRunDateCostMultiplier,
  selectRunOccupancyCostMultiplier,
  selectRunBuildingValueModifier,
} from '#selectors/entities/models.js';
import RepairCostExposureScaleFactorsApplied from './RepairCostExposureScaleFactorsApplied.jsx';

const mapStateToProps = (state, ownProps) => ({
  regionCostMultiplier: selectRunRegionCostMultiplier(state, ownProps),
  dateCostMultiplier: selectRunDateCostMultiplier(state, ownProps),
  occupancyCostMultiplier: selectRunOccupancyCostMultiplier(state, ownProps),
  buildingValueModifier: selectRunBuildingValueModifier(state, ownProps),
});

export default connect(mapStateToProps)(RepairCostExposureScaleFactorsApplied);
