/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const items = [
  { id: 'very-low', name: 'Very Low', value: '1' },
  { id: 'low', name: 'Low', value: '2' },
  { id: 'average', name: 'Average', value: '3' },
  { id: 'high', name: 'High', value: '4' },
  { id: 'very-high', name: 'Very High', value: '5' },
];

type Props = {|
  form: string,
  name: string,
  inputClassName: string,
|};

const ConstructionQualitySelectMenu = ({
  form,
  name,
  inputClassName,
}: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', inputClassName)}
    includeEmptyOption
  />
);

export default ConstructionQualitySelectMenu;
