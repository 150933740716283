/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
  answers: any,
|};

const mapItems = (name, answers) => answers.map((answer) => {
  const { value, text } = answer;
  return {
    id: `${name}-answer-${value}`,
    name: text,
    value,
  };
});

const ComponentQuestionSelectMenu = ({
  form, name, answers,
}: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={mapItems(name, answers)}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'component-checklist-question-select')}
  />
);

export default ComponentQuestionSelectMenu;
