/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

const calculateUserRequestPutBody = (values) => {
  const {
    active, firstName, lastName, email, orgId, isAdmin,
  } = values;

  const permissions = isAdmin !== undefined ? { isAdmin } : {};

  return {
    active: !!active,
    firstName,
    lastName,
    email,
    orgId,
    permissions,
  };
};

export default calculateUserRequestPutBody;
