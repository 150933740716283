import uniq from 'lodash/fp/uniq.js';
import { edpKeys } from '@hbrisk/sp3-risk-model-support/edps/edps.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';

const requiredEdpsForBuildingTypes = (buildingTypeDir1, buildingTypeDir2) => (
  buildingTypeDir1 && buildingTypeDir2
    ? uniq([
      ...buildingTypesById[buildingTypeDir1].edps,
      ...buildingTypesById[buildingTypeDir2].edps,
    ]) : edpKeys
);

export default requiredEdpsForBuildingTypes;
