export default [
  {
    lng: -215.8208287,
    lat: 42.29515419,
  },
  {
    lng: -214.5039194,
    lat: 42.65198238,
  },
  {
    lng: -213.643897,
    lat: 43.20704846,
  },
  {
    lng: -213.9395297,
    lat: 44.15859031,
  },
  {
    lng: -214.5039194,
    lat: 45.030837,
  },
  {
    lng: -215.4176932,
    lat: 45.11013216,
  },
  {
    lng: -216.600224,
    lat: 45.34801762,
  },
  {
    lng: -217.3527436,
    lat: 45.9030837,
  },
  {
    lng: -218.2665174,
    lat: 46.10132159,
  },
  {
    lng: -219.2071669,
    lat: 46.14096916,
  },
  {
    lng: -219.2340426,
    lat: 44.67400881,
  },
  {
    lng: -219.6371781,
    lat: 43.96035242,
  },
  {
    lng: -220.8197088,
    lat: 42.88986784,
  },
  {
    lng: -221.0615901,
    lat: 41.74008811,
  },
  {
    lng: -220.980963,
    lat: 40.59030837,
  },
  {
    lng: -220.980963,
    lat: 38.84581498,
  },
  {
    lng: -221.7066069,
    lat: 38.72687225,
  },
  {
    lng: -222.5128779,
    lat: 38.37004405,
  },
  {
    lng: -223.3729003,
    lat: 38.01321586,
  },
  {
    lng: -223.8835386,
    lat: 37.37885463,
  },
  {
    lng: -224.0716685,
    lat: 36.46696035,
  },
  {
    lng: -224.9854423,
    lat: 36.18942731,
  },
  {
    lng: -225.5767077,
    lat: 36.11013216,
  },
  {
    lng: -226.0604703,
    lat: 36.18942731,
  },
  {
    lng: -226.6517357,
    lat: 36.62555066,
  },
  {
    lng: -227.2698768,
    lat: 36.58590308,
  },
  {
    lng: -227.7267637,
    lat: 36.030837,
  },
  {
    lng: -228.1567749,
    lat: 35.43612335,
  },
  {
    lng: -228.9630459,
    lat: 34.72246696,
  },
  {
    lng: -229.8230683,
    lat: 34.52422907,
  },
  {
    lng: -230.1993281,
    lat: 35.11894273,
  },
  {
    lng: -231.2474804,
    lat: 35.19823789,
  },
  {
    lng: -231.9462486,
    lat: 32.5814978,
  },
  {
    lng: -231.4087346,
    lat: 31.86784141,
  },
  {
    lng: -230.6024636,
    lat: 32.46255507,
  },
  {
    lng: -230.6293393,
    lat: 31.78854626,
  },
  {
    lng: -230.7637178,
    lat: 30.79735683,
  },
  {
    lng: -231.2474804,
    lat: 28.73568282,
  },
  {
    lng: -232.0537514,
    lat: 27.9030837,
  },
  {
    lng: -233.075028,
    lat: 27.030837,
  },
  {
    lng: -234.1231803,
    lat: 26.15859031,
  },
  {
    lng: -235.5744681,
    lat: 25.44493392,
  },
  {
    lng: -236.0582307,
    lat: 26.27753304,
  },
  {
    lng: -237.106383,
    lat: 25.96035242,
  },
  {
    lng: -237.4557671,
    lat: 24.17621145,
  },
  {
    lng: -236.9182531,
    lat: 23.70044053,
  },
  {
    lng: -234.3650616,
    lat: 24.57268722,
  },
  {
    lng: -233.881299,
    lat: 25.68281938,
  },
  {
    lng: -233.1287794,
    lat: 25.96035242,
  },
  {
    lng: -231.8387458,
    lat: 25.84140969,
  },
  {
    lng: -230.9518477,
    lat: 27.11013216,
  },
  {
    lng: -230.1724524,
    lat: 27.74449339,
  },
  {
    lng: -228.7480403,
    lat: 25.52422907,
  },
  {
    lng: -228.4524076,
    lat: 25.76211454,
  },
  {
    lng: -230.1455767,
    lat: 29.13215859,
  },
  {
    lng: -229.6886898,
    lat: 29.84581498,
  },
  {
    lng: -228.9092945,
    lat: 30.00440529,
  },
  {
    lng: -228.5330347,
    lat: 31.03524229,
  },
  {
    lng: -228.1298992,
    lat: 32.0660793,
  },
  {
    lng: -227.3505039,
    lat: 32.34361233,
  },
  {
    lng: -226.4904815,
    lat: 32.66079295,
  },
  {
    lng: -225.4423292,
    lat: 32.93832599,
  },
  {
    lng: -224.6091825,
    lat: 33.05726872,
  },
  {
    lng: -223.3460246,
    lat: 33.53303965,
  },
  {
    lng: -222.2441209,
    lat: 33.969163,
  },
  {
    lng: -221.3034714,
    lat: 34.1277533,
  },
  {
    lng: -219.825308,
    lat: 30.08370044,
  },
  {
    lng: -218.6965286,
    lat: 23.93832599,
  },
  {
    lng: -218.2396417,
    lat: 24.13656388,
  },
  {
    lng: -218.5083987,
    lat: 25.72246696,
  },
  {
    lng: -217.4602464,
    lat: 26.5154185,
  },
  {
    lng: -217.7827548,
    lat: 28.25991189,
  },
  {
    lng: -218.9115342,
    lat: 27.66519824,
  },
  {
    lng: -220.1478163,
    lat: 34.28634361,
  },
  {
    lng: -219.1802912,
    lat: 35.15859031,
  },
  {
    lng: -218.6159015,
    lat: 37.22026432,
  },
  {
    lng: -217.5946249,
    lat: 38.8061674,
  },
  {
    lng: -217.5946249,
    lat: 40.66960352,
  },
  {
    lng: -218.5083987,
    lat: 41.89867841,
  },
  {
    lng: -216.4927212,
    lat: 41.62114537,
  },
  {
    lng: -215.8208287,
    lat: 42.29515419,
  },
];
