/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import withAppMaintenanceModePolling from '#components/support/polling/withAppMaintenanceModePolling/index.js';
import withAppVersionPolling from '#components/support/polling/withAppVersionPolling/index.js';
import { CREATE_PASSWORD_FORM } from '#constants/users/form/createPassword/name.js';
import SignInPage from './SignInPage.jsx';

const mapStateToProps = (state) => ({
  // If there is a user value set in the CREATE_PASSWORD_FORM form, we have determined that the
  // create password form should be shown in place of the sign in form
  shouldShowSignIn: !formValueSelector(CREATE_PASSWORD_FORM)(state, 'show'),
});

export default pipe(
  withAppMaintenanceModePolling,
  withAppVersionPolling,
  connect(mapStateToProps)
)(SignInPage);
