import debounce from 'lodash/fp/debounce.js';
import { change, formValueSelector } from 'redux-form';
import { REDUX_FORM_CHANGE } from '@hbrisk/sp3-risk-model-support/actions/types.js';
import { geocode } from '#actions/models/geocode.js';
import googleGeocodeUrl from '#support/models/geocoding/googleGeocodeUrl.js';
import format from '#support/models/formatCoordinateValue.js';

const setCoordinateFieldValuesBase = (store, form) => {
  const { dispatch, getState } = store;
  const state = getState();
  const {
    street, city, state: st, country,
  } = formValueSelector(form)(state, 'street', 'city', 'state', 'country');
  if (street && city && st && country) {
    dispatch(geocode(googleGeocodeUrl(street, city, st, country)))
      .then(({ payload }) => {
        if (payload) {
          const { lat, lng } = payload;
          dispatch(change(form, 'lat', format(lat)));
          dispatch(change(form, 'lng', format(lng)));
        }
      });
  }
};

const setCoordinateFieldValues = debounce(500, setCoordinateFieldValuesBase);

const geocodeOnAddressChange = ({ store, action, form }) => {
  const { type } = action;

  if (type === REDUX_FORM_CHANGE) {
    const { meta: { field, form: actionForm } } = action;
    if (
      actionForm === form
      && ['street', 'city', 'state', 'zip', 'country'].includes(field)
    ) {
      setCoordinateFieldValues(store, form);
    }
  }
};

export default geocodeOnAddressChange;
