/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { ImWrench } from 'react-icons/im';
import { Snippet } from '#components/support/layout/SubNav/index.js';
import { DEBUG, MODELS } from '#constants/pathStrings.js';

type Props = {|
  includeDebug: boolean,
  modelId: string,
  modelTypeName: string,
  runCreatedAt: Date,
|};
const ModelPageSubNavSnippet = ({
  includeDebug,
  modelId,
  modelTypeName,
  runCreatedAt,
}: Props): Element<'span'> => {
  const text = runCreatedAt
    ? `${modelTypeName} - Last Ran: ${moment(runCreatedAt).format('MM/DD/YY hh:mm a')}`
    : `${modelTypeName}`;

  const wrench = includeDebug
    ? (
      <Link className="ml-2" id="debugBtn" color="link" to={`/${MODELS}/${modelId}/${DEBUG}`}>
        <ImWrench size="1.1em" />
      </Link>
    ) : null;

  const additionalClassNames = runCreatedAt ? 'last-ran' : '';

  return (
    <Snippet className={classNames('model-page-sub-nav-snippet', additionalClassNames)}>
      {text}
      {wrench}
    </Snippet>
  );
};

export default ModelPageSubNavSnippet;
