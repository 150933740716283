/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Button, Table } from 'reactstrap';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {|
fields: {
    length: number,
    map: Function,
    push: Function,
    remove: Function,
  },
  meta: { error: string },
|}
const renderFloorPlanByStory = ({ fields, meta }: Props): Element<typeof Fragment> => {
  const { error } = meta;
  const form = 'type2Model';
  const allowDelete = fields.length > 1 || false;
  const floorPlanByStoryRows = fields.map((field, index) => (
    <tr key={field}>
      <td>
        <GridField
          name={`${field}.locationSpecifier`}
          form={form}
          options={{
            ComponentType: 'text',
            includeFormatting: false,
          }}
        />
      </td>
      <td>
        <GridField
          name={`${field}.ftDir1`}
          form={form}
          options={{
            ComponentType: 'text',
            includeFormatting: false,
            inputGroup: {
              addonType: 'append',
              addonText: 'ft.',
            },
          }}
        />
      </td>
      <td>
        <GridField
          name={`${field}.ftDir2`}
          form={form}
          options={{
            ComponentType: 'text',
            includeFormatting: false,
            inputGroup: {
              addonType: 'append',
              addonText: 'ft.',
            },
          }}
        />
      </td>
      <td>
        <Button
          color="link"
          onClick={() => fields.remove(index)}
          disabled={!allowDelete}
        >
          delete
        </Button>
      </td>
    </tr>
  ));

  return (
    <>
      <Table
        responsive
        className="floor-plan-by-story-table"
      >
        <thead>
          <tr>
            <th>
              Stories
              <Tip tipId="story" />
            </th>
            <th>Direction 1</th>
            <th>Direction 2</th>
          </tr>
        </thead>
        <tbody>
          {floorPlanByStoryRows}
        </tbody>
      </Table>
      { error && <div className="text-danger">{error}</div>}
      <Button
        size="sm"
        id="addFloorPlanRowButton"
        className="float-right"
        color="primary"
        onClick={() => fields.push({})}
      >
        Add Row
      </Button>

    </>
  );
};

export default renderFloorPlanByStory;
