/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

const items = [
  { id: 0, name: 'None', value: 0 },
  { id: 1, name: 'Moderate', value: 1 },
  { id: 2, name: 'Extreme', value: 2 },
];

type Props = {|
  form: string,
  name: string,
|};

const IrregularitySelectMenu = ({ form, name }: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'irregularity-select')}
  />
);

export default IrregularitySelectMenu;
