/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import getOr from 'lodash/fp/getOr.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';

const isTDMF = (buildingType) => getOr(false, `${buildingType}.isTDMF`, buildingTypesById);

export default isTDMF;
