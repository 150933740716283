/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import authorAbbr from '#support/utility/string/authorAbbreviation.js';
import { disabled } from '#components/pages/Models/Dashboard/ModelsTable/ModelsTable.module.scss';

type CellType = (data: CellData) => Element<'span'>;

const accessor = (data: Object) => {
  const { firstName, lastName } = data.createdBy;
  return `${firstName} ${lastName}`;
};

const Cell: CellType = (data: Object) => {
  const { firstName, lastName } = get('original.createdBy', data);
  const archived = get('original.archived', data);
  return (
    <span
      title={`${firstName} ${lastName}`}
      className={archived ? disabled : ''}
    >
      {authorAbbr(firstName, lastName)}
    </span>
  );
};

const column: ColumnType = {
  id: 'author',
  accessor,
  Header: 'Author',
  width: 90,
  Cell,
};

export default column;
