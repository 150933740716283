/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import DownloadsList from './DownloadsList/index.js';

type Props = {|
  heading: string,
  modelId: string,
  onBack: Function,
|};

const DownloadsSection = ({ heading, modelId, onBack }: Props): Element<'div'> => (
  <div>
    <h2>{heading}</h2>
    <DownloadsList modelId={modelId} />
    <BackButton onClick={onBack} />
  </div>
);

export default DownloadsSection;
