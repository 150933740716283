/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ActionRow, ActionRowButton } from '#components/support/layout/ActionRow/index.jsx';
import { CREATE } from '#constants/pathStrings.js';
import ViewOrganizationsTable from './ViewOrganizationsTable/index.js';

const ViewOrganizations = (): Element<'div'> => (
  <div>
    <Row>
      <Col md="12">
        <h1>Organizations</h1>
      </Col>
    </Row>
    <ActionRow>
      <Col md="12" className="col">
        <ActionRowButton
          className="float-right create-organization-button"
          tag={Link}
          to={`${CREATE}`}
        >
          Create Organization +
        </ActionRowButton>
      </Col>
    </ActionRow>
    <Row>
      <Col md="12">
        <ViewOrganizationsTable />
      </Col>
    </Row>
  </div>
);

export default ViewOrganizations;
