/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import React from 'react';
import { Col, Row } from 'reactstrap';
import CreateOrganizationForm from './CreateOrganizationForm/index.js';

const CreatOrganization = () => (
  <div>
    <Row>
      <Col md="12">
        <h1>Create Organization</h1>
      </Col>
    </Row>
    <Row>
      <Col md="12">
        <CreateOrganizationForm />
      </Col>
    </Row>
  </div>
);

export default CreatOrganization;
