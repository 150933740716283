import { connect } from 'react-redux';
import { selectGroundMotionDataByReturnPeriod } from '#selectors/entities/models.js';
import GroundMotionTable from './GroundMotionTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const groundMotionData = selectGroundMotionDataByReturnPeriod(state, ownProps);
  return {
    groundMotionData,
  };
};
const GroundMotionTableContainer = connect(mapStateToProps)(GroundMotionTable);

export default GroundMotionTableContainer;
