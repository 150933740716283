/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { required } from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import BasicReduxFormSelectMenu from '#components/support/forms/SelectMenus/BasicReduxFormSelectMenu/index.jsx';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';

type Props = {|
fetchOrgs: Function,
  orgs: { uuid: string, name: string }[],
|};

const OrgSelectMenu = ({
  fetchOrgs, orgs,
}: Props): null | Element<typeof BasicReduxFormSelectMenu> => {
  useEffect(() => {
    if (!orgs.length) {
      fetchOrgs();
    }
  }, [orgs]);

  if (!orgs.length) return null;

  const items = orgs.map((item) => ({
    id: item.uuid,
    name: item.name,
    value: item.uuid,
  }));

  return (
    <BasicReduxFormSelectMenu
      items={items}
      id="orgId"
      name="orgId"
      inputClassName={classNames('custom-select', 'org-select')}
      validate={[required]}
      component={RenderInput}
    />
  );
};

export default OrgSelectMenu;
