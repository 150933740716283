/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Row } from 'reactstrap';
import { mainHeadingRow } from './index.module.scss';

type Props = {|
  children: Node,
|};

const MainHeadingRow = ({ children }: Props): Element<typeof Row> => (
  <Row className={mainHeadingRow}>
    {children}
  </Row>
);
export default MainHeadingRow;
