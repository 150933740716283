/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import ModelFormSubmitButton from '#components/pages/Models/Model/support/ModelFormSubmitButton/index.js';

type Props = {|
  modelId: string,
  modelType: number,
|};

const ModelFormSectionSubmitButton = ({
  modelId,
  modelType,
}: Props): Element<typeof ModelFormSubmitButton> => (
  <ModelFormSubmitButton
    className="ml-2 float-right"
    modelType={modelType}
    modelId={modelId}
  />
);

export default ModelFormSectionSubmitButton;
