/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import {
  FETCH_ACTION,
  GEOCODE_REQUEST,
  GEOCODE_RESPONSE,
} from '#constants/actionTypes.js';

export const geocode = (url) => ({
  [FETCH_ACTION]: {
    types: [GEOCODE_REQUEST, GEOCODE_RESPONSE],
    endpoint: url,
    withAuth: false,
    dataTransform: (data) => get('results[0].geometry.location', data),
  },
});
