/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import Header from '#components/support/layout/Header/index.js';
import MainPageContainer from '#src/js/components/support/layout/MainPageContainer/index.jsx';
import {
  Sidebar,
  MainContentArea,
  SidebarLayout,
  SidebarNavLink,
} from '#src/js/components/support/layout/SidebarLayout/index.js';
import Page from '#components/support/layout/Page/index.js';
import { ORGANIZATION, USER } from '#constants/pathStrings.js';
import OrganizationsSection from './sections/Organizations/index.jsx';
import UsersSection from './sections/Users/index.jsx';

const AdminPage = (): Element<typeof Page> => (
  <Page className="admin-page">
    <Header />
    <MainPageContainer>
      <SidebarLayout>
        <Sidebar md="2" top="62px">
          <Nav vertical>
            <NavItem>
              <SidebarNavLink to={`${ORGANIZATION}`}> Organizations </SidebarNavLink>
            </NavItem>
            <NavItem>
              <SidebarNavLink to={`${USER}`}> Users </SidebarNavLink>
            </NavItem>
          </Nav>
        </Sidebar>
        <MainContentArea md="10" top="-13px">
          <Routes>
            <Route path={`${ORGANIZATION}/*`} element={<OrganizationsSection />} />
            <Route path={`${USER}/*`} element={<UsersSection />} />
            <Route path="*" element={<Navigate to={`${ORGANIZATION}`} />} />
          </Routes>
        </MainContentArea>
      </SidebarLayout>
    </MainPageContainer>
  </Page>
);

export default AdminPage;
