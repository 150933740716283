/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { showModelFormField, hideModelFormField } from '#actions/models/modelForm.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import YearOfConstructionOrCodeYear from './YearOfConstructionOrCodeYear.jsx';

const mapStateToProps = (state, props) => {
  const { form, yearOfConstructionName } = props;

  const {
    selectFormFieldVisibility,
    selectIsCountryUS,
    selectIsCountryJapan,
  } = modelTypesByForm[form];

  const isCountryJapan = selectIsCountryJapan(state);
  const isCountryUS = selectIsCountryUS(state);

  const useYearOfConstruction = selectFormFieldVisibility(state)(yearOfConstructionName);
  const showDesignCodeYearToggle = !isCountryJapan;
  const showYearOfConstructionToggle = isCountryUS;
  const showInternationalVersion = !isCountryJapan && !isCountryUS;
  return {
    useYearOfConstruction,
    showDesignCodeYearToggle,
    showYearOfConstructionToggle,
    showInternationalVersion,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleToggle: (useYearOfConstruction) => {
    const {
      yearOfConstructionName,
      designCodeYearName,
      form,
    } = ownProps;
    if (useYearOfConstruction) {
      dispatch(hideModelFormField(form, yearOfConstructionName));
      dispatch(showModelFormField(form, designCodeYearName));
    } else {
      dispatch(hideModelFormField(form, designCodeYearName));
      dispatch(showModelFormField(form, yearOfConstructionName));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(YearOfConstructionOrCodeYear);
