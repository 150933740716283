/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

export const COLOR_1 = '#1f77b4';
export const COLOR_2 = '#ff7f0e';
export const COLOR_3 = '#2ca02c';
export const COLOR_4 = '#d62728';
export const COLOR_5 = '#9467bd';
export const COLOR_6 = '#8c564b';
export const COLOR_7 = '#e377c2';
export const COLOR_8 = '#7f7f7f';
export const COLOR_9 = '#bcbd22';
export const COLOR_10 = '#17becf';
export const COLOR_11 = '#000';
export const COLORS = [
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
  COLOR_8,
  COLOR_9,
  COLOR_10,
  COLOR_11,
];
