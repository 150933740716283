/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectTotalLossByReturnPeriod } from '#selectors/entities/models.js';
import RepairCostByIntensityTable from './RepairCostByIntensityTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const totalLossData = selectTotalLossByReturnPeriod(state, ownProps);

  return ({
    totalLossData,
  });
};

export default connect(mapStateToProps)(RepairCostByIntensityTable);
