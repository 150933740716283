/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import unset from 'lodash/fp/unset.js';
import resolveFormInputs from '@hbrisk/sp3-risk-model-support/utility/form/resolveFormInputs/index.js';
import {
  AUTOFILL_FORM_FIELD,
  REDUX_FORM_ARRAY_UNSHIFT,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_BLUR,
  REDUX_FORM_CHANGE,
  INITIALIZE_FORM,
  SHOW_FIELD,
  HIDE_FIELD,
  UNAUTOFILL_FORM_FIELD,
} from '#constants/actionTypes.js';

const makeFormReducerPlugin = ({
  fields,
  name,
}) => (state = {}, action = null) => {
  const actionForm = get('meta.form', action);
  if (actionForm !== name) {
    return state;
  }
  const { type, meta, payload } = action;

  switch (type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          payload,
          action
        ),
        initial: payload.values,
      };
    case AUTOFILL_FORM_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            autofilled: { ...state.autofilled, [meta.field]: true },
            values: { ...state.values, [meta.field]: payload },
          },
          action
        ),
        syncErrors: unset(meta.field, state.syncErrors),
      };
    case UNAUTOFILL_FORM_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            autofilled: { ...state.autofilled, [meta.field]: false },
          },
          action
        ),
      };
    case SHOW_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            visibleFields: {
              ...state.visibleFields,
              [payload.name]: true,
            },
          },
          action
        ),
      };
    case REDUX_FORM_BLUR:
    case REDUX_FORM_CHANGE:
    case REDUX_FORM_ARRAY_UNSHIFT:
    case REDUX_FORM_ARRAY_PUSH:
    case REDUX_FORM_ARRAY_REMOVE:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          state,
          action
        ),
      };
    case HIDE_FIELD: {
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            visibleFields: {
              ...state.visibleFields,
              [payload.name]: false,
            },
          },
          action
        ),
      };
    }
    default:
      return state;
  }
};

export default makeFormReducerPlugin;
