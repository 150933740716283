// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';

type Props = {
  children: Node,
  className?: string | null,
}
const SidebarLayout = ({ children, className = null }: Props): Element<typeof Row> => (
  <Row className={classNames(className, 'sidebar-layout')}>
    {children}
  </Row>
);

export default SidebarLayout;
