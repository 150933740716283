/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button } from 'reactstrap';
// eslint-disable-next-line import/extensions
import { IoMdClose } from 'react-icons/io';
import classNames from 'classnames';

type Props = {|
  index: number,
  handleDelete: Function,
  autofilled: boolean,
|};

const ActionCell = (props: Props): Element<'div'> => {
  const {
    index,
    handleDelete,
    autofilled,
  } = props;

  return (
    <div className="text-center">
      <Button
        id={`${index}DeleteButton`}
        color="link"
        onClick={handleDelete}
        disabled={autofilled}
        className={classNames('p-0', 'delete-button')}
        title="Delete Component"
      >
        <IoMdClose size="1em" />
      </Button>
    </div>
  );
};

export default ActionCell;
