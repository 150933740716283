/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';

import type { BuildingType } from '#constants/buildingTypes/index.js';

type Props = {|
  buildingTypes: Array<BuildingType>,
  materialDescription: string,
  isJapan: boolean,
|};

const BuildingTypeOptionGroup = ({ buildingTypes, materialDescription, isJapan }: Props): Element<'optgroup'> => {
  const options = buildingTypes
    .filter((s) => ((isJapan && s.isJapan) || (!isJapan && !s.isJapan)))
    .map((mt) => (
      <option
        key={mt.buildingTypeId}
        value={mt.buildingTypeId}
      >
        {mt.buildingType}
      </option>
    ));
  return (
    <optgroup label={materialDescription}>
      {options}
    </optgroup>
  );
};

export default BuildingTypeOptionGroup;
