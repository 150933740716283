/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { putUser } from '#actions/users/users.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import { USER_EMAIL_ALREADY_EXISTS } from '#constants/users/errorMessages.js';
import calculateUserRequestPutBody from '#support/users/calculateUserRequestPutBody.js';
import { EDIT_USER } from '#constants/users/form/editUser/name.js';
import EditUserForm from './EditUserForm.jsx';

const makeOnSubmit = (userId) => (values, dispatch) => (
  dispatch(putUser(userId, calculateUserRequestPutBody(values)))
    .catch((action) => {
      const serverMessage = get('payload.message', action);
      const message = [USER_EMAIL_ALREADY_EXISTS].includes(serverMessage)
        ? serverMessage
        : PROBLEM_WITH_REQUEST;
      return Promise.reject(new SubmissionError({ _error: message }));
    })
);

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onSubmit: makeOnSubmit(ownProps.user.uuid),
});

export default connect(undefined, undefined, mergeProps)(reduxForm({
  form: EDIT_USER,
})(EditUserForm));
