/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import * as React from 'react';
import { Col, Row } from 'reactstrap';

type Props = {|
  showContent: boolean,
|};

type GetComponent = (React.ComponentType<Props>) => React.ComponentType<Props>;

const getComponent: GetComponent = (C) => (props: Props) => {
  const { showContent } = props;
  return (
    showContent
      // eslint-disable-next-line react/jsx-props-no-spreading
      ? <C {...props} />
      : (
        <Row style={{ height: '400px' }}>
          <Col className="my-auto text-center">
            <span className="pl-2 text-muted" style={{ fontSize: '1.4em' }}>Loading...</span>
          </Col>
        </Row>
      )
  );
};

export default getComponent;
