import React, { Component } from 'react';

const getComponent = (C) => class extends Component<Props> {
  componentDidMount() {
    const {
      initialize,
      initialized,
      inputs,
    } = this.props;

    if (!initialized && inputs) {
      initialize();
    }
  }

  componentDidUpdate() {
    const {
      initialize,
      initialized,
      inputs,
    } = this.props;
    if (!initialized && inputs) {
      initialize();
    }
  }

  render() {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (<C {...this.props} />);
  }
};

export default getComponent;
