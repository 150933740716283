/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

const validateField = (validation, value, values, array, index, state) => {
  const validators = Array.isArray(validation) ? validation : [validation];
  return validators.reduce(
    (acc, validator) => (acc || validator(value, values, array, index, state)),
    undefined
  );
};

const validateItemFields = (
  itemFieldValidations,
  itemValue,
  values,
  array,
  index,
  state
) => itemFieldValidations.reduce((acc, field) => {
  const { name, validation } = field;
  const result = validateField(validation, itemValue[name], values, array, index, state);
  return result && result.length > 0
    ? { ...acc, [name]: result }
    : acc;
}, {});

export default validateItemFields;
