/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';

type Props = {|
original: {
    locations: string,
    errorCount: number
  },
|};

const LocationCell = (props: Props): Element<'span'> => {
  const { original: { locations, errorCount } } = props;
  return errorCount > 0
    ? <span>--</span>
    : <span className="location">{locations}</span>;
};

export default LocationCell;
