/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { fetchOrganization } from '#actions/organizations/organizations.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import { selectOrgById } from '#selectors/entities/organizations.js';
import EditOrganization from './EditOrganization.jsx';

const mapStateToProps = (state, ownProps) => {
  const { orgId } = ownProps.routeParams;
  const organization = selectOrgById(state, { orgId });
  return ({
    organization,
  });
};

const mapStateToDispatch = (dispatch, ownProps) => {
  const { orgId } = ownProps.routeParams;
  return ({
    fetchOrg: () => dispatch(fetchOrganization(orgId)),
  });
};

export default pipe(
  connect(mapStateToProps, mapStateToDispatch),
  withRouteParams,
)(EditOrganization);
