/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  FETCH_ACTION,
  FETCH_APP_MAINTENANCE_MODE_REQUEST,
  FETCH_APP_MAINTENANCE_MODE_RESPONSE,
  SET_MAINTENANCE_OVERRIDE,
} from '#constants/actionTypes.js';

export const getMaintenanceMode = ({
  [FETCH_ACTION]: {
    types: [FETCH_APP_MAINTENANCE_MODE_REQUEST, FETCH_APP_MAINTENANCE_MODE_RESPONSE],
    endpoint: `${window.location.origin}/maintenance.json`,
    withAuth: false,
    options: {
      method: 'GET',
    },
  },
});

export const setMaintenanceOverride = (override) => ({
  type: SET_MAINTENANCE_OVERRIDE,
  payload: {
    override,
  },
});
