// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  DAMAGE_STATES,
  CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE,
  CONSEQUENCE_AFFECTED_AREA_SQ_FT,
  CONSEQUENCE_FATALITY_MEDIAN,
  CONSEQUENCE_FATALITY_BETA,
  CONSEQUENCE_INJURY_MEDIAN,
  CONSEQUENCE_INJURY_BETA,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { fieldsByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';

import { Field, Fieldset } from '#components/pages/Components/support/form/index.js';
import renderConsequenceDetailsTable from '#components/pages/Components/Component/sections/Consequences/support/renderConsequencePropertyRowTable/index.jsx';

const renderLifeSafetyTable = renderConsequenceDetailsTable([
  CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE,
  CONSEQUENCE_AFFECTED_AREA_SQ_FT,
  CONSEQUENCE_FATALITY_MEDIAN,
  CONSEQUENCE_FATALITY_BETA,
  CONSEQUENCE_INJURY_MEDIAN,
  CONSEQUENCE_INJURY_BETA,
]);

const ConsequencesLifeSafety = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form>
    <Fieldset>
      <legend>Life Safety</legend>
      <Field
        render={renderLifeSafetyTable}
        field={fieldsByName[DAMAGE_STATES]}
        readOnly={readOnly}
        excludeLabel
        excludeInputGroup
      />
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default ConsequencesLifeSafety;
