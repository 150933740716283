export default [
  {
    abbreviation: 'AF',
    name: 'Afghanistan',
  },
  {
    abbreviation: 'AL',
    name: 'Albania',
  },
  {
    abbreviation: 'DZ',
    name: 'Algeria',
  },
  {
    abbreviation: 'AS',
    name: 'American Samoa',
  },
  {
    abbreviation: 'AD',
    name: 'Andorra',
  },
  {
    abbreviation: 'AO',
    name: 'Angola',
  },
  {
    abbreviation: 'AI',
    name: 'Anguilla',
  },
  {
    abbreviation: 'AQ',
    name: 'Antarctica',
  },
  {
    abbreviation: 'AG',
    name: 'Antigua & Barbuda',
  },
  {
    abbreviation: 'AR',
    name: 'Argentina',
  },
  {
    abbreviation: 'AM',
    name: 'Armenia',
  },
  {
    abbreviation: 'AW',
    name: 'Aruba',
  },
  {
    abbreviation: 'AC',
    name: 'Ascension Island',
  },
  {
    abbreviation: 'AU',
    name: 'Australia',
  },
  {
    abbreviation: 'AT',
    name: 'Austria',
  },
  {
    abbreviation: 'AZ',
    name: 'Azerbaijan',
  },
  {
    abbreviation: 'BS',
    name: 'Bahamas',
  },
  {
    abbreviation: 'BH',
    name: 'Bahrain',
  },
  {
    abbreviation: 'BD',
    name: 'Bangladesh',
  },
  {
    abbreviation: 'BB',
    name: 'Barbados',
  },
  {
    abbreviation: 'BY',
    name: 'Belarus',
  },
  {
    abbreviation: 'BE',
    name: 'Belgium',
  },
  {
    abbreviation: 'BZ',
    name: 'Belize',
  },
  {
    abbreviation: 'BJ',
    name: 'Benin',
  },
  {
    abbreviation: 'BM',
    name: 'Bermuda',
  },
  {
    abbreviation: 'BT',
    name: 'Bhutan',
  },
  {
    abbreviation: 'BO',
    name: 'Bolivia',
  },
  {
    abbreviation: 'BA',
    name: 'Bosnia & Herzegovina',
  },
  {
    abbreviation: 'BW',
    name: 'Botswana',
  },
  {
    abbreviation: 'BV',
    name: 'Bouvet Island',
  },
  {
    abbreviation: 'BR',
    name: 'Brazil',
  },
  {
    abbreviation: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    abbreviation: 'VG',
    name: 'British Virgin Islands',
  },
  {
    abbreviation: 'BN',
    name: 'Brunei',
  },
  {
    abbreviation: 'BG',
    name: 'Bulgaria',
  },
  {
    abbreviation: 'BF',
    name: 'Burkina Faso',
  },
  {
    abbreviation: 'BI',
    name: 'Burundi',
  },
  {
    abbreviation: 'KH',
    name: 'Cambodia',
  },
  {
    abbreviation: 'CM',
    name: 'Cameroon',
  },
  {
    abbreviation: 'CA',
    name: 'Canada',
  },
  {
    abbreviation: 'IC',
    name: 'Canary Islands',
  },
  {
    abbreviation: 'CV',
    name: 'Cape Verde',
  },
  {
    abbreviation: 'BQ',
    name: 'Caribbean Netherlands',
  },
  {
    abbreviation: 'KY',
    name: 'Cayman Islands',
  },
  {
    abbreviation: 'CF',
    name: 'Central African Republic',
  },
  {
    abbreviation: 'EA',
    name: 'Ceuta & Melilla',
  },
  {
    abbreviation: 'TD',
    name: 'Chad',
  },
  {
    abbreviation: 'CL',
    name: 'Chile',
  },
  {
    abbreviation: 'CN',
    name: 'China',
  },
  {
    abbreviation: 'CX',
    name: 'Christmas Island',
  },
  {
    abbreviation: 'CP',
    name: 'Clipperton Island',
  },
  {
    abbreviation: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    abbreviation: 'CO',
    name: 'Colombia',
  },
  {
    abbreviation: 'KM',
    name: 'Comoros',
  },
  {
    abbreviation: 'CG',
    name: 'Congo - Brazzaville',
  },
  {
    abbreviation: 'CD',
    name: 'Congo - Kinshasa',
  },
  {
    abbreviation: 'CK',
    name: 'Cook Islands',
  },
  {
    abbreviation: 'CR',
    name: 'Costa Rica',
  },
  {
    abbreviation: 'HR',
    name: 'Croatia',
  },
  {
    abbreviation: 'CU',
    name: 'Cuba',
  },
  {
    abbreviation: 'CW',
    name: 'Curaçao',
  },
  {
    abbreviation: 'CY',
    name: 'Cyprus',
  },
  {
    abbreviation: 'CZ',
    name: 'Czechia',
  },
  {
    abbreviation: 'CI',
    name: 'Côte d’Ivoire',
  },
  {
    abbreviation: 'DK',
    name: 'Denmark',
  },
  {
    abbreviation: 'DG',
    name: 'Diego Garcia',
  },
  {
    abbreviation: 'DJ',
    name: 'Djibouti',
  },
  {
    abbreviation: 'DM',
    name: 'Dominica',
  },
  {
    abbreviation: 'DO',
    name: 'Dominican Republic',
  },
  {
    abbreviation: 'EC',
    name: 'Ecuador',
  },
  {
    abbreviation: 'EG',
    name: 'Egypt',
  },
  {
    abbreviation: 'SV',
    name: 'El Salvador',
  },
  {
    abbreviation: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    abbreviation: 'ER',
    name: 'Eritrea',
  },
  {
    abbreviation: 'EE',
    name: 'Estonia',
  },
  {
    abbreviation: 'SZ',
    name: 'Eswatini',
  },
  {
    abbreviation: 'ET',
    name: 'Ethiopia',
  },
  {
    abbreviation: 'FK',
    name: 'Falkland Islands (Islas Malvinas)',
  },
  {
    abbreviation: 'FO',
    name: 'Faroe Islands',
  },
  {
    abbreviation: 'FJ',
    name: 'Fiji',
  },
  {
    abbreviation: 'FI',
    name: 'Finland',
  },
  {
    abbreviation: 'FR',
    name: 'France',
  },
  {
    abbreviation: 'GF',
    name: 'French Guiana',
  },
  {
    abbreviation: 'PF',
    name: 'French Polynesia',
  },
  {
    abbreviation: 'TF',
    name: 'French Southern Territories',
  },
  {
    abbreviation: 'GA',
    name: 'Gabon',
  },
  {
    abbreviation: 'GM',
    name: 'Gambia',
  },
  {
    abbreviation: 'GE',
    name: 'Georgia',
  },
  {
    abbreviation: 'DE',
    name: 'Germany',
  },
  {
    abbreviation: 'GH',
    name: 'Ghana',
  },
  {
    abbreviation: 'GI',
    name: 'Gibraltar',
  },
  {
    abbreviation: 'GR',
    name: 'Greece',
  },
  {
    abbreviation: 'GL',
    name: 'Greenland',
  },
  {
    abbreviation: 'GD',
    name: 'Grenada',
  },
  {
    abbreviation: 'GP',
    name: 'Guadeloupe',
  },
  {
    abbreviation: 'GU',
    name: 'Guam',
  },
  {
    abbreviation: 'GT',
    name: 'Guatemala',
  },
  {
    abbreviation: 'GG',
    name: 'Guernsey',
  },
  {
    abbreviation: 'GN',
    name: 'Guinea',
  },
  {
    abbreviation: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    abbreviation: 'GY',
    name: 'Guyana',
  },
  {
    abbreviation: 'HT',
    name: 'Haiti',
  },
  {
    abbreviation: 'HM',
    name: 'Heard & McDonald Islands',
  },
  {
    abbreviation: 'HN',
    name: 'Honduras',
  },
  {
    abbreviation: 'HK',
    name: 'Hong Kong',
  },
  {
    abbreviation: 'HU',
    name: 'Hungary',
  },
  {
    abbreviation: 'IS',
    name: 'Iceland',
  },
  {
    abbreviation: 'IN',
    name: 'India',
  },
  {
    abbreviation: 'ID',
    name: 'Indonesia',
  },
  {
    abbreviation: 'IR',
    name: 'Iran',
  },
  {
    abbreviation: 'IQ',
    name: 'Iraq',
  },
  {
    abbreviation: 'IE',
    name: 'Ireland',
  },
  {
    abbreviation: 'IM',
    name: 'Isle of Man',
  },
  {
    abbreviation: 'IL',
    name: 'Israel',
  },
  {
    abbreviation: 'IT',
    name: 'Italy',
  },
  {
    abbreviation: 'JM',
    name: 'Jamaica',
  },
  {
    abbreviation: 'JP',
    name: 'Japan',
  },
  {
    abbreviation: 'JE',
    name: 'Jersey',
  },
  {
    abbreviation: 'JO',
    name: 'Jordan',
  },
  {
    abbreviation: 'KZ',
    name: 'Kazakhstan',
  },
  {
    abbreviation: 'KE',
    name: 'Kenya',
  },
  {
    abbreviation: 'KI',
    name: 'Kiribati',
  },
  {
    abbreviation: 'XK',
    name: 'Kosovo',
  },
  {
    abbreviation: 'KW',
    name: 'Kuwait',
  },
  {
    abbreviation: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    abbreviation: 'LA',
    name: 'Laos',
  },
  {
    abbreviation: 'LV',
    name: 'Latvia',
  },
  {
    abbreviation: 'LB',
    name: 'Lebanon',
  },
  {
    abbreviation: 'LS',
    name: 'Lesotho',
  },
  {
    abbreviation: 'LR',
    name: 'Liberia',
  },
  {
    abbreviation: 'LY',
    name: 'Libya',
  },
  {
    abbreviation: 'LI',
    name: 'Liechtenstein',
  },
  {
    abbreviation: 'LT',
    name: 'Lithuania',
  },
  {
    abbreviation: 'LU',
    name: 'Luxembourg',
  },
  {
    abbreviation: 'MO',
    name: 'Macao',
  },
  {
    abbreviation: 'MG',
    name: 'Madagascar',
  },
  {
    abbreviation: 'MW',
    name: 'Malawi',
  },
  {
    abbreviation: 'MY',
    name: 'Malaysia',
  },
  {
    abbreviation: 'MV',
    name: 'Maldives',
  },
  {
    abbreviation: 'ML',
    name: 'Mali',
  },
  {
    abbreviation: 'MT',
    name: 'Malta',
  },
  {
    abbreviation: 'MH',
    name: 'Marshall Islands',
  },
  {
    abbreviation: 'MQ',
    name: 'Martinique',
  },
  {
    abbreviation: 'MR',
    name: 'Mauritania',
  },
  {
    abbreviation: 'MU',
    name: 'Mauritius',
  },
  {
    abbreviation: 'YT',
    name: 'Mayotte',
  },
  {
    abbreviation: 'MX',
    name: 'Mexico',
  },
  {
    abbreviation: 'FM',
    name: 'Micronesia',
  },
  {
    abbreviation: 'MD',
    name: 'Moldova',
  },
  {
    abbreviation: 'MC',
    name: 'Monaco',
  },
  {
    abbreviation: 'MN',
    name: 'Mongolia',
  },
  {
    abbreviation: 'ME',
    name: 'Montenegro',
  },
  {
    abbreviation: 'MS',
    name: 'Montserrat',
  },
  {
    abbreviation: 'MA',
    name: 'Morocco',
  },
  {
    abbreviation: 'MZ',
    name: 'Mozambique',
  },
  {
    abbreviation: 'MM',
    name: 'Myanmar (Burma)',
  },
  {
    abbreviation: 'NA',
    name: 'Namibia',
  },
  {
    abbreviation: 'NR',
    name: 'Nauru',
  },
  {
    abbreviation: 'NP',
    name: 'Nepal',
  },
  {
    abbreviation: 'NL',
    name: 'Netherlands',
  },
  {
    abbreviation: 'NC',
    name: 'New Caledonia',
  },
  {
    abbreviation: 'NZ',
    name: 'New Zealand',
  },
  {
    abbreviation: 'NI',
    name: 'Nicaragua',
  },
  {
    abbreviation: 'NE',
    name: 'Niger',
  },
  {
    abbreviation: 'NG',
    name: 'Nigeria',
  },
  {
    abbreviation: 'NU',
    name: 'Niue',
  },
  {
    abbreviation: 'NF',
    name: 'Norfolk Island',
  },
  {
    abbreviation: 'KP',
    name: 'North Korea',
  },
  {
    abbreviation: 'MK',
    name: 'North Macedonia',
  },
  {
    abbreviation: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    abbreviation: 'NO',
    name: 'Norway',
  },
  {
    abbreviation: 'OM',
    name: 'Oman',
  },
  {
    abbreviation: 'PK',
    name: 'Pakistan',
  },
  {
    abbreviation: 'PW',
    name: 'Palau',
  },
  {
    abbreviation: 'PS',
    name: 'Palestine',
  },
  {
    abbreviation: 'PA',
    name: 'Panama',
  },
  {
    abbreviation: 'PG',
    name: 'Papua New Guinea',
  },
  {
    abbreviation: 'PY',
    name: 'Paraguay',
  },
  {
    abbreviation: 'PE',
    name: 'Peru',
  },
  {
    abbreviation: 'PH',
    name: 'Philippines',
  },
  {
    abbreviation: 'PN',
    name: 'Pitcairn Islands',
  },
  {
    abbreviation: 'PL',
    name: 'Poland',
  },
  {
    abbreviation: 'PT',
    name: 'Portugal',
  },
  {
    abbreviation: 'PR',
    name: 'Puerto Rico',
  },
  {
    abbreviation: 'QA',
    name: 'Qatar',
  },
  {
    abbreviation: 'RO',
    name: 'Romania',
  },
  {
    abbreviation: 'RU',
    name: 'Russia',
  },
  {
    abbreviation: 'RW',
    name: 'Rwanda',
  },
  {
    abbreviation: 'RE',
    name: 'Réunion',
  },
  {
    abbreviation: 'WS',
    name: 'Samoa',
  },
  {
    abbreviation: 'SM',
    name: 'San Marino',
  },
  {
    abbreviation: 'SA',
    name: 'Saudi Arabia',
  },
  {
    abbreviation: 'SN',
    name: 'Senegal',
  },
  {
    abbreviation: 'RS',
    name: 'Serbia',
  },
  {
    abbreviation: 'SC',
    name: 'Seychelles',
  },
  {
    abbreviation: 'SL',
    name: 'Sierra Leone',
  },
  {
    abbreviation: 'SG',
    name: 'Singapore',
  },
  {
    abbreviation: 'SX',
    name: 'Sint Maarten',
  },
  {
    abbreviation: 'SK',
    name: 'Slovakia',
  },
  {
    abbreviation: 'SI',
    name: 'Slovenia',
  },
  {
    abbreviation: 'SB',
    name: 'Solomon Islands',
  },
  {
    abbreviation: 'SO',
    name: 'Somalia',
  },
  {
    abbreviation: 'ZA',
    name: 'South Africa',
  },
  {
    abbreviation: 'GS',
    name: 'South Georgia & South Sandwich Islands',
  },
  {
    abbreviation: 'KR',
    name: 'South Korea',
  },
  {
    abbreviation: 'SS',
    name: 'South Sudan',
  },
  {
    abbreviation: 'ES',
    name: 'Spain',
  },
  {
    abbreviation: 'LK',
    name: 'Sri Lanka',
  },
  {
    abbreviation: 'BL',
    name: 'St. Barthélemy',
  },
  {
    abbreviation: 'SH',
    name: 'St. Helena',
  },
  {
    abbreviation: 'KN',
    name: 'St. Kitts & Nevis',
  },
  {
    abbreviation: 'LC',
    name: 'St. Lucia',
  },
  {
    abbreviation: 'MF',
    name: 'St. Martin',
  },
  {
    abbreviation: 'PM',
    name: 'St. Pierre & Miquelon',
  },
  {
    abbreviation: 'VC',
    name: 'St. Vincent & Grenadines',
  },
  {
    abbreviation: 'SD',
    name: 'Sudan',
  },
  {
    abbreviation: 'SR',
    name: 'Suriname',
  },
  {
    abbreviation: 'SJ',
    name: 'Svalbard & Jan Mayen',
  },
  {
    abbreviation: 'SE',
    name: 'Sweden',
  },
  {
    abbreviation: 'CH',
    name: 'Switzerland',
  },
  {
    abbreviation: 'SY',
    name: 'Syria',
  },
  {
    abbreviation: 'ST',
    name: 'São Tomé & Príncipe',
  },
  {
    abbreviation: 'TW',
    name: 'Taiwan',
  },
  {
    abbreviation: 'TJ',
    name: 'Tajikistan',
  },
  {
    abbreviation: 'TZ',
    name: 'Tanzania',
  },
  {
    abbreviation: 'TH',
    name: 'Thailand',
  },
  {
    abbreviation: 'TL',
    name: 'Timor-Leste',
  },
  {
    abbreviation: 'TG',
    name: 'Togo',
  },
  {
    abbreviation: 'TK',
    name: 'Tokelau',
  },
  {
    abbreviation: 'TO',
    name: 'Tonga',
  },
  {
    abbreviation: 'TT',
    name: 'Trinidad & Tobago',
  },
  {
    abbreviation: 'TA',
    name: 'Tristan da Cunha',
  },
  {
    abbreviation: 'TN',
    name: 'Tunisia',
  },
  {
    abbreviation: 'TR',
    name: 'Turkey',
  },
  {
    abbreviation: 'TM',
    name: 'Turkmenistan',
  },
  {
    abbreviation: 'TC',
    name: 'Turks & Caicos Islands',
  },
  {
    abbreviation: 'TV',
    name: 'Tuvalu',
  },
  {
    abbreviation: 'UM',
    name: 'U.S. Outlying Islands',
  },
  {
    abbreviation: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    abbreviation: 'UG',
    name: 'Uganda',
  },
  {
    abbreviation: 'UA',
    name: 'Ukraine',
  },
  {
    abbreviation: 'AE',
    name: 'United Arab Emirates',
  },
  {
    abbreviation: 'GB',
    name: 'United Kingdom',
  },
  {
    abbreviation: 'US',
    name: 'United States',
  },
  {
    abbreviation: 'UY',
    name: 'Uruguay',
  },
  {
    abbreviation: 'UZ',
    name: 'Uzbekistan',
  },
  {
    abbreviation: 'VU',
    name: 'Vanuatu',
  },
  {
    abbreviation: 'VA',
    name: 'Vatican City',
  },
  {
    abbreviation: 'VE',
    name: 'Venezuela',
  },
  {
    abbreviation: 'VN',
    name: 'Vietnam',
  },
  {
    abbreviation: 'WF',
    name: 'Wallis & Futuna',
  },
  {
    abbreviation: 'EH',
    name: 'Western Sahara',
  },
  {
    abbreviation: 'YE',
    name: 'Yemen',
  },
  {
    abbreviation: 'ZM',
    name: 'Zambia',
  },
  {
    abbreviation: 'ZW',
    name: 'Zimbabwe',
  },
  {
    abbreviation: 'AX',
    name: 'Åland Islands',
  },
];
