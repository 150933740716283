import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import getComponent from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withInitialization/withInitialization.jsx';
import { initializeComponentDetailForm } from '#actions/components/componentForm.js';
import { selectComponentFormInputsByUuid } from '#selectors/entities/components.js';

const getContainer = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { componentUuid } = ownProps;

    const formInputs = selectComponentFormInputsByUuid(
      state,
      { uuid: componentUuid }
    );

    const initialized = formInputs
      ? true
      : null;

    return ({
      inputs: formInputs,
      initialized,
    });
  };

  const mapDispatchToProps = (dispatch) => ({
    makeInitialize: (inputs) => () => {
      dispatch(initializeComponentDetailForm(inputs));
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { inputs } = stateProps;
    const { makeInitialize } = dispatchProps;
    return {
      ...stateProps,
      ...ownProps,
      initialize: makeInitialize(inputs),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
