import {
  signIn,
  signOut,
  fetchAuthSession,
  confirmSignIn,
  // eslint-disable-next-line import/extensions
} from 'aws-amplify/auth';
import { merge } from 'lodash';
import get from 'lodash/fp/get.js';
import { change } from 'redux-form';
import { normalize, schema } from 'normalizr';
import {
  CREATE_USER_PASSWORD,
  CREATE_USER_PASSWORD_REQUEST,
  CREATE_USER_PASSWORD_RESPONSE,
  CREATE_USER_REQUEST,
  CREATE_USER_RESPONSE,
  FETCH_ACTION,
  FETCH_USER_REQUEST,
  FETCH_USER_RESPONSE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_RESPONSE,
  SIGN_IN_USER,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_RESPONSE,
  SIGN_OUT_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESPONSE,
} from '#constants/actionTypes.js';
import {
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED,
} from '#constants/users/cognitoAuthChallenges.js';
import {
  COGNITO_NOT_AUTHORIZED_EXCEPTION,
  COGNITO_USER_NOT_FOUND_EXCEPTION,
} from '#constants/users/cognitoErrorCodes.js';
import { INVALID_CREDENTIALS } from '#constants/users/errorMessages.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import { CREATE_PASSWORD_FORM } from '#constants/users/form/createPassword/name.js';

const userSchema = new schema.Entity('users', undefined, {
  idAttribute: 'uuid',
});

export const fetchUser = (userId) => ({
  [FETCH_ACTION]: {
    types: [FETCH_USER_REQUEST, FETCH_USER_RESPONSE],
    endpoint: `/user/${userId}`,
    options: {
      method: 'GET',
    },
    errorTransform: () => new Error(PROBLEM_WITH_REQUEST),
  },
});

export const fetchUsers = ({
  [FETCH_ACTION]: {
    types: [FETCH_USERS_REQUEST, FETCH_USERS_RESPONSE],
    endpoint: '/user',
    options: {
      method: 'GET',
    },
    dataTransform: (json) => {
      const defaultEntities = { users: {} };
      const { result, entities } = normalize(json, [userSchema]);
      return {
        result,
        entities: merge(defaultEntities, entities),
      };
    },
  },
});

export const postUser = (body) => ({
  [FETCH_ACTION]: {
    types: [CREATE_USER_REQUEST, CREATE_USER_RESPONSE],
    endpoint: '/user',
    options: {
      method: 'POST',
      body,
    },
  },
});

export const putUser = (userId, body) => ({
  [FETCH_ACTION]: {
    types: [UPDATE_USER_REQUEST, UPDATE_USER_RESPONSE],
    endpoint: `/user/${userId}`,
    options: {
      method: 'PUT',
      body,
    },
  },
});

const signInUserRequest = () => ({
  type: SIGN_IN_USER_REQUEST,
});

const signInUserResponse = (error, data) => ({
  type: SIGN_IN_USER_RESPONSE,
  payload: error || data,
  error: !!error,
});

export const signOutUser = () => ({
  type: SIGN_OUT_USER,
});

const makeSignInUserPayload = async (email, password, dispatch) => {
  try {
    await dispatch(signInUserRequest());
    await signOut(); // Makes sure local storage for cognito is clear and ready to go
    const signInOutput = await signIn({ username: email, password });
    const { isSignedIn, nextStep } = signInOutput;
    if (isSignedIn) {
      // the user is authenticated, continue with the sign in
      return fetchAuthSession()
        .then((session) => {
          const { tokens: { idToken } } = session;
          const attributes = get('payload', idToken);
          const authUserId = get('[custom:uuid]', attributes);
          dispatch(signInUserResponse(
            undefined,
            {
              token: idToken.toString(),
              user: {
                ...attributes,
                uuid: authUserId,
              },
            }
          ));
          return { authUserId };
        });
    }

    // If a new password is required, pass the required user data along to CREATE_PASSWORD_FORM
    // and dispatch a null, but non-error sign in user response action
    if (nextStep.signInStep === CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
      dispatch(change(CREATE_PASSWORD_FORM, 'show', true));
    }
  } catch (error) {
    const message = [
      COGNITO_NOT_AUTHORIZED_EXCEPTION,
      COGNITO_USER_NOT_FOUND_EXCEPTION,
    ].includes(error.name)
      ? INVALID_CREDENTIALS
      : PROBLEM_WITH_REQUEST;

    const errorAction = signInUserResponse(new Error(message));
    dispatch(errorAction);
    return Promise.reject(errorAction);
  }
  dispatch(signInUserResponse(
    undefined,
    {
      token: null,
      user: {
        uuid: null,
      },
    }
  ));
  return { authUserId: null };
};

export const signInUser = (email, password, dispatch) => ({
  type: SIGN_IN_USER,
  payload: makeSignInUserPayload(email, password, dispatch),
});

const createUserPasswordRequest = () => ({
  type: CREATE_USER_PASSWORD_REQUEST,
});

const createUserPasswordResponse = (error, data) => ({
  type: CREATE_USER_PASSWORD_RESPONSE,
  payload: error || data,
  error: !!error,
});

const makeCreateUserPasswordPayload = async (password, dispatch) => {
  await dispatch(createUserPasswordRequest());
  const { isSignedIn } = await confirmSignIn({ challengeResponse: password });
  if (isSignedIn) {
    const session = await fetchAuthSession();
    const { tokens: { idToken } } = session;
    const attributes = get('payload', idToken);
    const authUserId = get('[custom:uuid]', attributes);
    dispatch(createUserPasswordResponse(
      undefined,
      {
        token: idToken.toString(),
        user: {
          ...attributes,
          uuid: authUserId,
        },
      }
    ));
    return { authUserId };
  }
  dispatch(createUserPasswordResponse(
    undefined,
    {
      token: null,
      user: {
        uuid: null,
      },
    }
  ));
  return { authUserId: null };
};

export const createUserPassword = (password, dispatch) => ({
  type: CREATE_USER_PASSWORD,
  payload: makeCreateUserPasswordPayload(password, dispatch),
});
