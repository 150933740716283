/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectUsers } from '#selectors/entities/users.js';
import ViewUsersTable from './ViewUsersTable.jsx';

const mapStateToProps = (state) => ({
  data: selectUsers(state),
});

const ViewUsersContainer = connect(mapStateToProps)(ViewUsersTable);

export default ViewUsersContainer;
