/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import { emptyOrMaxLength100, required } from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import type { Organization } from '#types/Organization.js';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

type Props = {|
  error?: string | null,
  form: string,
  handleSubmit: Function,
  initialize: Function,
  organization: Organization,
  submitSucceeded: boolean,
  submitting: boolean,
|};

const EditOrganizationForm = ({
  error = null, form, handleSubmit, initialize, organization, submitting, submitSucceeded,
}: Props): Element<typeof Form> => {
  useEffect(() => {
    if (organization) {
      initialize({
        orgName: organization.name,
        standard: organization.permissions.modelTypes.includes(1),
        advanced: organization.permissions.modelTypes.includes(2),
        allRealizations: organization.permissions.perRealization,
        recoveryTime: organization.permissions.recoveryTime,
      });
    }
  }, [organization]);

  const redirect = submitSucceeded ? <Navigate to=".." /> : null;
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="edit-org-form" onSubmit={handleSubmit}>
      {alert || redirect}
      <FormGroup tag="fieldset">
        <legend>General</legend>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="orgName">
              Name
            </Label>
            <Field
              id="orgName"
              name="orgName"
              type="text"
              component={RenderInput}
              validate={[required, emptyOrMaxLength100]}
            />
            <FieldFeedback name="orgName" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="orgId">
              Id
            </Label>
            <Input
              id="orgId"
              type="text"
              readOnly
              value={organization.uuid}
            />
            <FieldFeedback name="orgName" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="createdAt">
              Created
            </Label>
            <Input
              id="createdAt"
              type="text"
              readOnly
              value={formatDate(organization.createdAt)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="updatedAt">
              Last Updated
            </Label>
            <Input
              id="updatedAt"
              type="text"
              readOnly
              value={formatDate(organization.updatedAt)}
            />
          </Col>
        </FormGroup>
      </FormGroup>
      <FormGroup tag="fieldset">
        <legend>Permissions</legend>
        <Row>
          <Col lg="6">
            <h6>Model Types</h6>
          </Col>
        </Row>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="standard"
                name="standard"
                type="checkbox"
                component={RenderInput}
              />
              Standard
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="advanced"
                name="advanced"
                type="checkbox"
                component={RenderInput}
              />
              Advanced
            </Label>
          </Col>
        </FormGroup>
        <Row className="pt-2">
          <Col lg="6">
            <h6>Other Options</h6>
          </Col>
        </Row>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="allRealizations"
                name="allRealizations"
                type="checkbox"
                component={RenderInput}
              />
              All Realizations
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="recoveryTime"
                name="recoveryTime"
                type="checkbox"
                component={RenderInput}
              />
              Recovery Time
            </Label>
          </Col>
        </FormGroup>
      </FormGroup>
      <SubmitButton className="float-right" submitting={submitting}>
        Save
      </SubmitButton>
      <Button
        color="link"
        tag={Link}
        to=".."
      >
        Cancel
      </Button>
    </Form>
  );
};

export default EditOrganizationForm;
