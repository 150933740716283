/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  FETCH_ACTION,
  FETCH_APP_VERSION_REQUEST,
  FETCH_APP_VERSION_RESPONSE,
} from '#constants/actionTypes.js';

export const getAppVersion = ({
  [FETCH_ACTION]: {
    types: [FETCH_APP_VERSION_REQUEST, FETCH_APP_VERSION_RESPONSE],
    endpoint: `${window.location.origin}/version.json`,
    withAuth: false,
    options: {
      method: 'GET',
    },
  },
});
