/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType } from 'react';

import React from 'react';
import { useLocation } from 'react-router-dom';

/* Higher order component to inject react router location into a component */

function withLocation<EnhancedComponentProps>(
  Component: ComponentType<{| ...EnhancedComponentProps, location: {} |}>
): ComponentType<EnhancedComponentProps> {
  return (props: EnhancedComponentProps) => {
    const location = useLocation();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component location={location} {...props} />;
  };
}

export default withLocation;
