/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import keyBy from 'lodash/fp/keyBy.js';

export const COLLAPSE_METHOD_FEMA = 'fema';
export const COLLAPSE_METHOD_MCE = 'mce';
export const COLLAPSE_METHOD_MEDIAN = 'median';

const methods = [
  { id: COLLAPSE_METHOD_FEMA, name: 'FEMA P-154', showInJapan: false },
  { id: COLLAPSE_METHOD_MCE, name: 'Probability of Collapse at MCE', showInJapan: false },
  { id: COLLAPSE_METHOD_MEDIAN, name: 'Median', showInJapan: true },
];

export const methodById = keyBy('id', methods);

export default methods;
