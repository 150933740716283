/**
 * Owner: Haselton Baker Risk Group, LLC
 * Date: 12/15/20
 * Copyright All Rights Reserved
 */

import { createSelector } from 'reselect';
import orderBy from 'lodash/fp/orderBy.js';
import values from 'lodash/fp/values.js';

export const orgsById = (state) => state.entities.organizations.byId;

export const selectOrgs = createSelector(
  orgsById,
  (byId) => orderBy(['name'], ['asc'], values(byId)),
);

const selectOrgId = (_, props) => props.orgId;

export const selectOrgById = createSelector(
  orgsById,
  selectOrgId,
  (byId, id) => byId[id]
);
