// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col } from 'reactstrap';
import type { columnProps } from '#components/support/layout/SidebarLayout/types/index.js';
import { sidebar, sidebarSticky } from './index.module.scss';

type Props = {
  children?: Node,
  className?: string | null,
  xs?: columnProps | null,
  sm?: columnProps | null,
  md?: columnProps | null,
  lg?: columnProps | null,
  xl?: columnProps | null,
  top?: string,
};

const Sidebar = ({
  children = null,
  className = null,
  xs = null,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  top = '111px',
}: Props): Element<typeof Col> => (
  <Col
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    // widths={widths}
    className={classNames([sidebar, 'sidebar', className])}
    style={{ top }}
  >
    <div className={sidebarSticky}>
      {children}
    </div>
  </Col>
);

export default Sidebar;
