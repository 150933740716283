/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import filter from 'lodash/fp/filter.js';
import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import groupBy from 'lodash/fp/groupBy.js';
import map from 'lodash/fp/map.js';
import startCase from 'lodash/fp/startCase.js';
import uniq from 'lodash/fp/uniq.js';
import { keys } from 'ramda';
import {
  getFormInitialValues,
  getFormMeta,
  getFormSyncErrors,
  getFormValues,
  formValueSelector,
} from 'redux-form';
import { createSelector } from 'reselect';
import {
  COMPONENT_ID,
  DEFAULT_EDP,
  GROUP,
  NAME,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { rootComponentQuestions } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';
import { ATC_138_ID } from '@hbrisk/sp3-risk-model-support/models/repairTimeMethods.js';
import { getLocationStringFromArray } from '@hbrisk/sp3-risk-model-support/models/locationSpecifier/index.js';
import { edpsByKey } from '#constants/edps/index.js';
import { selectComponentsByUuid } from '#selectors/entities/components.js';
import createDeepEqualSelector from '#selectors/support/createDeepEqualSelector.js';
import countFieldArrayItemErrors from '#support/utility/form/validation/countFieldArrayItemErrors/index.js';
import componentRollup from '#support/components/componentRollup.js';
import getTouchedFromMeta from '#support/utility/form/getTouchedFromMeta/index.js';
import isEdpFloor from '#support/models/isFloor.js';
import { methodById } from '#constants/models/structuralResponseMethods/structuralResponseMethods.js';
import isInvisible from '#selectors/ui/form/support/isVisible.js';
import makeSelectAutofilled from '#selectors/ui/form/support/makeSelectAutofilled.js';
import makeSelectVisibleFields from '#selectors/ui/form/support/makeSelectVisibleFields.js';
import requiredEdpsForBuildingTypes from '#support/models/buildingType/requiredEdpsForBuildingTypes/index.js';

/**
 * Note: All exports on this page should be curried and have a first parameter of form
 */

const isVisible = (field, visibleFields) => !!get(field, visibleFields);

export const selectInvisibleFields = (form) => createSelector(
  (state) => getOr({}, `form[${form}].visibleFields`, state),
  (visibleFields) => keys(visibleFields).filter((field) => isInvisible(field, visibleFields))
);

export const selectVisibleFields = (form) => createSelector(
  (state) => getOr({}, `form[${form}].visibleFields`, state),
  (visibleFields) => keys(visibleFields).filter((field) => isVisible(field, visibleFields))
);

export const selectVisibleRootComponentQuestions = (form) => createSelector(
  selectVisibleFields(form),
  (visibleFields) => (
    rootComponentQuestions.filter((question) => visibleFields.includes(question.name))
  )
);

const selectVisibleRootComponentQuestionsByGroup = (form) => createSelector(
  selectVisibleRootComponentQuestions(form),
  (rootVisibleQuestions) => groupBy('group', rootVisibleQuestions)
);

export const selectVisibleRootComponentQuestionSubGroupsByGroup = (form) => (
  createSelector(
    selectVisibleRootComponentQuestionsByGroup(form),
    (questionsByGroup) => (group) => uniq(map(((q) => q.subGroup), questionsByGroup[group]))
  )
);

export const selectVisibleRootComponentQuestionsByGroupSubGroup = (form) => createSelector(
  selectVisibleRootComponentQuestionsByGroup(form),
  (questionsByGroup) => (group, subGroup) => (
    filter((q) => q.subGroup === subGroup, questionsByGroup[group])
  )
);

const selectName = (_, props) => props.name;

const selectPath = (_, props) => props.path;

export const selectFieldsVisibility = makeSelectVisibleFields;

export const selectFormFieldVisibility = (form) => createSelector(
  selectFieldsVisibility(form),
  (visibleFields) => (field) => isVisible(field, visibleFields)
);

export const makeFormFieldVisibilitySelector = (form) => () => createSelector(
  selectFormFieldVisibility(form),
  (visibleFields) => (field) => visibleFields(field),
);

export const selectFormInitialized = (form) => createSelector(
  (state) => getFormInitialValues(form)(state),
  (initial) => initial !== undefined,
);

export const selectAutofilledFormFields = makeSelectAutofilled;

export const makeFieldIsAutofilledSelector = (form) => () => createSelector(
  selectAutofilledFormFields(form),
  selectName,
  (autofilled, name) => get(name, autofilled)
);

export const selectIsAutofilledFormField = (form) => makeFieldIsAutofilledSelector(form)();

export const selectTouchedFields = (form) => createDeepEqualSelector(
  getFormMeta(form),
  getTouchedFromMeta
);

export const selectAllFormValues = (form) => getFormValues(form);

export const selectFormValues = (form) => formValueSelector(form);

export const selectModelComponentTableData = (form) => createDeepEqualSelector(
  (state) => formValueSelector(form)(state, 'componentPopulation', 'numberOfStories'),
  getFormSyncErrors(form),
  getFormMeta(form),
  selectComponentsByUuid,
  (formValues, errors, meta, componentsById) => {
    const { componentPopulation, numberOfStories: numberOfStoriesStr } = formValues;
    const numberOfStories = parseInt(numberOfStoriesStr, 10);

    if (!componentPopulation
      || componentPopulation.some((popItem) => {
        const { componentUuid } = popItem;
        return !componentsById[componentUuid];
      })) {
      return [];
    }
    return componentPopulation.map((populationItem, index) => {
      const { componentUuid, performanceGroups } = populationItem;
      const {
        [COMPONENT_ID]: componentId,
        [NAME]: name,
        [GROUP]: group,
        [DEFAULT_EDP]: defaultEdpKey,
      } = componentsById[componentUuid];
      const locationType = get(`${defaultEdpKey}.locationType`, edpsByKey);
      const isFloor = isEdpFloor(locationType);
      const rollups = performanceGroups.reduce((acc, curr) => {
        const { quantity, locations, error } = componentRollup(isFloor, numberOfStories, curr);
        return {
          error: acc.error || error,
          quantity: acc.quantity + quantity,
          locations: [...acc.locations, ...locations],
        };
      }, { quantity: 0, locations: [] });
      const locations = getLocationStringFromArray(
        isFloor,
        numberOfStories,
        uniq(rollups.locations).sort((a, b) => a - b)
      );
      const errorCount = countFieldArrayItemErrors('componentPopulation', index, errors, meta);
      return {
        index,
        componentUuid,
        componentId,
        name,
        group: startCase(group),
        locations,
        quantity: rollups.quantity,
        error: rollups.error,
        errorCount,
      };
    });
  }
);

export const selectFormSyncErrors = (form) => createSelector(
  getFormSyncErrors(form),
  selectPath,
  (errors, path) => get(path, errors)
);

const selectBuildingTypeIds = (form) => (state) => formValueSelector(form)(
  state,
  'buildingTypeDir1',
  'buildingTypeDir2',
);

const selectRequiredStructuralResponses = (form) => createSelector(
  selectBuildingTypeIds(form),
  ({ buildingTypeDir1, buildingTypeDir2 }) => (
    requiredEdpsForBuildingTypes(buildingTypeDir1, buildingTypeDir2)
  ),
);

export const selectStructuralResponses = (form) => (state) => formValueSelector(form)(state, 'structuralResponses');

export const selectStructuralResponseTableData = (form) => createSelector(
  selectStructuralResponses(form),
  selectRequiredStructuralResponses(form),
  getFormSyncErrors(form),
  getFormMeta(form),
  (responses, requiredResponses, errors, meta) => {
    if (!Array.isArray(responses)) {
      return [];
    }
    return responses.map((response, index) => {
      const errorCount = countFieldArrayItemErrors('structuralResponses', index, errors, meta);
      const { edpKey, method } = response;
      const { name, locationType, edpUnit } = edpsByKey[edpKey];
      return {
        ...response,
        responseIndex: index,
        name,
        methodDescription: method ? methodById[method].name : null,
        locationType,
        edpUnit,
        errorCount,
        isRequired: requiredResponses.includes(edpKey),
      };
    });
  },
);

export const selectEdpKeys = (form) => createSelector(
  selectStructuralResponses(form),
  (responses) => responses.map((response) => response.edpKey),
);

export const selectRepairTimeMethodSupportsServiceLocations = (form) => createSelector(
  (state) => formValueSelector(form)(state, 'repairTimeMethod'),
  (method) => method === ATC_138_ID
);

export const selectCountry = (form) => (state) => formValueSelector(form)(state, 'country');

export const selectIsCountryUS = (form) => createSelector(
  selectCountry(form),
  (country) => country === 'US',
);

export const selectIsCountryJapan = (form) => createSelector(
  selectCountry(form),
  (country) => country === 'JP',
);
