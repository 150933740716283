// @flow
import type { Element } from 'react';
import React, { Fragment } from 'react';

type Props = {
  text: string,
  addonType: string,
};
const EntityModeAddon = ({ text, addonType }: Props): Element<typeof Fragment> => (
  <>
    {addonType === 'append' && ' '}
    <span>{text}</span>
    {addonType === 'prepend' && ' '}
  </>
);

export default EntityModeAddon;
