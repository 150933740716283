/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import isEmpty from 'lodash/fp/isEmpty.js';

import {
  INITIALIZE_MODEL_PAGE,
  REDUX_FORM_CHANGE,
  RESET_MODEL_PAGE,
} from '#constants/actionTypes.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import {
  REPAIR_TIME_OPTIONS_SECTION,
  REPAIR_TIME_SECTION,
} from '#constants/models/modelPage/sectionIds.js';

const defaultState = {};

const getModelPageReducer = (modelTypeId) => (state = defaultState, action = {}) => {
  const { type, meta, payload } = action;
  const actionModelTypeId = get('modelType', meta);
  const actionFormName = get('form', meta);
  const actionField = get('field', meta);
  const modelTypeFormName = modelTypesById[modelTypeId].modelFormName;

  if (![RESET_MODEL_PAGE, REDUX_FORM_CHANGE].includes(type)) {
    if (actionModelTypeId !== modelTypeId) {
      return state;
    }
  }
  switch (type) {
    case RESET_MODEL_PAGE:
      return defaultState;
    case INITIALIZE_MODEL_PAGE:
      return ({
        activeSectionGroupIds: payload.sectionGroupIds,
        activeSectionIds: payload.sectionIds,
        initialized: true,
      });
    case REDUX_FORM_CHANGE: {
      let updates = {};
      if (actionField === 'includeRepairTime') {
        updates = {
          [REPAIR_TIME_OPTIONS_SECTION]: payload,
          [REPAIR_TIME_SECTION]: payload,
        };
      }
      return isEmpty(updates) || actionFormName !== modelTypeFormName
        ? state
        : ({
          ...state,
          activeSectionIds: { ...state.activeSectionIds, ...updates },
        });
    }
    default:
      return state;
  }
};

export default getModelPageReducer;
