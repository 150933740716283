/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import { FieldArray } from 'redux-form';
import renderEditComponent from './renderEditComponent/index.jsx';

type Props = {|
  form: string,
  currentComponent: {},
|};

const EditComponentItem = ({ form, currentComponent }: Props): Element<'div'> => (
  <div>
    <Form noValidate>
      <FieldArray
        form={form}
        currentComponent={currentComponent}
        name="componentPopulation"
        component={renderEditComponent}
      />
    </Form>
  </div>
);
export default EditComponentItem;
