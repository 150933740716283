/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import methods from '#constants/models/collapseDefinitionMethods.js';

type Props = {|
  form: string,
  name: string,
  isJapan: boolean,
|};

const CollapseDefinitionMethodSelectMenu = ({
  form,
  name,
  isJapan,
}: Props): Element<typeof SelectMenu> => {
  const items = methods
    .filter((item) => !isJapan || (isJapan && item.showInJapan))
    .map(({ id, name: key }) => ({
      id,
      name: key,
      value: id,
    }));

  return (
    <SelectMenu
      form={form}
      items={items}
      id={name}
      name={name}
      includeEmptyOption={false}
    />
  );
};

export default CollapseDefinitionMethodSelectMenu;
