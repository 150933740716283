// @flow
import type { Element } from 'react';
import React from 'react';
import { Col, Row } from 'reactstrap';
import ComponentSubNavSnippet from './Snippet/index.js';
import {
  BreadCrumb,
  BreadCrumbDivider,
  BreadCrumbTrail,
  SubNav,
} from '#components/support/layout/SubNav/index.js';

type Props = {
  componentId: string,
  componentUuid: string,
  top?: string,
}
const ComponentSubNav = ({ componentId, componentUuid, top = '61px' }: Props): Element<typeof SubNav> => (
  <SubNav className="pt-2" top={top}>
    <Row>
      <Col>
        <BreadCrumbTrail>
          <BreadCrumb to="./">Components</BreadCrumb>
          <BreadCrumbDivider />
          <BreadCrumb>
            {componentId}
          </BreadCrumb>
        </BreadCrumbTrail>
      </Col>
      <Col>
        <Row>
          <Col>
            <ComponentSubNavSnippet componentUuid={componentUuid} />
          </Col>
        </Row>
      </Col>
    </Row>
  </SubNav>
);

export default ComponentSubNav;
