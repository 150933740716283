import getOr from 'lodash/fp/getOr.js';
import { FILTERS, EXPANDED_NODES, TREE_DATA } from '#reducers/ui/componentSelector/index.js';
import { COMPONENT_SELECTOR } from '#reducers/ui/index.js';

export const selectFilters = (componentSelectorName) => (state) => getOr(
  {},
  `ui[${COMPONENT_SELECTOR}][${componentSelectorName}][${FILTERS}]`,
  state
);

export const selectExpandedNodes = (componentSelectorName) => (state) => getOr(
  {},
  `ui[${COMPONENT_SELECTOR}][${componentSelectorName}][${EXPANDED_NODES}]`,
  state
);

export const selectTreeData = (componentSelectorName) => (state) => getOr(
  {},
  `ui[${COMPONENT_SELECTOR}][${componentSelectorName}][${TREE_DATA}]`,
  state
);
