// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { InputGroup } from 'reactstrap';
import BaseField from '#components/pages/Components/support/form/Field/BaseField/index.js';
import InputGroupAddon from '#components/pages/Components/support/form/InputGroupAddon/index.js';
import Label from '#components/pages/Components/support/form/Label/index.jsx';
import getUnqualifiedFiledName
  from '#support/components/form/getUnqualifiedFieldName.js';
import ReadOnlyView
  from '#components/pages/Components/support/form/Field/ReadOnlyView/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';

type Props = {
  excludeInputGroup?: boolean,
  excludeLabel?: boolean,
  excludeTip?: boolean,
  excludeFeedback?: boolean,
  field: Object,
  item?: string | null,
  render?: Function,
  visible: boolean,
  readOnly?: boolean,
};

const Field = ({
  excludeInputGroup = false,
  excludeLabel = false,
  excludeTip = false,
  excludeFeedback = false,
  field,
  item = null,
  render = null,
  visible,
  readOnly = false,
}: Props): Element<typeof Fragment> | null => {
  if (!visible) {
    return null;
  }
  const unqualifiedName = getUnqualifiedFiledName(item, field.name);
  const excludeL = excludeLabel || field.excludeLabel;
  const excludeIG = excludeInputGroup || field.excludeInputGroup;
  const excludeF = excludeFeedback || field.excludeFeedback;
  const excludeT = excludeTip || field.excludeTip;

  const fieldElement = excludeIG || (!field.prefix && !field.suffix)
    ? (
      <BaseField
        form={field.form}
        item={item}
        name={unqualifiedName}
        options={field.options}
        type={field.type}
        readOnly={readOnly}
        readOnlyComponent={ReadOnlyView}
        render={render}
        format={field.format}
        normalize={field.normalize}
      />
    )
    : (
      <InputGroup>
        {
          field.prefix && (
            <InputGroupAddon field={field} addonType="prepend" />
          )
        }
        <BaseField
          form={field.form}
          item={item}
          name={unqualifiedName}
          options={field.options}
          type={field.type}
          readOnly={readOnly}
          readOnlyComponent={ReadOnlyView}
          render={render}
          format={field.format}
          normalize={field.normalize}
        />
        {
          field.suffix && (
            <InputGroupAddon field={field} addonType="append" />
          )
        }
      </InputGroup>
    );

  const labelElement = excludeL || !field.label
    ? null
    : (
      <Label excludeTip={excludeT} field={field} htmlFor={unqualifiedName}>
        {field.label}
      </Label>
    );
  const feedbackElement = excludeF || field.feedback === false
    ? null
    : <FieldFeedback form={field.form} name={unqualifiedName} />;

  return (
    <>
      {labelElement}
      {fieldElement}
      {feedbackElement}
    </>
  );
};

export default Field;
