/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import groupBy from 'lodash/fp/groupBy.js';
import head from 'lodash/fp/head.js';
import kebabCase from 'lodash/fp/kebabCase.js';
import last from 'lodash/fp/last.js';
import noop from 'lodash/fp/noop.js';
import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { SPEX_SERVICE_SUCCESS } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import componentsById from '#constants/models/modelPage/sectionComponentsById.js';
import RequireAdminPermissions from '#components/support/routing/RequireAdminPermissions/index.js';
import { DEBUG } from '#constants/pathStrings.js';
import Debug from '#components/pages/Models/Model/sections/shared/DebugSection/index.js';
import withSectionFunctionality from '#components/pages/Models/Model/support/Router/withSectionFunctionality/index.js';

type Section = {
  component: any,
  desiredStatus: string,
  pagerHeading?: string,
  heading: string,
  id: string,
  group: string,
}
type SectionGroup = {
  id: string,
};

type Props = {|
  sections: Section[],
  sectionGroups: SectionGroup[],
  modelFormName: string,
  modelId: string,
  modelType: number,
  handleUnmount: Function,
|};

const getUrl = get('url');

const getLastInGroup = (gId, a) => {
  const sectionsByGroup = groupBy('group', a);
  const sectionsInGroup = sectionsByGroup[gId];
  return last(sectionsInGroup).id;
};

const mapSectionsToRoutes = (
  sections,
  lastInGroupByGroup,
  lastSectionGroupId,
  navigate,
  modelFormName,
  modelType,
  modelId,
) => sections.map((section, index, arr) => {
  const {
    desiredStatus,
    group,
    pagerHeading,
    heading,
    id,
  } = section;
  const component = componentsById[id];
  const nextUrl = getUrl(arr[index + 1]);
  const backUrl = getUrl(arr[index - 1]);
  const currentSectionPath = get('path', section);

  const isLastInGroup = id === lastInGroupByGroup[group];
  const groupIsLast = group === lastSectionGroupId;

  const onNext = nextUrl
    ? () => {
      navigate(`./${nextUrl}`);
    }
    : noop;

  const onBack = backUrl
    ? () => {
      navigate(`./${backUrl}`);
    }
    : noop;

  const ModelSection = withSectionFunctionality(component);
  return (
    <Route
      key={id}
      path={currentSectionPath}
      element={(
        <ModelSection
          desiredStatus={desiredStatus}
          group={group}
          groupIsLast={groupIsLast}
          onNext={onNext}
          onBack={onBack}
          heading={pagerHeading || heading}
          isLastInGroup={isLastInGroup}
          modelId={modelId}
          form={modelFormName}
          modelType={modelType}
          sectionGroup={section.group}
        />
      )}
    />
  );
});

const mapSectionGroupsToRedirects = (sectionsByGroup) => {
  const groups = Object.keys(sectionsByGroup);
  return groups.map((group) => (
    <Route
      key={group}
      path={`${kebabCase(group)}`}
      element={
        (
          <Navigate
            to={`./${getUrl(head(sectionsByGroup[group]))}`}
          />
        )
      }
    />
  ));
};

const Router = ({
  sections,
  sectionGroups,
  modelFormName,
  modelType,
  modelId,
  handleUnmount,
}: Props): Element<typeof Routes> => {
  const navigate = useNavigate();

  useEffect(() => handleUnmount, []);

  const sectionsByGroup = groupBy('group', sections);

  const lastInGroupByGroup = sectionGroups.reduce(
    (acc, { id }) => ({ ...acc, [id]: getLastInGroup(id, sections) }),
    {},
  );

  const lastSectionGroupId = last(sectionGroups).id;
  const pageableRoutes = mapSectionsToRoutes(
    sections,
    lastInGroupByGroup,
    lastSectionGroupId,
    navigate,
    modelFormName,
    modelType,
    modelId,
  );
  const sectionGroupRedirects = mapSectionGroupsToRedirects(sectionsByGroup);
  const firstSectionUrl = getUrl(head(sections));

  return (
    <Routes>
      {pageableRoutes}
      {sectionGroupRedirects}
      <Route
        path={`/${DEBUG}`}
        element={
          (
            <RequireAdminPermissions>
              <Debug
                desiredStatus={SPEX_SERVICE_SUCCESS}
                modelId={modelId}
                modelType={modelType}
                form={modelFormName}
              />
            </RequireAdminPermissions>
          )
        }
      />
      <Route
        path="*"
        element={<Navigate to={`./${firstSectionUrl}`} />}
      />
    </Routes>
  );
};

export default Router;
