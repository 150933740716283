import { FETCH_MODEL_RESPONSE } from '#constants/actionTypes.js';

const validateOnFetchResponse = ({ store, action, validate }) => {
  const { type } = action;
  if (type === FETCH_MODEL_RESPONSE) {
    validate(store);
  }
};

export default validateOnFetchResponse;
