/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectRunOutputDBE,
  selectRunOutputMCE,
  selectHasGroundMotionData,
  selectIsGroundMotionMatch,
} from '#selectors/entities/models.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import GroundMotionSection from './GroundMotionSection.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelId, form } = ownProps;
  const { selectFormValues, selectFormFieldVisibility } = modelTypesByForm[form];
  const {
    includeDesignIntensities,
    groundMotionMethod: method,
    groundMotionUpload: upload,
    country,
  } = selectFormValues(state, 'includeDesignIntensities', 'groundMotionMethod', 'groundMotionUpload', 'country');

  const isGroundMotionMatch = selectIsGroundMotionMatch(state, { modelId, method, upload });
  const hasGroundMotionData = selectHasGroundMotionData(state, { modelId });

  return {
    country,
    dbe: selectRunOutputDBE(state, { modelId }),
    mce: selectRunOutputMCE(state, { modelId }),
    showIncludeDesignIntensities: selectFormFieldVisibility(state)('includeDesignIntensities'),
    showDesignIntensities: includeDesignIntensities === true,
    shouldShowReturnPeriods: selectFormFieldVisibility(state)('returnPeriods'),
    shouldShowReturnPeriodDEandMCE: isGroundMotionMatch && hasGroundMotionData && selectFormFieldVisibility(state)('returnPeriodDE'),
    shouldShowUploader: selectFormFieldVisibility(state)('groundMotionUpload'),
    shouldShowGroundMotionTable: isGroundMotionMatch && hasGroundMotionData,
  };
};

const GroundMotionContainer = connect(mapStateToProps)(GroundMotionSection);

export default GroundMotionContainer;
