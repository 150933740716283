/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import MeanLossBreakdownByIntensityTable from './MeanLossBreakdownByIntensityTable/index.js';
import RepairCostRatioBreakdownPlot from './RepairCostRatioBreakdownPlot/index.js';
import RepairCostAt475ReturnPeriod from './RepairCostAt475ReturnPeriod/index.jsx';
import RepairCostAverageAnnual from './RepairCostAverageAnnual/index.jsx';

type Props = {|
  heading: string,
  modelId: string,
  onBack: Function,
  onNext: Function,
  showAverageAnnual: boolean,
|};

const RepairCostBreakdownSection = ({
  heading, modelId, onBack, onNext, showAverageAnnual,
}: Props): Element<'div'> => (
  <div>
    <h2>
      {heading}
      {' '}
      <Tip tipId="repairCostBreakdown" />
    </h2>
    <div className="pt-2 pb-2">
      <h5>Expected Loss Normalized by Building Cost</h5>
      <MeanLossBreakdownByIntensityTable modelId={modelId} />
    </div>
    <div className="pt-2 pb-2">
      <h5>Contribution of component groups to Mean Loss Ratio</h5>
      <RepairCostRatioBreakdownPlot modelId={modelId} />
    </div>
    <div className="pt-2 pb-2">
      <RepairCostAt475ReturnPeriod modelId={modelId} />
    </div>
    {showAverageAnnual && (
      <RepairCostAverageAnnual modelId={modelId} />
    )}
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </div>
);

export default RepairCostBreakdownSection;
