/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import last from 'lodash/fp/last.js';
import map from 'lodash/fp/map.js';
import pipe from 'lodash/fp/pipe.js';
import reverse from 'lodash/fp/reverse.js';
import React from 'react';
import {
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  AreaSeries,
  LineSeries,
  DiscreteColorLegend,
} from 'react-vis';
import { v4 as uuid } from 'uuid';
import { colorLegend } from '#components/pages/Models/Model/sections/support/plots/Plot.module.scss';
import CustomAxisLabel from '#components/pages/Models/Model/sections/support/plots/CustomAxisLabel.jsx';
import { componentBreakdownById } from '#constants/models/componentBreakDownProperties.js';
import { ceil, ceilCoordinateValue } from '#support/utility/mathFunctions.js';

type IntensityLoss = {
  pga: number,
  returnPeriod: number,
  [string]: number,
};

type Props = {|
  data: IntensityLoss[],
  columns: string[]
|};

const AreaLine = (points, color) => (
  <AreaSeries
    key={uuid()}
    color={color}
    data={points}
    markStyle={{ opacity: 0.8 }}
    size={4}
  />
);

const Line = (points) => (
  <LineSeries
    key={uuid()}
    color="#000000"
    data={points}
    markStyle={{ opacity: 0 }}
    size={4}
  />
);

const RepairCostByIntensityPlot = (props: Props): Element<'div'> => {
  const {
    data,
    columns,
  } = props;

  const mapAreaData = (val) => data.map((item) => {
    const index = columns.indexOf(val);
    const y = columns.reduce((acc, curr, currIndex) => (
      currIndex <= index ? acc + item[curr] : acc
    ), 0);
    return {
      x: item.pga,
      y,
    };
  });

  const mapLineData = (item) => [
    { x: item.pga, y: 0 },
    { x: item.pga, y: item.total },
  ];

  const width = 600;
  const height = 400;

  const maxX = ceilCoordinateValue('pga', 1)(data);
  const maxY = ceil(1, last(data).total);
  return (
    <div style={{ position: 'relative', width: width + 50, height: height + 50 }}>
      <XYPlot
        width={width}
        height={height}
        xDomain={[0, maxX]}
        yDomain={[0, maxY]}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        {
          pipe(
            reverse,
            map((item) => AreaLine(mapAreaData(item), componentBreakdownById[item].color)),
          )(columns)
        }
        { map((item) => Line(mapLineData(item)), data) }

        <DiscreteColorLegend
          className={colorLegend}
          color="grey"
          width={200}
          items={columns.map((item) => {
            const {
              description: title,
              color,
            } = componentBreakdownById[item];
            return { title, color, strokeWidth: 12 };
          })}
          style={{
            position: 'absolute',
            top: '15px',
            left: `${width + 25}px`,
          }}
        />
      </XYPlot>
      <CustomAxisLabel title="Normalized Mean Loss" innerHeight={height} innerWidth={width} />
      <CustomAxisLabel title="PGA [g]" xAxis innerHeight={height} innerWidth={width} />
    </div>
  );
};

export default RepairCostByIntensityPlot;
