// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  DAMAGE_STATES,
  CONSEQUENCE_REPAIR_TIME_LOWER_QTY,
  CONSEQUENCE_REPAIR_TIME_UPPER_QTY,
  CONSEQUENCE_REPAIR_TIME_MIN_AMOUNT,
  CONSEQUENCE_REPAIR_TIME_MAX_AMOUNT,
  CONSEQUENCE_REPAIR_TIME_UNCERTAINTY,
  CONSEQUENCE_REPAIR_TIME_CURVE_TYPE,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { fieldsByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import {
  Field,
  Fieldset,
} from '#components/pages/Components/support/form/index.js';
import render from '#components/pages/Components/Component/sections/Consequences/support/renderConsequencePropertyRowTable/index.jsx';

const renderRepairTimeTable = render([
  CONSEQUENCE_REPAIR_TIME_LOWER_QTY,
  CONSEQUENCE_REPAIR_TIME_UPPER_QTY,
  CONSEQUENCE_REPAIR_TIME_MIN_AMOUNT,
  CONSEQUENCE_REPAIR_TIME_MAX_AMOUNT,
  CONSEQUENCE_REPAIR_TIME_UNCERTAINTY,
  CONSEQUENCE_REPAIR_TIME_CURVE_TYPE,
]);

const ConsequencesRepairTime = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form>
    <Fieldset>
      <legend>Repair Time</legend>
      <Field
        render={renderRepairTimeTable}
        field={fieldsByName[DAMAGE_STATES]}
        readOnly={readOnly}
        excludeLabel
        excludeInputGroup
      />
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default ConsequencesRepairTime;
