/**
 * Owner: Haselton Baker Risk Group, LLC
  * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import get from 'lodash/fp/get.js';
import type { CellData, ColumnType } from '#components/pages/Models/Dashboard/ModelsTable/support/types';
import { getStatusType, getStatusDesc } from '#components/pages/Models/Dashboard/ModelsTable/support/statusHelpers.js';
import StatusComponent from './StatusComponent/index.jsx';

type CellType = (data: CellData) => Element<typeof StatusComponent>;

const accessor = (data: Object) => {
  const status = get('currentRun.status', data);
  return getStatusDesc(status);
};

const Cell: CellType = (data: Object) => {
  const status = get('original.currentRun.status', data);
  const value = get('value', data);
  const statusType = getStatusType(status);
  return (
    <StatusComponent
      value={value}
      statusType={statusType}
    />
  );
};

const column: ColumnType = {
  id: 'status',
  accessor,
  Header: 'Status',
  width: 60,
  Cell,
};

export default column;
