// @flow
import type { Element } from 'react';

import React from 'react';
import indicator from 'ordinal/indicator.js';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {|
  form: string,
  period: number,
  showDir1: boolean,
  showDir2: boolean,
|};

const PeriodRow = (props: Props): null | Element<'tr'> => {
  const {
    form,
    period,
    showDir1,
    showDir2,
  } = props;

  return showDir1 || showDir2 ? (
    <tr>
      <td className="text-nowrap">
        {period}
        {indicator(period)}
        {' '}
        Mode Period (T
        <sub>{period}</sub>
        )
        {' '}
        <Tip tipId={`period${period}`} />
      </td>
      <td>
        {showDir1 && (
          <GridField
            name={`period${period}Dir1`}
            form={form}
            options={{
              ComponentType: 'text',
              includeFormatting: false,
              inputGroup: {
                addonType: 'append',
                addonText: 'sec',
              },
            }}
          />
        )}
      </td>
      <td>
        {showDir2 && (
          <GridField
            name={`period${period}Dir2`}
            form={form}
            options={{
              ComponentType: 'text',
              includeFormatting: false,
              inputGroup: {
                addonType: 'append',
                addonText: 'sec',
              },
            }}
          />
        )}
      </td>
    </tr>
  ) : null;
};

export default PeriodRow;
