/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { combineReducers } from 'redux';
import maintenance from './maintenance.js';
import version from './version.js';

export default combineReducers({
  maintenance,
  version,
});
