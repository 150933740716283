/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { combineReducers } from 'redux';
import models from './models/index.js';
import components from './components/index.js';
import componentCategories from './componentCategories/index.js';
import organizations from './organizations/index.js';
import terms from './terms/index.js';
import users from './users/index.js';

export default combineReducers({
  models,
  components,
  componentCategories,
  terms,
  organizations,
  users,
});
