/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import classNames from 'classnames';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import getDesignCodeYearDescFromDesignCodeYear from '#support/models/getDesignCodeYearDescFromDesignCodeYear.js';

const optionIdPrefix = 'ep-design-code-select-option';
const years = [1997, 2002, 2005, 2010, 2016, 2022];
const options = [
  ...(
    years.map((year) => ({
      id: `${optionIdPrefix}-${year}`,
      name: getDesignCodeYearDescFromDesignCodeYear(year),
      value: year,
    }))
  ),
  {
    id: `${optionIdPrefix}-override`,
    name: 'User Defined Accelerations',
    value: 'override',
  },
];

type Props = {|
  form: string,
  name: string,
|};

const EquipmentPrequalifiedDesignCodeYearSelectMenu = (
  { form, name }: Props
): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={options}
    id={name}
    name={name}
    inputClassName={classNames('custom-select', 'equipment-prequalified-design-code-year-select')}
    includeEmptyOption
  />
);

export default EquipmentPrequalifiedDesignCodeYearSelectMenu;
