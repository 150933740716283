// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { DAMAGE_STATE_CONSEQUENCES } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {
  fields: { push: Function },
  disabled: boolean,
  includeConsequence: boolean,
};

const AddDamageStateButton = ({
  fields,
  disabled,
  includeConsequence,
}: Props): Element<typeof Fragment> => (
  <>

    <Button
      size="sm"
      id="addDamageStateButton"
      className="float-right"
      color="primary"
      onClick={
        () => { fields.push(includeConsequence ? {} : { [DAMAGE_STATE_CONSEQUENCES]: [] }); }
      }
      disabled={disabled}
    >
      Add Damage State
    </Button>
    {disabled && <Tip className="float-right mr-2" tipId="addDamageStateButton" />}
  </>
);

export default AddDamageStateButton;
