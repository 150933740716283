// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import { DAMAGE_STATES } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { fieldsByName } from '@hbrisk/sp3-risk-model-support/components/form/fields/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import { Field } from '#components/pages/Components/support/form/index.js';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import renderMainConsequencesTable
  from '#components/pages/Components/Component/sections/Consequences/Main/renderMainConsequencesTable/index.jsx';

const ConsequencesMain = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form noValidate>
    <Field
      render={renderMainConsequencesTable}
      field={fieldsByName[DAMAGE_STATES]}
      readOnly={readOnly}
      excludeLabel
      excludeInputGroup
    />
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default ConsequencesMain;
