/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';

const mapStateToProps = (_, ownProps) => {
  const { name, externalAutofillSource, form } = ownProps;
  const { makeFieldIsAutofilledSelector } = modelTypesByForm[form];
  const selectFieldIsAutofilled = makeFieldIsAutofilledSelector();
  return (state) => {
    const autofilled = selectFieldIsAutofilled(state, { name: externalAutofillSource || name });
    return ({
      disabled: autofilled,
    });
  };
};
export default connect(mapStateToProps)(Field);
