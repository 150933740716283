/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import {
  selectModeShapeForDirGroupByMode,
  selectModeShapeFloors,
  selectModeShapeModes,
  selectMaxModeValueForDirection,
} from '#selectors/entities/models.js';
import ModeShapePlot from './ModeShapePlot.jsx';

const mapStateToProps = (state, ownProps) => {
  const modeShapeDataByMode = selectModeShapeForDirGroupByMode(state, ownProps);
  const maxModeValue = selectMaxModeValueForDirection(state, ownProps);
  const floors = selectModeShapeFloors(state, ownProps);
  const modes = selectModeShapeModes(state, ownProps);
  return {
    modeShapeDataByMode,
    maxModeValue,
    floors,
    modes,
  };
};

export default connect(mapStateToProps)(ModeShapePlot);
