// @flow
import type { Element, Node } from 'react';
import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string | null,
  children: Node,
}

const SnippetItem = ({ className = null, children }: Props): Element<'span'> => (
  <span className={classNames(className, 'sub-nav-snippet-item')}>{children}</span>
);

export default SnippetItem;
