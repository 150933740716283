// @flow
import {
  edps as _edps,
  edpsByKey as _edpsByKey,
  edpKeys as _edpKeys,
} from '@hbrisk/sp3-risk-model-support/edps/edps.js';
import type { StructuralResponseMethodId } from '#constants/models/structuralResponseMethods/index.js';

export type EdpLocationType = 'story' | 'floor';

export type EdpKey = 'idr' | 'lbr' | 'oop_drift' | 'oop_force' | 'cr' | 'cbr' | 'ridr' | 'diaph_duct' | 'pfa' | 'pfv';

export type EdpList = Array<EdpKey>;

export type Edp = {|
  locationType: EdpLocationType,
    name: string,
    key: EdpKey,
    isPercent: boolean,
    edpUnit: string,
    excludedMethods?: Array<StructuralResponseMethodId>
|};

type Edps = Array<Edp>;

export const edps: Edps = _edps;

type EdpsByKey = {
  [key: EdpKey]: Edp,
};

type EdpKeys = Array<EdpKey>;

export const edpsByKey: EdpsByKey = _edpsByKey;

export const edpKeys: EdpKeys = _edpKeys;
