// @flow
import type { Element } from 'react';

import { Button } from 'reactstrap';
import React, { Fragment } from 'react';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {
  fields: { push: Function },
  disabled: boolean,
  damageStateIndex: number,
};

const AddConsequenceButton = ({
  fields, disabled, damageStateIndex,
}: Props): Element<typeof Fragment> => (
  <>
    <Button
      size="sm"
      id={`damageState${damageStateIndex}AddConsequenceButton`}
      className="float-right"
      color="primary"
      onClick={
        () => { fields.push({}); }
      }
      disabled={disabled}
    >
      Add Consequence
    </Button>
    {disabled && <Tip className="float-right mr-2" tipId="addConsequenceButton" targetId={`damageState${damageStateIndex}AddConsequenceButtonTipTarget`} />}

  </>
);

export default AddConsequenceButton;
