/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Form,
  FormGroup,
  Row,
  Col,
} from 'reactstrap';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import YearOfConstructionOrCodeYear from '#components/pages/Models/Model/sections/support/forms/YearOfConstructionOrCodeYear/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { BUILDING_DESIGN_SECTION } from '#constants/models/modelPage/sectionIds.js';
import BuildingDetailLevelSelectMenu from './BuildingDetailLevelSelectMenu/index.jsx';
import RiskCategorySelectMenu from './RiskCategorySelectMenu/index.jsx';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
  showRetrofitFields: boolean,
  showRiskCategoryFields: boolean,
  showDriftLimit: boolean,
|};

const BuildingDesignInfoSection = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    onBack,
    onNext,
    showRetrofitFields,
    showRiskCategoryFields,
    showDriftLimit,
  } = props;

  const { isFieldInSection } = modelTypesByForm[form];
  const shouldShowRetrofitInfo = isFieldInSection('includeRetrofit', BUILDING_DESIGN_SECTION);
  return (
    <div>
      <h2>{heading}</h2>
      <Form noValidate>
        <FormGroup tag="fieldset">
          <legend>Building Design Information</legend>
          <FormGroup>
            <Row>
              <Col lg="4">
                Level of Detailing
                {' '}
                <Tip tipId="detailLevel" />
              </Col>
            </Row>
            <GridField
              name="detailLevelDir1"
              form={form}
              options={{
                columnSize: 4,
                columnClass: 'ml-3',
                labelText: 'Direction 1',
                ComponentType: BuildingDetailLevelSelectMenu,
                includeFormGroup: false,
              }}
            />
            <GridField
              name="detailLevelDir2"
              form={form}
              options={{
                columnSize: 4,
                columnClass: 'ml-3',
                labelText: 'Direction 2',
                ComponentType: BuildingDetailLevelSelectMenu,
                includeFormGroup: false,
              }}
            />
          </FormGroup>
          { showRiskCategoryFields && (
            <>
              <GridField
                name="riskCategory"
                form={form}
                options={{
                  columnSize: 4,
                  labelText: 'Risk Category',
                  ComponentType: RiskCategorySelectMenu,
                  includeToolTip: true,
                }}
              />
              <GridField
                name="seismicImportanceFactor"
                form={form}
                options={{
                  ComponentType: 'text',
                  columnSize: 4,
                  labelText: (
                    <span>
                      Seismic Importance Factor, I
                      <small>e</small>
                    </span>),
                  includeToolTip: true,
                }}
              />
              { showDriftLimit && (
                <FormGroup>
                  <Row>
                    <Col lg="4">
                      Drift Limit
                      {' '}
                      <Tip tipId="driftLimit" />
                    </Col>
                  </Row>
                  <GridField
                    name="driftLimitDir1"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Direction 1',
                      ComponentType: 'text',
                      includeFormGroup: false,
                      inputGroup: {
                        addonType: 'append',
                        addonText: '%',
                      },
                    }}
                  />
                  <GridField
                    name="driftLimitDir2"
                    form={form}
                    options={{
                      columnSize: 4,
                      columnClass: 'ml-3',
                      labelText: 'Direction 2',
                      ComponentType: 'text',
                      includeFormGroup: false,
                      inputGroup: {
                        addonType: 'append',
                        addonText: '%',
                      },
                    }}
                  />
                </FormGroup>
              )}
              <GridField
                name="componentImportanceFactor"
                form={form}
                options={{
                  ComponentType: 'text',
                  columnSize: 4,
                  labelText: (
                    <span>
                      Component Importance Factor, I
                      <small>p</small>
                    </span>
                  ),
                  includeToolTip: true,
                }}
              />
            </>
          )}
        </FormGroup>

        {shouldShowRetrofitInfo && (
          <FormGroup tag="fieldset">
            <legend>Retrofit Information</legend>
            <GridField
              name="includeRetrofit"
              form={form}
              options={{
                ComponentType: 'checkbox',
                columnSize: 4,
                labelText: 'Include retrofit',
              }}
            />
            { showRetrofitFields ? (
              <>
                <FormGroup>
                  <Row>
                    <Col lg="5">
                      <h5>Structural Retrofit</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5">
                      <YearOfConstructionOrCodeYear
                        yearOfConstructionName="structuralRetrofitYear"
                        designCodeYearName="structuralRetrofitCodeYear"
                        form={form}
                      />
                    </Col>
                    <Col lg="5">
                      <GridField
                        name="structuralRetrofitLevel"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          labelText: 'Percentage Code Compliance',
                          includeFormatting: false,
                          includeToolTip: true,
                          inputGroup: {
                            addonType: 'append',
                            addonText: '%',
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <h5>Non-Structural Retrofit</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5">
                      <YearOfConstructionOrCodeYear
                        yearOfConstructionName="nonStructuralRetrofitYear"
                        designCodeYearName="nonStructuralRetrofitCodeYear"
                        form={form}
                      />
                    </Col>
                    <Col lg="5">
                      <GridField
                        name="nonStructuralRetrofitLevel"
                        form={form}
                        options={{
                          ComponentType: 'text',
                          labelText: 'Percentage Code Compliance',
                          includeFormatting: false,
                          includeToolTip: true,
                          inputGroup: {
                            addonType: 'append',
                            addonText: '%',
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </>
            ) : null}
          </FormGroup>
        )}
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </Form>
    </div>
  );
};

export default BuildingDesignInfoSection;
