// @flow
import type { Element, Node } from 'React';

import React from 'react';
import { Col } from 'reactstrap';

type columnProps = string
  | number
  | boolean
  | {|
    size: string | number | boolean,
    order: string | number,
    offset: string | number
|};

type Props = {
  children?: Node,
  xs?: columnProps | null,
  sm?: columnProps | null,
  md?: columnProps | null,
  lg?: columnProps | null,
  xl?: columnProps | null,
  visible?: boolean,
}

const FormGroupColumn = ({
  children = null,
  xs = null,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  visible = true,
}: Props): Element<typeof Col> | null => {
  if (visible === false) {
    return null;
  }
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      // widths={widths}
    >
      {children}
    </Col>
  );
};

export default FormGroupColumn;
