/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';
import methods from '#constants/models/modeShapeMethods.js';

type Props = {|
  form: string,
  name: string,
|};

const items = methods.map(({ id, name }) => ({
  id,
  name,
  value: id,
}));

const ModeShapeMethodSelectMenu = ({ form, name }: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
    includeEmptyOption={false}
  />
);

export default ModeShapeMethodSelectMenu;
