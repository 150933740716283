/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

const getDesignCodeYearDescFromDesignCodeYear = (year) => (
  (year >= 2002) ? `ASCE ${year}` : `UBC ${year}`
);

export default getDesignCodeYearDescFromDesignCodeYear;
