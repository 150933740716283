/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

import { countryIsUS } from '#support/models/localeHelpers/index.js';

type Props = {|
  form: string,
  country: string,
  name: string,
|};

const getItems = (country) => {
  const sp3Name = countryIsUS(country) ? 'U.S. Geological Survey' : 'Approximate International Hazard';
  const items = [
    { id: 'usgs', name: sp3Name },
    { id: 'upload', name: 'User Defined Upload' },
  ];
  return items.map(({ id, name }) => ({
    id,
    name,
    value: id,
  }));
};

const GroundMotionMethodSelectMenu = ({
  country,
  form,
  name,
}: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={getItems(country)}
    id={name}
    name={name}
    includeEmptyOption={false}
  />
);

export default GroundMotionMethodSelectMenu;
