/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType, Element } from 'react';

import React, { Component } from 'react';
// eslint-disable-next-line import/extensions
import { FaQuestionCircle } from 'react-icons/fa';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { target } from '#components/support/tooltips/Tip/BaseTip/index.module.scss';

type Props = {|
  className?: string,
  targetId?: string | null,
  tipId: string,
  header: string | ComponentType<any>,
  body: string | ComponentType<any>,
|};

type State = {
  open: boolean,
}

class BaseTip extends Component<Props, State> {
  static defaultProps: {| className: void, targetId: void |} = {
    className: undefined,
    targetId: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggle: (() => void) = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  handleMouseEnter: (() => void) = () => {
    this.setState({ open: true });
  };

  render(): Element<"span"> | null {
    const {
      body,
      className,
      header,
      tipId,
      targetId,
    } = this.props;
    const defaultTargetId = `${tipId}TooltipTarget`;
    const { open } = this.state;
    return body
      ? (
        <span className={className}>
          <FaQuestionCircle
            size="1rem"
            className={target}
            id={targetId || defaultTargetId}
            onMouseEnter={this.handleMouseEnter}
          />
          <Popover
            placement="top"
            // We add the following modifiers to avoid bugs associated with placement flipping in
            // in Reactstrap 8.1
            modifiers={{
              preventOverflow: { boundariesElement: 'viewport' },
              flip: {
                enable: true,
                behavior: ['top', 'bottom', 'top'],
              },
            }}
            trigger="legacy"
            isOpen={open}
            toggle={this.toggle}
            target={targetId || defaultTargetId}
          >
            <PopoverHeader>{header}</PopoverHeader>
            <PopoverBody>
              {typeof body === 'string' ? <p>{body}</p> : body}
            </PopoverBody>
          </Popover>
        </span>
      ) : null;
  }
}

export default BaseTip;
