// @flow
import type { Element } from 'react';

import React from 'react';
// eslint-disable-next-line import/extensions
import { GoCheck, GoCircleSlash, GoSync } from 'react-icons/go';
import { SnippetItem, SnippetItemIcon, SnippetItemText } from '#components/support/layout/SubNav/index.js';

type Props = {
  inProgress: boolean,
  error: typeof Error | null
};

const SaveStatusItem = ({ inProgress, error }: Props): Element<typeof SnippetItem> => {
  const Saving = (
    <SnippetItem className="saving">
      <SnippetItemText>Saving ...</SnippetItemText>
      <SnippetItemIcon icon={GoSync} />
    </SnippetItem>
  );
  const Saved = error instanceof Error
    ? (
      <SnippetItem className="save-failed">
        <SnippetItemText>Save failed</SnippetItemText>
        <SnippetItemIcon className="text-danger" icon={GoCircleSlash} />
      </SnippetItem>
    ) : (
      <SnippetItem>
        <SnippetItemText className="saved">Saved</SnippetItemText>
        <SnippetItemIcon className="text-success" icon={GoCheck} />
      </SnippetItem>
    );
  return inProgress ? Saving : Saved;
};

export default SaveStatusItem;
