/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { createSelector } from 'reselect';
import getOr from 'lodash/fp/getOr.js';
import {
  UUID,
  COMPONENT_ID,
  IS_CUSTOM,
  DAMAGE_STATES,
  DAMAGE_STATE_CONSEQUENCES,
  FORM_INPUTS,
  NAME,
  _FORM_ATTRIBUTES,
  ARCHIVED,
  UPDATED_AT,
  MODEL_COMPONENT_POPULATION_ITEMS,
  RUN_COMPONENT_POPULATION_ITEMS,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import resolveComponentFormInputs
  from '@hbrisk/sp3-risk-model-support/components/form/utility/resolveComponentFormInputs.js';
import { selectModels } from '#selectors/entities/models.js';
import {
  BY_UUID,
  CURRENT,
  LAST_UPDATED,
  TOGGLING_ARCHIVED,
  UPDATING as CURRENT_UPDATING,
  UUID as CURRENT_UUID,
} from '#reducers/entities/components/index.js';
import { COMPONENT_SELECTION_METHOD_CUSTOM } from '#constants/models/componentSelectionMethods.js';

const selectOptions = (_, props = {}) => props.options;

const selectUuid = (_, props) => props[UUID];

const selectFieldSchema = (_, props) => props.fieldSchema;

export const selectComponentLastUpdated = (state) => state.entities.components[LAST_UPDATED];

const selectRawComponentsByUuid = (state) => state.entities.components[BY_UUID];

export const selectCurrentComponentUuid = (
  state
) => state.entities.components[CURRENT][CURRENT_UUID];

export const selectCurrentComponentUpdating = (
  state
) => state.entities.components[CURRENT][CURRENT_UPDATING];

export const selectCurrentComponentTogglingArchived = (
  state
) => state.entities.components[CURRENT][TOGGLING_ARCHIVED];

const selectRawComponentByUuid = createSelector(
  selectRawComponentsByUuid,
  selectUuid,
  (components, uuid) => components[uuid],
);

export const selectComponentsByUuid = createSelector(
  selectRawComponentsByUuid,
  (rawComponents) => {
    if (!rawComponents) {
      return null;
    }
    return Object.keys(rawComponents).reduce((acc, uuid) => {
      const rawComponent = rawComponents[uuid];
      const { [_FORM_ATTRIBUTES]: _formAttributes, ...rest } = rawComponent;
      return ({
        ...acc,
        [uuid]: {
          ...rest,
          ..._formAttributes,
        },
      });
    }, {});
  }
);

export const selectComponents = createSelector(
  selectComponentsByUuid,
  (componentsByUuid) => Object.values(componentsByUuid),
);

export const selectComponentByUuid = createSelector(
  selectUuid,
  selectRawComponentsByUuid,
  selectOptions,
  (uuid, rawComponents, options) => {
    const rawComponent = rawComponents[uuid];
    if (!rawComponent) {
      return null;
    }
    const { [_FORM_ATTRIBUTES]: _formAttributes, ...rest } = rawComponent;
    const component = {
      ...rest,
      ..._formAttributes,
    };
    if (options && options.includeFormInputs) {
      return {
        ...component,
        [FORM_INPUTS]: resolveComponentFormInputs({ values: _formAttributes }),
      };
    }
    return component;
  },
);

export const selectComponentFormInputsByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent) {
      return null;
    }
    const {
      [_FORM_ATTRIBUTES]: _formAttributes,
      [COMPONENT_ID]: componentId,
    } = rawComponent;
    return resolveComponentFormInputs({
      values: {
        ..._formAttributes,
        [COMPONENT_ID]: componentId,
      },
    });
  },
);

export const selectComponentIsReadOnlyByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent) {
      return null;
    }
    const {
      [IS_CUSTOM]: isCustom,
      [ARCHIVED]: archived,
    } = rawComponent;
    return !isCustom || !!archived;
  },
);

export const selectComponentNameByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent) {
      return null;
    }
    const {
      [_FORM_ATTRIBUTES]: _formAttributes,
    } = rawComponent;
    return _formAttributes[NAME];
  },
);

export const selectComponentIdByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent) {
      return null;
    }
    return rawComponent[COMPONENT_ID];
  },
);

export const selectComponentArchivedByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent) {
      return null;
    }
    return rawComponent[ARCHIVED];
  },
);

const getMaxUpdatedAtReducer = (acc, item) => {
  const updatedAt = new Date(item[UPDATED_AT]);
  if (!acc) { return updatedAt; }
  return acc > updatedAt ? acc : updatedAt;
};

export const selectModelsFromComponentByUuid = createSelector(
  selectRawComponentByUuid,
  selectModels,
  (rawComponent, models) => {
    if (!rawComponent) {
      return [];
    }

    const modelPopulationItems = rawComponent[MODEL_COMPONENT_POPULATION_ITEMS];
    const runPopulationItems = rawComponent[RUN_COMPONENT_POPULATION_ITEMS];

    if (!modelPopulationItems || !runPopulationItems) {
      return [];
    }

    const modelItems = rawComponent[MODEL_COMPONENT_POPULATION_ITEMS].map((item) => item.modelId);
    const runItems = rawComponent[RUN_COMPONENT_POPULATION_ITEMS].map((item) => item.runId);
    return models.filter((model) => {
      const { componentPopulationMethod: modelComponentPopulationMethod } = model;
      const runComponentPopulationMethod = getOr(null, 'currentRun.componentPopulationMethod', model);
      return (
        (
          modelComponentPopulationMethod === COMPONENT_SELECTION_METHOD_CUSTOM
          && modelItems.includes(model.uuid)
        )
        || (
          runComponentPopulationMethod !== COMPONENT_SELECTION_METHOD_CUSTOM
            && runItems.includes(model.currentRunId)
        )
      );
    });
  },
);

export const selectComponentMaxUpdatedAtByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent
        || !rawComponent[MODEL_COMPONENT_POPULATION_ITEMS]
        || !rawComponent[RUN_COMPONENT_POPULATION_ITEMS]
    ) {
      return null;
    }
    const maxUpdatedAt = [
      rawComponent,
      ...rawComponent[MODEL_COMPONENT_POPULATION_ITEMS],
      ...rawComponent[RUN_COMPONENT_POPULATION_ITEMS],
    ].reduce(getMaxUpdatedAtReducer, null);
    return maxUpdatedAt.toISOString();
  },
);

export const selectComponentModelPopulationItemsCountByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => getOr(0, `${MODEL_COMPONENT_POPULATION_ITEMS}.length`, rawComponent),
);

export const selectComponentRunPopulationItemsCountByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => getOr(0, `${RUN_COMPONENT_POPULATION_ITEMS}.length`, rawComponent),
);

export const selectComponentInModelsByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent || !Array.isArray(rawComponent[MODEL_COMPONENT_POPULATION_ITEMS])) {
      return null;
    }
    return rawComponent[MODEL_COMPONENT_POPULATION_ITEMS].length > 0;
  },
);

const selectVisibleFieldsByUuid = createSelector(
  selectComponentFormInputsByUuid,
  (formInputs) => (formInputs ? formInputs.visibleFields : null),
);

export const someConsequenceHasVisibleInstancesOfFieldSchemaUuid = createSelector(
  selectFieldSchema,
  selectVisibleFieldsByUuid,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES].some(
        (damageState) => damageState[DAMAGE_STATE_CONSEQUENCES].some(
          (consequence) => consequence[schema.name] === true
        )
      );
    }
    return null;
  },
);

export const someDamageStateHasVisibleInstancesOfFieldSchemaUuid = createSelector(
  selectFieldSchema,
  selectVisibleFieldsByUuid,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES]
        .some((damageState) => damageState[schema.name] === true);
    }
    return null;
  }
);
