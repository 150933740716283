import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import ModelUsageTable from '#components/pages/Components/Component/sections/ModelUsage/ModelUsageTable/ModelUsageTable.jsx';
import { selectComponentModelsWithUsageWarnings } from '#selectors/ui/componentDetailView.js';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  return ({
    models: selectComponentModelsWithUsageWarnings(state, { [UUID]: componentUuid }),
  });
};

export default connect(mapStateToProps)(ModelUsageTable);
